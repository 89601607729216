import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Accordion, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaUniversity } from 'react-icons/fa';
import { MdSportsCricket, MdSportsGolf, MdSportsHandball, MdSportsRugby } from 'react-icons/md';
import { GrLineChart } from 'react-icons/gr';
import { GiUnlitBomb, GiDiceFire, GiCardJackHearts, GiShipWheel, GiCartwheel } from 'react-icons/gi';
import { GiThreeBurningBalls, GiBaseballGlove, GiBoxingGlove, GiBoxingGloveSurprise, GiCardKingClubs, GiHockey, GiSoccerBall, GiVolleyballBal } from 'react-icons/gi';
import { IoMdAmericanFootball, IoMdBasketball, IoMdCash, IoMdTennisball, IoMdLogOut, IoMdPerson, IoMdGrid, IoMdList, IoIosList, IoMdStar } from 'react-icons/io';
import LoadingScreen from '../pages/LoadingScreen';
import iconRoulette from '../assets/images/roulette/roulette.png'
import iconBlackJack from '../assets/images/blackjack/blackjack.svg'
import { TbMultiplier15X } from 'react-icons/tb';

const Dashboard = ({ children }) => {
    const [user, setUser] = useState({ username: localStorage.getItem("username"), balance: localStorage.getItem("balance"), api: localStorage.getItem("api"), casino: localStorage.getItem("casino") });
    const [expanded, setExpanded] = useState(false);
    const [login_status, setLoginStatus] = useState(0);
    function hideRightBar() {
        setExpanded(false);
        document.body.classList.remove('right_bar');
    }

    function showRightBar() {
        document.body.classList.add('right_bar');
        setExpanded("expanded");
    }


    function hideLeftBar() {
        document.body.classList.remove('left_bar');
    }

    function showLeftBar() {
        document.body.classList.add('left_bar');
    }

    useEffect(() => {
        function handleStorage() {
            setUser({
                username: localStorage.getItem("username"),
                balance: localStorage.getItem("balance"),
                api: localStorage.getItem("api"),
                casino: localStorage.getItem("casino"),
            });
        }

        window.addEventListener('storage', handleStorage)
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener('storage', handleStorage)
        };
    });

    return (
        <>{login_status === 1 ?
            <>
                <div className='leftbaroverlay' onClick={hideLeftBar}></div>
                <div className='topbar'>
                    <div className='rightbaroverlay' onClick={hideRightBar}></div>
                    <Container fluid className="full-height">
                        <Row className="align-items-center full-height">
                            <Col xs={3} md={3} className="d-lg-none"><Button variant='dark' className='btn-menu' onClick={showLeftBar}><IoIosList /></Button></Col>
                            <Col xs={6} md={6} lg={2} className="logo">
                                Tutoring 89
                            </Col>
                            <Col xs={3} md={3} lg={10} className="top-menu">
                                <Navbar expand="lg" variant="dark" expanded={expanded}>
                                    <Container>
                                        <Navbar.Toggle aria-controls="navbar-nav" onClick={showRightBar} />
                                        <Navbar.Collapse id="navbar-nav">
                                            <Nav className="me-auto">
                                                <div className="user-balance d-lg-none"><IoMdPerson /> {user.username} <small>Balance: ${user.balance}</small></div>
                                                <NavLink exact to="/" className="nav-link" onClick={hideRightBar}>Dashboard</NavLink>
                                                <NavLink exact to="/history" className="nav-link" onClick={hideRightBar}>History</NavLink>
                                                <NavLink exact to="/transactions" className="nav-link" onClick={hideRightBar}>Transactions</NavLink>
                                                <NavLink exact to="/balance-sheet" className="nav-link" onClick={hideRightBar}>Balance Sheet</NavLink>
                                                <NavLink to="/logout" className="nav-link d-lg-none" onClick={hideRightBar}>Logout</NavLink>
                                            </Nav>
                                            <Nav className="ms-auto d-none d-lg-block">
                                                <NavDropdown title={<div className="user-balance"><IoMdPerson /> {user.username}<small>Balance: ${user.balance}</small></div>} id="nav-dropdown">
                                                    <NavLink to="/logout" className="dropdown-item"><IoMdLogOut /> Logout</NavLink>
                                                </NavDropdown>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='sidebar'>
                    <Accordion defaultActiveKey={['0', '1']}>
                        { user.casino == '0' ? "" :
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Casino</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><NavLink to="/casino/roulette" onClick={hideLeftBar}><GiCartwheel color='black' className='bg-warning p-2 rounded-circle' /> Roulette</NavLink></li>
                                    <li><NavLink to="/casino/blackjack" onClick={hideLeftBar}><GiCardJackHearts color='black' className='bg-warning p-2 rounded-circle' /> Black Jack</NavLink></li>
                                    <li><NavLink to="/casino/mines" onClick={hideLeftBar}><GiUnlitBomb color='black' className='bg-warning p-2 rounded-circle' /> Mines</NavLink></li>
                                    
                                    {user.username === 'donkeytest' || user.username === 'kattest' ?
                                        <>
                                            <li><NavLink to="/casino/crash" onClick={hideLeftBar}><GrLineChart className='bg-warning p-2 rounded-circle' /> Crash</NavLink></li>
                                            <li><NavLink to="/casino/plinko" onClick={hideLeftBar}><GiThreeBurningBalls color='black' className='bg-warning p-2 rounded-circle' /> Plinko</NavLink></li>
                                            <li><NavLink to="/casino/dice" onClick={hideLeftBar}><GiDiceFire color='black' className='bg-warning p-2 rounded-circle' /> Dice</NavLink></li>
                                            <li><NavLink to="/casino/wheel" onClick={hideLeftBar}><GiCartwheel color='black' className='bg-warning p-2 rounded-circle' /> Wheel </NavLink></li>
                                            <li><NavLink to="/casino/limbo" onClick={hideLeftBar}><TbMultiplier15X color='black' className='bg-warning p-2 rounded-circle' /> Limbo </NavLink></li>
                                        </>
                                        : ""}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item> 
                        }

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Sports</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <>
                                        {/*<li><NavLink to="/sports/special" onClick={hideLeftBar}><IoMdStar /> The Masters</NavLink></li>*/}
                                        <li><NavLink to="/sports/basketball" onClick={hideLeftBar}><IoMdBasketball /> Basketball</NavLink></li>
                                        <li><NavLink to="/sports/football" onClick={hideLeftBar}><IoMdAmericanFootball /> Football</NavLink></li>
                                        <li><NavLink to="/sports/soccer" onClick={hideLeftBar}><GiSoccerBall /> Soccer</NavLink></li>
                                        {/*<li><NavLink to="/sports/tennis" onClick={hideLeftBar}><IoMdTennisball /> Tennis</NavLink></li>
                                            <li><NavLink to="/sports/volleyball" onClick={hideLeftBar}><GiVolleyballBall /> Volleyball</NavLink></li>*/}
                                        <li><NavLink to="/sports/baseball" onClick={hideLeftBar}><GiBaseballGlove /> Baseball</NavLink></li>
                                        {/*<li><NavLink to="/sports/handball" onClick={hideLeftBar}><MdSportsHandball /> Handball</NavLink></li>*/}
                                        <li><NavLink to="/sports/hockey" onClick={hideLeftBar}><GiHockey /> Hockey</NavLink></li>
                                        <li><NavLink to="/sports/MMA" onClick={hideLeftBar}><GiBoxingGlove /> MMA</NavLink></li>
                                    </>
                                        
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </> : <LoadingScreen />}
            {React.cloneElement(children, { setLoginStatus: setLoginStatus })}
        </>
    )
}

export default Dashboard;