import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Form, Button, Alert, Modal } from 'react-bootstrap';



let source = axios.CancelToken.source();
class Users extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        login_status: 1,
        data: [{}],
        user: [],
        loading: false,
        mdlDelete: false,
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        const uid = this.props.match.params.uid;
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/users/details', {
            params: { id: id, uid: uid },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {
                    this.setState({
                        login_status: 1,
                        user: response.user,
                        loading: false,
                        data: response.data
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    handleChange = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'bookie_id') {
            const selectedIndex = event.target.selectedIndex;
            const manager_id = event.target.options[selectedIndex].getAttribute('mid');
            this.setState({
                manager_id: manager_id
            });
        }
        this.setState({
            [name]: value
        });

    }


    handleSave = (event) => {

        const type = this.props.match.params.type;
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/users/add', {
            id: id,
            token: token,
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            weekly_balance: this.state.weekly_balance,
            username: this.state.username,
            password: this.state.password,
            manager_id: this.state.manager_id,
            bookie_id: this.state.bookie_id,
            type: type
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg,
                    name: "",
                    email: "",
                    contact: "",
                    username: "",
                    password: "",
                    weekly_balance: ""
                });

            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    showmdl = (name) => {
        this.setState({
            [name]: true,
            mdl_error: false,
            mdl_error_msg: ""
        })
    }

    hidemdl = (name) => {
        this.setState({
            [name]: false,
            mdl_error: false,
            mdl_error_msg: ""
        })
    }


    mdlMoney = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let amount = event.target.amount.value;

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        const uid = this.props.match.params.uid;
        axios.get('/api/admin/users/add-money', {
            params: { id: id, uid: uid, amount: amount },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            this.get_data();
            this.setState({
                mdl_error: false,
                mdl_error_msg: "",
                mdl_btn_disable: false
            });
            this.hidemdl("mdlMoney");
        }).catch((e) => {
            //yconsole.log(e.message);
        });


    }


    mdlPaid = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let amount = event.target.amount.value;

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        const uid = this.props.match.params.uid;
        axios.get('/api/admin/users/paid-money', {
            params: { id: id, uid: uid, amount: amount },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            this.get_data();
            this.setState({
                mdl_error: false,
                mdl_error_msg: "",
                mdl_btn_disable: false
            });
            this.hidemdl("mdlPaid");
        }).catch((e) => {
            //yconsole.log(e.message);
        });


    }


    mdlPassword = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let password = event.target.password.value;
        let cpassword = event.target.cpassword.value;

        if (password === cpassword) {
            const token = localStorage.getItem("admin_token");
            const id = localStorage.getItem("admin_id");
            const uid = this.props.match.params.uid;
            axios.get('/api/admin/users/change-password', {
                params: { id: id, uid: uid, password: password },
                headers: { token: token },
                cancelToken: source.token
            }).then((res) => {
                this.get_data();
                this.setState({
                    mdl_error: false,
                    mdl_error_msg: "",
                    mdl_btn_disable: false
                });
                this.hidemdl("mdlPassword");
            }).catch((e) => {
                //yconsole.log(e.message);
            });
        } else {
            this.setState({
                mdl_error: true,
                mdl_error_msg: "Your password didn't match",
                mdl_btn_disable: false
            });
        }

    }


    mdlAccount = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let weekly_balance = event.target.weekly_balance.value;
        let freebet_balance = event.target.freebet_balance.value;
        let acc_status = event.target.acc_status.value;
        let casino = event.target.casino.value;
        let name = event.target.name.value;
        let email = event.target.email.value;
        let contact = event.target.contact.value;

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        const uid = this.props.match.params.uid;
        axios.get('/api/admin/users/update', {
            params: { id: id, uid: uid, weekly_balance: weekly_balance, acc_status: acc_status, name: name, email: email, contact: contact, freebet_balance: freebet_balance, casino:casino },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            this.get_data();
            this.setState({
                mdl_error: false,
                mdl_error_msg: "",
                mdl_btn_disable: false
            });
            this.hidemdl("mdlAccount");
        }).catch((e) => {
            //yconsole.log(e.message);
        });

    }


    mdlDelete = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        var del = event.target.delete.value;
        if (del === 'delete') {
            this.hidemdl("mdlDelete");
            const token = localStorage.getItem("admin_token");
            const id = localStorage.getItem("admin_id");
            const uid = this.props.match.params.uid;
            axios.get('/api/admin/users/delete', {
                params: { id: id, uid: uid },
                headers: { token: token },
                cancelToken: source.token
            }).then((res) => {

            }).catch((e) => {
                //yconsole.log(e.message);
            });

            this.props.history.push("/admin/users");
        } else {
            this.setState({
                mdl_error: true,
                mdl_error_msg: "Type in correct `delete`",
                mdl_btn_disable: false
            });
        }
    }


    UNSAFE_componentWillMount() {
        this.get_data();
    }

    render() {
        if (this.props.login_status === 0) return (<></>);

        return (
            <>
                {this.state.loading ? <div className="loading mt-60">Loading...</div> :
                    <>
                        <Container fluid className='mb-20 mt-60'>
                            <Row>
                                <Col md={9}>
                                    <Form.Group className="mb-20">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" value={this.state.data.username} readOnly />
                                        <Form.Text className="text-muted">
                                            {this.state.data.manager_username} {">"} {this.state.data.bookie_username}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Current balance</Form.Label>
                                        <Form.Control type="text" value={"$" + this.state.data.balance} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Free bet balance</Form.Label>
                                        <Form.Control type="text" value={"$" + this.state.data.freebet_balance} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Weekly balance</Form.Label>
                                        <Form.Control type="text" value={"$" + this.state.data.weekly_balance} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" value={this.state.data.name} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" value={this.state.data.email} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Contact #</Form.Label>
                                        <Form.Control type="text" value={this.state.data.contact} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Account Status</Form.Label>
                                        <Form.Control type="text" value={this.state.data.acc_status === 1 ? "Active" : "Suspended"} readOnly />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Casino</Form.Label>
                                        <Form.Control type="text" value={this.state.data.casino === 1 ? "On" : "Off"} readOnly />
                                    </Form.Group>
                                </Col>

                                <Col md={3}>
                                    <Button className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlMoney")}>Add money</Button>
                                    {this.state.user.type <= 2 ? <Button className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlPaid")}>Player Paid</Button> : ""}
                                    <Button className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlPassword")}>Change password</Button>
                                    <Button className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlAccount")}>Update user details</Button>
                                    <Button variant='danger' className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlDelete")}>Delete user</Button>
                                </Col>
                            </Row>
                        </Container>


                        <Modal show={this.state.mdlMoney} onHide={() => this.hidemdl("mdlMoney")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Form onSubmit={this.mdlMoney}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add money</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

                                    <Form.Group className="mb-20">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control type="number" name="amount" required />
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.hidemdl("mdlMoney")}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>


                        <Modal show={this.state.mdlPaid} onHide={() => this.hidemdl("mdlPaid")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Form onSubmit={this.mdlPaid}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Player paid</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

                                    <Form.Group className="mb-20">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control type="number" name="amount" required />
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.hidemdl("mdlPaid")}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>


                        <Modal show={this.state.mdlPassword} onHide={() => this.hidemdl("mdlPassword")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Form onSubmit={this.mdlPassword}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Password</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

                                    <Form.Group className="mb-20">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="text" name="password" required />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="text" name="cpassword" required />
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.hidemdl("mdlPassword")}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>


                        <Modal show={this.state.mdlAccount} onHide={() => this.hidemdl("mdlAccount")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Form onSubmit={this.mdlAccount}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Account</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

                                    <Form.Group className="mb-20">
                                        <Form.Label>Weekly Balance</Form.Label>
                                        <Form.Control type="number" name="weekly_balance" defaultValue={this.state.data.weekly_balance} required />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Free bet balance</Form.Label>
                                        <Form.Control type="number" name="freebet_balance" defaultValue={this.state.data.freebet_balance} />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Account Status</Form.Label>
                                        <select className='form-control text-white' name="acc_status" defaultValue={this.state.data.acc_status} required>
                                            <option value="1">Active</option>
                                            <option value="0">Suspended</option>
                                        </select>
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Casino</Form.Label>
                                        <select className='form-control text-white' name="casino" defaultValue={this.state.data.casino} >
                                            <option value="1">On</option>
                                            <option value="0">Off</option>
                                        </select>
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" defaultValue={this.state.data.name} />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" name="email" defaultValue={this.state.data.email} />
                                    </Form.Group>

                                    <Form.Group className="mb-20">
                                        <Form.Label>Contact #</Form.Label>
                                        <Form.Control type="text" name="contact" defaultValue={this.state.data.contact} />
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.hidemdl("mdlAccount")}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>


                        <Modal show={this.state.mdlDelete} onHide={() => this.hidemdl("mdlDelete")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Form onSubmit={this.mdlDelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete User</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}
                                    <Form.Group className="mb-20">
                                        <Form.Label>Type in "delete" to remove this user.</Form.Label>
                                        <Form.Control type="text" name="delete" required="true" />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.hidemdl("mdlDelete")}>
                                        Close
                                    </Button>
                                    <Button variant="danger" type="submit">
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </>
                }
            </>
        );
    }
}

export default withRouter(Users);