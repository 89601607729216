import React, { Component} from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Form, Alert} from 'react-bootstrap';

class Login extends Component {
    state = {
        loading:true,
        disabled:false,
        layout:0,
        user : {},
        error_code:0,
        error_msg:"",
        username:"",
        password:""
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    

    Login = (event) => {
        event.preventDefault();
        this.setState({
            error_code:0,
            error_msg:"",
            disabled:true
        });

        axios.post('/api/login', { 
            username:this.state.username,
            password:this.state.password
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('username', response.data.username);
                localStorage.setItem('balance', response.data.balance);
                localStorage.setItem('api', response.api);
                localStorage.setItem('casino', response.data.casino);
                
                this.props.history.push("/");
            } else {
                this.setState({
                    error_code:response.error_code,
                    error_msg:response.error_msg,
                    disabled : false
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    componentWillMount(){
        this.setState({
            error_code:0,
            error_msg:""
        });
    }


    render() {
        return(
            <>
                <Container fluid className="h-100">
                    <Row className="h-100 align-items-center justify-content-center">
                        <Col xl={4} lg={5} md={8}>
                            <Card className='bg-s-primary'>
                                <Card.Body className="p-50">

                                    <Card.Title>TUTORING 89</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">Login</Card.Subtitle>

                                    {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}

                                    <Form className='onboarding' onSubmit={this.Login}>
                                        <Form.Group className="mb-20" controlId="formBasicEmail">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" name="username" required={true} value={this.state.username} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20" controlId="formBasicPassword">
                                            <Form.Label className="w-100">Password</Form.Label>
                                            <Form.Control type="password" name="password" required={true}  onChange={this.handleChange} />
                                        </Form.Group>
                                       
                                       <div className='d-grid gap-2'>
                                            <Button className="mb-20" variant="warning"  type="submit" disabled={this.state.disabled}>
                                                Sign in
                                            </Button>
                                       </div>
                                        
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default withRouter(Login);