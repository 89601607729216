import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';

var dateFormat = require("dateformat");

createTheme('dark_s', {
    text: {
      primary: '#fff',
      secondary: '#aaa',
    },
    background: {
      default: 'transparent',
    },
    context: {
      background: '#091929',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(255,255,255,.54)',
      hover: 'rgba(255,255,255,.08)',
      disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

const arraytorow = (data) => {
    return data.map((row) => <p>{row}</p>);
}

const columns = [
    {
        name: 'Date',
        selector: 'created_on',
        sortable: false,
        left: true,
        cell: row => <div data-tag="allowRowEvents">{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""}</div>,
    },
    {
        name: 'Type',
        selector: 'amount',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.amount>0 ? "Cash Out" : "Cash In"}</div>,
    },
    {
        name: 'Amount',
        selector: 'amount',
        sortable: false,
        right: true,
        cell: row =><div data-tag="allowRowEvents">${row.amount}</div>,
    }
];

let source = axios.CancelToken.source();
class Transaction extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }


    state = {
        data:[{}],
        user:[],
        loading:false,
        page:1,
        login_status: 0
    };

    
    setPage = (page) => {
        this.setState({page:page});
    }

    get_data = () => {
        const token = localStorage.getItem("token");
        if(!token){this.props.history.push("/login");}
        const id = localStorage.getItem("id");
        this.setState({data:[{}], loading:true});
        axios.get('/api/transactions', {
            params:{id:id, page:this.state.page},
            headers:{token: token},
            cancelToken: source.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                
                this.setState({
                    login_status:1,
                    user:response.user,
                    data:response.data,
                    total:response.total,
                    loading:false
                });
                localStorage.setItem('balance', response.user.balance);
                localStorage.setItem('casino', response.data.casino);
                window.dispatchEvent(new Event('storage'));
             
                if(this.props.login_status !== 1) 
                    this.props.setLoginStatus(1);
            }
        })
        .catch((e) => {
            //console.log(e.message);
        });
    }

    componentDidMount(){
        let upage = this.props.match.params.page;
        if(!upage){ upage=1;}
        this.setState({page:upage});
        this.get_data();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.get_data();
        }
    }
    
    render () {
        if(this.state.login_status === 0) return ( <></> );
        
        return(
            <>
                <div className='main-content'>
                    <Container fluid className='mb-20'>
                        <Row>
                            <Col className='billing col-lg-6'>
                                <Card body className='bg-s-secondary datatable'>
                                    {this.state.loading ? <div className="loading">Loading...</div> : 
                                        <DataTable
                                            title="Transactions"
                                            columns={columns}
                                            data={this.state.data}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                            pagination={true}
                                            paginationServer={true}
                                            page={this.state.page}
                                            paginationTotalRows={this.state.total}
                                            paginationPerPage={100}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => this.setPage(page)}
                                            paginationDefaultPage={this.state.page}
                                        />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default withRouter(Transaction);