import React, { useState, useRef, useLayoutEffect } from "react";
import { withRouter } from 'react-router-dom';
import './mines.css';
import diamond from './diamond.png'
import bomb from './bomb.png';
import { Container, Row, Col, Card, Form, Button, Alert, InputGroup } from "react-bootstrap";
import axios from "axios";

/**
 * When authentication succeeds game is loaded with its grid and Form to customize the game.
 * User can choose the amount he is betting based on its current balance and a maximum of $100
 * When User place a bet to start the game, the state changes to playing and game logic is applied
 * User lose when opens a bomb meaning he lose his bet, 
 * otherwise user can keep playing or cash out his current winnings.
 * In both cases a call to API is made to update user's balance and insert a new bet entry with the recently played information.
 * After updates has been made user can run another instance of the game. 
 */

class Mines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_status: 0,
      user: [],
      show_result: false,
      result_msg: '',
      bet_error: false,
      bet_error_msg: '',
      status: '',
      has_profits: false,
      has_lost: false,
      bet_amount: 0,
      mines_amount: 3,
      gems_amount: 22,
      gems_uncovered: 22,
      multiplier: 1,
      win: 0,
      is_updating: false,
      total_win: 0,
      max_multiplier: 1,
      grid: new Array(25).fill({ open: false, content: '' })
    }
  }

  componentDidMount() {
    this.verify_token();
    // window.addEventListener('beforeunload', e => {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   e.returnValue = '';
    //   this.updateBetResult();
    // })
  }

  verify_token = () => {
    const token = localStorage.getItem("token");
    axios.get('/api/casino/mines-auth', {
      headers: { token: token }
    })
      .then((res) => {
        const response = res.data;

        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          let curr_grid = new Array(25).fill({ open: false, content: '' });

          if (response.open_squares) {
            response.open_squares.forEach(element => {
              curr_grid[element.index] = {open: true, content: 'gem'}
            });
          }
          
          this.setState({
            user: response.data,
            login_status: 1,
            status: response.status,
            multiplier: response.multiplier,
            mines_amount: response.mines_amount,
            gems_uncovered: response.gems_uncovered,
            win: response.win,
            open_squares: response.open_squares,
            grid: curr_grid,
            bet_amount: response.bet_amount,
            has_profits: false
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          localStorage.setItem('balance', response.data.balance);
          window.dispatchEvent(new Event('storage'));

        }
      })
      .catch((e) => {
        //console.log(e)
        //yconsole.log(e.message);
      });
  }

  handleStart = () => {
    if (this.state.bet_error) return;
    let token = localStorage.getItem('token');
    let id = this.state.user.id;

    let betdata = {
      bet_amount: parseFloat(this.state.bet_amount),
      mines_amount: this.state.mines_amount
    }
    this.setState({
      grid: new Array(25).fill({open: false, content: ''}),
      has_profits: false,
      is_updating: true
    })

    axios.post('/api/casino/mines-bet', {
      id: id,
      betdata: betdata
    },
      { headers: { token: token } }
    )
      .then(res => {
        const response = res.data;

        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          this.setState({
            error_code: 0,
            error_msg: "",
            status: response.status,
            win: response.win,
            multiplier: response.multiplier,
            gems_uncovered: response.gems_amount,
            gems_amount: response.gems_amount,
            is_updating: false
          })

          localStorage.setItem('balance', response.balance);
          window.dispatchEvent(new Event('storage'));
        } else {
          this.setState({
            error_code: 1,
            error_msg: response.error_msg,
            bet_amount: 0,
            status: 'not started',
            is_updating: false
          })

          setTimeout( () => {
            this.setState({
              error_code: 0,
              error_msg: ''
            })
          }, 8000);
        }
      })
      .catch(err => {
        //console.log(err);
      })
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === 'mines_amount') {
      this.setState({
        [name]: value,
        gems_amount: 25 - value,

      });
      return;
    }

    if (name === 'bet_amount' && value > 100) {
      this.setState({
        bet_error: true,
        bet_error_msg: "Maximum allowed is $100"
      })
      return;
    }

    this.setState({
      [name]: value,
      bet_error: false
    });
  }

  handleLost = (restart) => {
    this.setState({
      has_lost: true,
      has_won: false,
      restart: restart,
      win: 0
    })
  }

  handleWin = () => {
    this.setState({
      has_won: true,
      has_lost: false,
      bet: false
    })
  }

  handleCashOut = () => {
    let token = localStorage.getItem('token');
    
    axios.post('/api/casino/mines-cashout', {
      id: this.state.user.id
    }, { headers: { token: token } }
    )
      .then(res => {
        let response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          let new_grid = this.state.grid.slice()

          this.setState({
            error_code: 0,
            status: response.status,
            bet_amount: response.bet_amount,
            win: response.win,
            total_win: response.win.toFixed(2),
            multiplier: response.multiplier,
            gems_uncovered: response.gems_uncovered,
            grid: new_grid,
            has_profits: true
          })

          localStorage.setItem('balance', response.balance);
          window.dispatchEvent(new Event('storage'));
        }
      })
  }

  openMine = (index) => {
    let token = localStorage.getItem('token');
    
    axios.post('/api/casino/mines-open-square', {
      id: this.state.user.id,
      play_data: { square_index: index }
    }, { headers: { token: token } }
    )
      .then(res => {
        let response = res.data;
        
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          let new_grid = this.state.grid.slice();

          if (response.status === 'playing') {
            new_grid[index] = { open: true, content: 'gem' }
          } else {
            new_grid = response.game_data.squares;
          }

          this.setState({
            error_code: 0,
            status: response.status,
            bet_amount: response.bet_amount,
            win: response.win,
            multiplier: response.multiplier,
            gems_uncovered: response.gems_uncovered,
            grid: new_grid

          })
        }
      })
      .catch(err => {})
  }

  render() {

    if (this.state.login_status === 0) return (<></>);

    return (
      <>
        <div className="main-content">
          <Container className="bet-lines">
            <Card className="bg-s-dark">
              <Card.Header><h1>Mines</h1></Card.Header>
              <Card.Body className="body-game bg-s-secondary">
                {this.state.show_result ? <Alert variant='success'>{this.state.result_msg}</Alert> : ''}
                {this.state.bet_error ? <Alert variant='success'>{this.state.bet_error_msg}</Alert> : ''}
                {this.state.error_code === 1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ''}
                <Row>
                  <Col className="mb-15">
                    <MinesGame
                      grid={this.state.grid}
                      has_profits={this.state.has_profits}
                      profit={{
                        total_win: this.state.total_win,
                        multiplier: this.state.multiplier
                      }}
                      has_finished={this.state.status === 'game over'}
                      openMine={this.openMine}
                      is_playing={this.state.status === 'playing'} 
                    />
                  </Col>
                  <Col className="mines-config" md={4} >
                    <Form.Group className="mb-10">
                      <Form.Label className="text-start">Bet: </Form.Label>
                      <InputGroup>
                        <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
                      {this.state.status === 'playing'
                        ? <Form.Control className="form-control text-white" name="bet_amount" type="number" step="0.001" value={this.state.bet_amount} onChange={this.handleChange} disabled />
                        : <Form.Control className="form-control text-white" name="bet_amount" type="number" step="0.001" defaultValue={0.0} onChange={this.handleChange} />
                      }
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-15">
                      <Form.Label className="text-start">Mines: </Form.Label>

                      {this.state.status === 'playing'
                        ? <>
                          <Form.Control className="form-control text-white" name="mines_amount" type="number" step="0.001" value={this.state.mines_amount} disabled />
                          <Form.Label className="text-start">Gems:</Form.Label>
                          <Form.Control type="text" value={this.state.gems_uncovered} readOnly />
                        </>
                        : 
                        
                        <>
                          <select name="mines_amount" className='form-control text-white' value={this.state.mines_amount} onChange={this.handleChange} >
                            {new Array(4).fill(3).map((amount, index) => (
                              <option key={index + amount} value={index + amount}>{index + amount}</option>
                            ))}
                          </select>
                        </>

                      }
                    </Form.Group>
                    {
                      this.state.status === 'playing' ?
                        <>
                          <Form.Group className="mb-15">
                            <Form.Label className="text-start">
                              Total Win:
                              <span className="multiplier float-end">x{this.state.multiplier}</span>
                            </Form.Label>
                            <Form.Control type="text"
                              value={this.state.win} readOnly />
                          </Form.Group>
                        </>
                        : ''
                    }
                    <button
                      disabled={this.state.is_updating}
                      onClick={this.state.status === 'playing' ? this.handleCashOut : this.handleStart} className='make-bet-button text-white d-block w-100 p-5 mb-10'>
                      {this.state.status === 'playing' ? 'Cash Out' : 'Bet'}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}

function MinesGame({ grid, openMine, is_playing, has_finished, has_profits, profit }) {
  
  return (
    <>
      <div className="game-container bg-s-dark">
        <GridContainer>
          {grid.map((mine, index) => (
            <MineButton key={index}
              has_finished={has_finished}
              open={mine.open}
              content={mine.content}
              setOpen={is_playing ? () => openMine(index) : () => { }}
            />
          ))
          }
          {has_finished && has_profits ?
            <div className="profit-card">
              <div className="profit-multiplier">x{profit.multiplier}</div>
              <div className="profit-win">${profit.total_win}</div>
            </div>
            : ''}
        </GridContainer>
      </div>
    </>
  );

}

function GridContainer({ children }) {
  const [size, setSize] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    let width = ref.current.clientWidth;
    let height = 0.8 * window.innerHeight;
    let size = Math.min(width, height);
    ref.current.style.width = size + 'px';
    ref.current.style.height = size + 'px';
    setSize(size);
  }, [])

  return (
    <>
      <div ref={ref} className="mines">
        {children}
      </div>
    </>
  )
}


function MineButton({ content, open, setOpen, has_finished }) {

  function handleClick() {
    setOpen();
  }

  const getContentImg = (content) => {
    if(content === 'gem' ) return <img src={diamond} alt='Gem' />
    if(content === 'bomb') return <img src={bomb} alt='Bomb' />
  }

  return (
    <>
      <div className={"mine-container " + (open ? 'open-button' : '')}>
        <button className={"mine-button " + (open ? 'open-button' : '')} onClick={handleClick} disabled={open}>
          {open || has_finished ? getContentImg(content) : ''}
          <div className={'trasparent-div ' + (!open && has_finished ? 'not-opened-square' : '')}></div>
        </button>
      </div>
    </>
  )
}
export default withRouter(Mines)