import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './assets/pd-mr.css';
import './assets/App.css';

import ScrollToTop from './components/ScrollToTop'

/* Landing Page */

import Dashboard from './wrapper/dashboard';
import Home from './pages/Home';
import History from './pages/History';
import Transaction from './pages/Transaction';
import Sports from './pages/Sports';
import Logout from './pages/Logout'
import Login from './pages/Login';

/* Admin Page */
import Admin from './wrapper/admin';
import AdminHome from './pages/admin/Home';
import AdminUsers from './pages/admin/Users';
import AdminUsersAdd from './pages/admin/UsersAdd';
import AdminUsersDetails from './pages/admin/UsersDetails';
import AdminAccounts from './pages/admin/Accounts';
import AdminGrading from './pages/admin/Grading';
import AdminHistory from './pages/admin/History';
import AdminTransactions from './pages/admin/Transactions';
import AdminSetting from './pages/admin/Setting';
import AdminKrank from './pages/admin/Krank';
import AdminBalance from './pages/admin/BalanceSheet';
import AdminLogin from './pages/admin/Login';
import AdminLogout from './pages/admin/Logout';
import AdminDetails from './pages/admin/AdminDetails';
import ChargeHistory from './pages/admin/ChargeHistory';
import AddCustomBet from './pages/admin/AddCustomBet';
import ManualBet from './pages/admin/ManualBet';
/* Casino */

/* Roulette */
import Roulette from './casino/roulette/roulette';
import Crash from './casino/crash/crash';
import BlackJack from './casino/blackjack/blackjack';
import Mines from './casino/mines/mines';
import Plinko from './casino/plinko/plinko';
import Dice from './casino/dice/dice';
import Wheel from './casino/wheel/wheel';
import Limbo from './casino/limbo/limbo';

import BalanceSheet from './pages/BalanceSheet';
import CustomBets from './pages/admin/CustomBets';
import EditCustomBet from './pages/admin/EditCustomBet';
import AdminList from './pages/admin/AdminList';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {/*Landing Page */}
          <Route exact path="/"><Dashboard><Home /></Dashboard></Route>
          <Route path="/history"><Dashboard><History /></Dashboard></Route>
          <Route path="/transactions"><Dashboard><Transaction /></Dashboard></Route>
          <Route path="/sports/:sport/:league?/:event_id?"><Dashboard><Sports /></Dashboard></Route>
          <Route path="/login"><Login /></Route>
          <Route path="/logout"><Logout /></Route>
          <Route path="/balance-sheet"><Dashboard><BalanceSheet /></Dashboard></Route>

          {/*Admin Pages*/}
          <Route exact path="/admin"><Admin><AdminHome /></Admin></Route>
          <Route exact path="/admin/users"><Admin><AdminUsers /></Admin></Route>
          <Route exact path="/admin/accounts"><Admin><AdminAccounts /></Admin></Route>
          <Route exact path="/admin/users/add/:type?"><Admin><AdminUsersAdd /></Admin></Route>
          <Route exact path="/admin/users/details/:uid"><Admin><AdminUsersDetails /></Admin></Route>
          <Route exact path="/admin/adminusers/:type"><Admin><AdminList /></Admin></Route>
          <Route exact path="/admin/adminusers/details/:aid"><Admin><AdminDetails /></Admin></Route>
          <Route exact path="/admin/grading"><Admin><AdminGrading /></Admin></Route>
          <Route exact path="/admin/history/:mid?/:bid?/:uid?"><Admin><AdminHistory /></Admin></Route>
          <Route exact path="/admin/transactions"><Admin><AdminTransactions /></Admin></Route>
          <Route exact path="/admin/balance-sheet"><Admin><AdminBalance /></Admin></Route>
          <Route exact path="/admin/charge-history"><Admin><ChargeHistory /></Admin></Route>
          <Route exact path="/admin/custom-bets/add-custom-bet"><Admin><AddCustomBet /></Admin></Route>
          <Route exact path="/admin/custom-bets"><Admin><CustomBets /></Admin></Route>
          <Route exact path="/admin/custom-bets/edit-custom-bet/:cbid"><Admin><EditCustomBet /></Admin></Route>
          <Route exact path="/admin/settings"><Admin><AdminSetting /></Admin></Route>
          <Route exact path="/admin/krank"><Admin><AdminKrank /></Admin></Route>
          <Route exact path="/admin/manual-bet"><Admin><ManualBet /></Admin></Route>
          <Route path="/admin/login"><AdminLogin /></Route>
          <Route path="/admin/logout"><AdminLogout /></Route>

          {/* Casino */}
          <Route exact path="/casino/roulette"><Dashboard><Roulette /></Dashboard></Route>
          <Route exact path="/casino/crash"><Dashboard><Crash /></Dashboard></Route>
          <Route exact path="/casino/blackjack"><Dashboard><BlackJack /></Dashboard></Route>
          <Route exact path="/casino/mines"><Dashboard><Mines /></Dashboard></Route>
          <Route exact path="/casino/plinko"><Dashboard><Plinko /></Dashboard></Route>
          <Route exact path="/casino/dice"><Dashboard><Dice /></Dashboard></Route>
          <Route exact path="/casino/wheel"><Dashboard><Wheel /></Dashboard></Route>
          <Route exact path="/casino/limbo"><Dashboard><Limbo /></Dashboard></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;