import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card } from 'react-bootstrap';
var dateFormat = require("dateformat");

createTheme('dark_s', {
  text: {
    primary: '#fff',
    secondary: '#aaa',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#091929',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.08)',
    disabled: 'rgba(255,255,255,.12)',
  },
  sortFocus: {
    default: '#ffc107',
  },
}, 'dark');

const columns = [
  {
    name: "User",
    selector: "username",
    sortable: false,
    left: true,
    style: { wordBreak: "normal" },
    cell: row => <div data-tag="allowRowEvents">
      {row.username}
    </div>
  },
  {
    name: 'Tuesday',
    selector: "",
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Tuesday} />
  },
  {
    name: 'Wednesday',
    selector: '',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Wednesday} />
  },
  {
    name: 'Thursday',
    selector: 'username',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Thursday} />
  },
  {
    name: 'Friday',
    selector: 'type',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Friday} />
  },
  {
    name: 'Saturday',
    selector: 'Saturday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Saturday} />
  },
  {
    name: 'Sunday',
    selector: 'Sunday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Sunday} />
  },
  {
    name: 'Monday',
    selector: 'Monday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Monday} />
  },
  {
    name: "Total",
    selector: 'Total',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Total} />
  }
];

function Cell({ pl }) {
  if (pl < 0) {
    return (
      <div data-tag="allowRowEvents">
        <span className='text-danger'>${"-" + Math.abs(pl.toFixed(2))}</span>
      </div>
    )
  } if (pl > 0) {
    return (
      <div data-tag="allowRowEvents">
        <span className='text-success'>${Math.abs(pl.toFixed(2))}</span>
      </div>
    );
  }
  return (
    <div data-tag="allowRowEvents">
      {pl.toFixed(2)}
    </div>

  )

}

let source = axios.CancelToken.source();
class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{}],
      user: [],
      loading: true,
      week: 0,
      total_stake: 0,
      total_win: 0,
      total_bet: 0,
      balance_type: 0,
      login_status: 0
    };

  }

  get_data = (week) => {
    const token = localStorage.getItem("token");
    if(!token){this.props.history.push("/login");}
    const id = localStorage.getItem("id");
    this.setState({ data: [{}], loading: true });
    axios.get('/api/history', {
      params: { id: id, week: week, type: this.state.history_type },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          let data = this.getDailyProfitLoss(response.data);
          data[0].username = response.user.username;
          this.setState({
            login_status: 1,
            user: response.user,
            data: data,
            start_date: response.start_date,
            end_date: response.end_date,
            loading: false,
            total_stake: response.total_stake,
            total_win: response.total_win,
            total_bet: response.total_bet
          });

          localStorage.setItem('balance', response.user.balance);
          localStorage.setItem('casino', response.user.casino);
          window.dispatchEvent(new Event('storage'));

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);
        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  getDailyProfitLoss = (data) => {

    let days = {
      username: "",
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
      Monday: 0,
      Total: 0
    }
    let { balance_type } = this.state;
    for (let entry of data) {
      let { created_on, pl, type, status } = entry;
      if(status==='Open') { continue; }
      if (balance_type === 9 && type !== 9) continue;
      if (balance_type === 1 && type === 9) continue;

      days[dateFormat(created_on, "dddd")] += pl;
      days.Total += pl;
    }

    return [days];
  }

  updateWeek = (event) => {
    const value = event.target.value;
    this.setState({
      "week": value
    });
    this.get_data(value);
  }

  updateSel = (event) => {
    this.setState({
      balance_type: parseInt(event.target.value)
    }, () => this.get_data(this.state.week));
  }


  UNSAFE_componentWillMount() {
    this.get_data(this.state.week);
  }

  render() {
    if(this.state.login_status === 0) return (<></>);

    return (
      <>
        <div className='main-content'>
          <Container fluid className='mb-20 mt-20'>
            <Row>
              <Col className="text-end">
                <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="balance_type" onChange={this.updateSel} defaultValue={this.state.history_type}>
                  <option value="0" key='0'>All</option>
                  <option value="1" key='1'>Sports</option>
                  <option value="9" key='9'>Casino</option>
                </select>

                <select className='form-control d-inline w-auto bg-s-secondary mx-1 mb-5 text-white' onChange={this.updateWeek} defaultValue={this.state.week}>
                  <option value="0">This Week</option>
                  <option value="1">Last Week</option>
                  <option value="2">2 Weeks Ago</option>
                  <option value="3">3 Weeks Ago</option>
                  <option value="4">4 Weeks Ago</option>
                  <option value="5">5 Weeks Ago</option>
                  <option value="6">6 Weeks Ago</option>
                  <option value="7">7 Weeks Ago</option>
                  <option value="8">8 Weeks Ago</option>
                </select>
              </Col>
            </Row>
          </Container>

          <Container fluid className='mb-20'>
            <Row>
              <Col className='billing'>
                <Card body className='bg-s-secondary'>
                  {this.state.loading ? <div className="loading">Loading...</div> :
                    <DataTable
                      title={"Balance Sheet (" + dateFormat(this.state.start_date, "mm/dd/yyyy") + " to " + dateFormat(this.state.end_date, "mm/dd/yyyy") + ")"}
                      columns={columns}
                      data={this.state.data}
                      highlightOnHover={true}
                      theme="dark_s"
                    />
                  }
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(BalanceSheet);