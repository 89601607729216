import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button,Toast } from 'react-bootstrap';
import Wheel from "./Wheel"
var dateFormat = require("dateformat");

let source = axios.CancelToken.source();
const blackNumbers = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 29, 28, 31, 33, 35];
class Roulette extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        user:[],
        number: {next: null},
        chip : 1,
        history:[],
        stake:0,
        placingbet:0,
        toastMsg:"",
        showToast:false,
        resultmsg : "Place your bets...",
        showWheel : false,
        placedchips : {
            number:{},
            color: {},
            group: {},
            parity: {}
        },
        login_status: 0
    
    };

    UNSAFE_componentWillMount(){
        this.verify_token();
    }

    verify_token = () => {
        const token = localStorage.getItem("token");
        axios.get('/api/simpleauth', {
            headers:{token: token}
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    user:response.data,
                    login_status: 1
                });

                if(this.props.login_status !== 1)
                    this.props.setLoginStatus(1);

                localStorage.setItem('balance', response.data.balance);
                window.dispatchEvent(new Event('storage'));

            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    setChip = (v) => {
        this.setState({
            chip : v
        });
    }

    setHistory = (v) => {
        let hi = this.state.history;
        if(hi.length>=5) {
            hi.shift();
        }
        hi.push(v);

        this.setState({
            history : hi
        });
    }

    placeChip = (type, value) => {
        /*
            type
            number - n0 - n36,
            color - red, black
            group - row1, row2, row3, n_1_12, n_13_24, n_25_36, n_1_18, n_19_36
            parity - odd, even
        */

        if(this.state.placingbet===0) {
            let currentchips = this.state.placedchips;
            let active_chip = this.state.chip;
            let current_value = currentchips?.[type]?.[value];
            if(current_value>0) {
                current_value = current_value+active_chip
            } else {
                current_value = active_chip;
            }
            currentchips[type][value] = current_value;

            let stake = this.state.stake;
            stake = stake+active_chip;

            this.setState({
                stake : stake,
                placedchips : currentchips
            });

            //console.log(currentchips);
        }
    }

    clearChips = () => {
        this.setState({
            stake : 0,
            placedchips : {
                number:{},
                color: {},
                group: {},
                parity: {}
            }
        });
    }

    placeBet = (event) => {
        //update immediate balance
        let balance = localStorage.getItem('balance');
        if(balance>=this.state.stake && this.state.stake<=100){
            balance = (balance - this.state.stake).toFixed(2);
            localStorage.setItem('balance', balance);
            window.dispatchEvent(new Event('storage'));
        }

        this.setState({
            placingbet:1,
            resultmsg:"No more bets..."
        });
        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        axios.post('/api/casino/roulette-bet', {
            id:this.state.user.id,
            betdata:this.state.placedchips
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                //success
                const num = response.winningNumber;
                this.setState({ 
                    number: { next: num },
                    placingbet:2,
                    showWheel:true
                });


                setTimeout(()=>{

                    let tm = "You won $"+response.win;
                    if(response.win<=0) {
                        tm = "No win";
                    }

                    this.setState({
                        placingbet:0,
                        resultmsg:tm,
                        showWheel:false
                    });

                    this.setHistory(num);
                    //this.clearChips();
                    localStorage.setItem('balance', response.balance);
                    window.dispatchEvent(new Event('storage'));
                }, 8000);
                
            } else {
                this.setState({
                    placingbet:0,
                    toastMsg:response.error_msg,
                    showToast:true,
                    resultmsg:"Place your bets...",
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                toastMsg:e.message,
                resultmsg:"Place your bets...",
                showToast:true
            });
            //yconsole.log(e.message);
        });
    }

    render () {
        if(this.state.login_status === 0) return (<></>);
        return(
            <>
                <Toast bg="dark" onClose={() => this.setState({ showToast:false, toastMsg:""})} show={this.state.showToast} delay={3000} autohide>
                    <Toast.Body>{this.state.toastMsg}</Toast.Body>
                </Toast>

                <div className='main-content'>
                    <div className='sport-betting-lines casino-roulette'>
                        <Container className={this.state.showWheel ? 'wheelwrapper show' : 'wheelwrapper'}>
                            <Row>
                                <Col>
                                    <Wheel number={this.state.number} />
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className='r-history mb-15'>
                            <Row>
                                <Col className='text-center'>
                                    { this.state.history.map((entry, e) => {
                                        if (entry === 0) {
                                           return <span className='r-history-number r-green' key={e}>{entry}</span>
                                        } else if (blackNumbers.includes(entry)) {
                                            return <span className='r-history-number r-black' key={e}>{entry}</span>
                                        } else {
                                            return <span className='r-history-number r-red' key={e}>{entry}</span>
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className='r-board'>
                            <Row>
                                <Col>
                                    <div className='r-number'></div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n3")}>3 {this.state.placedchips?.number?.n3 > 0 ? <span className='chip'>{this.state.placedchips.number.n3}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n6")}>6 {this.state.placedchips?.number?.n6 > 0 ? <span className='chip'>{this.state.placedchips.number.n6}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n9")}>9 {this.state.placedchips?.number?.n9 > 0 ? <span className='chip'>{this.state.placedchips.number.n9}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n12")}>12 {this.state.placedchips?.number?.n12 > 0 ? <span className='chip'>{this.state.placedchips.number.n12}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n15")}>15 {this.state.placedchips?.number?.n15 > 0 ? <span className='chip'>{this.state.placedchips.number.n15}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n18")}>18 {this.state.placedchips?.number?.n18 > 0 ? <span className='chip'>{this.state.placedchips.number.n18}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n21")}>21 {this.state.placedchips?.number?.n21 > 0 ? <span className='chip'>{this.state.placedchips.number.n21}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n24")}>24 {this.state.placedchips?.number?.n24 > 0 ? <span className='chip'>{this.state.placedchips.number.n24}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n27")}>27 {this.state.placedchips?.number?.n27 > 0 ? <span className='chip'>{this.state.placedchips.number.n27}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n30")}>30 {this.state.placedchips?.number?.n30 > 0 ? <span className='chip'>{this.state.placedchips.number.n30}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n33")}>33 {this.state.placedchips?.number?.n33 > 0 ? <span className='chip'>{this.state.placedchips.number.n33}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n36")}>36 {this.state.placedchips?.number?.n36 > 0 ? <span className='chip'>{this.state.placedchips.number.n36}</span> : ""}</div>
                                    <div className='r-number' onClick={()=>this.placeChip("group", "row1")}>2:1 {this.state.placedchips?.group?.row1 > 0 ? <span className='chip'>{this.state.placedchips.group.row1}</span> : ""}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='r-number position-relative' onClick={()=>this.placeChip("number", "n0")}><div className='r-number-0'>0 {this.state.placedchips?.number?.n0 > 0 ? <span className='chip'>{this.state.placedchips.number.n0}</span> : ""}</div></div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n2")}>2 {this.state.placedchips?.number?.n2 > 0 ? <span className='chip'>{this.state.placedchips.number.n2}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n5")}>5 {this.state.placedchips?.number?.n5 > 0 ? <span className='chip'>{this.state.placedchips.number.n5}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n8")}>8 {this.state.placedchips?.number?.n8 > 0 ? <span className='chip'>{this.state.placedchips.number.n8}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n11")}>11 {this.state.placedchips?.number?.n11 > 0 ? <span className='chip'>{this.state.placedchips.number.n11}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n14")}>14 {this.state.placedchips?.number?.n14 > 0 ? <span className='chip'>{this.state.placedchips.number.n14}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n17")}>17 {this.state.placedchips?.number?.n17 > 0 ? <span className='chip'>{this.state.placedchips.number.n17}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n20")}>20 {this.state.placedchips?.number?.n20 > 0 ? <span className='chip'>{this.state.placedchips.number.n20}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n23")}>23 {this.state.placedchips?.number?.n23 > 0 ? <span className='chip'>{this.state.placedchips.number.n23}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n26")}>26 {this.state.placedchips?.number?.n26 > 0 ? <span className='chip'>{this.state.placedchips.number.n26}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n29")}>29 {this.state.placedchips?.number?.n29 > 0 ? <span className='chip'>{this.state.placedchips.number.n29}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n32")}>32 {this.state.placedchips?.number?.n32 > 0 ? <span className='chip'>{this.state.placedchips.number.n32}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n35")}>35 {this.state.placedchips?.number?.n35 > 0 ? <span className='chip'>{this.state.placedchips.number.n35}</span> : ""}</div>
                                    <div className='r-number' onClick={()=>this.placeChip("group", "row2")}>2:1 {this.state.placedchips?.group?.row2 > 0 ? <span className='chip'>{this.state.placedchips.group.row2}</span> : ""}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='r-number r-blank-left'></div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n1")}>1 {this.state.placedchips?.number?.n1 > 0 ? <span className='chip'>{this.state.placedchips.number.n1}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n4")}>4 {this.state.placedchips?.number?.n4 > 0 ? <span className='chip'>{this.state.placedchips.number.n4}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n7")}>7 {this.state.placedchips?.number?.n7 > 0 ? <span className='chip'>{this.state.placedchips.number.n7}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n10")}>10 {this.state.placedchips?.number?.n10 > 0 ? <span className='chip'>{this.state.placedchips.number.n10}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n13")}>13 {this.state.placedchips?.number?.n13 > 0 ? <span className='chip'>{this.state.placedchips.number.n13}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n16")}>16 {this.state.placedchips?.number?.n16 > 0 ? <span className='chip'>{this.state.placedchips.number.n16}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n19")}>19 {this.state.placedchips?.number?.n19 > 0 ? <span className='chip'>{this.state.placedchips.number.n19}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n22")}>22 {this.state.placedchips?.number?.n22 > 0 ? <span className='chip'>{this.state.placedchips.number.n22}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n25")}>25 {this.state.placedchips?.number?.n25 > 0 ? <span className='chip'>{this.state.placedchips.number.n25}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n28")}>28 {this.state.placedchips?.number?.n28 > 0 ? <span className='chip'>{this.state.placedchips.number.n28}</span> : ""}</div>
                                    <div className='r-number r-black' onClick={()=>this.placeChip("number", "n31")}>31 {this.state.placedchips?.number?.n31 > 0 ? <span className='chip'>{this.state.placedchips.number.n31}</span> : ""}</div>
                                    <div className='r-number r-red' onClick={()=>this.placeChip("number", "n34")}>34 {this.state.placedchips?.number?.n34 > 0 ? <span className='chip'>{this.state.placedchips.number.n34}</span> : ""}</div>
                                    <div className='r-number' onClick={()=>this.placeChip("group", "row3")}>2:1 {this.state.placedchips?.group?.row3 > 0 ? <span className='chip'>{this.state.placedchips.group.row3}</span> : ""}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='r-number r-blank-left r-border-top'></div>
                                    <div className='r-number r-number-3' onClick={()=>this.placeChip("group", "n_1_12")}>1 to 12 {this.state.placedchips?.group?.n_1_12 > 0 ? <span className='chip'>{this.state.placedchips.group.n_1_12}</span> : ""}</div>
                                    <div className='r-number r-number-3' onClick={()=>this.placeChip("group", "n_13_24")}>13 to 24 {this.state.placedchips?.group?.n_13_24 > 0 ? <span className='chip'>{this.state.placedchips.group.n_13_24}</span> : ""}</div>
                                    <div className='r-number r-number-3' onClick={()=>this.placeChip("group", "n_25_36")}>25 to 36 {this.state.placedchips?.group?.n_25_36 > 0 ? <span className='chip'>{this.state.placedchips.group.n_25_36}</span> : ""}</div>
                                    <div className='r-number r-blank-right r-border-top'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='r-number r-blank-left'></div>
                                    <div className='r-number r-number-6' onClick={()=>this.placeChip("group", "n_1_18")}>1 to 18 {this.state.placedchips?.group?.n_1_18 > 0 ? <span className='chip'>{this.state.placedchips.group.n_1_18}</span> : ""} </div>
                                    <div className='r-number r-number-6' onClick={()=>this.placeChip("parity", "even")}>Even {this.state.placedchips?.parity?.even > 0 ? <span className='chip'>{this.state.placedchips.parity.even}</span> : ""} </div>
                                    <div className='r-number r-number-6 r-red' onClick={()=>this.placeChip("color", "red")}> {this.state.placedchips?.color?.red > 0 ? <span className='chip'>{this.state.placedchips.color.red}</span> : ""} </div>
                                    <div className='r-number r-number-6 r-black' onClick={()=>this.placeChip("color", "black")}> {this.state.placedchips?.color?.black > 0 ? <span className='chip'>{this.state.placedchips.color.black}</span> : ""} </div>
                                    <div className='r-number r-number-6' onClick={()=>this.placeChip("parity", "odd")}>Odd {this.state.placedchips?.parity?.odd > 0 ? <span className='chip'>{this.state.placedchips.parity.odd}</span> : ""} </div>
                                    <div className='r-number r-number-6' onClick={()=>this.placeChip("group", "n_19_36")}>19 to 36 {this.state.placedchips?.group?.n_19_36 > 0 ? <span className='chip'>{this.state.placedchips.group.n_19_36}</span> : ""} </div>
                                    <div className='r-number r-blank-right'></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='casino-bet-slip'>
                        <div className='py-2 casino-bet-slip-results'>
                            <Container fluid>
                                <Row>
                                    <Col className='text-center'>
                                        <h4 className='m-0'>{this.state.resultmsg}</h4>
                                    </Col> 
                                </Row>
                            </Container>
                        </div>
                        <div className='chips py-3'>
                            <Container fluid>
                                <Row>
                                    <Col className='text-center'>
                                        <Button className={this.state.chip===1 ? 'chip active' : 'chip'} onClick={()=>this.setChip(1)}><div className='chip-text'>1</div></Button>
                                        <Button className={this.state.chip===5 ? 'chip active' : 'chip'} onClick={()=>this.setChip(5)}><div className='chip-text'>5</div></Button>
                                        <Button className={this.state.chip===10 ? 'chip active' : 'chip'} onClick={()=>this.setChip(10)}><div className='chip-text'>10</div></Button>
                                        <Button className={this.state.chip===25 ? 'chip active' : 'chip'} onClick={()=>this.setChip(25)}><div className='chip-text'>25</div></Button>
                                        <Button className={this.state.chip===50 ? 'chip active' : 'chip'} onClick={()=>this.setChip(50)}><div className='chip-text'>50</div></Button>
                                        <Button className={this.state.chip===100 ? 'chip active' : 'chip'} onClick={()=>this.setChip(100)}><div className='chip-text'>100</div></Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className='casino-bet-details'>
                            <Container fluid>
                                <Row>
                                    <Col className='cbd-title'>Total Bet : ${this.state.stake}</Col>
                                    <Col className='cbd-value'><Button className='btn btn-xs no-shadow' disabled={this.state.placingbet===0 ? false : true} onClick={()=>this.clearChips()}>Clear</Button></Col>
                                </Row>
                            </Container>
                        </div>

                        <div className='casino-bet-button mb-20'>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        {this.state.placingbet===0 ?
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.stake>0 ? false : true} onClick={()=>this.placeBet()}>Place Bets</Button>
                                        : <Button className='btn btn-warning w-100 btn-yellow' disabled>{this.state.placingbet===1 ? "Placing Bet..." : "Results..." }</Button>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Roulette);