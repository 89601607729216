import React, { Component} from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import LoadingScreen from '../LoadingScreen';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
    text: {
      primary: '#fff',
      secondary: '#aaa',
    },
    background: {
      default: 'transparent',
    },
    context: {
      background: '#091929',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(255,255,255,.54)',
      hover: 'rgba(255,255,255,.08)',
      disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

const owes = (win) => {
    const pay =  parseFloat(win);
    if(pay>0) { 
        return (
            <span className='text-danger'>${"-"+Math.abs(pay)}</span>
        )
    }
    return (
        <span className='text-success'>${Math.abs(pay)}</span>
    )
}

const arraytorow = (data) => {
    if(data){
        return data.map((row) => <p>{row}</p>);
    } else {
        return "";
    }
    
}

let columns = [];


const columns_owes = [
    {
        name: 'User Details',
        selector: 'username',
        sortable: false,
        left: true,
        width: "180px",
        cell: row => <div data-tag="allowRowEvents"><small className='d-block text-secondary'>{row.manager_username} &gt; {row.bookie_username} &gt;</small><NavLink className={"text-white"} to={"admin/users/details/"+row.uid}>{row.username}</NavLink></div>,
    },
    {
        name: 'Total Stake',
        selector: 'stake',
        sortable: false,
        right: false,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">${row.stake}</div>,
    },
    {
        name: 'Open bet',
        selector: 'openstake',
        sortable: false,
        right: false,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">${row.openstake}</div>,
    },
    {
        name: 'Total Win / Loss',
        selector: 'bet',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">${row.win}</div>,
    },
    {
        name: 'Owes / Pay',
        selector: 'owes',
        sortable: false,
        right: true,
        width: "200px",
        cell: row =><div data-tag="allowRowEvents"><NavLink to={"admin/history/"+row.manager_id+"/"+row.bookie_id+"/"+row.uid}>{owes(row.win)}</NavLink></div>,
    }
];

let source = axios.CancelToken.source();
class Home extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
        this.handleCallback = this.handleCallback.bind(this);

        columns = [
            {
                name: 'Placed On',
                selector: 'created_on',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents">{"B"+row.id} <br/>{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""} <br/> {row.fb===1 ? <span className='badge bg-primary'>Free Bet</span> : ""}</div>,
            },
            {
                name: 'User Details',
                selector: 'username',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents"><small className='d-block text-secondary'>{row.manager_username} &gt; {row.bookie_username} &gt;</small> {row.username}</div>,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: false,
                right: false,
                width: "100px",
                cell: row =><div data-tag="allowRowEvents">{row.type===1 ? "Single" : row.type===3 ? "Credit" : row.type===9 ? "Casino" : "Multiple"}</div>,
            },
            {
                name: 'Bet Details',
                selector: 'bet',
                sortable: false,
                right: false,
                cell: row =><div data-tag="allowRowEvents">{arraytorow(row.bet)}</div>,
            },
            {
                name: 'Risk / Win',
                selector: 'stake',
                sortable: false,
                right: true,
                width: "200px",
                cell: row =><div data-tag="allowRowEvents">${row.stake} / ${row.win}</div>,
            },
            {
                name: 'Status',
                selector: 'status_api',
                sortable: false,
                right: true,
                width: "100px",
                cell: row =><div data-tag="allowRowEvents">
                        { this.state.user.type<=2 ?
                        <select key={row.id} defaultValue={row.status_api} className='form-control form-control-sm d-inline w-auto text-white' onChange={(event)=>this.changeMe(row.id, event)}>
                            <option value="0" key="0">Open</option>
                            <option value="1" key="1">Win</option>
                            <option value="2" key="2">Loss</option>
                            <option value="3" key="3">Push</option>
                        </select>
                        : "Open" }
                    </div>,
            },
            {
                name: 'Confirm',
                selector: 'id',
                sortable: false,
                right: true,
                width: "100px",
                cell: row =><div data-tag="allowRowEvents">{ this.state.user.type<=2 ? <Button className='btn btn-primary btn-xs' onClick={()=>this.confirm(row.id)}>Confirm</Button> : "" }</div>,
            }
        ];
    }


    state = {
        data:[{}],
        user:[],
        loading:false,
        start_date:"",
        end_date:"",
        week_range: {"this_week":[], "last_week":[], "2_weeks_ago":[], "3_weeks_ago":[], "4_weeks_ago":[], "5_weeks_ago":[]},
        manager_id:0,
        bookie_id:0,
        user_id:0,
        managers:[],
        bookies:[],
        users:[],
        total_stake:0,
        total_win:0,
        total_bet:0,
        page:1,
        total:0,
        possible_win:0,
        open_bet:0,
        user_owes:[],
        status:[],
        history_type:0,
        bookies_charges: [],
        current_bookie_charge: 0,
        login_status: 0
    };

    updateSel = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name==='manager_id' && value==='0') {
            this.setState({
                manager_id:0,
                bookie_id:0,
                user_id:0
            }, function(){this.get_data()});
        } else if(name==='bookie_id' && value==='0') {
            this.setState({
                bookie_id:0,
                user_id:0
            }, function(){this.get_data()});
        } else {
            this.setState({
                [name]:value
            }, function(){this.get_data()});
        }
    }   

    resetweek = () => {
        this.setState({
            week_settled:1
        });
        axios.get('/api/cron/weekly-balance', {})
        .then((res) => {
            this.get_data();
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    changeMe = (id, event) => {
        const status = parseInt(event.target.value);
        let status_data = this.state.status;
        status_data[id]=status;
        this.setState({
            status:status_data
        });
        
    }

    removeData(arr, id) {
        return arr.filter((obj) => obj.id !== id);
    }

    confirm = (bid) => {
        const status = this.state.status[bid];
        if(status > 0) {
            const data = this.state.data;
            const newdata = this.removeData(data, bid);
            this.setState({
                data:newdata
            });

            const token = localStorage.getItem("admin_token");
            const id = localStorage.getItem("admin_id");

            axios.get('/api/admin/grading-confirm', {
                params:{id:id, bid:bid, status:status},
                headers:{token: token},
                cancelToken: source.token
            }).then((res) => {
                
            }).catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }

    get_data = () => {
        let start_date="";
        let end_date="";
        if(this.state.start_date){start_date = moment(this.state.start_date).format("YYYY-MM-DD 09:00:00");}
        if(this.state.end_date){end_date = moment(this.state.end_date).format("YYYY-MM-DD 08:59:59");}
        const token = localStorage.getItem("admin_token");
        if(!token){this.props.history.push("/admin/login");}
        const id = localStorage.getItem("admin_id");
        


        this.setState({data:[{}], loading:true});
        axios.get('/api/admin/dashboard', {
            params:{page:this.state.page, id:id, start_date:start_date, end_date:end_date, manager_id:this.state.manager_id, bookie_id:this.state.bookie_id, user_id:this.state.user_id, type:this.state.history_type},
            headers:{token: token},
            cancelToken: source.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/admin/login");
            } else if(response.error_code===0){
                this.setState({
                    login_status:1,
                    user:response.user,
                    data:response.data,
                    start_date:response.start_date,
                    end_date:response.end_date,
                    loading:false,
                    week_range:response.week_range,
                    managers:response.managers,
                    bookies:response.bookies,
                    users:response.users,
                    total_stake:response.total_stake,
                    total_win:response.total_win,
                    total_bet:response.total_bet,
                    total:response.total,
                    possible_win:response.possible_win,
                    open_bet:response.open_bet,
                    user_owes:response.user_owes,
                    week_now:response.week_now,
                    week_from:response.week_from,
                    week_to:response.week_to,
                    week_settled:response.week_settled,
                    status:response.status,
                    bookies_charges: response.bookies_charges,
                    current_bookie_charge: response.current_bookie_charge 
                });
                
                if(this.props.login_status !== 1) 
                    this.props.setLoginStatus(1);
                    
                if(this.props.user_type !== response.user.type)
                    this.props.setUserType(response.user.type);
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    handleCallback(start, end, label) {
        this.setState({
            start_date:moment(start).format("MM/DD/YYYY"),
            end_date:moment(end).format("MM/DD/YYYY")
        }, function(){this.get_data()});
    }

    componentDidMount(){
        this.get_data();
    }

    setPage = (page) => {
        this.setState({page:page});
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.get_data();
        }
        
    }

    
    render () {

        if(this.props.login_status === 0) return (<></>);

        const drp_setting = {
            startDate: this.state.start_date, 
            endDate: this.state.end_date,
            ranges: {
                'This Week': this.state.week_range.this_week,
                'Last Week': this.state.week_range.last_week,
                '2 Weeks Ago': this.state.week_range['2_weeks_ago'],
                '3 Weeks Ago': this.state.week_range['3_weeks_ago'],
                '4 Weeks Ago': this.state.week_range['4_weeks_ago'],
                '5 Weeks Ago': this.state.week_range['5_weeks_ago']
            }
        }

        return(
            <>
                <Container fluid className='mb-20 mt-20'>
                    <Row>
                        <Col className="text-end">
                            <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="history_type" onChange={this.updateSel} defaultValue={this.state.history_type}>
                                <option value="0" key='0'>All History</option>
                                <option value="1" key='1'>Sports</option>
                                <option value="2" key='2'>Casino</option>
                            </select>

                            { this.state.user.type===1 ?
                            <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} defaultValue={this.state.manager_id}>
                                <option value="0">All Managers</option>
                                {
                                    this.state.managers.map((data, e) => ( 
                                        <option key={data.id} value={data.id}>{data.username}</option>
                                    ))
                                }
                            </select>
                            : "" }

                            { this.state.user.type===1 || this.state.user.type===2 ?
                            <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="bookie_id" onChange={this.updateSel} defaultValue={this.state.bookie_id}>
                                <option value="0">All Bookies</option>
                                {
                                    this.state.bookies.map((data, e) => ( 
                                        <option key={data.id} value={data.id}>{data.username}</option>
                                    ))
                                }
                            </select>
                            : "" }

                            <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="user_id" onChange={this.updateSel} defaultValue={this.state.user_id}>
                                <option value="0">All Users</option>
                                {
                                    this.state.users.map((data, e) => ( 
                                        <option key={data.id} value={data.id}>{data.username}</option>
                                    ))
                                }
                            </select>


                            {this.state.start_date ?
                            <DateRangePicker initialSettings={drp_setting} onCallback={this.handleCallback} >
                                <input type="text" className="form-control w-auto d-inline-block ml-1 mb-5" />
                            </DateRangePicker>
                            : ""
                            }
                        </Col>
                    </Row>
                </Container>

                {this.state.user.type===1 && this.state.week_settled===0 ? 
                <Container fluid className='mb-20'>
                    <Row>
                        <Col>
                            <Card body className='bg-s-secondary datatable'>
                                <h4>Reset week</h4>
                                <p className='mb-0'>From: {this.state.week_from}</p>
                                <p>To: {this.state.week_to}</p>
                                <Button className='btn btn-primary'onClick={()=>this.resetweek()}>Reset Week</Button>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                : "" }

                <Container fluid className='mb-20'>
                    <Row className="g-2">
                        <Col xs={6} lg={4}>
                            
                            <div className='stats'>
                                <Col xs={12} className="text">
                                    <p className='tagvalue'>${this.state.total_stake}</p>
                                    <p className='tagname'>Total Stake</p>
                                </Col>
                            </div>
                            
                        </Col>
                        <Col xs={6} lg={4}>
                            
                            <div className='stats'>
                                <Col xs={12} className="text">
                                    <p className='tagvalue'>${this.state.open_bet}</p>
                                    <p className='tagname'>Open Bet</p>
                                </Col>
                            </div>
                            
                        </Col>
                        <Col xs={12} lg={4}>
                            
                            <div className='stats'>
                                <Col xs={12} className="text">
                                    <p className='tagvalue'>{owes(this.state.total_win)}</p>
                                    <p className='tagname'>Total P/L</p>
                                </Col>
                            </div>
                            
                        </Col>

                        {/**
                         * Changed P/L for earns / owes
                         */
                        /* 
                        }

                        {this.state.user.type === 2 || this.state.user.type === 1?
                            <Col xs={12} lg={3}>
                                <div className='stats'>
                                    <Col xs={12} className="text">
                                        <p className='tagvalue'>
                                            {owes(-this.state.bookies_charges.reduce(
                                                (acc,bookie_c) => bookie_c.amount + acc, 0
                                            ))}
                                        </p>
                                        <p className='tagname'>Earns</p> 
                                    </Col>
                                </div>
                            </Col> : this.state.user.type === 3 ?
                            <Col xs={12} lg={3}>
                                <div className='stats'>
                                    <Col xs={12} className="text">
                                        <p className='tagvalue'>
                                            {owes(this.state.current_bookie_charge?.amount || 0)}
                                        </p>
                                        <p className='tagname'>Owes</p> 
                                    </Col>
                                </div>
                            </Col> : 
                            <Col xs={12} lg={3}>
                                <div className='stats'>
                                    <Col xs={12} className="text">
                                        <p className='tagvalue'>
                                            {owes(0)}
                                        </p>
                                        <p className='tagname'>---</p> 
                                    </Col>
                                </div>
                            </Col>   
                        }
                        */}
                    </Row>
                </Container>

                <Container fluid className='mb-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body className='bg-s-secondary datatable'>
                                {this.state.loading ? <div className="loading">Loading...</div> : 
                                    <>
                                    <DataTable
                                        title={"Statement"}
                                        columns={columns_owes}
                                        data={this.state.user_owes}
                                        highlightOnHover={true}
                                        theme="dark_s"
                                    />
                                    
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='mb-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body className='bg-s-secondary datatable'>
                                {this.state.loading ? <div className="loading">Loading...</div> : 
                                    <>
                                    <DataTable
                                        title={"Open Bets"}
                                        columns={columns}
                                        data={this.state.data}
                                        highlightOnHover={true}
                                        theme="dark_s"
                                        pagination={true}
                                        paginationPerPage={100}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                    />
                                    
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Home);