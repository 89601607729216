import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
var dateFormat = require("dateformat");

createTheme('dark_s', {
    text: {
      primary: '#fff',
      secondary: '#aaa',
    },
    background: {
      default: 'transparent',
    },
    context: {
      background: '#091929',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(255,255,255,.54)',
      hover: 'rgba(255,255,255,.08)',
      disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

const arraytorow = (data) => {
    if(data){
        return data.map((row) => <p>{row}</p>);
    } else {
        return "";
    }
}

const columns = [
    {
        name: 'Placed On',
        selector: 'created_on',
        sortable: false,
        left: true,
        width: "180px",
        cell: row => <div data-tag="allowRowEvents">{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""} <br/> {row.fb===1 ? <span className='badge bg-primary'>Free Bet</span> : ""}</div>,
    },
    {
        name: 'Type',
        selector: 'type',
        sortable: false,
        right: false,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">{row.type===1 ? "Single" : row.type===3 ? "Credit" : row.type===9 ? "Casino" : "Multiple"}</div>,
    },
    {
        name: 'Bet Details',
        selector: 'bet',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{arraytorow(row.bet)}</div>,
    },
    {
        name: 'Risk / Win',
        selector: 'stake',
        sortable: false,
        right: true,
        width: "200px",
        cell: row =><div data-tag="allowRowEvents">${row.stake} / ${row.win}</div>,
    },
    {
        name: 'Win',
        selector: 'pl',
        sortable: false,
        right: true,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">{row.pl ? "$"+row.pl : ""}</div>,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        right: true,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">{row.status}</div>,
    }
];

let source = axios.CancelToken.source();
class History extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }


    state = {
        data:[{}],
        user:[],
        loading:false,
        week:0,
        total_stake:0,
        total_win:0,
        total_bet:0,
        history_type:0,
        login_status:0
    };

    

    get_data = (week) => {
        const token = localStorage.getItem("token");
        if(!token){this.props.history.push("/login");}
        const id = localStorage.getItem("id");
        this.setState({data:[{}], loading:true});
        axios.get('/api/history', {
            params:{id:id, week:week, type:this.state.history_type},
            headers:{token: token},
            cancelToken: source.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                
                this.setState({
                    login_status: 1,
                    user:response.user,
                    data:response.data,
                    start_date:response.start_date,
                    end_date:response.end_date,
                    loading:false,
                    total_stake:response.total_stake,
                    total_win:response.total_win,
                    total_bet:response.total_bet
                });

                localStorage.setItem('balance', response.user.balance);
                localStorage.setItem('casino', response.user.casino);
                window.dispatchEvent(new Event('storage'));
            
                if(this.props.login_status !== 1) 
                    this.props.setLoginStatus(1);
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    updateWeek = (event) => {
        const value = event.target.value;
        this.setState({
            "week": value
        });
        this.get_data(value);
    }

    UNSAFE_componentWillMount(){
        this.get_data(this.state.week);
    }

    updateSel = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]:value
        }, function(){this.get_data(this.state.week)});
    }   


    
    render () {
        if(this.state.login_status === 0) return (<></>);

        return(
            <>
                <div className='main-content'>
                    <Container fluid className='mb-20'>
                        <Row>
                            <Col className="text-end">
                                <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="history_type" onChange={this.updateSel} defaultValue={this.state.history_type}>
                                    <option value="0" key='0'>All History</option>
                                    <option value="1" key='1'>Sports</option>
                                    <option value="2" key='2'>Casino</option>
                                </select>

                                <select className='form-control d-inline w-auto bg-s-secondary mx-1 mb-5 text-white' onChange={this.updateWeek} defaultValue={this.state.week}>
                                    <option  value="0">This Week</option>
                                    <option  value="1">Last Week</option>
                                    <option  value="2">2 Weeks Ago</option>
                                    <option  value="3">3 Weeks Ago</option>
                                    <option  value="4">4 Weeks Ago</option>
                                    <option  value="5">5 Weeks Ago</option>
                                    <option  value="6">6 Weeks Ago</option>
                                    <option  value="7">7 Weeks Ago</option>
                                    <option  value="8">8 Weeks Ago</option>
                                </select>
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid className='mb-20'>
                        <Row>
                            <Col className='billing'>
                                <Card body className='bg-s-secondary'>
                                    {this.state.loading ? <div className="loading">Loading...</div> : 
                                        <>
                                        <DataTable
                                            title={"Wager History ("+dateFormat(this.state.start_date, "mm/dd/yyyy")+" to "+dateFormat(this.state.end_date, "mm/dd/yyyy")+")"}
                                            columns={columns}
                                            data={this.state.data}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                        />
                                        <div className='text-center mt-10 border-top pt-15'>Total Bets: {this.state.total_bet}, Total Stake: ${this.state.total_stake}, Total Win / Loss: ${this.state.total_win}</div>
                                        </>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default withRouter(History);