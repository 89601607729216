import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card} from 'react-bootstrap';
import { TbCalendarPlus, TbCalendarStats, TbCurrencyDollar, TbFileDollar } from 'react-icons/tb';
var dateFormat = require("dateformat");


createTheme('dark_s', {
    text: {
      primary: '#fff',
      secondary: '#aaa',
    },
    background: {
      default: 'transparent',
    },
    context: {
      background: '#091929',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(255,255,255,.54)',
      hover: 'rgba(255,255,255,.08)',
      disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

const arraytorow = (data) => {
    return data.map((row) => <p>{row}</p>);
}

const columns = [
    {
        name: 'Placed On',
        selector: 'created_on',
        sortable: false,
        left: true,
        width: "180px",
        cell: row => <div data-tag="allowRowEvents">{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""} <br/> {row.fb===1 ? <span className='badge bg-primary'>Free Bet</span> : ""}</div>,
    },
    {
        name: 'Type',
        selector: 'type',
        sortable: false,
        right: false,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">{row.type===1 ? "Single" : row.type===3 ? "Credit" : row.type===9 ? "Casino" : "Multiple"}</div>,
    },
    {
        name: 'Bet Details',
        selector: 'bet',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{arraytorow(row.bet)}</div>,
    },
    {
        name: 'Risk',
        selector: 'stake',
        sortable: false,
        right: true,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">${row.stake}</div>,
    },
    {
        name: 'Win',
        selector: 'win',
        sortable: false,
        right: true,
        width: "100px",
        cell: row =><div data-tag="allowRowEvents">${row.win}</div>,
    }
];


let source = axios.CancelToken.source();


class Home extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }


    state = {
        data:[{}],
        user:[],
        loading:false,
        login_status: 0
    };

    get_data = () => {
        const token = localStorage.getItem("token");
        if(!token){this.props.history.push("/login");}
        const id = localStorage.getItem("id");
        this.setState({data:[{}], loading:true});
        axios.get('/api/dashboard', {
            params:{id:id},
            headers:{token: token},
            cancelToken: source.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                
                this.setState({
                    login_status: 1,
                    user:response.user,
                    data:response.data,
                    loading:false
                });
                localStorage.setItem('balance', response.user.balance);
                localStorage.setItem('casino', response.user.casino);
                window.dispatchEvent(new Event('storage'));
        
                if(this.props.login_status !== 1) 
                    this.props.setLoginStatus(1);
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    UNSAFE_componentWillMount(){
        this.get_data();
    }

    render () {
        if(this.state.login_status === 0) return (<></>);

        return (
            <>
                <div className='main-content'>

                    <Container fluid className='mb-20'>
                        <Row className="g-2">
                            <Col xs={6} lg={3}>
                                
                                <div className='stats'>
                                    <Col xs={3} className="icon">
                                        <TbCurrencyDollar/>
                                    </Col>
                                    <Col xs={9} className="text">
                                        <p className='tagvalue'>${this.state.data.balance}</p>
                                        <p className='tagname'>Balance</p>
                                    </Col>
                                </div>
                                
                            </Col>
                            <Col xs={6} lg={2}>
                                
                                <div className='stats'>
                                    <Col xs={3} className="icon">
                                        <TbFileDollar/>
                                    </Col>
                                    <Col xs={9} className="text">
                                        <p className='tagvalue'>${this.state.data.openbet}</p>
                                        <p className='tagname'>Open Bet</p>
                                    </Col>
                                </div>
                                
                            </Col>
                            <Col xs={6} lg={2}>
                                
                                <div className='stats'>
                                    <Col xs={3} className="icon">
                                        <TbCalendarPlus/>
                                    </Col>
                                    <Col xs={9} className="text">
                                        <p className='tagvalue'>${this.state.data.this_week_pl}</p>
                                        <p className='tagname'>This week P/L</p>
                                    </Col>
                                </div>
                                
                            </Col>
                            <Col xs={6} lg={2}>
                                
                                <div className='stats'>
                                    <Col xs={3} className="icon">
                                        <TbCalendarStats/>
                                    </Col>
                                    <Col xs={9} className="text">
                                        <p className='tagvalue'>${this.state.data.last_week_pl}</p>
                                        <p className='tagname'>Last week P/L</p>
                                    </Col>
                                </div>
                                
                            </Col>

                            <Col xs={6} lg={3}>
                                
                                <div className='stats'>
                                    <Col xs={3} className="icon">
                                        <TbFileDollar/>
                                    </Col>
                                    <Col xs={9} className="text">
                                        <p className='tagvalue'>${this.state.data.outstanding}</p>
                                        <p className='tagname'>Owe/owed</p>
                                    </Col>
                                </div>
                                
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid className='mb-20'>
                        <Row>
                            <Col className='billing'>
                                <Card body className='bg-s-secondary'>
                                    {this.state.loading ? <div className="loading">Loading...</div> : 
                                        <DataTable
                                            title="Open Bets"
                                            columns={columns}
                                            data={this.state.data.openbetlist}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                        />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default withRouter(Home);