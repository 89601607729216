import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Button,Toast } from 'react-bootstrap';
import socketIOClient from "socket.io-client";
import config from '../../config'
import rocket from './rocket.gif'
import explosion from './explosion.gif'
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const SOCKET_URL = config.SOCKET_URL_CRASH;
const socket = socketIOClient(SOCKET_URL);




let source = axios.CancelToken.source();
class Crash extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
        this.register_socket();
    }

    state = {
        login_status: 0,
        toastMsg:"",
        showToast:false,
        user:[],
        multiplier:1.00,
        game_status:0,
        hash:"",
        bet_stake:0,
        bet_multiplier:2,
        bet_win:0,
        placingbet:0,
        chartlabels:this.range(1,200),
        chartdata:[1.00],
        pointradius:[6],
        rocket_style:{
            left:"1%",
            bottom:"1%",
            rotate:0,
            display:1
        },
        bomb:0,
        newgametime:10,
        betdata:{},
        previousGames:[],
        userBets:[],
        cashout:0,
        manualcashout:0,
        currentchartvalue:1,
        chart_bg:"rgb(255, 193, 7)",
        chart_border:"rgb(255, 255, 255)",
        yaxis:[3, 2.5, 2, 1.5],
        xaxis:[2, 4, 6, 8],
    };

    UNSAFE_componentWillMount(){
        this.verify_token();
    }

    register_socket = () => {
        socket.on("crash_start", data => {
            //new game start
            this.setState({
                game_status:1,
                hash:data.hash, 
                multiplier:1.00,
                chartdata:[1.00],
                chartlabels:this.range(1,200),
                rocket_style:{
                    left:"1%",
                    bottom:"1%",
                    rotate:50,
                    display:1
                },
                bomb:0,
                previousGames:data.previousGames,
                cashout:0,
                currentchartvalue:1,
                pointradius:[6],
                chart_bg:"rgb(255, 193, 7)",
                chart_border:"rgb(255, 255, 255)",
                xaxis:[2, 4, 6, 8],
                yaxis:[3, 2.5, 2, 1.5],
            });

            this.newgametimeInterval = setInterval(() => {
                this.setState(({ newgametime }) => ({
                    newgametime: newgametime - 1
                }))
            }, 1000)
        });


        socket.on("crash_update", data => {
            //update new multiplier of crash
            const betdata = this.state.betdata;
            let cashout = 0;
            let manualcashout = this.state.manualcashout
            if(data.multiplier>=betdata.multiplier) {
                cashout = betdata.multiplier;
                manualcashout= 2;
            }
            let left = (data.multiplier-1)*15;
            if(left>=80){ left = 80 }

            let bottom = (data.multiplier-1)*10;
            if(bottom>=70){ bottom = 70 }

            let rotate = 50;
            rotate = rotate-(data.multiplier-1)*15;
            if(rotate<=-40) {
                rotate=-40;
            }
            
            const rocket_style = {
                left:left+"%",
                bottom:bottom+"%",
                rotate:rotate
            }

            let current_chartdata = this.state.chartdata;
            let current_labels = this.state.chartlabels;
            
            /*
            if(data.updatechart===1) {
                console.log(current_chartdata.length)
                if(current_chartdata.length>10) {
                    //current_chartdata.shift();
                    //current_labels.shift();
                }
                current_chartdata.push(data.multiplier);
                if(data.sec/1000>3){
                    current_labels.push(data.sec/1000);
                }
            }
            */
            let current_xaxis = this.state.xaxis;
            if(data.sec/1000>8){
                if((data.sec/1000 % 2 === 0)) {
                    current_xaxis.shift();
                    current_xaxis.push(data.sec/1000);
                }
                
            }

            let current_yaxis = this.state.yaxis;
            let extra = 0.5;
            if(data.multiplier>100){
                extra = 50;
            } else if(data.multiplier>50){
                extra = 25;
            } else if(data.multiplier>10){
                extra = 5;
            }

            if(data.multiplier>2.5){
                if((data.multiplier.toFixed(2) % 0.5 === 0)) {
                    current_yaxis.pop();
                    current_yaxis.unshift((data.multiplier+extra).toFixed(1));
                }
                
            }
           
            const currentcvalue = this.state.currentchartvalue+1,
            pointradius=this.state.pointradius;

            
            if(currentcvalue<=200){
                current_chartdata.push(currentcvalue);
                pointradius.unshift(0);
            } else {
                const pr = pointradius[pointradius.length-1];    
                if(pr===6) {
                    pointradius[pointradius.length-1]=5
                } else {
                    pointradius[pointradius.length-1]=6
                }
                
            }   
            

            this.setState({
                game_status:2,
                multiplier:data.multiplier,
                rocket_style:rocket_style,
                bomb:0,
                cashout:cashout,
                manualcashout:manualcashout,
                chartdata:current_chartdata,
                chartlabels:current_labels,
                currentchartvalue:currentcvalue,
                pointradius:pointradius,
                xaxis:current_xaxis,
                yaxis:current_yaxis

            });
        });

        socket.on("crash_end", data => {
            //game ended waiting for new game to start
            //display results
            clearInterval(this.newgametimeInterval)
            this.setState({
                game_status:3,
                hash:data.hash,
                multiplier:data.multiplier,
                bomb:1,
                newgametime:10,
                betdata:{},
                placingbet:0,
                manualcashout:0,
                chart_bg:"rgb(23, 43, 57)",
                chart_border:"rgb(5, 21, 37)"
            });

            this.verify_token();
        });
    }

    verify_token = () => {
        const token = localStorage.getItem("token");
        axios.get('/api/simpleauth', {
            headers:{token: token}
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    user:response.data,
                    login_status: 1
                });

                if(this.props.login_status !== 1)
                    this.props.setLoginStatus(1);

                localStorage.setItem('balance', response.data.balance);
                window.dispatchEvent(new Event('storage'));
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    betchange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]:value
        }, () => { this.calculateWin()});
        
        
    }

    calculateWin = () => {
        const stake = this.state.bet_stake;
        const multiplier = this.state.bet_multiplier
        const win = stake*multiplier;
        this.setState({
            bet_win:win.toFixed(2)
        });
    }

    placeBet = (event) => {
        //update immediate balance
        this.setState({
            placingbet:1
        });

        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        const betdata = {
            stake:this.state.bet_stake,
            multiplier:this.state.bet_multiplier,
            win:this.state.bet_win,
            hash:this.state.hash
        }
        axios.post('/api/casino/crash-bet', {
            id:this.state.user.id,
            betdata:betdata
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                //success
                const userBets = this.state.userBets;
                if(userBets.length>=5){
                    userBets.shift();
                }

                userBets[betdata.hash] = betdata.multiplier;

                this.setState({ 
                    placingbet:2,
                    betdata:betdata,
                    userBets:userBets
                });

                localStorage.setItem('balance', response.balance);
                window.dispatchEvent(new Event('storage'));
                
            } else {
                this.setState({
                    placingbet:0,
                    toastMsg:response.error_msg,
                    showToast:true
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                toastMsg:e.message,
                showToast:true
            });
            //yconsole.log(e.message);
        });
    }


    cashOut = (event) => {
        //update immediate balance
        this.setState({
            manualcashout:1
        });

        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        const win = this.state.bet_stake*this.state.multiplier;
        const betdata = {
            stake:this.state.bet_stake,
            multiplier:this.state.multiplier,
            win:win,
            hash:this.state.hash
        }
        axios.post('/api/casino/crash-cashout', {
            id:this.state.user.id,
            betdata:betdata
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                //success
                const userBets = this.state.userBets;
                if(userBets.length>=5){
                    userBets.shift();
                }

                userBets[betdata.hash] = betdata.multiplier;

                this.setState({ 
                    manualcashout:2,
                    betdata:betdata,
                    userBets:userBets
                });
                
            } else {
                this.setState({
                    manualcashout:0,
                    toastMsg:response.error_msg,
                    showToast:true
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                manualcashout:0,
                toastMsg:e.message,
                showToast:true
            });
            //yconsole.log(e.message);
        });
    }

    componentWillUnmount(){
        clearInterval(this.newgametimeInterval);
        socket.removeAllListeners();
        //console.log("component unmount")
    }

    range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    render () {
        if(this.state.login_status === 0) return (<></>);

        const ngtime = this.state.newgametime;
        if(ngtime<=0){
            clearInterval(this.newgametimeInterval)
        }

        const options = {
            animation:false,
            responsive: true,
            tension : 0.4,
            layout: {
                padding: {
                    left:60,
                    right:30
                }
            },
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false
              }
            },
            scales: {
                x: {
                    display:false,
                },
                y: {
                    min:-20,
                    max:220,
                    ticks: {
                        stepSize: 1,
                        padding: 50
                    },
                    display:false
                }
            }
        };

        //console.log(this.state.chartdata[0])

        const labels = this.state.chartlabels;
        const chartdata = {
            labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: this.state.chartdata,
                borderWidth:8,
                borderColor: this.state.chart_border,
                backgroundColor: this.state.chart_border,
                lineTension: 1,        
                radius: this.state.pointradius,
                fill: {
                    target: 'origin',
                    above: this.state.chart_bg,   // Area will be red above the origin
                }
              }
            ],
        };
        
        const Rocketstyles = { 
            transform: `rotate(${this.state.rocket_style.rotate}deg)`,
            left:this.state.rocket_style.left,
            bottom:this.state.rocket_style.bottom,
        };

        return(
            <>
                <Toast bg="dark" onClose={() => this.setState({ showToast:false, toastMsg:""})} show={this.state.showToast} delay={3000} autohide>
                    <Toast.Body>{this.state.toastMsg}</Toast.Body>
                </Toast>

                <div className='main-content'>
                    <div className='sport-betting-lines casino-crash'>
                        <div className='playground-crash'>
                            <div className='crash-previous'>
                                {
                                    this.state.previousGames.map((games, e) => (
                                        <span key={e} className={this.state.userBets[games.hash] && this.state.userBets[games.hash]<=games.multiplier ? 'badge bg-success mx-1 crash-m-game' : this.state.userBets[games.hash] && this.state.userBets[games.hash]>=games.multiplier ? 'badge bg-danger mx-1 crash-m-game' : 'badge mx-1 crash-m-game' } >{games.multiplier}x {this.state.userBets[games.hash] ? <span className='crash-m-user'>{parseFloat(this.state.userBets[games.hash]).toFixed(2)}x</span> : ""}</span>
                                    ))
                                }
                            </div>
                            <div className='crash-chartwrapper'>
                                <div className='crash-yaxis'>
                                    <ul>
                                        
                                        {
                                            this.state.yaxis.map((ya, e) => (
                                                <li key={e}><span>{ya}x</span></li>
                                            ))
                                        }   
                                        <li key={'ey1'}><span>1.1x</span></li>
                                    </ul>
                                </div>
                                <Line ref="chart" data={chartdata} options={options}  className="areaChart"/>
                                <div className='crash-xaxis'>
                                    <ul>
                                        <li key={'xe0'}>0s</li>
                                        {
                                            this.state.xaxis.map((xa, e) => (
                                                <li key={e}>{xa}s</li>
                                            ))
                                        }   
                                    </ul>
                                </div>

                                {this.state.cashout>0 ? <div className='crash-cashout'>CASHED OUT AT {parseFloat(this.state.cashout).toFixed(2)}x</div> : "" }
                                <div className={this.state.game_status === 3 ? "crash-multiplier end" : "crash-multiplier"}>{this.state.multiplier.toFixed(2)} x</div>
                                <div className='crash-status'>
                                        {this.state.game_status === 0 ? "Connecting to server..." :  ""}
                                        {this.state.game_status === 1 ? "Starting game in "+ngtime :  ""}
                                        {this.state.game_status === 3 ? "Crashed" :  ""}
                                </div>
                            </div>
                            {/*<img src={this.state.bomb===1 ? explosion : rocket} className="crash-rocket" alt="Rocket" style={Rocketstyles} />*/}
                            
                        </div>
                    </div>

                    <div className='casino-bet-slip'>
                        <div className=''>
                            <Container fluid>
                                <Row className='bs-inputs'>
                                    <Col xs={6} md={6} lg={12} className='mb-10'>
                                        <label>Bet Amount</label>
                                        <input className="form-control form-control-sm" type="number" name="bet_stake" placeholder="Bet Amount" value={this.state.bet_stake} onChange={this.betchange}  disabled={this.state.placingbet===0 ? false : true}/>
                                    </Col>
                                    <Col xs={6} md={6} lg={12} className='mb-10'>
                                        <label>Cashout At</label>
                                        <input className="form-control form-control-sm" type="number" name="bet_multiplier" placeholder="Cashout At" value={this.state.bet_multiplier} onChange={this.betchange} disabled={this.state.placingbet===0 ? false : true} />
                                    </Col>
                                    <Col xs={12} className='mb-20'>
                                        <label>Profit to win</label>
                                        <input className="form-control form-control-sm" type="readonly" name="bet_win" placeholder="Profit to Win" value={this.state.bet_win} disabled />
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className='casino-bet-button mb-20'>
                            <Container fluid>
                                <Row>
                                    {this.state.game_status===1 ?
                                    <Col>
                                        {this.state.placingbet===0 ?
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.bet_win>0 ? false : true} onClick={()=>this.placeBet()}>Bet</Button>
                                        : <Button className='btn btn-warning w-100 btn-yellow' disabled>{this.state.placingbet===1 ? "Placing Bet..." : "Bet Placed" }</Button>
                                        }
                                    </Col>

                                    :

                                    this.state.game_status===2 &&  this.state.placingbet===2 && this.state.manualcashout===0 ?
                                    <Col>
                                        <Button className='btn btn-warning w-100 btn-yellow' onClick={()=>this.cashOut()}>Cash Out</Button>
                                    </Col>

                                    : this.state.manualcashout===1 ?
                                    
                                    <Col>
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled>Cashing Out</Button>
                                    </Col>

                                    : this.state.manualcashout===2 ?
                                    
                                    <Col>
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled>Cashed Out</Button>
                                    </Col>

                                    :

                                    <Col>
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled>Wait for next round</Button>
                                    </Col>
                                    }
                                </Row>
                            </Container>
                        </div>

                        
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Crash);