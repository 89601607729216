import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Form, Alert } from 'react-bootstrap';

let source = axios.CancelToken.source();
class Setting extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data: [{}],
        loading: false,
        password: "",
        error_msg: "",
        error_code: "",
        settings: {},
        login_status: 0,
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/auth', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {

                    this.setState({
                        login_status: 1,
                        data: response.data,
                        settings: response.settings,
                        loading: false
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    handleChange = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    handleChange2 = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let settings = this.state.settings;
        settings[name] = value;
        this.setState({
            settings: settings
        });
    }

    handleSave = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/settingSave', {
            id: id,
            password: this.state.password,
            token: token
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg
                });
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    UpdateSetting = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/settingUpdate', {
            id: id,
            settings: this.state.settings,
            token: token
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg
                });
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    UNSAFE_componentWillMount() {
        this.get_data();
    }



    render() {
        if (this.props.login_status === 0) return (<></>);

        return (
            <>
                {this.state.loading ? "" :
                    <Container fluid className='mb-20 mt-20'>
                        {this.state.error_code === -1 ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>{this.state.error_msg}</Alert>
                                </Col>
                            </Row>
                            : ""}
                        <Row>
                            <Col>
                                <Card body className='bg-s-secondary'>
                                    <Form onSubmit={this.handleSave}>
                                        <Form.Group className="mb-20">
                                            <Form.Label>User Type</Form.Label>
                                            <Form.Control type="text" value={this.state.data.type === 1 ? "Admin" : this.state.data.type === 2 ? "Manager" : "Bookie"} disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" value={this.state.data.username} disabled />
                                        </Form.Group>


                                        <Form.Group className="mb-20">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" value={this.state.data.name} disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" value={this.state.data.email} disabled />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" name="password" placeholder="Update Password" onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }

            </>
        );
    }
}

export default withRouter(Setting);