class Ball {
  constructor(pos, velocity, radius, path) {
    this.pos = pos;
    this.velocity = velocity;
    this.radius = radius;
    this.path = path;

    this.move = this.move.bind(this);
    this.updateOnCollition = this.updateOnCollition.bind(this);
  }

  static createNewBall(pos, radius, path, obstacles, interval, obstacle_radius, containers) {
    
    path =  createFullPath(path, obstacles, interval, obstacle_radius, containers);
    
    return new Ball(pos, [0, 0], radius, path);
  }

  move(step, obstacles, y_limit, dispatchOutcome) {
    let gravity = 45;
    let { pos, velocity } = this;
    let new_pos = [
      pos[0] + (velocity[0] * step),
      pos[1] + (velocity[1] * step)
    ];

    let new_velocity = [
      this.velocity[0],
      this.velocity[1] + (gravity * step)
    ];
    
    let {vel, path} = this.updateOnCollition(new_pos, new_velocity, obstacles);

    if (new_pos[1] >= y_limit)
      dispatchOutcome(new_pos[0]);

    return new Ball(new_pos, vel, this.radius, path);
  }

  updateOnCollition(pos, vel, obstacles) {
    const g = 45;
    
    const distance = ([x1, y1], [x2, y2]) => {
      let dx = x1 - x2;
      let dy = y1 - y2;
      return Math.sqrt((dx * dx) + (dy * dy));
    }

    let path = this.path;

    let bottom_position = [
      this.pos[0],
      this.pos[1] + this.radius
    ];
    if(!path[0] || !path[1])
      return {vel: vel, path: path}
    if (bottom_position[1] >= path[0][1] 
        // && (bottom_position[0] + this.radius) <= (path[0][0] + obstacles.radius) 
        // && (bottom_position[0] - this.radius) >= (path[0][0] - obstacles.radius)
      ) {
      let v_y = (-1) * vel[1] * 0.5
      let dy = path[1]?.[1] - this.pos[1]  
      // console.log(dy);
      let t = (v_y / g) - (Math.sqrt((v_y*v_y) + (2 * g * dy)) / g)
      let v_x = ( this.pos[0] - path[1]?.[0] ) / t;
      // console.log(v_x)
      return {vel: [v_x, v_y], path: path.slice(1)}
    }

    return {vel: vel, path: path}

    /**
     *  -- Simplified Physics Model
     */

    // let min_distance = this.radius + obstacles.radius;
    // let current_row;
    // let obstacle = obstacles.pos.find((row, index) => {
    //   current_row = index;
    //   return Math.abs(row[0][1] - pos[1]) < min_distance
    // })
    //   ?.find((obs_pos) => distance(pos, obs_pos) < min_distance);

    // if (obstacle) {
    //   let [x, y] = obstacle;
    //   let [ball_x, ball_y] = pos;
    //   let dx = ball_x - x
    //   let dy = ball_y - y

    //   let norm = Math.sqrt((dx * dx) + (dy * dy))

    //   let [vx, vy] = this.velocity;
    //   let speed = Math.sqrt((vx * vx) + (vy * vy));

    //   let vel_y = (0.5 * speed * dy / norm) + (0.5);
    //   if(speed < 1) {
    //     vel_y = ( speed * dy / norm) + (0.5);
    //   }

    //   let vel_x = 0.5 * speed * dx / norm ;

    //   return [vel_x, vel_y]
    // }

    // return vel
  }

}

function createFullPath(path, obstacles, interval, obstacle_radius, containers) {
  let index = path.reduce(
    (prev, curr) => prev + (curr === 'R' ? 1 : 0), 0
  );
  let xy_path = [];
  let obs = obstacles.slice()
  xy_path[0] = [containers[index][0] + interval / 2, containers[index][1]];
  for (let i = path.length - 1; i >= 0; i--) {
    let obstacles_row = obs[i];
    let obstacle;
    if (path[i] === 'L')
      obstacle = obstacles_row.find(obs => obs[0] > xy_path[0][0]).slice();
    else
      obstacle = obstacles_row.slice().reverse().find(obs => obs[0] < xy_path[0][0]).slice();

    obstacle[1] = obstacle[1] - obstacle_radius;
    xy_path.unshift(obstacle);
  }

  return xy_path;
}

export default Ball;