import { useLayoutEffect, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Ball from './ball';

const mults = {
  'low': [
    [0.5, 1.0, 1.1, 2.1, 5.6],
    [0.7, 1.0, 1.6, 2.0, 5.6],
    [0.5, 1.0, 1.1, 1.4, 3.0, 8.9],
    [0.7, 1.0, 1.3, 1.9, 3.0, 8.4],
    [0.5, 1.0, 1.1, 1.4, 1.6, 3.0, 10.0],
    [0.7, 0.9, 1.2, 1.9, 3.0, 4.0, 8.1],
    [0.5, 1.0, 1.1, 1.3, 1.4, 1.9, 4.0, 7.1],
    [0.7, 1.0, 1.1, 1.5, 2.0, 3.0, 8.0, 15.0],
    [0.5, 1.0, 1.1, 1.2, 1.4, 1.4, 2.0, 9.0, 16.0]
  ],
  'medium': [
    [0.4, 0.7, 1.3, 3.0, 13.0],
    [0.5, 0.9, 1.7, 4.0, 18.0],
    [0.4, 0.6, 1.4, 2.0, 5.0, 22.0],
    [0.5, 0.7, 1.8, 3.0, 6.0, 24.0],
    [0.3, 0.6, 1.1, 2.0, 4.0, 11.0, 33.0],
    [0.4, 0.7, 1.3, 3.0, 6.0, 13.0, 43.0],
    [0.2, 0.5, 1.0, 1.9, 4.0, 7.0, 15.0, 58.0],
    [0.3, 0.5, 1.3, 3.0, 5.0, 11.0, 18.0, 88.0],
    [0.3, 0.5, 1.0, 1.5, 3.0, 5.0, 10.0, 41.0, 110.0]
  ],
  'high': [
    [0.2, 0.3, 1.5, 4.0, 29.0],
    [0.2, 0.6, 2.0, 7.0, 43.0],
    [0.2, 0.3, 0.9, 3.0, 10.0, 76.0],
    [0.2, 0.4, 1.4, 5.2, 14.0, 120.0],
    [0.2, 0.2, 0.7, 2.0, 8.1, 24.0, 170.0],
    [0.2, 0.2, 1.0, 4.0, 11.0, 37.0, 260.0],
    [0.2, 0.2, 0.3, 1.9, 5.0, 18.0, 56.0, 420.0],
    [0.2, 0.2, 0.5, 3.0, 8.0, 27.0, 83.0, 620.0],
    [0.2, 0.2, 0.2, 2.0, 4.0, 9.0, 26.0, 130.0, 1000.0]
  ]
}

function PlinkoGame({ props, rows, risk, balls, updateBalls, setControllers, setConfiguration }) {

  const [counters, setCounters] = useState(null);
  const [canvas, setCanvas] = useState(null);

  const [game_configuration, setGameConfiguration] = useState({});
  const [current_multipliers, setCurrentMultipliers] = useState();
  // const [balls, setBalls] = useState([]);
  const [multipliers, setMultipliers] = useState([]);

  const [bet_amount, setBetAmount] = useState(0);
  const [bet_results, setBetResults] = useState([]);

  const canvasRef = useRef(null);

  const updateCounter = (x, counters) => {

    let index = counters.findIndex(({ box_coord }) => {
      return box_coord.x0 <= (x) && box_coord.x1 >= (x);
    });

    if (index < 0)
      return { index: -1, new_counters: counters };

    return updateCounters(index, counters);
  }

  const updateCounters = (index, counters) => {
    let new_counters = counters.slice();
    new_counters[index] = {
      ...new_counters[index],
      count: new_counters[index].count + 1
    }

    return { index: index, new_counters: new_counters };
  }

  const drawBall = (cx, radius, [x, y]) => {
    cx.fillStyle = '#EEE2DE';
    cx.strokeStyle = '#EA906C'
    cx.beginPath()
    cx.arc(x, y, radius, 0, 2 * Math.PI)
    cx.fill()
    cx.stroke();
  }

  const drawObstacle = (cx, radius, [x, y]) => {
    cx.fillStyle = 'rgba(255,255,255,0.3)'
    cx.strokeStyle = '#fff'
    cx.beginPath()
    cx.arc(x, y, radius, 0, 2 * Math.PI)
    cx.fill();
    cx.stroke();
  }

  function createBall(balls) {
    return start(balls, counters, multipliers, bet_results, game_configuration);
  }

  function animate(balls, counters, multipliers, bet_results, update, dispatch) {
    let last_time = null;
    let animation_frame_id = null;

    function frame(time) {

      if (time - last_time >= 100) {
        let { obstacles, lines } = game_configuration;

        balls = update(balls)
        balls = balls.filter(({ dispatch, ball: b }) => {
          let reached_bottom = (b.pos[1] > obstacles[lines - 1][0][1] + 20);
          if (reached_bottom) {
            let { index, new_counters } = updateCounter(b.pos[0], counters);
            counters = new_counters;
            if (index >= 0) {
              dispatch()
              let multiplier = current_multipliers[index];

              multipliers = [multiplier, ...multipliers];
              bet_results = [multiplier * bet_amount, ...bet_results];

              setMultipliers(multipliers);
              setBetResults(bet_results);
            }
            setCounters(counters);
            return false;
          }

          return true;
        })

        updateBalls(balls)
        if (balls.length === 0) {

          update(balls);
          cancelAnimationFrame(animation_frame_id);
          return;
        }

      }

      animation_frame_id = requestAnimationFrame(frame)
    }

    animation_frame_id = requestAnimationFrame(frame);

    return () => {
      cancelAnimationFrame(animation_frame_id);
    }
  }

  const setup = canvas => {
    let total_width = canvas.parentElement.clientWidth;
    let width = canvas.width = total_width;
    let height = canvas.height = width * 0.8;
    let cx = canvas.getContext('2d');

    const lines = rows;

    let { obstacles, interval } =
      getObstaclesPositions(width, height, lines);

    let squareCounter = []
    obstacles[lines - 1].forEach(([x, y], i) => {
      if (i === (obstacles[lines - 1].length - 1)) {
        return;
      }

      squareCounter.push({
        box_coord: {
          x0: x,
          y0: y + 20,
          x1: x + interval,
          y1: y + interval
        },
        count: 0
      })
    })

    setCounters(squareCounter);

    let ballRadius = interval * 0.2;
    let obstaclesRadius = interval * 0.1;

    let curr_mult = mults[risk][lines - 8];

    let multip = [];
    if (squareCounter.length % 2 === 0) {
      for (let i = curr_mult.length - 1; i >= 0; i--)
        multip.push(curr_mult[i]);

      for (let i = 0; i < curr_mult.length; i++)
        multip.push(curr_mult[i]);
    } else {
      for (let i = curr_mult.length - 1; i >= 0; i--)
        multip.push(curr_mult[i]);

      for (let i = 1; i < curr_mult.length; i++)
        multip.push(curr_mult[i]);
    }

    setCurrentMultipliers(multip);

    obstacles.forEach((row) => row.forEach(
      (pos) => {
        drawObstacle(cx, obstaclesRadius, pos)
      }))

    let config = {
      context: cx,
      width: width,
      height: height,
      ballRadius: ballRadius,
      obstaclesRadius: obstaclesRadius,
      obstacles: obstacles,
      interval: interval,
      lines: lines,
      counters: squareCounter
    }
    
    setConfiguration(config);
    setGameConfiguration(config);
  }

  const start = (balls, counters, multipliers, bet_results, game_configuration) => {

    let { context, width, height, ballRadius, obstaclesRadius, obstacles, lines } = game_configuration;

    let cx = context;

    obstacles.forEach((row) => row.forEach(
      (pos) => {
        drawBall(cx, obstaclesRadius, pos)
      })
    )

    return animate(balls, counters, multipliers, bet_results, (balls) => {
      cx.clearRect(0, 0, width, height)

      let nBalls = balls?.map(({ dispatch, ball: b }) => {
        let new_ball = b.move(0.1,
          { radius: obstaclesRadius, pos: obstacles },
          obstacles[lines - 1][0][1] + 20,
          () => { }
        )

        return { dispatch: dispatch, ball: new_ball };
      });

      nBalls?.forEach(({ ball: b }) => drawBall(cx, ballRadius, b.pos));

      obstacles.forEach((row) => row.forEach(
        (pos) => {
          drawObstacle(cx, obstaclesRadius, pos)
        })
      )

      return nBalls;
    });
  }

  useLayoutEffect(() => {
    let can = canvasRef.current;
    setup(can);
    setCanvas(can);
  }, [risk, rows]);


  return (
    <>
      <Col className="mb-10">
        <div className='plinko-container bg-s-dark '>
          <div className='multipliers'>
            {multipliers?.map((m, ind) => {
              if (ind > 3) return;
              return <div className='mult'>{m}x</div>
            })}
          </div>
          <div className="canvas-container">
            <canvas ref={canvasRef} {...props} />
          </div>{game_configuration?.obstacles?.[rows - 1] ?
            <div style={{
              width: (
                (game_configuration.obstacles[rows - 1][game_configuration.obstacles[rows - 1].length - 1][0] - (game_configuration.obstacles[rows - 1][0][0]))) + 'px'
            }}
              className='multipliers-list'>
              {
                current_multipliers?.map((m) => (
                  <div
                    style={{ minWidth: (game_configuration?.interval - 25) + 'px', fontSize: '10px' }}
                    className='current-game-multipliers'>
                    {m}
                  </div>
                ))
              }
            </div> : ''}
        </div>
      </Col>
      <Col className="mines-config plinko-game-controllers" md={4} >
        {setControllers(
          canvas ? createBall : () => { },
          balls.length !== 0
        )}
      </Col>
    </>
  )
}


function getObstaclesPositions(width, height, lines) {
  let positions = [];
  let middle = width / 2;
  width = width * 0.8;

  let row_interval_size = width / lines;
  let shift = row_interval_size / 2;
  let height_offset = height * 0.1;
  let height_available = height * 0.9;
  let row_height = height_available / lines;

  for (let i = 0; i < lines; i++) {
    let y = height_offset + row_height * i
    let row = []
    let row_width = i * row_interval_size;
    for (let j = 0; j <= i + 2; j++) {
      let x = 0;
      x = middle + row_interval_size * j - 2 * shift - row_width / 2;
      row.push([x, y])
    }
    positions.push(row);
  }

  return { obstacles: positions, interval: row_interval_size };
}

export default PlinkoGame;