import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
    text: {
        primary: '#fff',
        secondary: '#aaa',
    },
    background: {
        default: 'transparent',
    },
    context: {
        background: '#091929',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333',
    },
    action: {
        button: 'rgba(255,255,255,.54)',
        hover: 'rgba(255,255,255,.08)',
        disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');


const arraytorow = (data) => {
    if (data) {
        return data.map((row, index) => <p key={index}>{row}</p>);
    } else {
        return "";
    }

}

let columns = [];



let source = axios.CancelToken.source();
class Grading extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();

        columns = [
            {
                name: 'date',
                selector: 'created_on',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents">{"B" + row.id} <br />{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""} <br /> {row.fb === 1 ? <span className='badge bg-primary'>Free Bet</span> : ""}</div>,
            },
            {
                name: 'User Details',
                selector: 'username',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents"><small className='d-block text-secondary'>{row.manager_username} &gt; {row.bookie_username} &gt;</small> {row.username}</div>,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: false,
                right: false,
                width: "100px",
                cell: row => <div data-tag="allowRowEvents">{row.type === 1 ? "Single" : row.type === 3 ? "Credit" : row.type === 9 ? "Casino" : "Multiple"}</div>,
            },
            {
                name: 'Bet Details',
                selector: 'bet',
                sortable: false,
                right: false,
                cell: row => <div data-tag="allowRowEvents">{arraytorow(row.bet)}</div>,
            },
            {
                name: 'Risk / Win',
                selector: 'stake',
                sortable: false,
                right: true,
                width: "200px",
                cell: row => <div data-tag="allowRowEvents">${row.stake} / ${row.win}</div>,
            },
            {
                name: 'Status',
                selector: 'status_api',
                sortable: false,
                right: true,
                width: "100px",
                cell: row => <div data-tag="allowRowEvents">
                    <select key={row.id} defaultValue={row.status_api} className='form-control form-control-sm d-inline w-auto text-white' onChange={(event) => this.changeMe(row.id, event)}>
                        <option value="1" key="1">Win</option>
                        <option value="2" key="2">Loss</option>
                        <option value="3" key="3">Push</option>
                    </select>
                </div>,
            },
            {
                name: 'Confirm',
                selector: 'id',
                sortable: false,
                right: true,
                width: "100px",
                cell: row => <div data-tag="allowRowEvents"><Button className='btn btn-primary btn-xs' onClick={() => this.confirm(row.id)}>Confirm</Button></div>,
            }
        ];
    }


    state = {
        data: [{}],
        user: [],
        status: [],
        loading: false,
        login_status: 0
    };

    changeMe = (id, event) => {
        const status = parseInt(event.target.value);
        let status_data = this.state.status;
        status_data[id] = status;
        this.setState({
            status: status_data
        });

    }

    removeData(arr, id) {
        return arr.filter((obj) => obj.id !== id);
    }

    confirm = (bid) => {
        const status = this.state.status[bid];
        if (status > 0) {
            const data = this.state.data;
            const newdata = this.removeData(data, bid);
            this.setState({
                data: newdata
            });

            const token = localStorage.getItem("admin_token");
            const id = localStorage.getItem("admin_id");

            axios.get('/api/admin/grading-confirm', {
                params: { id: id, bid: bid, status: status },
                headers: { token: token },
                cancelToken: source.token
            }).then((res) => {

            }).catch((e) => {
                //yconsole.log(e.message);
            });
        }
    }


    get_data = () => {

        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");

        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/grading', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if (response.error_code === 401) {
                this.props.history.push("/admin/login");
            } else if (response.error_code === 0) {

                this.setState({
                    login_status: 1,
                    user: response.user,
                    data: response.data,
                    loading: false,
                    status: response.status
                });

                if (this.props.login_status !== 1)
                    this.props.setLoginStatus(1);

                if (this.props.user_type !== response.user.type)
                    this.props.setUserType(response.user.type);
            }
        }).catch((e) => {
            //yconsole.log(e.message);
        });
    }

    UNSAFE_componentWillMount() {
        this.get_data();
    }


    render() {
        if (this.props.login_status === 0) return (<></>);


        return (
            <>
                <Container fluid className='mb-20 mt-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body className='bg-s-secondary datatable'>
                                {this.state.loading ? <div className="loading">Loading...</div> :
                                    <>
                                        <DataTable
                                            title={"Bet Grading"}
                                            columns={columns}
                                            data={this.state.data}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                            pagination={true}
                                            paginationServer={true}
                                            page={this.state.page}
                                            paginationTotalRows={this.state.total}
                                            paginationPerPage={100}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => this.setPage(page)}
                                            paginationDefaultPage={this.state.page}
                                        />

                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Grading);