import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Form, Alert, Nav, Badge, Button, Modal } from 'react-bootstrap';
var dateFormat = require("dateformat");

let source = axios.CancelToken.source();
class AdminDetails extends Component {
  constructor(props) {
    super(props);

  }

  state = {
    data: [{}],
    user: [],
    managers_list: [],
    loading: false,
    username: "",
    manager_id: "",
    bookie_id: "",
    admin_data: {},
    managers: [],
    bookies: [],
    payment_type: 0,
    payment_value: 0,
    login_status: 0,
    mdl_error: false,
    mdl_error_msg: "",
    mdl_btn_disable: false,
    change_manager: false,
    change_password: false
  };

  UNSAFE_componentWillMount() {
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    if (!token) { this.props.history.push("/admin/login"); }
    const id = localStorage.getItem("admin_id");
    const aid = this.props.match.params.aid;
    this.setState({ data: [{}], loading: true });
    axios.get('/api/admin/users/get-admin-details', {
      params: { id: id, admin_id: aid },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          this.setState({
            login_status: 1,
            user: response.user,
            loading: false,
            admin_data: response.admin_data,
            managers_list: response.managers_list
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);
        }

        const type = this.props.match.params.type;
        if (response.user.type >= type) {
          //unauthorise to add
          this.props.history.push("/admin/users");
        }

      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    const type = this.props.match.params.type;
    event.preventDefault();
    this.setState({ loading: true });
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    axios.post('/api/admin/users/update-admin', {
      id: id,
      token: token,
      user_id: this.state.admin_id,
      admin_id: this.state.admin_data.admin_id,
      username: this.state.data.username,
      payment_type: this.state.payment_type,
      payment_value: this.state.payment_value,
      type: type
    })
      .then((res) => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
          name: "",
          email: "",
          contact: "",
          username: "",
        });
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  showmdl = (name) => {
    this.setState({
      [name]: true,
      mdl_error: false,
      mdl_error_msg: ""
    })
  }

  hidemdl = (name) => {
    this.setState({
      [name]: false,
      mdl_error: false,
      mdl_error_msg: ""
    })
  }

  mdlPassword = (event) => {
    event.preventDefault();
    this.setState({
      mdl_error: false,
      mdl_error_msg: "",
      mdl_btn_disable: true
    });

    let password = event.target.password.value;
    let cpassword = event.target.cpassword.value;

    if (password === cpassword) {
      const token = localStorage.getItem("admin_token");
      const id = localStorage.getItem("admin_id");
      const uid = this.props.match.params.aid;
      axios.get('/api/admin/users/change-admin-password', {
        params: { id: id, uid: uid, password: password },
        headers: { token: token },
        cancelToken: source.token
      }).then((res) => {
        this.get_data();
        this.setState({
          mdl_error: false,
          mdl_error_msg: "",
          mdl_btn_disable: false
        });
        this.hidemdl("change_password");
      }).catch((e) => {
        //yconsole.log(e.message);
      });
    } else {
      this.setState({
        mdl_error: true,
        mdl_error_msg: "Your password didn't match",
        mdl_btn_disable: false
      });
    }

  }

  mdlAccount = (event) => {
    event.preventDefault();
    this.setState({
      mdl_error: false,
      mdl_error_msg: "",
      mdl_btn_disable: true
    });

    let username = event.target.username.value;
    let name = event.target.name.value;
    let email = event.target.email.value;
    let contact = event.target.contact.value;
    let payment_type = event.target.payment_type?.value;
    let payment_value = event.target.payment_value?.value;

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const aid = this.props.match.params.aid;

    axios.post('/api/admin/users/update-admin', {
      id: id,
      user_id: aid,
      username: username,
      name: name,
      email: email,
      contact: contact,
      payment_type: payment_type,
      payment_value: payment_value,
      token: token,
      cancelToken: source.token
    }).then((res) => {
      this.get_data();
      this.setState({
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false
      });
      console.log("Closing modal")
      this.hidemdl("mdlAccount");
    }).catch((e) => {
      //yconsole.log(e.message);
    });

  }

  handleManagerChange = (event) => {
    event.preventDefault();

    console.log("Sending Request")
    let user_id = this.state.admin_data.id;
    let manager_id = event.target.manager_id.value;

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    axios.post('/api/admin/users/update-admin', {
      id: id,
      user_id: user_id,
      admin_id: manager_id,
      token: token,
      cancelToken: source.token
    }).then((res) => {
      this.get_data();
      this.setState({
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false
      });
      this.hidemdl('change_manager');
    }).catch((e) => {
      console.log(e);
      //yconsole.log(e.message);
    });
  }

  getUserTitle = () => {
    if (this.state.admin_data?.type === 2) return 'Manager';
    if (this.state.admin_data?.type === 3) return 'Bookie';
    return '';
  }

  render() {
    if (this.state.login_status === 0) return (<></>);

    return (
      <>
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col md="9" className='billing'>
              <Card className='bg-s-dark'>
                <Card.Header>{this.getUserTitle()} Details</Card.Header>
                <Card.Body className='bg-s-secondary'>
                  {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
                  {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}



                  <Form>

                    <Form.Group className="mb-20">
                      <Form.Label>Username</Form.Label>
                      <Form.Control type="text"
                        value={this.state.admin_data.username}
                        onChange={this.handleChange} readOnly
                      />
                    </Form.Group>

                    {this.state.admin_data.type === 3
                      ? <Form.Group className="mb-20">
                        <Form.Label>Manager</Form.Label>
                        <Form.Control type="text"
                          value={this.state.admin_data.manager_username} readOnly
                        />
                      </Form.Group>
                      : ''
                    }


                    <Form.Group className="mb-20">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" value={this.state.admin_data.name} readOnly />
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" value={this.state.admin_data.email} readOnly />
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>Contact #</Form.Label>
                      <Form.Control type="text" value={this.state.admin_data.contact} readOnly />
                    </Form.Group>


                    {this.state.admin_data?.payment_type ?
                      <Form.Group className="mb-20">
                        <Form.Label>Payment Type</Form.Label>
                        <Form.Control type="text"
                          value={
                            this.state.admin_data.payment_type === 0
                              ? "Not Selected"
                              : this.state.admin_data.payment_type === 1 ? "Percentage" : "Pay per head"
                          }
                          readOnly
                        />
                      </Form.Group> : ""}

                    {this.state.admin_data?.payment_type ?
                      <Form.Group className="mb-20">
                        <Form.Label>{this.state.admin_data.payment_type === 1 ? "%" : "$"}</Form.Label>
                        <Form.Control type="text"
                          value={this.state.admin_data.payment_value}
                          readOnly
                        />
                      </Form.Group>
                      : ""
                    }

                  </Form>

                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              {this.state.admin_data?.type === 3
                ? <Button variant="primary" className='d-block w-100 mb-10' onClick={() => this.showmdl("change_manager")} >
                  Change Manager
                </Button> : ""}
              <Button variant="primary" className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlAccount")}>
                Edit {this.getUserTitle()}
              </Button>
              <Button variant="primary" className='d-block w-100 mb-10' onClick={() => this.showmdl("change_password")}>
                Change Password
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal show={this.state.mdlAccount} onHide={() => this.hidemdl("mdlAccount")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Form onSubmit={this.mdlAccount}>
            <Modal.Header closeButton>
              <Modal.Title>Update Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

              <Form.Group className="mb-20">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" name="username" defaultValue={this.state.admin_data.username} required />
              </Form.Group>

              <Form.Group className="mb-20">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" defaultValue={this.state.admin_data.name} />
              </Form.Group>

              <Form.Group className="mb-20">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" name="email" defaultValue={this.state.admin_data.email} />
              </Form.Group>

              <Form.Group className="mb-20">
                <Form.Label>Contact #</Form.Label>
                <Form.Control type="text" name="contact" defaultValue={this.state.admin_data.contact} />
              </Form.Group>

              {this.state.admin_data?.type === 3
                ? <>
                  <Form.Group className="mb-20">
                    <Form.Label>Payment Type</Form.Label>
                    <select className='form-control text-white' name="payment_type" defaultValue={this.state.admin_data.payment_type} required>
                      <option value="1">Percentage</option>
                      <option value="2">Pay per head</option>
                    </select>
                  </Form.Group>

                  <Form.Group className="mb-20">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="number" name="payment_value" defaultValue={this.state.admin_data.payment_value} required />
                  </Form.Group>
                </> : ""}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hidemdl("mdlAccount")}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                Update
              </Button>
            </Modal.Footer>

          </Form>
        </Modal>
        <Modal show={this.state.change_manager} onHide={() => this.hidemdl("change_manager")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Form onSubmit={this.handleManagerChange}>
            <Modal.Header closeButton>
              <Modal.Title>Update Manager</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-20">
                <Form.Label>Manager :</Form.Label>
                <select className='form-control text-white' name="manager_id" defaultValue={this.state.admin_data.admin_id} required>
                  {this.state.managers_list.map((manager) => (
                    <option key={manager.manager_id} value={manager.manager_id}>{manager.manager_username}</option>
                  ))}
                </select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hidemdl("change_manager")}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                Update
              </Button>
            </Modal.Footer>
          </Form>

        </Modal>

        <Modal show={this.state.change_password} onHide={() => this.hidemdl("change_password")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Form onSubmit={this.mdlPassword}>
            <Modal.Header closeButton>
              <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

              <Form.Group className="mb-20">
                <Form.Label>New Password</Form.Label>
                <Form.Control type="text" name="password" required />
              </Form.Group>

              <Form.Group className="mb-20">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="text" name="cpassword" required />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hidemdl("change_password")}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                Update
              </Button>
            </Modal.Footer>
          </Form>

        </Modal>
      </>
    );
  }
}

export default withRouter(AdminDetails);