import React, { useState, useRef, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Container, Card, Row, Col, Form, InputGroup, Alert } from "react-bootstrap";
import './dice.css';
import useSound from "use-sound";
import clickSound from "./button_click.mp3";

class Dice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bet_amount: 0,
      results: [],
      login_status: 0,
      user: {}
    }
  }

  componentDidMount() {
    this.verify_token();
  }

  verify_token = () => {
    const token = localStorage.getItem("token");
    axios.get('/api/simpleauth', {
      headers: { token: token }
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          this.setState({
            user: response.data,
            login_status: 1
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          localStorage.setItem('balance', response.data.balance);
          window.dispatchEvent(new Event('storage'));

        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      })
  }


  setBet = (target, condition, addOutcomePoint) => {
    let bet_data = {
      bet_amount: parseFloat(this.state.bet_amount),
      target: target,
      condition: condition
    }

    let token = localStorage.getItem('token');
    let id = this.state.user.id;

    axios.post('/api/casino/dice-bet', {
      bet_data: bet_data,
      id: id
    }, {
      headers: { token: token }
    })
      .then(res => {
        const response = res.data;

        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          let isWin;

          if (response.bet_data.condition === 'above')
            isWin = response.bet_data.outcome >= response.bet_data.target;
          else
            isWin = response.bet_data.outcome <= response.bet_data.target;

          let result = { win: isWin ? 'win' : '', outcome: response.bet_data.outcome }

          this.setState({
            error_code: 0,
            error_msg: "",
            bet_data: response.bet_data,
            results: [result, ...this.state.results]
          })

          localStorage.setItem('balance', response.balance);
          window.dispatchEvent(new Event('storage'));

          addOutcomePoint(response.bet_data.outcome);

        } else {
          this.setState({
            error_code: response.error_code,
            error_msg: response.error_msg
          })

          setTimeout(() => {
            this.setState({
              error_code: 0,
              error_msg: ''
            })
          }, 8000);
        }
      })
  }

  handleChange = event => {
    let { name, value } = event.target;

    this.setState({
      [name]: value
    })
  }

  render() {
    if (this.state.login_status === 0) return (<></>);

    return (
      <div className="main-content mines-config dice-game">
        <Container className="bet-lines">
          <Card className="bg-s-dark">
            <Card.Header>
              <h1>DICE</h1>
            </Card.Header>
            <Card.Body className="body-game bg-s-secondary">
              {this.state.error_code === 1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ''}
              <Row>
                <SlideBar
                  result_list={this.state.results}
                  setControllers={(target, condition, multiplier, addOutcomePoint) => {
                    return (
                      <Col md={4}>
                        <Form.Group className="mb-10">
                          <Form.Label className="text-start">Bet: </Form.Label>
                          <InputGroup>
                            <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
                            <Form.Control className="form-control text-white" name="bet_amount" type="number" step="0.001" value={this.state.bet_amount} onChange={this.handleChange} />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-10">
                          <Form.Label className="text-start">Profit on Win: </Form.Label>
                          <InputGroup>
                            <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
                            <Form.Control className="form-control text-white" name="profit" type="number" step="0.001" value={(this.state.bet_amount * multiplier).toFixed(2)} disabled />
                          </InputGroup>
                        </Form.Group>
                        <button
                          onClick={() => this.setBet(target, condition, addOutcomePoint)} className='make-bet-button text-white d-block w-100 p-5 mb-10'>
                          {'Bet'}
                        </button>
                      </Col>
                    )
                  }}
                />
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}

function SlideBar({ setControllers, result_list }) {
  const [position, setPosition] = useState(50);
  const [containerWidth, setContainertWidth] = useState(0);
  const [over, setOver] = useState(true);
  const [outcome_point, setOutcomePoint] = useState(null);

  const [playSound] = useSound(clickSound);
  const slider = useRef();

  useEffect(() => {
    let slideBar = slider.current;

    if (slideBar) {
      let containerRect = slideBar.parentElement.getBoundingClientRect();
      setContainertWidth(containerRect.width);

      slideBar.addEventListener('pointerdown', event => {
        let pos_x = event.clientX;
        let slideBarRect = slideBar.getBoundingClientRect();

        let offset = pos_x - slideBarRect.x;

        let limit_left = containerRect.width * 0.02;
        let limit_right = containerRect.width * 0.98;

        let move = event => {
          let new_x = event.clientX;
          new_x = new_x - containerRect.x;

          if (event.buttons === 0)
            return;

          if (new_x <= limit_left || new_x >= limit_right)
            return;

          let position = Math.round(new_x * 100 / containerRect.width);

          setPosition(position);
        }

        let end = () => {
          window.removeEventListener('pointermove', move);
          window.removeEventListener('pointerup', end);
        }

        window.addEventListener('pointermove', move);
        window.addEventListener('pointerup', end);

      })
    }
  }, []);

  function roll() {
    setOver(!over);
    setPosition(100 - position);
  }

  let target = position;

  let chance = over
    ? 100 - position
    : position;

  let multiplier = (100 / chance) * 0.99;
  return (
    <>
      <Col className="mb-10">
        <div className='dice-container bg-s-dark'>
          <ResultsQueue list={result_list} />
          <div className="bar-section">
            <div className="bar-wrapper">
              <div className="bar-container">
                <div className={over ? "bar-green-section" : "bar-red-section"}
                  style={{ width: '100%' }}
                ></div>
                <div className={over ? "bar-red-section" : "bar-green-section"}
                  style={{ width: (position * containerWidth / 100) + 'px' }}
                ></div>
                <div
                  ref={slider}
                  className="slider"
                  style={{ left: (position * containerWidth / 100), zIndex: 101 }}
                ></div>
                {outcome_point
                  ? <div
                    className="outcome-point"
                    style={{ left: (outcome_point * containerWidth / 100) + 'px' }}
                  ></div>
                  : ''
                }
              </div>
            </div>
            <div className="points">
              <div style={{ left: '0px' }}>0</div>
              <div style={{ left: (0.25 * containerWidth) + 'px' }}>25</div>
              <div style={{ left: (0.5 * containerWidth) + 'px' }}>50</div>
              <div style={{ left: (0.75 * containerWidth) + 'px' }}>75</div>
              <div style={{ left: containerWidth + 'px' }}>100</div>
            </div>
          </div>
          <div className="odds-data">
            <OddsData over={over} chance={chance} multiplier={multiplier} position={position} roll={roll} />
          </div>
        </div>
      </Col>
      {setControllers(target, over ? 'above' : 'below', multiplier, (point) => {
        playSound();
        
        setOutcomePoint(point);
      })}
    </>
  );
}

function ResultsQueue({ list }) {
  return (
    <>
      <div className="results-queue">
        {list.map(({ outcome, win }, index) => {
          if (index > 4) return;
          return (
            <div className={"result " + win}>{outcome.toFixed(2)}</div>
          )
        })}
      </div>
    </>
  );
}

function OddsData({ chance, position, over, multiplier, roll }) {

  return (
    <>
      <div className="mines-config ">
        <Row>
          <Col className="odds-data-container">
            <Form.Group >
              <Form.Label>Multiplier (x)</Form.Label>
              <Form.Control className="form-control text-white" type="number" value={multiplier.toFixed(2)} />
            </Form.Group>
          </Col>
          <Col className="odds-data-container">
            <Form.Group onClick={roll}>
              <Form.Label>Roll {over ? 'Over' : 'Under'}</Form.Label>
              <Form.Control className="form-control text-white roll" type="number" value={position.toFixed(2)} />
            </Form.Group>
          </Col>
          <Col className="odds-data-container">
            <Form.Group >
              <Form.Label>Win Chance (%)</Form.Label>
              <Form.Control className="form-control text-white" type="number" value={chance.toFixed(2)} />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  )

}

export default withRouter(Dice);