import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Card, Row, Col, Container, Button, Table } from 'react-bootstrap';
import { IoMdLock, IoMdAlert } from 'react-icons/io';
import axios from 'axios';
const moment = require('moment-timezone');

let source = axios.CancelToken.source();

class CustomBets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_status: 0,
      loading: false,
      user: [],
      id: 0,
      data: [],
      bet_type: '1'
    }
  }

  componentDidMount() {
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    this.setState({ data: [], loading: true });

    axios.get('/api/admin/custom-bets', {
      params: {
        id: id
      },
      headers: { token: token },
      canceToken: source.token
    })
      .then(res => {
        let response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          this.setState({
            login_status: 1,
            id: id,
            user: response.user,
            data: response.data
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);
        }
      });
  }

  getFormatedData = (data) => {
    if (data.length === 0) return { leagues: [], events: {} };
    let leagues = data.map(event => event.event_data?.league_name); //GET LEAGUES ARRAY
    leagues = leagues.filter((league, index) => leagues.indexOf(league) === index);

    let events = leagues.reduce((prev, curr) => { //Make obj with league_name as keys
      return { ...prev, [curr]: [] };
    }, {});

    data.forEach(event => events[event.event_data?.league_name].push(
      { event_id: event.event_id, ...event?.event_data }
    )); //Add each event in its corresponging league array


    return { leagues: leagues, events: events };
  }

  deleteBet = (event_id) => {

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    axios.get('/api/admin/custom-bets/delete', {
      params: { id: id, event_id: event_id },
      headers: { token: token },
      cancelToken: source.token
    }).then((res) => {

    }).catch((e) => {
      //yconsole.log(e.message);
    });

    this.props.history.push("/admin/custom-bets");

  }

  updateSel = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  sign = (team, hdp) => {
    if (team === 'away') {
      if (hdp < 0) { return "+" + Math.abs(hdp); }
      if (hdp > 0) { return "-" + Math.abs(hdp); }
      return Math.abs(hdp);
    }

    if (team === 'home') {
      if (hdp < 0) { return "-" + Math.abs(hdp); }
      if (hdp > 0) { return "+" + Math.abs(hdp); }
      return Math.abs(hdp);
    }
  }

  signsymbol = (num) => {
    if (num > 0) { return "+" + Math.abs(num); }
    if (num < 0) { return "-" + Math.abs(num); }
  }

  setBetterOdds = (odd) => {
    let digit = parseFloat(odd / 10).toFixed(1).split(".")[1];
    let nodigit = parseInt(parseFloat(odd / 10).toFixed(0) * 10);
    let final = odd > 0 ? (
      digit < 5 ? nodigit : odd + (5 - digit)
    ) : (
      digit < 5 ? odd - (5 - digit) : nodigit
    );
    return final;
  };

  format_odds = (odds) => {
    odds = parseFloat(odds);

    if (odds >= 2) {
      odds = (odds - 1) * 100;
      odds = this.setBetterOdds(odds);
      return this.signsymbol(parseFloat(odds).toFixed(0));
    } else {
      odds = (-100) / (odds - 1);
      odds = this.setBetterOdds(odds);
      return this.signsymbol(parseFloat(odds).toFixed(0));
    }
  }

  render() {

    if (this.props.login_status === 0) return (<></>);

    let { leagues, events } = this.getFormatedData(
      this.state.data.filter(d => d.event_data.bet_type !== 'list-bet')
    );

    let data = this.state.data.filter(
      event => event.event_data.bet_type === 'list-bet'
    );

    return (
      <>
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col className="text-end">
              <select className='form-control d-inline-block w-auto text-white mb-10' name="bet_type" onChange={this.updateSel} defaultValue={this.state.bet_type}>
                <option value="1">Normal Bets</option>
                <option value="2">List Bets</option>
              </select>

              <NavLink to="/admin/custom-bets/add-custom-bet" className="btn btn-primary d-inline text-light mb-10 ml-10">
                Add Custom Bet
              </NavLink>
            </Col>
          </Row>
          {this.state.bet_type === '1' ?
            <Row>
              <Card className="bg-s-secondary mb-10">
                <Card.Header className="card-header-small">Custom Bets</Card.Header>
                <Card.Body className="bg-s-primary p-0">
                  {Object.keys(events).length > 0 ? <EventsTable leagues={leagues} events={events} /> : <></>}
                </Card.Body>
              </Card>
            </Row> :
            this.state.bet_type === '2' ?
              data.map((event) => (
                <ListBet
                  key={event.id}
                  event_id={event.event_id}
                  sport_title={event.event_data.sport_title}
                  starts={event.event_data.starts}
                  outcomes={event.event_data.periods.num_0.outcomes}
                />
              )) : ""
          }
        </Container>
      </>
    );
  }
}

function EventsTable({ leagues, events }) {

  const format_datetime = (time) => {
    const event_time = moment.tz(moment(time + "Z"), "America/Los_Angeles").format("MM/DD/YYYY hh:mm A");
    return event_time;
  }

  const sign = (team, hdp) => {
    if (team === 'away') {
      if (hdp < 0) { return "+" + Math.abs(hdp); }
      if (hdp > 0) { return "-" + Math.abs(hdp); }
      return Math.abs(hdp);
    }

    if (team === 'home') {
      if (hdp < 0) { return "-" + Math.abs(hdp); }
      if (hdp > 0) { return "+" + Math.abs(hdp); }
      return Math.abs(hdp);
    }
  }

  const setBetterOdds = (odd) => {

    let digit = parseFloat(odd / 10).toFixed(1).split(".")[1];
    let nodigit = parseInt(parseFloat(odd / 10).toFixed(0) * 10);
    let final = odd > 0 ? (
      digit < 5 ? nodigit : odd + (5 - digit)
    ) : (
      digit < 5 ? odd - (5 - digit) : nodigit
    );
    return final;

  };

  const signsymbol = (num) => {
    if (num > 0) { return "+" + Math.abs(num); }
    if (num < 0) { return "-" + Math.abs(num); }
  }

  const format_odds = (odds) => {
    odds = parseFloat(odds);
    if (odds >= 2) {
      odds = (odds - 1) * 100;
      odds = setBetterOdds(odds);
      return signsymbol(parseFloat(odds).toFixed(0));
    } else {
      // if (odds === 1) return odds;

      odds = (-100) / (odds - 1);
      odds = setBetterOdds(odds);
      return signsymbol(parseFloat(odds).toFixed(0));
    }
  }

  if (!events) {
    return (
      <div className='no-live-data'>
        <IoMdAlert />
        <p>There are currently no events to show.</p>
      </div>)
      ;
  }

  return (
    <>
      {leagues?.map((league, i) => (
        events[league] ?

          <div key={i}>
            <div className='league_name bg-s-light'>
              <Container fluid>
                <Row>
                  <Col xs={4}>{league}</Col>
                  <Col xs={8}>
                    <Row>
                      <Col xs={4} className="bet-heading">HANDICAP</Col>
                      <Col xs={4} className="bet-heading">MONEY LINE</Col>
                      <Col xs={4} className="bet-heading">TOTAL</Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="events">
              <Container fluid>
                {
                  events[league].map((event, e) => (
                    event.event_id ?
                      <Row key={event.event_id} className="event-row">
                        <Col xs={4}>
                          <NavLink to={"/admin/custom-bets/edit-custom-bet/" + event.event_id} className="event_link">
                            <ul className='team-name'>
                              <li>{event.away}</li>
                              <li>{event.home}</li>
                              <li className='time'>{format_datetime(event.starts)}</li>
                            </ul>
                            <span className='more-bets'>Edit Bet</span>
                          </NavLink>
                        </Col>
                        <Col xs={8}>
                          <Row>
                            <Col xs={4} className="bet-lines">
                              <Row className="g-1">
                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.spreads !== null
                                      && event?.periods?.num_0?.spreads !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.spreads?.hdp !== "" ?

                                      <Button className="btn-sm" >
                                        <small>{sign("away", Object.values(event?.periods?.num_0?.spreads)[0]?.hdp)}</small>
                                        {format_odds(Object.values(event.periods.num_0.spreads)[0]?.away)}
                                      </Button>
                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>
                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.spreads !== 'undefined'
                                      && event?.periods?.num_0?.spreads !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.spreads?.hdp !== "" ?

                                      <Button className={"btn-sm"} >
                                        <small>{sign("home", Object.values(event.periods.num_0.spreads)[0]?.hdp)}</small>
                                        {format_odds(Object.values(event.periods.num_0.spreads)[0]?.home)}
                                      </Button>

                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={4} className="bet-lines">
                              <Row className="g-1">
                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.money_line !== 'undefined'
                                      && event?.periods?.num_0?.money_line !== null
                                      && event?.periods?.num_0?.money_line?.away !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.money_line?.away !== "" ?

                                      <Button className={"btn-sm"} >
                                        {format_odds(event.periods.num_0.money_line.away)}
                                      </Button>

                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>

                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.money_line !== 'undefined'
                                      && event?.periods?.num_0?.money_line !== null
                                      && event?.periods?.num_0?.money_line?.home !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.money_line?.away !== "" ?

                                      <Button className={"btn-sm"} >
                                        {format_odds(event.periods.num_0.money_line.home)}
                                      </Button>

                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>

                                {


                                  <Col xs={12} className="bet-lines">
                                    {
                                      event?.periods?.num_0?.money_line !== 'undefined'
                                        && event?.periods?.num_0?.money_line !== null
                                        && event?.periods?.num_0?.money_line?.draw !== null
                                        && event?.periods?.num_0?.period_status === 1
                                        && event?.periods?.num_0?.money_line?.away !== "" ?

                                        <Button className={"btn-sm btn-draw"}  >
                                          {format_odds(event.periods.num_0.money_line.draw)}
                                        </Button>

                                        : <Button className='btn-sm'><IoMdLock /></Button>
                                    }
                                  </Col>


                                }
                              </Row>
                            </Col>
                            <Col xs={4} className="bet-lines">
                              <Row className="g-1">
                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.totals !== 'undefined'
                                      && event?.periods?.num_0?.totals !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.totals?.points !== "" ?

                                      <Button className={"btn-sm"} >
                                        <small>O {Object.values(event.periods.num_0.totals)[0]?.points}</small>
                                        {format_odds(Object.values(event.periods.num_0.totals)[0]?.over)}
                                      </Button>

                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>
                                <Col xs={12} className="bet-lines">
                                  {
                                    event?.periods?.num_0?.totals !== 'undefined'
                                      && event?.periods?.num_0?.totals !== null
                                      && event?.periods?.num_0?.period_status === 1
                                      && event?.periods?.num_0?.totals?.points !== "" ?

                                      <Button className={"btn-sm"} >
                                        <small>U {Object.values(event.periods.num_0.totals)[0]?.points}</small>
                                        {format_odds(Object.values(event.periods.num_0.totals)[0]?.under)}
                                      </Button>

                                      : <Button className='btn-sm'><IoMdLock /></Button>
                                  }
                                </Col>

                              </Row>
                            </Col>

                          </Row>
                        </Col>
                      </Row>
                      : ""
                  ))
                }
              </Container>
            </div>
          </div> : ""
      ))}
    </>
  );
}

function ListBet({ sport_title, starts, outcomes, event_id }) {
  const format_datetime = (time) => {
    const event_time = moment.tz(moment(time + "Z"), "America/Los_Angeles").format("MM/DD/YYYY hh:mm A");
    return event_time;
  }
  return (
    <>
      <Container fluid className='mb-20 mt-20'>
        <Card className="bg-s-secondary mb-15">
          <Card.Header className="class-header">
            <Card.Title>{sport_title}</Card.Title>
            <Card.Subtitle className="mb-5">
              {starts}
              <NavLink to={"/admin/custom-bets/edit-custom-bet/" + event_id} className='btn btn-xs btn-warning ml-10 mx-1'>View Details</NavLink>
            </Card.Subtitle>
            <Card.Subtitle>Winner</Card.Subtitle>
          </Card.Header>
          <Card.Body className="bg-s-primary p-0">
            <Row>
              <Col className="text-end">
                <Table bordered hover variant="dark" responsive className='bg-s-dark mb-0'>
                  <thead>
                    <tr>
                      <th className='text-start pl-10'>Name</th>
                      <th className='text-center'>Price</th>
                      <th className="text-center"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outcomes.map(outcome => (
                      <>
                        <tr key={outcome.name} className='text-center'>
                          <td className='text-start'>{outcome.name}</td>
                          <td>{outcome.price}</td>
                          <td>
                            <Button className='btn btn-xs btn-warning ml-10 mx-1'>Edit</Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

    </>
  );
}

export default withRouter(CustomBets);