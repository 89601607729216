import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button, Table, Alert, Modal, Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

createTheme('dark_s', {
    text: {
        primary: '#fff',
        secondary: '#aaa',
    },
    background: {
        default: 'transparent',
    },
    context: {
        background: '#091929',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333',
    },
    action: {
        button: 'rgba(255,255,255,.54)',
        hover: 'rgba(255,255,255,.08)',
        disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

let columns = [];
let source = axios.CancelToken.source();
class Accounts extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
        columns = [
            {
                name: 'Username',
                selector: 'username',
                sortable: true,
                cell: row => <div>{row.username}</div>,
            },
            {
                name: 'Bookie',
                selector: 'bookie',
                sortable: true,
                cell: row => <div>{row.bookie}</div>,
            },
            
            {
                name: 'Outstanding',
                selector: 'totalpl',
                sortable: true,
                right: true,
                cell: row => <div data-tag="allowRowEvents">{this.formatOS(row.totalpl, row.paid)}</div>,
            },
            
            {
                name: 'Action',
                selector: 'totalpl',
                sortable: true,
                width:"200px",
                right: true,
                cell: row => <div data-tag="allowRowEvents">{this.formatOSAction(row.id, row.totalpl, row.paid)}</div>,
            }
        ];
    }

    formatOS = (pl, paid) => {
        paid = paid * -1;
        let balance = pl + paid;
        if(balance>0) {
            return (<span className='text-danger'>$ {(-1*balance).toFixed(2)}</span>);
        } else if(balance<0) {
            return (<span className='text-success'>$ {(-1*balance).toFixed(2)}</span>);
        } else {
            return (<span className='text-secondary'>$ 0.00</span>);
        }
    }

    formatOSAction = (id, pl, paid) => {
        let balance = this.state.ubalance[id];
        if(balance>0) {
            return (
                <div className='input-group input-group-sm'>
                    <input type="text" className='form-control d-inline' defaultValue={(balance).toFixed(2)} onChange={event => this.handlechange(event, id)} />
                    <button className='btn btn-sm btn-danger d-inline' onClick={() => this.paid(id)}>Paid</button>
                </div>
            );
        } else if(balance<0) {
            return (
                <div className='input-group input-group-sm'>
                    <input type="text" className='form-control d-inline' defaultValue={(-1*balance).toFixed(2)} onChange={event => this.handlechange(event, id)} />
                    <button className='btn btn-sm btn-success d-inline' onClick={() => this.received(id)}>Received</button>
                </div>
            );
        } else {
            return ("");
        }
    }

    updatepaid = (id, amount) => {
        let players = this.state.data;
        let balance = 0;
        for (let player in players) { 
            if(players[player].id==id) {
                players[player]['paid'] = players[player]['paid']+amount;
                players[player]['balance'] = players[player]['totalpl']-players[player]['paid'];
                balance = players[player]['balance'];
                break;
            }
        }

        let ubalance = this.state.ubalance;
        ubalance[id] = 0;
        
        this.setState({
            ubalance: ubalance,
            data: JSON.parse(JSON.stringify(players)),
        });

        const token = localStorage.getItem("admin_token");
        const aid = localStorage.getItem("admin_id");
        axios.get('/api/admin/users/transactions', {
            params: { id: aid, uid:id, amount: amount},
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            
        }).catch((e) => {
            //yconsole.log(e.message);
        });

    }

    paid = (id) => {
        let amount = Math.abs(this.state.ubalance[id]);
        this.updatepaid(id, amount);
    }

    received = (id) => {
        let amount = -1*Math.abs(this.state.ubalance[id]);
        this.updatepaid(id, amount);
        
    }

    handlechange = (event, id) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let ubalance = this.state.ubalance;
        ubalance[id] = value;
        this.setState({
            ubalance: ubalance
        });
    }

    state = {
        data: [{}],
        user: [],
        loading: false,
        error: "",
        fb_id: 0,
        fb_username: "",
        fb_bal: 0,
        mdlfb: false,
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false,
        login_status: 0,

        edit_current_balance: false,
        edit_weekly_balance: false,

        edit_id: 0,
        edit_username: '',
        edit_balance_value: 0,

        ubalance: []
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/users/list-account', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {
                    this.setState({
                        login_status: 1,
                        loading: false,
                        user: response.user,
                        data: response.data,
                        ubalance: response.ubalance,
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    UNSAFE_componentWillMount() {
        this.get_data();
        
    }

    

    render() {
        if (this.props.login_status === 0) return (<></>);

        return (<>
            <Container fluid className='mb-20 mt-60'>
                <Row>
                    <Col className='billing'>
                        <Card body className='bg-s-secondary datatable'>
                            {this.state.loading ? <div className="loading">Loading...</div> :
                                <>
                                    <DataTable
                                        title={"Accounting"}
                                        columns={columns}
                                        data={this.state.data}
                                        highlightOnHover={true}
                                        theme="dark_s"
                                        pagination={false}
                                    />
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

function EditModal({ show, hide, submit, title, modalName, fields, error, error_msg, btn_disabled }) {
    return (
        <Modal show={show} onHide={() => hide(modalName)} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Form onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error === true ? <Alert variant="danger">{error_msg}</Alert> : ""}

                    {fields.map(({ label, type, step, name, value }) => <>
                        <Form.Group className="mb-20" key={`${title}-${label}`}>
                            <Form.Label>{label}</Form.Label>
                            <Form.Control type={type} name={name} step={step} defaultValue={value} />
                        </Form.Group>
                    </>)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hide(modalName)}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" disabled={btn_disabled}>
                        Update
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default withRouter(Accounts);