import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Alert, FloatingLabel, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
const moment = require('moment');

let source = axios.CancelToken.source();
class EditCustomBet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_status: 0,
      mdlDelete: false,
      mdl_error: false,
      mdl_error_msg: "",
      id: 0,
      event_id: 0,
      user: [],
      league_name: "",
      starts_date: "01/01/2024",
      starts_time: "",
      home: "",
      away: "",

      edit_moneyline: false,
      have_moneyline: false,
      moneyline_home: "",
      moneyline_draw: "",
      moneyline_away: "",

      edit_spreads: false,
      have_spreads: false,
      spreads_handicap: "",
      spreads_home: "",
      spreads_away: "",
      spreads_max: "",

      edit_totals: false,
      have_totals: false,
      totals_points: "",
      totals_over: "",
      totals_under: "",
      totals_max: "",

      edit_team_totals: false,
      have_team_totals: false,
      team_totals_home_points: "",
      team_totals_home_over: "",
      team_totals_home_under: "",

      team_totals_away_points: "",
      team_totals_away_over: "",
      team_totals_away_under: "",

      bet_type: '',
      outcomes: [],
      edit_outcome_field: false,
      modal_index: -1,

    }

  }

  componentDidMount() {
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const event_id = this.props.match.params.cbid;


    this.setState({ data: [{}], loading: true });
    axios.get('/api/admin/custom-bets/get-event-data', {
      params: { id: id, event_id: event_id },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          console.log(response.data.event_data.bet_type)
          if (response.data.event_data.bet_type === 'list-bet') {
            return this.setListBet(response);
          }

          let { num_0 } = response.data.event_data.periods;
          //this can be checked directly with event_data   
          let have_moneyline = num_0.money_line !== null;
          let have_spreads = num_0.spreads !== null;
          let have_totals = num_0.totals !== null;
          let have_team_totals = num_0.team_total !== null && num_0.team_total !== undefined;

          let { money_line, spreads, totals, team_total } = num_0;
          spreads = Object.values(spreads || {})?.[0];
          totals = Object.values(totals || {})?.[0];

          console.log(response.data.event_data);

          console.log(have_moneyline);
          let date = moment.tz(moment(response.data.event_data.starts + "Z"), "America/Los_Angeles")
          // console.log(moment.tz(moment(response.data.event_data.starts + "Z"), "America/Los_Angeles").format("DD/MM/YYYY HH:MM"));
          console.log( moment.tz(moment(response.data.event_data.starts + "Z"), "America/Los_Angeles").format("hh:mm "));
          this.setState({
            login_status: 1,
            user: response.data,
            loading: false,
            event_data: response.data,
            event_id: response.data.event_id,
            league_name: response.data.event_data.league_name,
            home: response.data.event_data.home,
            away: response.data.event_data.away,
            starts_date: moment.tz(moment(response.data.event_data.starts + "Z"), "America/Los_Angeles").format("YYYY-MM-DD"),
            starts_time: moment.tz(moment(response.data.event_data.starts + "Z"), "America/Los_Angeles").format("HH:mm"),
            have_moneyline: have_moneyline,
            have_spreads: have_spreads,
            have_totals: have_totals,
            have_team_totals: have_team_totals,

            moneyline_home: money_line?.home || "",
            moneyline_draw: money_line?.draw || "",
            moneyline_away: money_line?.away || "",

            spreads_handicap: spreads?.hdp || "",
            spreads_home: spreads?.home || "",
            spreads_away: spreads?.away || "",
            spreads_max: spreads?.max || "",

            totals_points: totals?.points || "",
            totals_over: totals?.over || "",
            totals_under: totals?.under || "",
            totals_max: totals?.under || "",

            team_totals_away_points: team_total?.away?.points || "",
            team_totals_away_over: team_total?.away?.over || "",
            team_totals_away_under: team_total?.away?.under || "",

            team_totals_home_points: team_total?.home?.points || "",
            team_totals_home_over: team_total?.home?.over || "",
            team_totals_home_under: team_total?.home?.under || "",

          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);

        }
      })
      .catch((e) => {
        console.log(e);
        //yconsole.log(e.message);
      });
  }

  setListBet = (response) => {
    let { sport_title, starts, periods } = response.data.event_data;
    this.setState({
      event_id: response.data.event_id,
      bet_type: 'list-bet',
      sport_title: sport_title,
      starts_date: moment(starts).format("YYYY-MM-DD"),
      starts_time: moment(starts).format("HH:MM"),
      outcomes: periods.num_0.outcomes
    });

    if (this.props.login_status !== 1)
      this.props.setLoginStatus(1);

    if (this.props.user_type !== response.user.type)
      this.props.setUserType(response.user.type);
  }

  handleSave = (event) => {
    event.preventDefault();
    let state = this.state;
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    let moneyline = null;
    let spreads = null;
    let totals = null;
    let team_total = null;
    if (state.have_moneyline) {
      moneyline = {
        home: state.moneyline_home,
        draw: state.moneyline_draw,
        away: state.moneyline_away
      }
    }
    if (state.have_spreads && state.spreads_handicap) {
      spreads = {
        [state.spreads_handicap]: {
          hdp: state.spreads_handicap,
          home: state.spreads_home,
          away: state.spreads_away,
          max: state.spreads_max
        }
      }
    }
    if (state.have_totals) {
      totals = {
        [state.totals_points]: {
          points: state.totals_points,
          over: state.totals_over,
          under: state.totals_under,
          max: state.totals_max
        }
      }
    }
    if (state.have_team_totals) {
      team_total = {
        home: {
          points: state.team_totals_home_points,
          over: state.team_totals_home_over,
          under: state.team_totals_home_under
        },
        away: {
          points: state.team_totals_away_points,
          over: state.team_totals_away_over,
          under: state.team_totals_away_under
        }
      }
    }

    let date = moment(state.starts_date + " " + state.starts_time).format("YYYY-MM-DD HH:mm:ss")
    axios.post('/api/admin/custom-bets/edit-custom-bet',
      {
        id: id,
        token: token,
        event_id: state.event_id,
        event_data: {
          event_id: state.event_id,
          league_name: state.league_name,
          starts: date,
          home: state.home,
          away: state.away,

          money_line: moneyline,
          spreads: spreads,
          totals: totals,
          team_total: team_total
        }
      }
    )
      .then(res => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
          name: "",
          email: "",
          contact: "",
          username: "",
          password: "",
          weekly_balance: ""
        });
      });
  }

  handleBetListSave = (event, data) => {
    event.preventDefault();

    console.log(data.sport_title);

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const event_id = this.state.event_id;
    let date = moment(data.starts_date + " " + data.starts_time).format("YYYY-MM-DD HH:mm:ss");
    console.log(date);
    axios.post('/api/admin/custom-bets/edit-custom-bet',
      {
        id: id,
        token: token,
        event_id: event_id,
        event_data: {
          event_id: event_id,
          bet_type: 'list-bet',
          starts: date,
          sport_title: data.sport_title,
          outcomes: data.outcomes
        }
      }
    )
      .then(res => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
          name: "",
          email: "",
          contact: "",
          username: "",
          password: "",
          weekly_balance: ""
        });
      });
  }

  handleChange = (event) => {
    let { name, value } = event.target;

    this.setState({
      [name]: value
    });


  }

  showmdl = (name) => {
    this.setState({
      [name]: true,
      mdl_error: false,
      mdl_error_msg: ""
    })
  }

  hidemdl = (name) => {
    this.setState({
      [name]: false,
      mdl_error: false,
      mdl_error_msg: ""
    })
  }

  modalSubmit = (name, event) => {
    event.preventDefault();

    this.hidemdl(name);
    if (name === 'edit_moneyline') {

      let { moneyline_home, moneyline_away, moneyline_draw } = event.target;
      this.setState({
        have_moneyline: true,
        moneyline_home: moneyline_home.value,
        moneyline_away: moneyline_away.value,
        moneyline_draw: moneyline_draw.value
      });

    }

    if (name === 'edit_spreads') {

      let { spreads_handicap, spreads_home, spreads_away, spreads_max } = event.target;
      this.setState({
        have_spreads: true,
        spreads_handicap: spreads_handicap.value,
        spreads_home: spreads_home.value,
        spreads_away: spreads_away.value,
        spreads_max: spreads_max.value
      });

    }

    if (name === 'edit_totals') {

      let { totals_points, totals_over, totals_under, totals_max } = event.target;
      this.setState({
        have_totals: true,
        totals_points: totals_points.value,
        totals_over: totals_over.value,
        totals_under: totals_under.value,
        totals_max: totals_max.value
      });

    }

    if (name === 'edit_team_totals') {

      let {
        team_totals_home_points, team_totals_home_over, team_totals_home_under,
        team_totals_away_points, team_totals_away_over, team_totals_away_under
      } = event.target;

      this.setState({
        have_team_totals: true,
        team_totals_home_points: team_totals_home_points.value,
        team_totals_home_over: team_totals_home_over.value,
        team_totals_home_under: team_totals_home_under.value,
        team_totals_away_points: team_totals_away_points.value,
        team_totals_away_over: team_totals_away_over.value,
        team_totals_away_under: team_totals_away_under.value
      })

    }

  }

  mdlDelete = (event) => {
    event.preventDefault();
    this.setState({
      mdl_error: false,
      mdl_error_msg: "",
      mdl_btn_disable: true
    });

    var del = event.target.delete.value;
    if (del === 'delete') {
      this.hidemdl("mdlDelete");
      const token = localStorage.getItem("admin_token");
      const id = localStorage.getItem("admin_id");
      const event_id = this.props.match.params.cbid;
      axios.get('/api/admin/custom-bets/delete', {
        params: { id: id, event_id: event_id },
        headers: { token: token },
        cancelToken: source.token
      }).then((res) => {

      }).catch((e) => {
        //yconsole.log(e.message);
      });

      this.props.history.push("/admin/custom-bets");
    } else {
      this.setState({
        mdl_error: true,
        mdl_error_msg: "Type in correct `delete`",
        mdl_btn_disable: false
      });
    }
  }
  

  render() {

    if (this.props.login_status === 0) return (<></>);

    if (this.state.bet_type === 'list-bet') {
      return (
        <>
          <EditListBet
            event={{
              sport_title: this.state.sport_title,
              starts_date: this.state.starts_date,
              starts_time: this.state.starts_time,
              outcomes: this.state.outcomes
            }}
            error={this.state.error_code? {error_code: this.state.error_code, error_msg: this.state.error_msg}: undefined }
            saveChanges={this.handleBetListSave}
            deleteBet={() => this.showmdl("mdlDelete")}
          />
          <Modal show={this.state.mdlDelete} onHide={() => this.hidemdl("mdlDelete")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Form onSubmit={this.mdlDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Bet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}
              <Form.Group className="mb-20">
                <Form.Label>Type in "delete" to remove this bet.</Form.Label>
                <Form.Control type="text" name="delete" required="true" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hidemdl("mdlDelete")}>
                Close
              </Button>
              <Button variant="danger" type="submit">
                Delete
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        </>
      );
    }

    console.log("inside render: " + this.state.starts_time);

    return (
      <>
        <Container fluid className='mb-20 mt-60'>
          <Row>
            <Col md={9} className='billing'>
              <Card className='bg-s-dark'>
                <Card.Header>Edit Custom Bet</Card.Header>
                <Card.Body className='bg-s-secondary'>
                  {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
                  {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}


                  <Form id='add-custom-bet-form' onSubmit={this.handleSave}>

                    <Form.Group className="mb-20">
                      <Form.Label>League</Form.Label>
                      <Form.Control type="text" name="league_name"
                        value={this.state.league_name}
                        onChange={this.handleChange} readOnly
                      />
                    </Form.Group>

                    <Form.Label>Starts</Form.Label>
                    <Row>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='date' name="starts_date"
                          value={this.state.starts_date}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='time' name="starts_time"
                          value={this.state.starts_time}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-20">
                      <Form.Label>Home</Form.Label>
                      <Form.Control type="text" name="home"
                        value={this.state.home}
                        onChange={this.handleChange} readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>Away</Form.Label>
                      <Form.Control type="text" name="away"
                        value={this.state.away}
                        onChange={this.handleChange} readOnly
                      />
                    </Form.Group>

                    <Row className="mb-20">
                      {this.state.have_moneyline ?
                        <>
                          <Form.Label>Moneyline</Form.Label>
                          <Row>
                            <Form.Group as={Col}>
                              <FloatingLabel label="home">
                                <Form.Control type="text" name="moneyline_home" placeholder="home"
                                  value={this.state.moneyline_home}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="draw">
                                <Form.Control type="text" name="moneyline_draw" placeholder="draw"
                                  value={this.state.moneyline_draw}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="away">
                                <Form.Control type="text" name="moneyline_away" placeholder="away"
                                  value={this.state.moneyline_away}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }
                    </Row>

                    <Row className="mb-20">

                      {this.state.have_spreads ?
                        <>
                          <Form.Label>Spreads</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <FloatingLabel label="handicap">
                                <Form.Control type="text" name="spreads_handicap" placeholder="hdp"
                                  value={this.state.spreads_handicap}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="home">
                                <Form.Control type="text" name="spreads_home" placeholder="home"
                                  value={this.state.spreads_home}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="away">
                                <Form.Control type="text" name="spreads_away" placeholder="away"
                                  value={this.state.spreads_away}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="max">
                                <Form.Control type="text" name="spreads_max" placeholder="max"
                                  value={this.state.spreads_max}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }

                    </Row>

                    <Row className="mb-20">

                      {this.state.have_totals ?
                        <>
                          <Form.Label>Totals</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <FloatingLabel label="points" className="mb-3">
                                <Form.Control type="text" name="totals_points" placeholder="points"
                                  value={this.state.totals_points}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="over">
                                <Form.Control type="text" name="totals_over" placeholder="over"
                                  value={this.state.totals_over}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="under">
                                <Form.Control type="text" name="totals_under" placeholder="under"
                                  value={this.state.totals_under}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} >
                              <FloatingLabel label="max">
                                <Form.Control type="text" name="totals_max" placeholder="max"
                                  value={this.state.totals_max}
                                  onChange={this.handleChange} readOnly
                                />
                              </FloatingLabel>
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }

                    </Row>
                    <Row className="20">
                      {this.state.have_team_totals ?
                        <>
                          <Form.Label>Team Totals - Away</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_points" placeholder="points"
                                value={this.state.team_totals_away_points}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_over" placeholder="over"
                                value={this.state.team_totals_away_over}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_under" placeholder="under"
                                value={this.state.team_totals_away_under}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                          </Row>
                          <Form.Label>Team Totals - Home</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_points" placeholder="points"
                                value={this.state.team_totals_home_points}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_over" placeholder="over"
                                value={this.state.team_totals_home_over}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_under" placeholder="under"
                                value={this.state.team_totals_home_under}
                                onChange={this.handleChange} readOnly
                              />
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }
                    </Row>


                    {/* <Button variant="primary" className="m-5" type="submit">
                      Submit
                    </Button> */}

                  </Form>
                </Card.Body>
              </Card>


            </Col>

            <Col md={3}>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ edit_moneyline: true })}>
                {this.state.have_moneyline ? 'Edit' : 'Add'} Moneyline
              </Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ edit_spreads: true })}>
                {this.state.have_spreads ? 'Edit' : 'Add'} Spreads
              </Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ edit_totals: true })}>
                {this.state.have_totals ? 'Edit' : 'Add'} Totals
              </Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ edit_team_totals: true })}>
                {this.state.have_team_totals ? 'Edit' : 'Add'} Team Totals
              </Button>
              <Button form='add-custom-bet-form' variant='success' className='d-block w-100 mb-10' type='submit'>
                Update Bet
              </Button>
              <Button form='add-custom-bet-form' variant='danger' className='d-block w-100 mb-10' onClick={() => this.showmdl("mdlDelete")}>
                Delete Bet
              </Button>
            </Col>
          </Row>
        </Container>

        {/**
         *  Handle Odds changes 
         */}

        <EditFields
          title="Moneyline"
          modalName="edit_moneyline"
          show={this.state.edit_moneyline}
          hidemdl={this.hidemdl}
          submit={(event) => this.modalSubmit('edit_moneyline', event)}
          fields={[
            { label: 'home', name: 'moneyline_home', type: 'number', step: '0.001', required: true },
            { label: 'draw', name: 'moneyline_draw', type: 'number', step: '0.001', required: false },
            { label: 'away', name: 'moneyline_away', type: 'number', step: '0.001', required: true }
          ]}
        />

        <EditFields
          title="Spreads"
          modalName="edit_spreads"
          show={this.state.edit_spreads}
          hidemdl={this.hidemdl}
          submit={(event) => this.modalSubmit('edit_spreads', event)}
          fields={[
            { label: 'handicap', name: 'spreads_handicap', type: 'number', step: '0.001', required: true },
            { label: 'home', name: 'spreads_home', type: 'number', step: '0.001', required: true },
            { label: 'away', name: 'spreads_away', type: 'number', step: '0.001', required: true },
            { label: 'max', name: 'spreads_max', type: 'number', step: '0.001', required: false }
          ]}
        />

        <EditFields
          title="Totals"
          modalName="edit_totals"
          show={this.state.edit_totals}
          hidemdl={this.hidemdl}
          submit={(event) => this.modalSubmit('edit_totals', event)}
          fields={[
            { label: 'points', name: 'totals_points', type: 'number', step: '0.001', required: true },
            { label: 'over', name: 'totals_over', type: 'number', step: '0.001', required: true },
            { label: 'under', name: 'totals_under', type: 'number', step: '0.001', required: true },
            { label: 'max', name: 'totals_max', type: 'number', step: '0.001', required: false }
          ]}
        />

        <EditFields
          title="Team Totals"
          modalName="edit_team_totals"
          show={this.state.edit_team_totals}
          hidemdl={this.hidemdl}
          submit={(event) => this.modalSubmit('edit_team_totals', event)}
          fields={[
            { label: 'Home Points', name: 'team_totals_home_points', type: 'number', step: '0.001', required: true },
            { label: 'Home Over', name: 'team_totals_home_over', type: 'number', step: '0.001', required: true },
            { label: 'Home Under', name: 'team_totals_home_under', type: 'number', step: '0.001', required: true },
            { label: 'Away Points', name: 'team_totals_away_points', type: 'number', step: '0.001', required: true },
            { label: 'Away Over', name: 'team_totals_away_over', type: 'number', step: '0.001', required: true },
            { label: 'Away Under', name: 'team_totals_away_under', type: 'number', step: '0.001', required: true }
          ]}
        />

        <Modal show={this.state.mdlDelete} onHide={() => this.hidemdl("mdlDelete")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
          <Form onSubmit={this.mdlDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Bet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}
              <Form.Group className="mb-20">
                <Form.Label>Type in "delete" to remove this bet.</Form.Label>
                <Form.Control type="text" name="delete" required="true" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hidemdl("mdlDelete")}>
                Close
              </Button>
              <Button variant="danger" type="submit">
                Delete
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }


}

function EditFields({ title, modalName, show, hidemdl, submit, fields, deleteEntry }) {

  const [mdl_error, setMdlError] = useState(false);
  const [mdl_error_msg, setMdlErrorMsg] = useState("");
  const [mdl_btn_disabled, setMdlBtnDisabled] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => hidemdl(modalName)}
      animation={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mdl_error === true ? <Alert variant="danger">{mdl_error_msg}</Alert> : ""}

          {fields.map(({ label, type, step, name, value, required }) =>
            <Form.Group key={`${title}-${label}`} className="mb-20">
              <Form.Label>{label}</Form.Label>
              <Form.Control type={type} name={name} step={step || 1}
                defaultValue={value}
                required={required}
              />
            </Form.Group>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hidemdl(modalName)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteEntry}>
            Delete
          </Button>
          <Button variant="primary" type="submit" disabled={mdl_btn_disabled}>
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function OutcomeField({ outName, outPrice, update, children }) {

  return (
    <Row>
      <InputGroup as={Col} className="mb-20">
        <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>
          Name:
        </InputGroup.Text>
        <Form.Control type='text' name="name"
          value={outName}
          required
        />
      </InputGroup>
      <InputGroup as={Col} className="mb-20">
        <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>
          Price:
        </InputGroup.Text>
        <Form.Control type='number' name="price"
          value={outPrice}
          required
        />
      </InputGroup>
      <Col md={"auto"} className="mb-10">
        {children}
      </Col>
    </Row>
  );
}

function EditListBet({ event, saveChanges, error = {} }) {
  const [sport_title, setSportTitle] = useState(event.sport_title);
  const [outcomes, setOutcomes] = useState(event.outcomes);
  const [index, setIndex] = useState(-1);
  const [starts_date, setStartsDate] = useState(event.starts_date);
  const [starts_time, setStartsTime] = useState(event.starts_time);
  const [newEntry, setNewEntry] = useState(false);
  
  function updateField(setValue){
    return (event) => {
      let {value} = event.target;
      console.log(value);
      setValue(value);
    }
  }

  function openOutcomEditor(index) {
    setIndex(index);
  }

  function handleOutcomeFieldChange(event) {
    event.preventDefault();
    let name = event.target.name.value;
    let price = event.target.price.value;
    let out = outcomes.slice();
    out[index] = { name: name, price: price };
    setOutcomes(out);
    setIndex(-1);
  }

  function addNewOutcome(event) {
    event.preventDefault();
    let {name,  price} = event.target;
    setOutcomes([
      ...outcomes,
      { name: name.value, price: price.value }
    ]);
    setNewEntry(false);
  }

  function handleDelete(index){
    let out = outcomes.slice(0, index).concat(outcomes.slice(index + 1));
    setOutcomes(out);
    setIndex(-1);
  }

  function submit(e){
    e.preventDefault();
    console.log("Inside: " + sport_title);
    let data = {
      starts_date: starts_date,
      starts_time: starts_time,
      sport_title: sport_title,
      outcomes: outcomes
    }
    console.log(outcomes);

    saveChanges(e, data);
  }

  return (
    <Container fluid className='mb-20 mt-60'>
      <Row>
        <Col md={9} className="billing">
          <Card className='bg-s-dark'>
            <Card.Header>Edit Custom Bet</Card.Header>
            <Card.Body className='bg-s-secondary'>
              {error.error_code === -1 ? <Alert variant='success'>{error.error_msg}</Alert> : ""}
              {error.error_code > 0 ? <Alert variant='danger'>{error.error_msg}</Alert> : ""}

              <Form id='add-custom-bet-form'>
                <Form.Group className="mb-20">
                  <Form.Label>Sport Title</Form.Label>
                  <Form.Control type="text" name="sport_title"
                    value={sport_title}
                    onChange={updateField(setSportTitle)}
                  />
                </Form.Group>

                <Form.Label>Starts</Form.Label>
                <Row>
                  <Form.Group as={Col} className="mb-20">
                    <Form.Control type='date' name="starts_date"
                      value={starts_date}
                      onChange={updateField(setStartsDate)} required
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-20">
                    <Form.Control type='time' name="starts_time"
                      value={starts_time}
                      onChange={updateField(setStartsTime)} required
                    />
                  </Form.Group>
                </Row>

                {outcomes?.map(({ name, price }, i) =>
                  //TODO: Extract this component 
                  <>
                    <OutcomeField
                      key={i}
                      outName={name} outPrice={price}
                      opdate={() => { return }}
                    >
                      <Button variant={'primary'} onClick={() => openOutcomEditor(i)} >edit</Button>
                      <EditFields
                        title={'Outcome'}
                        modalName={'edit_outcome_field'}
                        show={index === i}
                        hidemdl={() => setIndex(-1)}
                        fields={[
                          { label: 'Name', name: 'name', type: 'text', value: name, required: true },
                          { label: 'Price', name: 'price', type: 'number', step: '0.001', value: price, required: true }
                        ]}
                        submit={handleOutcomeFieldChange}
                        deleteEntry={() => handleDelete(i)}
                      />
                    </OutcomeField>

                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Button className='d-block w-100 mb-10' onClick={() => setNewEntry(true)}>
            Add Outcome
          </Button>
          <Button form='add-custom-bet-form' variant='success' className='d-block w-100 mb-10' onClick={submit}>
            Save Changes
          </Button>
          <Button form='add-custom-bet-form' variant='danger' className='d-block w-100 mb-10' >
            Delete Bet
          </Button>

          <EditFields
            title={'New Outcome'}
            modalName={'new_outcome_field'}
            show={newEntry}
            hidemdl={() => setNewEntry(false)}
            fields={[
              { label: 'Name', name: 'name', type: 'text', value: '', required: true },
              { label: 'Price', name: 'price', type: 'number', step: '0.001', value: '', required: true }
            ]}
            submit={addNewOutcome}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(EditCustomBet);