var moment = require('moment-timezone');

export function nFormatter(num, digits) {
    if(!num){num=0;}
    
    if(num===0){
        return num;
    } else {
        const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
        });
        return (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
    }
}


export function initials(name){
    let initials = name.split(' ');
    if(initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 1);
    }
    return initials.toUpperCase();
}



const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const sortByKey = (array, key) => {
  return array.sort(function(a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
};

const convertToSlug = (Text) => {
  return Text.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
}

const format_sp_events = async (data) => {
  if(!data){ data = [] }
  let fdata = [];
  for(const event of data ){
      const now = moment.tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");
      if(now<event.expiry) {
          fdata.push(event);
      }
  }

  return fdata;
  
}

export async function format_sportdata(id, name, data, prevsportdata) {
    if(!data){ data = [] }
    const top_leagues = {
        1 : ['England - Premier League', 'FIFA - World Cup', 'France - Ligue 1'],
        2 : [],
        3 : ['NBA', 'NCAA'],
        4 : ['NHL', 'NCAA'],
        5 : [],
        6 : [],
        7 : ['NCAA', 'NFL', 'NFL - Alternate Lines'],
        8 : [],
        9 : ['MLB', 'NCAA'],
        11 : [],
        12 : [],
        13 : [],
        14 : [],
        15 : [],
        16 : [],
        17 : [],
        99: []
    }

    let live_data = [];
    let live_data_league = [];
    let upcoming_data = [];
    let upcoming_data_league = [];
    let matchup_data = [];
    let matchup_data_league = [];
    let upcoming_leagues_slugs=[];
    let all_events=[];

    //fetch previous data and merge the new data

    if(prevsportdata) {
      let prev_data = prevsportdata[name].all_events;
      for(const event of data ){
        //console.log(spd.category);
        let added = 0;
        for (let key in prev_data) {
            if(prev_data[key].event_id === event.event_id){
                prev_data[key]=event;
                added = 1;
            }
        }
        if(added===0) { prev_data.push(event); }
        //console.log(added);
      }

      data = prev_data;
    }
    

    for(const event of data ){
        const now = moment.tz("America/Los_Angeles").format("YYYYMMDDHHmmss");
        //console.log(event);
        const event_time = moment.tz(moment(event.starts+"Z"), "America/Los_Angeles").format("YYYYMMDDHHmmss");
        //console.log(event.starts+"Z");

        if(id===2 && event.resulting_unit==='Games') {
        continue;
        }

        if(id===1 || id===5) {
        // Remove 0, 0.25 && 0.75 handicap & total
        for (const prs in event.periods) {
            for(const hkey in event.periods[prs].spreads) {
                let n = Math.abs(event.periods[prs].spreads[hkey].hdp);
                let decimal = n - Math.floor(n);
                if( decimal == '0.25' || decimal == '0.75' || n == '0') {
                delete event.periods[prs].spreads[hkey];
                }
            }

            for(const tkey in event.periods[prs].totals) {
                let n = Math.abs(event.periods[prs].totals[tkey].points);
                let decimal = n - Math.floor(n);
                if( decimal == '0.25' || decimal == '0.75' || n == '0') {
                delete event.periods[prs].totals[tkey];
                }
            }

        }
        }

        //if(event.event_type==='live' && !event.period_results && event.periods.num_0 && event.periods.num_0.period_status!==2) {
        if(event.event_type==='live') {
            let addlive = 1;
            if(event?.period_results?.length > 0) {
            for(let index in event.period_results) {
                let result = event.period_results[index];
                if(result.number==0){
                    addlive=0;
                }
            }
            }

            if(addlive===1){
            live_data.push(event);
            live_data_league.push(event.league_name);
            all_events.push(event);
            }
        } else if(event.event_type==='prematch' && !event.period_results && event.periods.num_0 && event.periods.num_0.period_status===1 && event_time>now) {
            upcoming_data.push(event);
            upcoming_data_league.push(event.league_name);
            all_events.push(event);
            if(top_leagues[id].length>0) {
            if(top_leagues[id].includes(event.league_name)){
                matchup_data.push(event);
                matchup_data_league.push(event.league_name);
            }
            } else {
                matchup_data.push(event);
                matchup_data_league.push(event.league_name);
            }
            
        }
    }

    live_data = sortByKey(live_data, "starts");
    upcoming_data = sortByKey(upcoming_data, "starts");
    matchup_data = sortByKey(matchup_data, "starts");
    
    
    live_data = groupBy(live_data, "league_name");
    upcoming_data = groupBy(upcoming_data, "league_name");
    matchup_data = groupBy(matchup_data, "league_name");

    live_data_league = [...new Set(live_data_league)].sort();
    upcoming_data_league = [...new Set(upcoming_data_league)].sort();
    matchup_data_league = [...new Set(matchup_data_league)].sort();

    for(const league of upcoming_data_league ){
        upcoming_leagues_slugs.push({
        name : league,
        slug : convertToSlug(league),
        count: upcoming_data[league].length
        });
    }

    if(matchup_data_league.length===0) {
        matchup_data=upcoming_data;
        matchup_data_league=upcoming_data_league;
    }

    let sp_data = {}

    const sport_data = {
        live: live_data,
        live_data_league:live_data_league,
        upcoming:upcoming_data,
        upcoming_data_league:upcoming_data_league,
        matchup:matchup_data,
        matchup_data_league:matchup_data_league,
        upcoming_leagues_slugs:upcoming_leagues_slugs,
        all_events:all_events,
        top_leagues:top_leagues[id],
        sp_data:sp_data
    }

    return sport_data;
}
