import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Container, Card, Row, Col, Form, InputGroup, Alert } from "react-bootstrap";
import './limbo.css';

class Limbo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_status: 0,
      error_code: 0,
      error_msg: '',
      loading: false,
      bet_amount: 0,
      multiplier: 2,
      user: {},
      results: [],
      result: {},
      balance_update: 0
    };
  }

  componentDidMount() {
    this.verify_token();
  }

  verify_token = () => {
    const token = localStorage.getItem("token");
    axios.get('/api/simpleauth', {
      headers: { token: token }
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          this.setState({
            user: response.data,
            login_status: 1
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          localStorage.setItem('balance', response.data.balance);
          window.dispatchEvent(new Event('storage'));

        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  placeBet = () => {
    this.setState({
      loading: true
    });

    let bet_data = {
      bet_amount: parseFloat(this.state.bet_amount),
      multiplier: this.state.multiplier
    }

    let token = localStorage.getItem('token');
    let id = this.state.user.id;
    axios.post('/api/casino/limbo-bet', {
      bet_data: bet_data,
      id: id
    }, {
      headers: { token: token }
    })
      .then(res => {
        const response = res.data;

        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {

          let result = { outcome: response.bet_data.outcome, win: response.bet_data.outcome >= response.bet_data.multiplier };
          this.setState({
            error_code: 0,
            error_msg: "",
            bet_data: response.bet_data,
            result: result,
            balance_update: response.balance
          })



        } else {
          this.setState({
            error_code: response.error_code,
            error_msg: response.error_msg,
            loading: false
          })

          setTimeout(() => {
            this.setState({
              error_code: 0,
              error_msg: ''
            })
          }, 8000);
        }
      })
  }

  updateOnAnimationEnd = () => {
    this.setState({
      results: [this.state.result, ...this.state.results],
      loading: false
    })

    localStorage.setItem('balance', this.state.balance_update);
    window.dispatchEvent(new Event('storage'));
  }

  handleChange = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value })
  }

  handleMultiplierChange = event => {
    let { value } = event.target;
    this.setState({
      multiplier: parseFloat(value)
    })
  }

  render() {
    if (this.props.login_status === 0) return (<></>);

    return (
      <GameWrapper title={"Limbo"} error_code={this.state.error_code} error_msg={this.state.error_msg}>
        <Col className="mb-10">
          <div className='bg-s-dark limbo-game'>
            <ResultsQueue results={this.state.results} />
            <LimboGame multiplier={this.state.multiplier} target={this.state.result.outcome} updateOnAnimationEnd={this.updateOnAnimationEnd} />
            <OddsData multiplier={this.state.multiplier} handleChange={this.handleMultiplierChange} />
          </div>
        </Col>
        <Col className="mines-config" md={4}>
          <Form.Group className="mb-10">
            <Form.Label className="text-start">Bet: </Form.Label>
            <InputGroup>
              <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
              <Form.Control
                className="form-control text-white"
                name="bet_amount"
                type="number"
                step="0.001"
                value={this.state.bet_amount}
                onChange={this.handleChange}
              />
            </InputGroup>
          </Form.Group><Form.Group className="mb-10">
            <Form.Label className="text-start">Profit on win: </Form.Label>
            <InputGroup>
              <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
              <Form.Control
                className="form-control text-white"
                name="profit"
                type="readonly"
                step="0.001"
                value={(this.state.bet_amount * this.state.multiplier).toFixed(2)}
              />
            </InputGroup>
          </Form.Group>
          <button
            disabled={this.state.loading}
            onClick={() => this.placeBet()}
            className='make-bet-button text-white d-block w-100 p-5 mb-10'
          >
            Bet
          </button>
        </Col>
      </GameWrapper>
    );
  }
}

function GameWrapper({ title, error_code, error_msg, children }) {
  return (
    <div className="main-content wheel-game">
      <Container className="bet-lines">
        <Card className="bg-s-dark">
          <Card.Header><h1>{title}</h1></Card.Header>
          <Card.Body className="bg-s-secondary">
            {error_code === 1 ? <Alert variant='success'>{error_msg}</Alert> : ''}
            <Row>
              {children}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

function LimboGame({ multiplier, target = 0, updateOnAnimationEnd }) {

  const [current_number, setCurrentNumber] = useState(1.00);
  const [result, setResult] = useState('');

  useEffect(() => {
    if (target > 0) {
      let number = 1;
      let interval = (target - number) / 30;
      if (interval <= 0.01) interval = 0.01;

      setCurrentNumber(number);
      setResult('');

      let interval_id = setInterval(() => {
        number = number + interval;

        if (number >= target) {
          setCurrentNumber(target);
          clearInterval(interval_id);
          updateOnAnimationEnd();
          setResult(target >= multiplier ? 'win' : 'loss')
          return;
        }

        setCurrentNumber(number);
        
      }, 50);
    }
  }, [target])

  return (
    <div className={"limbo-number-container " + result}>
      {current_number.toFixed(2)}x
    </div>
  );
}

function OddsData({ multiplier, handleChange }) {
  let chance = 100 * 0.97 * Math.exp(8) / (multiplier * Math.exp(8));
  return (
    <div className="odds-data">
      <div className="mines-config ">
        <Row>
          <Col>
            <Form.Group >
              <Form.Label>Multiplier (x)</Form.Label>
              <Form.Control className="form-control text-white" type="number" step={0.001} value={multiplier} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group >
              <Form.Label>Win Chance (%)</Form.Label>
              <Form.Control className="form-control text-white" type="readonly" value={chance.toFixed(2)} />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  )
}

function ResultsQueue({ results }) {
  return (
    <div className="results-queue">
      {results.slice(0, 4).map(result => (
        <div className={"result " + (result.win ? 'win' : '')}>
          {result.outcome.toFixed(2)}x
        </div>
      ))}
    </div>
  )
}


export default withRouter(Limbo);