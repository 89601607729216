import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button, Table, Alert, Modal, Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let source = axios.CancelToken.source();
class Users extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data: [{}],
        user: [],
        loading: false,
        error: "",
        fb_id: 0,
        fb_username: "",
        fb_bal: 0,
        mdlfb: false,
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false,
        login_status: 0,

        edit_current_balance: false,
        edit_weekly_balance: false,

        edit_id: 0,
        edit_username: '',
        edit_balance_value: 0,
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/users/list', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {
                    this.setState({
                        login_status: 1,
                        loading: false,
                        user: response.user,
                        data: response.data,
                        pageheading: response.pageheading
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    UNSAFE_componentWillMount() {
        this.get_data();
        
    }

    deleteUser = (uid) => {
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true, error_code: 0 });
        axios.get('/api/admin/users/admin-delete', {
            params: { id: id, uid: uid },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                }
                else if (response.error_code === 1) {
                    this.setState({
                        loading: false,
                        error_code: response.error_code,
                        error_msg: response.error_msg,
                    });
                } else {
                    this.setState({
                        loading: false,
                        error_code: response.error_code,
                        error_msg: response.error_msg,
                    });
                    this.get_data();
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    confirmDelete = (uid) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete user.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteUser(uid)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    Login = (userId, username, type) => {
        this.setState({
            error_code: 0,
            error_msg: "",
            disabled: true
        });

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");

        axios.post('/api/admin/login-to-user', {
            id: id,
            username: username,
            userId: userId,
            type: type
        }, {
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 0) {


                    if (response.data.type <= 3) {

                        localStorage.setItem('admin_token', response.data.token);
                        localStorage.setItem('admin_id', response.data.id);
                        localStorage.setItem('admin_username', response.data.username);

                        this.props.history.push("/admin");
                    }
                    else {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('id', response.data.id);
                        localStorage.setItem('username', response.data.username);
                        localStorage.setItem('balance', response.data.balance);
                        localStorage.setItem('api', response.api);
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        error_code: response.error_code,
                        error_msg: response.error_msg,
                        disabled: false
                    });
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    list_users = (udata) => {
        let list = '';
        list = udata.map((data, i) => (
            <>
                <Card className="bg-s-secondary mb-15">
                    <Card.Header className="card-header-small bg-s-dark">
                        <div className="header-components-container">
                            {data.username}
                            <Badge className='ml-5' bg="dark">Manager</Badge>
                        </div>
                        <div className="header-components-container d-block">
                            <NavLink to={"history/" + data.id} className="btn btn-dark  text-white btn-xs mr-5">Bet History</NavLink>
                            {this.state.user.type === 1
                                ? <>
                                    <Button className={"btn btn-sm btn-warning  btn-xs mr-5 "} onClick={() => this.Login(data.id, data.username, 2)}>Login</Button>
                                    <NavLink to={"/admin/adminusers/details/" + data.id} className="btn btn-warning btn-xs mr-5 ">View Details</NavLink>
                                </>
                                : ""
                            }
                        </div>
                    </Card.Header>
                    {data?.bookies?.length > 0 ?
                        <Card.Body className="bg-s-primary p-0">
                            <Row className="list-bookie-wrapper">
                                <Col>
                                    {data.bookies.map((bdata, b) => (
                                        <>
                                            <Card className="bg-s-secondary">
                                                <Card.Header className="card-header-small">
                                                    <div>
                                                        {bdata.username}
                                                        <Badge className='ml-5' bg="dark">Bookie</Badge>
                                                        <Badge style={{ fontSize: 10, fontWeight: "normal" }} className='ml-5' bg="secondary">
                                                            {bdata.payment_type === 1 ? `%` : `$`}{bdata.payment_value || 0}
                                                        </Badge>
                                                    </div>
                                                    <div>

                                                        <NavLink to={"history/" + data.id + "/" + bdata.id} className="btn btn-dark text-white btn-xs mr-5 ">Bet History</NavLink>
                                                        {this.state.user.type <= 2
                                                            ? <>
                                                                <Button className={"btn btn-sm btn-warning btn-xs mr-5"} onClick={() => this.Login(bdata.id, bdata.username, 3)}>Login</Button>
                                                                <NavLink to={"/admin/adminusers/details/" + bdata.id} className="btn btn-warning btn-xs mr-5">View Details</NavLink>
                                                            </>
                                                            : ""
                                                        }


                                                    </div>
                                                </Card.Header>
                                                {/* <Card.Header className="card-header-small">
                                                    {bdata.username}
                                                    <Badge className='ml-5' bg="dark">Bookie</Badge>
                                                    {this.state.user.type <= 2
                                                        ? <NavLink to={"/admin/adminusers/details/" + bdata.id} className="btn btn-warning btn-xs mr-5 float-end">View Details</NavLink>
                                                        : ""
                                                    }
                                                    <NavLink to={"history/" + data.id + "/" + bdata.id} className="btn btn-dark text-white btn-xs mr-5 float-end">
                                                        Bet History
                                                    </NavLink>
                                                </Card.Header> */}
                                                {bdata?.users?.length > 0 ?
                                                    <Card.Body className="bg-s-primary p-0">
                                                        <Row>
                                                            <Col>
                                                                <Table bordered hover variant="dark" responsive className='bg-s-dark mb-0'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='text-center'>Username</th>
                                                                            <th className='text-center'>Current Balance</th>
                                                                            <th className='text-center'>Weekly Balance</th>
                                                                            <th className='text-center'>Free Bet</th>
                                                                            <th className='text-center'>Status</th>
                                                                            <th className='text-center'>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            bdata.users.map((pdata, p) => (
                                                                                <tr key={p} className='text-center'>
                                                                                    <td>{pdata.username}</td>
                                                                                    <td>${pdata.balance} <Button className='btn btn-xs btn-warning mx-1' onClick={() => this.editBalance(pdata.id, pdata.username, pdata.balance, 'edit_current_balance')}>Edit</Button> </td>
                                                                                    <td>${pdata.weekly_balance} <Button className='btn btn-xs btn-warning mx-1' onClick={() => this.editBalance(pdata.id, pdata.username, pdata.weekly_balance, 'edit_weekly_balance')}>Edit</Button></td>
                                                                                    <td>${pdata.freebet_balance} <Button className='btn btn-xs btn-warning mx-1' onClick={() => this.editfb(pdata.id, pdata.username, pdata.freebet_balance)}>Edit</Button></td>
                                                                                    <td>{pdata.acc_status === 1 ? "Active" : "Suspended"}</td>
                                                                                    <td className='user-action-table-cell'>
                                                                                        <div className='data-cell-container'>
                                                                                            <NavLink to={"history/" + data.id + "/" + bdata.id + "/" + pdata.id} className="btn btn-primary text-white btn-xs mr-5">Bet History</NavLink>
                                                                                            <NavLink className={"btn btn-sm btn-warning btn-xs mr-5"} to={"/admin/users/details/" + pdata.id}>View Details</NavLink>
                                                                                            <Button className={"btn btn-sm btn-warning btn-xs"} onClick={() => this.Login(pdata.id, pdata.username, 4)}>Login</Button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    : ""}
                                            </Card>
                                        </>
                                    ))}
                                </Col>
                            </Row>
                        </Card.Body>
                        : ""}
                </Card>

                <EditModal
                    title={this.state.edit_username}
                    modalName={'edit_current_balance'}
                    show={this.state.edit_current_balance}
                    hide={this.hidemdl}
                    submit={(event) => this.balanceUpdate(event, 'edit_current_balance', '/api/admin/users/current-balance-update')}
                    fields={[
                        { label: 'Current Balance', name: 'balance', type: 'number', step: 0.01, value: this.state.edit_balance_value }
                    ]}

                    error={this.state.mdl_error} error_msg={this.state.mdl_error_msg} btn_disabled={this.state.mdl_btn_disable}

                />

                <EditModal
                    title={this.state.edit_username}
                    modalName={'edit_weekly_balance'}
                    show={this.state.edit_weekly_balance}
                    hide={this.hidemdl}
                    submit={(event) => this.balanceUpdate(event, 'edit_weekly_balance', '/api/admin/users/weekly-balance-update')}
                    fields={[
                        { label: 'Weekly Balance', name: 'balance', type: 'number', step: 0.01,value: this.state.edit_balance_value }
                    ]}

                    error={this.state.mdl_error} error_msg={this.state.mdl_error_msg} btn_disabled={this.state.mdl_btn_disable}

                />

                <Modal show={this.state.mdlfb} onHide={() => this.hidemdl("mdlfb")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.mdlAccount}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.fb_username}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}

                            <Form.Group className="mb-20">
                                <Form.Label>Free bet balance</Form.Label>
                                <Form.Control type="number" step={0.01} name="freebet_balance" defaultValue={this.state.fb_bal} />
                            </Form.Group>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.hidemdl("mdlfb")}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={this.state.mdl_btn_disable}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        ));

        return (<Container className='userlist'><Row><Col>{list}</Col></Row></Container>);
    }

    editBalance = (id, username, balance, balance_type) => {
        this.setState({
            edit_id: id,
            edit_username: username,
            edit_balance_value: balance
        });

        this.showmdl(balance_type);
    }

    editfb = (id, username, bal) => {
        this.setState({
            fb_id: id,
            fb_username: username,
            fb_bal: bal,
        })

        this.showmdl('mdlfb')
    }

    showmdl = (name) => {
        this.setState({
            [name]: true,
            mdl_error: false,
            mdl_error_msg: ""
        })
    }

    hidemdl = (name) => {
        this.setState({
            [name]: false,
            mdl_error: false,
            mdl_error_msg: ""
        })
    }

    balanceUpdate = (event, balance_type, api) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let balance = event.target.balance.value;

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        const uid = this.state.edit_id;
        axios.get(api, {
            params: { id: id, uid: uid, balance: balance },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            // this.updateData();
            this.setState({
                mdl_error: false,
                mdl_error_msg: "",
                mdl_btn_disable: false,
                edit_bal: 0,
                edit_id: 0,
                edit_username: ""
            });
            this.hidemdl(balance_type);
            this.updateData();
        }).catch((e) => {
            //yconsole.log(e.message);
        });
    }

    mdlAccount = (event) => {
        event.preventDefault();
        this.setState({
            mdl_error: false,
            mdl_error_msg: "",
            mdl_btn_disable: true
        });

        let freebet_balance = event.target.freebet_balance.value;

        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        const uid = this.state.fb_id;
        axios.get('/api/admin/users/fbupdate', {
            params: { id: id, uid: uid, freebet_balance: freebet_balance },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            // this.updateData();
            this.setState({
                mdl_error: false,
                mdl_error_msg: "",
                mdl_btn_disable: false,
                fb_bal: 0,
                fb_id: 0,
                fb_username: ""
            });
            this.hidemdl("mdlfb");
            this.updateData();
        }).catch((e) => {
            //yconsole.log(e.message);
        });

    }

    updateData = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        // this.setState({  loading: true });
        axios.get('/api/admin/users/list', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {
                    this.setState({
                        login_status: 1,
                        loading: false,
                        user: response.user,
                        data: response.data,
                        pageheading: response.pageheading
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    render() {
        if (this.props.login_status === 0) return (<></>);

        return (<>
            <Container fluid className='mb-20 mt-60'>

                <Row>
                    <Col className='billing'>
                        {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}

                        {this.state.loading ? <div className="loading">Loading...</div> :
                            <>
                                {this.list_users(this.state.data)}
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

function EditModal({ show, hide, submit, title, modalName, fields, error, error_msg, btn_disabled }) {
    return (
        <Modal show={show} onHide={() => hide(modalName)} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Form onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error === true ? <Alert variant="danger">{error_msg}</Alert> : ""}

                    {fields.map(({ label, type, step, name, value }) => <>
                        <Form.Group className="mb-20" key={`${title}-${label}`}>
                            <Form.Label>{label}</Form.Label>
                            <Form.Control type={type} name={name} step={step} defaultValue={value} />
                        </Form.Group>
                    </>)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hide(modalName)}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" disabled={btn_disabled}>
                        Update
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default withRouter(Users);