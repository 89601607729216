import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
    text: {
        primary: '#fff',
        secondary: '#aaa',
    },
    background: {
        default: 'transparent',
    },
    context: {
        background: '#091929',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333',
    },
    action: {
        button: 'rgba(255,255,255,.54)',
        hover: 'rgba(255,255,255,.08)',
        disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');


const columns = [
    {
        name: 'Date',
        selector: 'created_on',
        sortable: false,
        left: true,
        cell: row => <div data-tag="allowRowEvents">{"T" + row.id} <br />{row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""}</div>,
    },
    {
        name: 'User Details',
        selector: 'username',
        sortable: false,
        left: true,
        width: "180px",
        cell: row => <div data-tag="allowRowEvents"><small className='d-block text-secondary'>{row.manager_username} &gt; {row.bookie_username} &gt;</small> {row.username}</div>,
    },
    {
        name: 'Amount',
        selector: 'amount',
        sortable: false,
        right: true,
        cell: row => <div data-tag="allowRowEvents">${-1*row.amount}</div>
    }
];
let source = axios.CancelToken.source();
class Transactions extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
        this.handleCallback = this.handleCallback.bind(this);
    }


    state = {
        data: [{}],
        user: [],
        loading: false,
        start_date: "",
        end_date: "",
        week_range: { "this_week": [], "last_week": [], "2_weeks_ago": [], "3_weeks_ago": [], "4_weeks_ago": [], "5_weeks_ago": [] },
        manager_id: 0,
        bookie_id: 0,
        user_id: 0,
        managers: [],
        bookies: [],
        users: [],
        page: 1,
        total: 0,
        login_status: 0
    };

    updateSel = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'manager_id' && value === '0') {
            this.setState({
                manager_id: 0,
                bookie_id: 0,
                user_id: 0
            }, function () { this.get_data() });
        } else if (name === 'bookie_id' && value === '0') {
            this.setState({
                bookie_id: 0,
                user_id: 0
            }, function () { this.get_data() });
        } else {
            this.setState({
                [name]: value
            }, function () { this.get_data() });
        }
    }



    get_data = () => {
        let start_date = "";
        let end_date = "";
        if (this.state.start_date) { start_date = moment(this.state.start_date).format("YYYY-MM-DD 09:00:00"); }
        if (this.state.end_date) { end_date = moment(this.state.end_date).format("YYYY-MM-DD 08:59:59"); }
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/transactions', {
            params: { page: this.state.page, id: id, start_date: start_date, end_date: end_date, manager_id: this.state.manager_id, bookie_id: this.state.bookie_id, user_id: this.state.user_id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {

                    this.setState({
                        login_status: 1,
                        user: response.user,
                        data: response.data,
                        start_date: response.start_date,
                        end_date: response.end_date,
                        loading: false,
                        week_range: response.week_range,
                        managers: response.managers,
                        bookies: response.bookies,
                        users: response.users,
                        total: response.total,
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    handleCallback(start, end, label) {
        this.setState({
            start_date: moment(start).format("MM/DD/YYYY"),
            end_date: moment(end).format("MM/DD/YYYY")
        }, function () { this.get_data() });
    }

    UNSAFE_componentWillMount() {
        this.get_data();
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.get_data();
        }
    }


    render() {
        if (this.props.login_status === 0) return (<></>);

        const drp_setting = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            ranges: {
                'This Week': this.state.week_range.this_week,
                'Last Week': this.state.week_range.last_week,
                '2 Weeks Ago': this.state.week_range['2_weeks_ago'],
                '3 Weeks Ago': this.state.week_range['3_weeks_ago'],
                '4 Weeks Ago': this.state.week_range['4_weeks_ago'],
                '5 Weeks Ago': this.state.week_range['5_weeks_ago']
            }
        }

        return (
            <>
                <Container fluid className='mb-20 mt-20'>
                    <Row>
                        <Col className="text-end">
                            {this.state.user.type === 1 ?
                                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} defaultValue={this.state.manager_id}>
                                    <option value="0">All Managers</option>
                                    {
                                        this.state.managers.map((data, e) => (
                                            <option key={data.id} value={data.id}>{data.username}</option>
                                        ))
                                    }
                                </select>
                                : ""}

                            {this.state.user.type === 1 || this.state.user.type === 2 ?
                                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="bookie_id" onChange={this.updateSel} defaultValue={this.state.bookie_id}>
                                    <option value="0">All Bookies</option>
                                    {
                                        this.state.bookies.map((data, e) => (
                                            <option key={data.id} value={data.id}>{data.username}</option>
                                        ))
                                    }
                                </select>
                                : ""}

                            <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="user_id" onChange={this.updateSel} defaultValue={this.state.user_id}>
                                <option value="0">All Users</option>
                                {
                                    this.state.users.map((data, e) => (
                                        <option key={data.id} value={data.id}>{data.username}</option>
                                    ))
                                }
                            </select>


                            {this.state.start_date ?
                                <DateRangePicker initialSettings={drp_setting} onCallback={this.handleCallback} >
                                    <input type="text" className="form-control w-auto d-inline-block ml-1 mb-5" />
                                </DateRangePicker>
                                : ""
                            }
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='mb-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body className='bg-s-secondary datatable'>
                                {this.state.loading ? <div className="loading">Loading...</div> :
                                    <>
                                        <DataTable
                                            title={"Transactions"}
                                            columns={columns}
                                            data={this.state.data}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                            pagination={true}
                                            paginationServer={true}
                                            page={this.state.page}
                                            paginationTotalRows={this.state.total}
                                            paginationPerPage={100}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => this.setPage(page)}
                                            paginationDefaultPage={this.state.page}
                                        />
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Transactions);