import React from 'react';
import { Container, Row, Card, Col } from 'react-bootstrap';

export default function LoadingScreen() {
  return (
    <>
      <Container fluid className="vh-100 fixed-top">
        <Row className="h-100 align-items-center justify-content-center">
          <Col xl={4} lg={5} md={8}>
            <Card className='bg-s-primary'>
              <Card.Body className="p-50">
                <Card.Title className="mb-30 text-secondary">
                  Loading...
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}