import React, { useState, useRef, useLayoutEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Container, Card, Row, Col, Form, InputGroup, Alert } from "react-bootstrap";
import PlinkoGame from "./plinkoGame";
import DataTable, { createTheme } from 'react-data-table-component';
import './plinko.css'
import Ball from "./ball";

createTheme('dark_s', {
  text: {
    primary: '#fff',
    secondary: '#aaa',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#091929',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.08)',
    disabled: 'rgba(255,255,255,.12)',
  },
  sortFocus: {
    default: '#ffc107',
  },
}, 'dark');

const columns = [
  {
    name: 'Bet',
    selector: 'bet',
    sortable: false,
    left: true,
    style: { wordBreak: 'normal' },
    cell: row => <div data-tag="allowRowEvents">
      ${row.bet_amount}
    </div>
  },
  {
    name: 'Mult',
    selector: 'type',
    sortable: false,
    right: false,
    cell: row => (
      <div data-tag="allowRowEvents">
        {row.multiplier}x
      </div>
    )
  },
  {
    name: 'Total',
    selector: 'type',
    sortable: false,
    right: false,
    cell: row => (
      <div data-tag="allowRowEvents">
        ${row.win.toFixed(2)}
      </div>
    )
  }
];

class Plinko extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_status: 0,
      risk: 'low',
      bet_amount: 0,
      rows: 8,
      user: {},
      async_results: [],
      sync_results: [],
      balls: [],
      configuration: {},
      curr_animation: () => {}
    }

    this.handleResponse = this.handleResponse.bind(this);
  }

  componentDidMount() {
    this.verify_token();
  }

  verify_token = () => {
    const token = localStorage.getItem("token");
    axios.get('/api/simpleauth', {
      headers: { token: token }
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/login");
        } else if (response.error_code === 0) {
          this.setState({
            user: response.data,
            login_status: 1
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          localStorage.setItem('balance', response.data.balance);
          window.dispatchEvent(new Event('storage'));

        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  setBet = async (dropBall) => {

    let bet_data = {
      bet_amount: parseFloat(this.state.bet_amount),
      risk: this.state.risk,
      rows: this.state.rows
    }

    let token = localStorage.getItem('token');
    let id = this.state.user.id;

    let res = await axios.post('/api/casino/plinko-bet', {
      bet_data: bet_data,
      id: id
    }, {
      headers: { token: token }
    })

    const response = res.data;

    if (response.error_code === 401) {
      this.props.history.push("/login");
    } else if (response.error_code === 0) {
      let results = [
        {
          index: 0,
          win: response.bet_data.win,
          multiplier: response.bet_data.multiplier,
          bet_amount: response.bet_data.stake
        },
        ...this.state.sync_results.map((el) => ({ ...el, index: el.index + 1 }))
      ];

      this.setState({
        error_code: 0,
        error_msg: "",
        bet_data: response.bet_data,
        sync_results: results
      })

      let { width, ballRadius, obstacles, interval, obstaclesRadius, counters } = this.state.configuration;
      let path = response.bet_data.path;

      let initialPosition = [
        (width / 2),
        obstacles[0][0][1] - interval
      ];

      let containers = counters.map(({ box_coord }) => Object.values(box_coord));

      let newBall = Ball.createNewBall(initialPosition, ballRadius, path, obstacles.slice(), interval, obstaclesRadius, containers)

      let newGameBalls = [
        ...this.state.balls,
        {
          ball: newBall,
          dispatch: () => {
            localStorage.setItem('balance', response.balance);
            window.dispatchEvent(new Event('storage'));

            this.setState({
              async_results: results
            })
          }
        }
      ]

      this.state.curr_animation();
      this.setState({ balls: newGameBalls, curr_animation: dropBall(newGameBalls) }, () => {
        
      });

      // dropBall(response.bet_data.path, () => {
      //   localStorage.setItem('balance', response.balance);
      //   window.dispatchEvent(new Event('storage'));

      //   this.setState({
      //     async_results: results
      //   })
      // });

    } else {
      this.setState({
        error_code: response.error_code,
        error_msg: response.error_msg
      })

      setTimeout(() => {
        this.setState({
          error_code: 0,
          error_msg: ''
        })
      }, 8000);
    }


  }

  handleClick = () => {

  }

  handleResponse(res, dropBall) {
    const response = res.data;
    if (response.error_code === 401) {
      this.props.history.push("/login");
    } else if (response.error_code === 0) {
      let results = [
        {
          index: 0,
          win: response.bet_data.win,
          multiplier: response.bet_data.multiplier,
          bet_amount: response.bet_data.stake
        },
        ...this.state.sync_results.map((el) => ({ ...el, index: el.index + 1 }))
      ];

      this.setState({
        error_code: 0,
        error_msg: "",
        bet_data: response.bet_data,
        sync_results: results
      })

      dropBall(response.bet_data.path, () => {
        localStorage.setItem('balance', response.balance);
        window.dispatchEvent(new Event('storage'));

        this.setState({
          async_results: results
        })
      });

    } else {
      this.setState({
        error_code: response.error_code,
        error_msg: response.error_msg
      })

      setTimeout(() => {
        this.setState({
          error_code: 0,
          error_msg: ''
        })
      }, 8000);
    }
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  render() {
    if (this.state.login_status === 0) return (<></>);
    
    return (
      <>
        <div className="main-content plinko-game">
          <Container className="bet-lines">
            <Card className="bg-s-dark">
              <Card.Header><h1>PLINKO!</h1></Card.Header>
              <Card.Body className="body-game bg-s-secondary">
                {this.state.error_code === 1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ''}

                <Row>
                  <PlinkoGame
                    risk={this.state.risk}
                    rows={this.state.rows}
                    balls={this.state.balls}
                    updateBalls={(balls) => this.setState({ balls: balls })}
                    setConfiguration={(config) => this.setState({ configuration: config })}
                    setControllers={(dropBall, disabled) => (
                      <>
                        <Form.Group className="mb-10">
                          <Form.Label className="text-start">Bet: </Form.Label>
                          <InputGroup>
                            <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
                            {disabled
                              ? <Form.Control className="form-control text-white" name="bet_amount" type="number" step="0.001" value={this.state.bet_amount} onChange={this.handleChange} disabled />
                              : <Form.Control className="form-control text-white" name="bet_amount" type="number" step="0.001" defaultValue={0.0} onChange={this.handleChange} />
                            }
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-10">
                          <Form.Label className="text-start" >Risk: </Form.Label>
                          <select value={this.state.risk} name={'risk'} onChange={this.handleChange} className='form-control text-white' disabled={disabled}>
                            <option value={'low'}>Low</option>
                            {/*<option value={'medium'}>Medium</option>*/}
                            <option value={'high'}>High</option>
                          </select>
                        </Form.Group>
                        <Form.Group className="mb-10">
                          <Form.Label className="text-start" >Rows: </Form.Label>
                          <select value={this.state.rows} name={'rows'} onChange={this.handleChange} className='form-control text-white' disabled={disabled}>
                            {/*new Array(9).fill(8).map((rows, index) => (
                              <option key={index} value={rows + index}>{rows + index}</option>
                            ))*/}
                            <option key="o1" value="8">8</option>
                            <option key="o2" value="16">16</option>
                          </select>
                        </Form.Group>
                        <button
                          onClick={
                            this.state.balls.length >= 10 
                              ? () => {console.log("Balls limit reached!")}
                              : () => this.setBet(dropBall)
                            } 
                          className='make-bet-button text-white d-block w-100 p-5 mb-10'
                        >
                          Bet
                        </button>
                      </>
                    )}
                  />
                </Row>
              </Card.Body>
            </Card>
            <Row>
              <Col>
                <Card body className='bg-s-secondary mt-10 datatable'>
                  <DataTable
                    title={"Results"}
                    columns={columns}
                    data={this.state.async_results}
                    highlightOnHover={true}
                    theme="dark_s"
                    pagination={true}
                    paginationServer={true}
                    page={this.state.page}
                    paginationTotalRows={this.state.total}
                    paginationPerPage={100}
                    paginationComponentOptions={{
                      noRowsPerPage: true
                    }}
                    onChangePage={page => this.setPage(page)}
                    paginationDefaultPage={this.state.page}
                  />
                </Card>
              </Col>
              <Col md={4}></Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(Plinko);