import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Form, Alert } from 'react-bootstrap';

let source = axios.CancelToken.source();
class Krank extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data: [{}],
        loading: false,
        password: "",
        error_msg: "",
        error_code: "",
        settings: {},
        login_status: 0,
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/auth', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {

                    this.setState({
                        login_status: 1,
                        data: response.data,
                        settings: response.settings,
                        loading: false
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    handleChange = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    handleChange2 = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let settings = this.state.settings;
        settings[name] = value;
        this.setState({
            settings: settings
        });
    }

    handleSave = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/settingSave', {
            id: id,
            password: this.state.password,
            token: token
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg
                });
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    UpdateSetting = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/settingUpdate', {
            id: id,
            settings: this.state.settings,
            token: token
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg
                });
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    UNSAFE_componentWillMount() {
        this.get_data();
    }



    render() {
        if (this.props.login_status === 0) return (<></>);

        return (
            <>
                {this.state.loading ? "" :
                    <Container fluid className='mb-20 mt-20'>
                        {this.state.error_code === -1 ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>{this.state.error_msg}</Alert>
                                </Col>
                            </Row>
                            : ""}
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-20 mt-20 bg-s-secondary'>
                                    <Card.Header>Roulette Settings</Card.Header>
                                    <Card.Body >
                                        <Form onSubmit={this.UpdateSetting}>
                                            <Form.Group className="mb-20">
                                                <Form.Label>RG %</Form.Label>
                                                <Form.Control as="select" name="rg_roulette_per" value={this.state.settings.rg_roulette_per} onChange={this.handleChange2}>
                                                    <option value="0">0%</option>
                                                    <option value="25">25%</option>
                                                    <option value="33">33%</option>
                                                    <option value="50">50%</option>
                                                    <option value="100">100%</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group className="mb-20">
                                                <Form.Label>RG over amount</Form.Label>
                                                <Form.Control type="text" name="rg_roulette_amount" value={this.state.settings.rg_roulette_amount} onChange={this.handleChange2} />
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Update
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card className='mb-20 mt-20 bg-s-secondary'>
                                    <Card.Header>Mines Settings</Card.Header>
                                    <Card.Body >
                                        <Form onSubmit={this.UpdateSetting}>

                                            <Form.Group className="mb-20">
                                                <Form.Label>RG</Form.Label>
                                                <Form.Control as="select" name="rg_mines" value={this.state.settings.rg_mines} onChange={this.handleChange2}>
                                                    <option value="0">OFF</option>
                                                    <option value="1">ON</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group className="mb-20">
                                                <Form.Label>RG %</Form.Label>
                                                <Form.Control type="number" name="rg_mines_per" value={this.state.settings.rg_mines_per} onChange={this.handleChange2} />
                                            </Form.Group>

                                            <Form.Group className="mb-20">
                                                <Form.Label>RG over amount</Form.Label>
                                                <Form.Control type="text" name="rg_mines_amount" value={this.state.settings.rg_mines_amount} onChange={this.handleChange2} />
                                            </Form.Group>

                                            <Form.Group className="mb-20">
                                                <Form.Label>RG after mines</Form.Label>
                                                <Form.Control type="text" name="rg_mines_gem" value={this.state.settings.rg_mines_gem} onChange={this.handleChange2} />
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Update
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        
                    </Container>
                }

            </>
        );
    }
}

export default withRouter(Krank);