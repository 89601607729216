import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { Container, Col, Row, Card, Button, Table, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';


let source = axios.CancelToken.source();
class AdminList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_status: 0,
      loading: false,
      user: {},
      data: [],
      managers_list: [],
      columns: [],
      user_id: '0',
      manager_id: '0',
      edit_bookie_manager: false,
      edit_payment: false,
      edit_payment_amount: false,

      edit_payment_type: 0,
      edit_payment_value: 0,
      edit_user: {},
      edit_current_manager_id: 0,

      search_bar_filter: ''
    };
  }

  componentDidMount() {
    this.get_data();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      this.get_data();
    }
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    if (!token) { this.props.history.push("/admin/login"); }
    const usersType = this.props.match.params.type;
    const id = localStorage.getItem("admin_id");
    this.setState({ data: [], loading: true });
    axios.get('/api/admin/users/list', {
      params: { id: id },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          const managers_list = this.getManagersList(response.data);
          const data = this.filterList(usersType, response.data);
          this.setState({
            login_status: 1,
            loading: false,
            user: response.user,
            data: data,
            managers_list: managers_list,
            columns: this.getUsersColumns(),
            pageheading: response.pageheading,
            user_id: '0',
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);
        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  getManagersList = (data) => {
    return data.map(entry => (
      { manager_id: entry.id, manager_username: entry.username }
    ));
  }

  editPayment = (user, editType) => {
    this.setState({
      edit_user: user,
      edit_payment_type: user.payment_type,
      edit_payment_value: user.payment_value,
    });

    this.showmdl(editType);
  }

  showmdl = (modalName) => {
    this.setState({
      [modalName]: true
    });
  }

  hidemdl = (modalName) => {
    this.setState({
      [modalName]: false
    })
  }

  handleSave = (event, editType) => {
    event.preventDefault();

    let user_id = this.state.edit_user.id;
    console.log(user_id);
    let { username, name, email, contact } = this.state.edit_user;

    let payment_type = event.target.payment_type?.value || this.state.edit_user.payment_type;
    let payment_value = event.target.payment_value?.value;

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    axios.post('/api/admin/users/update-admin', {
      id: id,
      user_id: user_id,
      username: username,
      name: name,
      email: email,
      contact: contact,
      payment_type: payment_type,
      payment_value: payment_value,
      token: token,
      cancelToken: source.token
    }).then((res) => {
      this.get_data();
      this.setState({
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false
      });
      this.hidemdl(editType);
    }).catch((e) => {
      console.log(e);
      //yconsole.log(e.message);
    });
  }

  handleManagerChange = (event) => {
    event.preventDefault();
    let user_id = this.state.edit_user.id;
    let manager_id = event.target.manager_id.value;

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    axios.post('/api/admin/users/update-admin', {
      id: id,
      user_id: user_id,
      admin_id: manager_id,
      token: token,
      cancelToken: source.token
    }).then((res) => {
      this.get_data();
      this.setState({
        mdl_error: false,
        mdl_error_msg: "",
        mdl_btn_disable: false
      });
      this.hidemdl('edit_bookie_manager');
    }).catch((e) => {
      console.log(e);
      //yconsole.log(e.message);
    });
  }

  filterList = (type, list) => {
    if (type === '2') return list;

    let filteredList = list.slice();

    if (type === '3') {
      filteredList = list.reduce((prev, curr) => {
        let newEntries = [...curr?.bookies];
        newEntries.forEach(e => {
          e.manager_username = curr.username;
          e.manager_id = curr.id;
        });
        return [...prev, ...newEntries]
      }, []);


      return filteredList;
    }

    return [];
  }

  openManagerChanger = (user) => {
    this.setState({
      edit_user: user,
      edit_current_manager_id: user.manager_id
    });

    this.showmdl('edit_bookie_manager');
  }

  Login = (userId, username, type) => {
    this.setState({
      error_code: 0,
      error_msg: "",
      disabled: true
    });

    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");

    axios.post('/api/admin/login-to-user', {
      id: id,
      username: username,
      userId: userId,
      type: type
    }, {
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 0) {


          if (response.data.type <= 3) {

            localStorage.setItem('admin_token', response.data.token);
            localStorage.setItem('admin_id', response.data.id);
            localStorage.setItem('admin_username', response.data.username);

            this.props.history.push("/admin");
          }
          else {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('balance', response.data.balance);
            localStorage.setItem('api', response.api);
            this.props.history.push("/");
          }
        } else {
          this.setState({
            error_code: response.error_code,
            error_msg: response.error_msg,
            disabled: false
          });
        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  getTitle = () => {
    if (this.props.match.params.type === '2') return 'Managers';
    if (this.props.match.params.type === '3') return 'Bookies';
    return '';
  }

  getUsersColumns = () => {
    if (this.props.match.params.type === '2') return this.managersColumns;
    if (this.props.match.params.type === '3') return this.bookiesColumns;
    return [];
  }

  updateSel = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  filterSelection = (el) => {
    let { user_id, manager_id } = this.state;

    if (user_id === '0' && manager_id === '0')
      return true;

    if (manager_id === '0' && user_id !== '0')
      return `${el.id}` === this.state.user_id;

    if (user_id === '0' && manager_id !== '0')
      return `${el.manager_id}` === this.state.manager_id;

    return (
      `${el.id}` === this.state.user_id &&
      `${el.manager_id}` === this.state.manager_id
    );
  }

  searchBarFilter = (user) => {
    let { username } = user;
    if (this.state.search_bar_filter === "") return true;

    return username.includes(this.state.search_bar_filter)
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    if (this.props.login_status === 0) return (<></>);

    if (this.state.loading)
      return (
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col className="text-end">
              <Form.Group className='d-inline-block  mb-5'>
                <Form.Control name="search_bar_filter" type="text" placeholder="search" value={this.state.search_bar_filter} onChange={this.handleChange} />
              </Form.Group>
              {this.props.match.params.type === '3' ?

                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} defaultValue={this.state.manager_id}>
                  <option value="0">All Managers</option>
                  {
                    this.state.data.filter((el, i, arr) => arr.map(a => a.manager_id).lastIndexOf(el.manager_id) === i)
                      .map((data, e) => (
                        <option key={data.manager_id} value={data.manager_id}>{data.manager_username}</option>
                      ))
                  }
                </select> : ""
              }
              <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="user_id" onChange={this.updateSel} defaultValue={this.state.user_id}>
                <option value="0">All {this.getTitle()}</option>
                {
                  this.state.data.map((data, e) => (
                    <option key={data.id} value={data.id}>{data.username}</option>
                  ))
                }
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="billing">
              <div className="loading">Loading...</div>
            </Col>
          </Row>
        </Container>
      );

    return (
      <>
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col className="text-end">
              <Form.Group className='d-inline-block  mb-5'>
                <Form.Control name="search_bar_filter" type="text" placeholder="search" value={this.state.search_bar_filter} onChange={this.handleChange} />
              </Form.Group>
              {this.props.match.params.type === '3' ?

                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} defaultValue={this.state.manager_id}>
                  <option value="0">All Managers</option>
                  {
                    this.state.data.filter((el, i, arr) => arr.map(a => a.manager_id).lastIndexOf(el.manager_id) === i)
                      .map((data, e) => (
                        <option key={data.manager_id} value={data.manager_id}>{data.manager_username}</option>
                      ))
                  }
                </select> : ""
              }
              <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="user_id" onChange={this.updateSel} defaultValue={this.state.user_id}>
                <option value="0">All {this.getTitle()}</option>
                {
                  this.state.data.map((data, e) => (
                    <option key={data.id} value={data.id}>{data.username}</option>
                  ))
                }
              </select>
            </Col>
          </Row>
        </Container>
        <Container fluid className='mb-20 mt-20'>
          <Card className="bg-s-secondary mb-15">
            <Card.Header className="class-header"><Card.Title>
              {this.getTitle()}
            </Card.Title>
            </Card.Header>
            <Card.Body className="bg-s-primary p-0">
              <Row>
                <Col className="text-end">
                  <Table bordered hover variant="dark" responsive className='bg-s-dark mb-0'>
                    <thead>
                      <tr>
                        <th className='text-start pl-10'>Username</th>
                        {this.props.match.params.type === '3' ?
                          <>
                            <th className='text-center'>Manager</th>
                            <th className='text-center'>Payment Type</th>
                            <th className='text-center'>Payment Amount</th>
                          </> : ""
                        }
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.data.filter(this.searchBarFilter).filter(this.filterSelection)
                          .map((pdata, p) => (
                            <>
                              <tr key={p} className='text-center'>

                                <td className='text-start align-middle pl-10'>
                                 <div className='data-username-column'> {pdata.username} </div>
                                </td>

                                {this.props.match.params.type === '3' ?
                                  <>
                                    <td className='text-start align-middle'>
                                      <div className='data-manager-column'>
                                        {pdata.manager_username}
                                        {this.state.user.type === 1 ?
                                          <Button className='btn btn-xs brn-warning mx-1 float-end' onClick={() => this.openManagerChanger(pdata)}>Edit</Button>
                                          : ''}
                                      </div>
                                    </td>
                                    <td className='align-middle'>
                                      <div className='data-cell-container'>
                                        {pdata.payment_type === 1 ? "percentage" : pdata.payment_type === 2 ? "pay per head" : "not selected"}
                                        <Button className='btn btn-xs btn-warning mx-1' onClick={() => this.editPayment(pdata, 'edit_payment')}>Edit</Button>
                                      </div>
                                    </td>
                                    <td className='align-middle'>
                                      <div className='data-cell-container'>
                                        {pdata.payment_type === 1 ? "%" : "$"}{pdata.payment_value}
                                        <Button className='btn btn-xs btn-warning mx-1' onClick={() => this.editPayment(pdata, 'edit_payment_amount')}>Edit</Button>
                                      </div>
                                    </td>
                                  </> : ""
                                }
                                <td className='align-middle'>
                                  <div className='data-cell-container'>
                                    {this.props.match.params.type === '3'
                                      ? <NavLink to={`/admin/history/${pdata.manager_id}/${pdata.id}`} className="btn btn-primary text-white btn-xs mr-5">Bet History</NavLink>
                                      : <NavLink to={`/admin/history/${pdata.id}`} className="btn btn-primary text-white btn-xs mr-5">Bet History</NavLink>
                                    }
                                    <NavLink className={"btn btn-sm btn-warning btn-xs mr-5"} to={"/admin/adminusers/details/" + pdata.id}>View Details</NavLink>
                                    <Button className={"btn btn-sm btn-warning btn-xs"} onClick={() => this.Login(pdata.id, pdata.username, 4)}>Login</Button>
                                  </div>
                                </td>
                              </tr>


                            </>
                          ))
                      }
                    </tbody>
                  </Table>

                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>

        <EditModal
          title={this.state.edit_user.username}
          modalName={'edit_payment'}
          show={this.state.edit_payment}
          hide={this.hidemdl}
          submit={(event) => this.handleSave(event, 'edit_payment')}
          fields={[
            {
              label: 'payment type', name: 'payment_type', type: 'select', value: this.state.edit_payment_type,
              options: [{ id: 1, text: 'Percentage' }, { id: 2, text: 'Pay per head' }]
            },
            { label: 'payment amount', name: 'payment_value', type: 'number', value: this.state.edit_payment_value }
          ]}
          errror={false} error_msg={''} btn_disabled={false}
        />

        <EditModal
          title={this.state.edit_user.username}
          modalName={'edit_payment_amount'}
          show={this.state.edit_payment_amount}
          hide={this.hidemdl}
          submit={(event) => this.handleSave(event, 'edit_payment_amount')}
          fields={[
            { label: 'payment amount', name: 'payment_value', type: 'number', value: this.state.edit_payment_value }
          ]}
          errror={false} error_msg={''} btn_disabled={false}
        />

        {this.props.match.params.type === '3' ?
          <EditModal
            title={this.state.edit_user.username}
            modalName={'edit_bookie_manager'}
            show={this.state.edit_bookie_manager}
            hide={this.hidemdl}
            submit={this.handleManagerChange}
            fields={[
              {
                label: 'manager', name: 'manager_id', type: 'select', value: this.state.edit_current_manager_id,
                options: this.state.managers_list.map(manager => ({ id: manager.manager_id, text: manager.manager_username }))
              }
            ]}
            error={false} error_msg={''} btn_disabled={false}
          /> : ""}
      </>
    );
  }
}

function EditModal({ show, hide, submit, title, modalName, fields, error, error_msg, btn_disabled }) {
  return (
    <Modal show={show} onHide={() => hide(modalName)} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <Form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error === true ? <Alert variant="danger">{error_msg}</Alert> : ""}

          {fields.map(({ label, type, name, value, options }) => (
            type === 'select' ?
              <>
                <Form.Group className='mb-20' key={`${title}-${label}`}>
                  <Form.Label>{label}</Form.Label>
                  <select className='form-control text-white' name={name} defaultValue={value}>
                    {options.map((opt) => <option key={opt.id} value={opt.id}>{opt.text}</option>)}
                  </select>
                </Form.Group>
              </>
              : <>
                <Form.Group className="mb-20" key={`${title}-${label}`}>
                  <Form.Label>{label}</Form.Label>
                  <Form.Control type={type} name={name} defaultValue={value} />
                </Form.Group>
              </>))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hide(modalName)}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={btn_disabled}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}


export default withRouter(AdminList);