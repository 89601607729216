import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import axios from 'axios';
let moment = require('moment-timezone');

let source = axios.CancelToken.source();
class ManualBet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_status: 0,
      id: 0,
      user: [],
      users: [],
      user_id: '',
      sport: '',
      team: '',
      stake: 0,
      league_name: "",
      starts_date: "",
      starts_time: "",
      home: "",
      away: "",

      bet_type: '1',

      add_moneyline: false,
      moneyline_home: "",
      moneyline_draw: "",
      moneyline_away: "",
      moneyline_team: "",
      moneyline_stake: 0,
      moneyline_win: 0,

      add_spreads: false,
      spreads_handicap: "",
      spreads_home: "",
      spreads_away: "",
      spreads_max: "",
      spreads_team: "",
      spreads_stake: 0,
      spreads_win: 0,

      add_totals: false,
      totals_points: "",
      totals_over: "",
      totals_under: "",
      totals_max: "",
      totals_point: '',
      totals_stake: 0,
      totals_win: 0,

      add_team_totals_home: false,
      team_totals_home_points: "",
      team_totals_home_over: "",
      team_totals_home_under: "",
      team_totals_home_point: '',
      team_totals_home_stake: 0,
      team_totals_home_win: 0,

      add_team_totals_away: false,
      team_totals_away_points: "",
      team_totals_away_over: "",
      team_totals_away_under: "",
      team_totals_away_point: '',
      team_totals_away_stake: 0,
      team_totals_away_win: 0,
    }

    this.sports = ["Basketball", "Football", "Soccer", "Baseball", "Hockey", "MMA"];
  }

  componentDidMount() {
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    this.setState({ data: [{}], loading: true });
    axios.get('/api/admin/users/list', {
      params: { id: id },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          let users = this.mapUsers(response.data);

          this.setState({
            login_status: 1,
            user: response.user,
            users: users,
            loading: false,
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);

        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  mapUsers = users_list => {
    let users = users_list.reduce((prev, manager) => {
      return prev.concat(manager.bookies.reduce((prev, bookie) => {
        return prev.concat(bookie.users.map(user => ({
          id: user.id,
          username: user.username,
          manager: manager.username,
          bookie: bookie.username
        })))
      }, []))
    }, [])
      .sort((a,b) => a.username.localeCompare(b.username));

    return users;
  }

  handleSave = (event) => {
    event.preventDefault();
    let state = this.state;
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const bet_type = this.state.bet_type;
    let odds = this.getFormatedOdds();

    let event_data = this.formatEvent(odds);
    let bets = this.getFormatedBets(event_data, odds);

    let date = moment(state.starts_date + " " + state.starts_time).format("YYYY-MM-DD HH:mm:ss")
    axios.post('/api/admin/manual-bet',
      {
        id: id,
        token: token,
        user_id: this.state.user_id,
        bet_type: bet_type,
        bet_data: bets,
        event_data: event_data
      }
    )
      .then(res => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
        });
      });
  }

  getFormatedBets = (event, odds) => {
    let bets = {};
    if (this.state.add_moneyline) {
      bets.moneyline_bet = {
        id: "MB"+Math.floor(Math.random() * 1000000000),
        type: 'moneyline',
        line: 'num_0',
        line_name: 'Game',
        point: '0',
        data: odds.moneyline,
        team: this.state.moneyline_team,
        event_id: "MB"+Math.floor(Math.random() * 1000000000),
        sport: this.state.sport,
        bettype: 'matchups',
        stake: this.state.moneyline_stake,
        win: this.getWin('moneyline'),
        event: event
      }
    }

    if (this.state.add_spreads) {
      bets.spreads_bet = {
        id: "MBxxxxxx",
        type: 'spread',
        line: 'num_0',
        line_name: 'Game',
        point: this.state.spreads_handicap,
        data: odds.spreads[this.state.spreads_handicap],
        team: this.state.spreads_team,
        event_id: "MBxxxxxx",
        sport: this.state.sport,
        bettype: 'matchups',
        stake: this.state.spreads_stake,
        win: this.getWin('spreads'),
        event: event
      }
    }

    if (this.state.add_totals) {
      bets.totals_bet = {
        id: 2211173859,
        type: 'totals',
        line: 'num_0',
        line_name: 'Game',
        point: this.state.totals_points,
        data: odds.totals[this.state.totals_points],
        team: this.state.totals_point,
        event_id: "MBxxxxxx",
        sport: this.state.sport,
        bettype: 'matchups',
        stake: this.state.totals_stake,
        win: this.getWin('totals'),
        event: event
      }
    }

    if (this.state.add_team_totals_home) {
      let point = this.state.team_totals_home_point
      bets.team_totals_bet_home = {
        id: "MBxxxxxx",
        type: 'teamtotal',
        line: 'num_0',
        line_name: 'Game',
        point: point,
        data: {
          points: this.state.team_totals_home_points,
          over: odds.team_total.home.over,
          under: odds.team_total.home.under
        },
        team: 'home',
        event_id: "MB"+Math.floor(Math.random() * 1000000000),
        sport: this.state.sport,
        bettype: 'all',
        stake: this.state.team_totals_home_stake,
        win: this.getWin('team_totals_home'),
        event: event
      }
    }

    if (this.state.add_team_totals_away) {
      let point = this.state.team_totals_away_point;
      bets.team_totals_bet_away = {
        id: "MBxxxxxx",
        type: 'teamtotal',
        line: 'num_0',
        line_name: 'Game',
        point: point,
        data: {
          points: this.state.team_totals_away_points,
          over: odds.team_total.away.over,
          under: odds.team_total.away.under
        },
        team: 'away',
        event_id: "MBxxxxxx",
        sport: this.state.sport,
        bettype: 'all',
        stake: this.state.team_totals_away_stake,
        win: this.getWin('team_totals_away'),
        event: event
      }
    }

    return bets;
  }

  formatEvent = (odd_lines) => {
    let date = moment(this.state.starts_date + " " + this.state.starts_time).format("YYYY-MM-DD HH:mm:ss")
    return {
      event_id: "MB"+Math.floor(Math.random() * 1000000000),
      sport_id: 99,
      league_id: '',
      league_name: this.state.league_name,
      starts: (moment.tz(date, "America/Los_Angeles").utc().format()).replace("Z", ""),
      last: '',
      home: this.state.home,
      away: this.state.away,
      event_type: 'prematch',
      parent_id: null,
      resulting_unit: 'Regular',
      is_have_odds: true,
      periods: {
        num_0: odd_lines
      }
    }
  }

  getDecimalOdds = (odds) => {
    if (!odds) return 0;

    if (odds > 0) {
      return ((odds) / 100) + 1;
    } else {
      return 1 - (100 / (odds))
    }
  }

  getDecimalOddsdb = (odds) => {
    if (!odds) return 0;

    if (odds > 0) {
      return ((odds-5) / 100) + 1;
    } else {
      return 1 - (100 / (odds-5))
    }
  }


  getFormatedOdds = () => {

    let { state } = this;
    let moneyline = null;
    let spreads = null;
    let totals = null; 
    let team_total = null;
    if (state.add_moneyline) {
      let isAmerican = (Math.abs(state.moneyline_home) >= 100) || (Math.abs(state.moneyline_away) >= 100) || (Math.abs(state.moneyline_draw) >= 100)
      if (isAmerican) {
        moneyline = {
          home: this.getDecimalOddsdb(state.moneyline_home),
          draw: this.getDecimalOddsdb(state.moneyline_draw),
          away: this.getDecimalOddsdb(state.moneyline_away)
        }
      } else {
        moneyline = {
          home: state.moneyline_home,
          draw: state.moneyline_draw,
          away: state.moneyline_away
        }
      }

    }
    if (state.add_spreads) {
      let isAmerican = (Math.abs(state.spreads_home) >= 100) 
        || (Math.abs(state.spreads_away) >= 100) 
      if (isAmerican) {
        spreads = {
          [state.spreads_handicap]: {
            hdp: state.spreads_handicap,
            home: this.getDecimalOddsdb(state.spreads_home),
            away: this.getDecimalOddsdb(state.spreads_away),
            max: state.spreads_max
          }
        }
      } else {
        spreads = {
          [state.spreads_handicap]: {
            hdp: state.spreads_handicap,
            home: state.spreads_home,
            away: state.spreads_away,
            max: state.spreads_max
          }
        }
      }
    }
    if (state.add_totals) {
      let isAmerican = (Math.abs(this.state.totals_over) >= 100) || (Math.abs(this.state.totals_under) >= 100);

      if (isAmerican) {
        totals = {
          [state.totals_points]: {
            points: state.totals_points,
            over: this.getDecimalOddsdb(state.totals_over),
            under: this.getDecimalOddsdb(state.totals_under),
            max: state.totals_max
          }
        }
      } else {
        totals = {
          [state.totals_points]: {
            points: state.totals_points,
            over: state.totals_over,
            under: state.totals_under,
            max: state.totals_max
          }
        }
      }
    }
    if (state.add_team_totals_home || state.add_team_totals_away) {
      let isAmerican = (
        (Math.abs(state.team_totals_away_over ) >= 100) || (Math.abs(state.team_totals_away_under) >= 100) ||
        (Math.abs(state.team_totals_home_over ) >= 100) || (Math.abs(state.team_totals_home_under) >= 100)
      )
      if (isAmerican) {
        team_total = {
          home: {
            points: state.team_totals_home_points,
            over: this.getDecimalOddsdb(state.team_totals_home_over),
            under: this.getDecimalOddsdb(state.team_totals_home_under)
          },
          away: {
            points: state.team_totals_away_points,
            over: this.getDecimalOddsdb(state.team_totals_away_over),
            under: this.getDecimalOddsdb(state.team_totals_away_under)
          }
        }
      } else {
        team_total = {
          home: {
            points: state.team_totals_home_points,
            over: state.team_totals_home_over,
            under: state.team_totals_home_under
          },
          away: {
            points: state.team_totals_away_points,
            over: state.team_totals_away_over,
            under: state.team_totals_away_under
          }
        }
      }
    }
    return {
      moneyline, spreads, totals, team_total
    };
  }

  handleChange = (event) => {
    let { name, value } = event.target;

    this.setState({
      [name]: value
    });

  }

  handleStakeChange = event => {
    let { name, value } = event.target;
    let multiplier;
    if (name === 'moneyline_stake') {
      let team = this.state.moneyline_team;
      let odds = team === 'away' ? this.state.moneyline_away : this.state.moneyline_home;
      odds = parseFloat(odds);

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      const win = (value * multiplier).toFixed(2);

      this.setState({
        moneyline_stake: value,
        moneyline_win: win
      })
    }

    if (name === 'spreads_stake') {
      let team = this.state.spreads_team;
      let odds = team === 'away' ? this.state.spreads_away : this.state.spreads_home;
      odds = parseFloat(odds);

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      const win = (value * (multiplier-1)).toFixed(2);

      this.setState({
        spreads_stake: value,
        spreads_win: win
      })
    }

    if (name === 'totals_stake') {
      let team = this.state.totals_point;
      let odds = team === 'over' ? this.state.totals_over : this.state.totals_under;
      odds = parseFloat(odds);

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      const win = (value * (multiplier-1)).toFixed(2);

      this.setState({
        totals_stake: value,
        totals_win: win
      })
    }

    if (name === 'team_totals_home_stake') {
      let team = this.state.team_totals_home_point;
      let odds = team === 'over' ? this.state.team_totals_home_over : this.state.team_totals_home_under;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      const win = (value * (multiplier-1)).toFixed(2);

      this.setState({
        team_totals_home_stake: value,
        team_totals_home_win: win
      })
    }

    if (name === 'team_totals_away_stake') {
      let team = this.state.team_totals_away_point;
      let odds = team === 'away' ? this.state.team_totals_away_over : this.state.team_totals_away_under;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      const win = (value * (multiplier-1)).toFixed(2);

      this.setState({
        team_totals_away_stake: value,
        team_totals_away_win: win
      })
    }

  }

  getWin = (type) => {
    if(type === 'moneyline'){
      let multiplier = 0;
      let stake = this.state.moneyline_stake;
      let team = this.state.moneyline_team;
      let odds = team === 'away' ? this.state.moneyline_away: this.state.moneyline_home;
      
      if(!stake || !odds) return 0;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      return (stake * (multiplier-1)).toFixed(2);
    }

    if(type === 'spreads'){
      let multiplier = 0;
      let stake = this.state.spreads_stake;
      let team = this.state.spreads_team;
      let odds = team === 'away' ? this.state.spreads_away: this.state.spreads_home;

      if(!stake || !odds) return 0;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      return (stake * multiplier).toFixed(2);
    }

    if(type === 'totals'){
      let multiplier
      let team = this.state.totals_point;
      let odds = team === 'over' ? this.state.totals_over : this.state.totals_under;
      let stake = this.state.totals_stake;
      odds = parseFloat(odds);

      if(!stake || !odds) return 0;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      return (stake * (multiplier-1)).toFixed(2);
    }

    if(type === 'team_totals_home'){
      let multiplier;
      let team = this.state.team_totals_home_point;
      let odds = team === 'over' ? this.state.team_totals_home_over : this.state.team_totals_home_under;
      let stake = this.state.team_totals_home_stake;

      if(!stake || !odds) return 0;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      return (stake * (multiplier-1)).toFixed(2);
    }

    if(type === 'team_totals_away'){
      let multiplier;
      let team = this.state.team_totals_away_point;
      let odds = team === 'over' ? this.state.team_totals_away_over : this.state.team_totals_away_under;
      let stake = this.state.team_totals_away_stake;

      if(!stake || !odds) return 0;

      if (Math.abs(odds) >= 100) {
        multiplier = this.getDecimalOdds(odds);
      } else {
        multiplier = odds;
      }

      return (stake * (multiplier-1)).toFixed(2);
    }

  }

  render() {
    if (this.props.login_status === 0) return (<></>);

    return (
      <>
        <Container fluid className='mb-20 mt-60'>
          <Row>
            <Col md={9} className='billing mb-20'>
              <Card className='bg-s-dark'>
                <Card.Header>Add Custom Bet</Card.Header>
                <Card.Body className='bg-s-secondary'>
                  {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
                  {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}

                  <Form.Group className='mb-20'>
                    <Form.Label>User</Form.Label>
                    <select className='form-control text-white' name='user_id' onChange={this.handleChange} value={this.state.user_id}>
                      <option value=""> user </option>
                      {this.state.users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </Form.Group>


                  <Form id='add-custom-bet-form' onSubmit={this.handleSave}>

                    <Form.Group className="mb-20">
                      <Form.Label>Sport</Form.Label>
                      <select className='form-control text-white' name='sport' onChange={this.handleChange} value={this.state.sport} defaultValue={'0'}>
                        <option value={'0'}>sport</option>
                        {this.sports.map(sport => (
                          <option key={sport} value={sport}>{sport}</option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>League</Form.Label>
                      <Form.Control type="text" name="league_name"
                        value={this.state.league_name}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Form.Label>Starts</Form.Label>
                    <Row>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='date' name="starts_date"
                          value={this.state.starts_date}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='time' name="starts_time"
                          value={this.state.starts_time}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-20">
                      <Form.Label>Home</Form.Label>
                      <Form.Control type="text" name="home"
                        value={this.state.home}
                        onChange={this.handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>Away</Form.Label>
                      <Form.Control type="text" name="away"
                        value={this.state.away}
                        onChange={this.handleChange} required
                      />
                    </Form.Group>

                    {/* Moneyline section  */}
                    <Row>
                      {this.state.add_moneyline ?
                        <>
                          <Form.Label>Moneyline</Form.Label>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control type="number" name="moneyline_home" placeholder="home" step={0.001}
                                value={this.state.moneyline_home}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="moneyline_draw" placeholder="draw" step={0.001}
                                value={this.state.moneyline_draw}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="moneyline_away" placeholder="away" step={0.001}
                                value={this.state.moneyline_away}
                                onChange={this.handleChange}
                              />
                            </Form.Group>

                          </Row>
                          <Row>
                            <TeamSelector handleChange={this.handleChange} name="moneyline_team" value={this.state.moneyline_team} />
                            {(this.state.moneyline_team === 'home' || this.state.moneyline_team === 'away') ?
                              <>
                                <Form.Group as={Col} >
                                  <Form.Label>Stake </Form.Label>
                                  <Form.Control type="number" name="moneyline_stake" placeholder="stake" step={0.001}
                                    value={this.state.moneyline_stake}
                                    onChange={this.handleStakeChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} >
                                  <Form.Label>Win</Form.Label>
                                  <Form.Control type="readonly" name="moneyline_win" placeholder="away" step={0.001}
                                    value={this.getWin('moneyline')}
                                  />
                                </Form.Group>
                              </> : ''}
                          </Row>
                        </> :
                        ""
                      }
                    </Row>

                    {/* Spreads section */}
                    <Row>

                      {this.state.add_spreads ?
                        <>
                          <Form.Label>Spreads</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="spreads_handicap" placeholder="hdp" step={0.001}
                                value={this.state.spreads_handicap}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="spreads_home" placeholder="home" step={0.001}
                                value={this.state.spreads_home}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="spreads_away" placeholder="away" step={0.001}
                                value={this.state.spreads_away}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="spreads_max" placeholder="max" step={0.001}
                                value={this.state.spreads_max}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <TeamSelector name="spreads_team" value={this.state.spreads_team} handleChange={this.handleChange} />
                            {this.state.spreads_team === 'away' || this.state.spreads_team === 'home' ?
                              <>
                                <Form.Group as={Col}>
                                  <Form.Label>Stake</Form.Label>
                                  <Form.Control type="number" name="spreads_stake" placeholder="stake" step={0.001}
                                    value={this.state.spreads_stake}
                                    onChange={this.handleStakeChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col}>
                                  <Form.Label>Win</Form.Label>
                                  <Form.Control type="text" name="spreads_win" placeholder="win" step={0.001}
                                    value={this.getWin('spreads')}
                                    onChange={this.handleChange}
                                  />
                                </Form.Group>
                              </> : ''}
                          </Row>
                        </> :
                        ""
                      }

                    </Row>

                    {/* Totals section */}
                    <Row>

                      {this.state.add_totals ?
                        <>
                          <Form.Label>Totals</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="totals_points" placeholder="points" step={0.001}
                                value={this.state.totals_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="totals_over" placeholder="over" step={0.001}
                                value={this.state.totals_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="totals_under" placeholder="under" step={0.001}
                                value={this.state.totals_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="totals_max" placeholder="max" step={0.001}
                                value={this.state.totals_max}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group>
                              <Form.Label>Point</Form.Label>
                              <select value={this.state.totals_point} defaultValue={'select'} name={'totals_point'} onChange={this.handleChange} className='form-control text-white'>
                                <option value={'select'}>choose point</option>
                                <option value={'over'}>Over</option>
                                <option value={'under'}>Under</option>
                              </select>
                            </Form.Group>
                            {this.state.totals_point === 'over' || this.state.totals_point === 'under' ?
                              <>
                                <Form.Group as={Col} >
                                  <Form.Label>Stake</Form.Label>
                                  <Form.Control type="number1" name="totals_stake" placeholder="stake" step={0.001}
                                    value={this.state.totals_stake}
                                    onChange={this.handleStakeChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} >
                                  <Form.Label>Win</Form.Label>
                                  <Form.Control type="readonly" name="totals_win" placeholder="away" step={0.001}
                                    value={this.getWin('totals')}
                                  />
                                </Form.Group>
                              </>
                              : ''
                            }
                          </Row>
                        </> :
                        ""
                      }

                    </Row>
                    <Row>
                      {/* Team totals home section */}
                      {this.state.add_team_totals_home ?
                        <>
                          <Form.Label>Team Totals - Home</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_home_points" placeholder="points" step={0.001}
                                value={this.state.team_totals_home_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_home_over" placeholder="over" step={0.001}
                                value={this.state.team_totals_home_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_home_under" placeholder="under" step={0.001}
                                value={this.state.team_totals_home_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group>
                              <Form.Label>Point</Form.Label>
                              <select value={this.state.team_totals_home_point} defaultValue={'select'} name={'team_totals_home_point'} onChange={this.handleChange} className='form-control text-white'>
                                <option value={'select'}>choose point</option>
                                <option value={'over'}>Over</option>
                                <option value={'under'}>Under</option>
                              </select>
                            </Form.Group>
                            {this.state.team_totals_home_point === 'over' || this.state.team_totals_home_point === 'under' ?
                              <>
                                <Form.Group as={Col} >
                                  <Form.Label>Stake</Form.Label>
                                  <Form.Control type="number" name="team_totals_home_stake" placeholder="stake" step={0.001}
                                    value={this.state.team_totals_home_stake}
                                    onChange={this.handleStakeChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} >
                                  <Form.Label>Win</Form.Label>
                                  <Form.Control type="readonly" name="team_totals_home_win" placeholder="away"
                                    value={this.getWin('team_totals_home')}
                                  />
                                </Form.Group>
                              </> : ""
                            }
                          </Row>
                        </> : ''
                      }

                      {/* Team totals home section */}
                      {this.state.add_team_totals_away ?
                        <>
                          <Form.Label>Team Totals - Away</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_away_points" placeholder="points" step={0.001}
                                value={this.state.team_totals_away_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_away_over" placeholder="over" step={0.001}
                                value={this.state.team_totals_away_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="number" name="team_totals_away_under" placeholder="under" step={0.001}
                                value={this.state.team_totals_away_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group>
                              <Form.Label>Point</Form.Label>
                              <select value={this.state.team_totals_away_point} defaultValue={'select'} name={'team_totals_away_point'} onChange={this.handleChange} className='form-control text-white'>
                                <option value={'select'}>choose point</option>
                                <option value={'over'}>Over</option>
                                <option value={'under'}>Under</option>
                              </select>
                            </Form.Group>
                            {this.state.team_totals_away_point === 'over' || this.state.team_totals_away_point === 'under' ?
                              <>
                                <Form.Group as={Col} >
                                  <Form.Label>Stake</Form.Label>
                                  <Form.Control type="number" name="team_totals_away_stake" placeholder="stake" step={0.001}
                                    value={this.state.team_totals_away_stake}
                                    onChange={this.handleStakeChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} >
                                  <Form.Label>Win</Form.Label>
                                  <Form.Control type="readonly" name="team_totals_away_win" placeholder="away"
                                    value={this.getWin('team_totals_away')}
                                  />
                                </Form.Group>
                              </> : ""
                            }
                          </Row>
                        </> :
                        ""
                      }
                    </Row>

                  </Form>
                </Card.Body>
              </Card>


            </Col>

            <Col md={3}>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_moneyline: true })}>Add Moneyline</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_spreads: true })}>Add Spreads</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_totals: true })}>Add Totals</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_team_totals_home: true })}>Add Team Totals - Home</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_team_totals_away: true })}>Add Team Totals - Away</Button>
              <Button form='add-custom-bet-form' variant='success' className='d-block w-100 mb-10' type='submit'>Submit Bet</Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function TeamSelector({ handleChange, name, value }) {
  return (
    <>
      <Form.Group>
        <Form.Label>Team</Form.Label>
        <select value={value} defaultValue={'select'} name={name} onChange={handleChange} className='form-control text-white'>
          <option value={'select'}>choose team</option>
          <option value={'home'}>Home</option>
          <option value={'away'}>Away</option>
        </select>
      </Form.Group>
    </>
  );
}

export default withRouter(ManualBet);