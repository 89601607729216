import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import DateRangePicker from "react-bootstrap-daterangepicker";
import axios from 'axios';
import { Container, Col, Row, Card } from 'react-bootstrap';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
  text: {
    primary: '#fff',
    secondary: '#aaa',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#091929',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.08)',
    disabled: 'rgba(255,255,255,.12)',
  },
  sortFocus: {
    default: '#ffc107',
  },
}, 'dark');



const columns = [
  {
    name: 'User',
    selector: "username",
    sortable: false,
    left: true,
    style: { wordBreak: "normal" },
    cell: row => <div data-tag="allowRowEvents">
      {row.username}
    </div>
  },
  {
    name: 'Tuesday',
    selector: "",
    sortable: false,
    right: true,
    cell: row => <Cell day="Tuesday" pl={row.Tuesday} />
  },
  {
    name: 'Wednesday',
    selector: '',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Wednesday} />
  },
  {
    name: 'Thursday',
    selector: 'username',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Thursday} />
  },
  {
    name: 'Friday',
    selector: 'type',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Friday} />
  },
  {
    name: 'Saturday',
    selector: 'Saturday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Saturday} />
  },
  {
    name: 'Sunday',
    selector: 'Sunday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Sunday} />
  },
  {
    name: 'Monday',
    selector: 'Monday',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Monday} />
  },
  {
    name: "Total",
    selector: 'Total',
    sortable: false,
    right: true,
    cell: row => <Cell pl={row.Total} />
  }
];

function Cell({ pl }) {
  if (pl > 0) {
    return (
      <div data-tag="allowRowEvents">
        <span className='text-danger'>${"-" + Math.abs(pl).toFixed(2)}</span>
      </div>
    )
  }
  if (pl < 0) {
    return (
      <div data-tag="allowRowEvents">
        <span className='text-success'>${Math.abs(pl).toFixed(2)}</span>
      </div>
    );
  }
  return (
    <div data-tag="allowRowEvents">
      {pl.toFixed(2)}
    </div>

  )

}

let source = axios.CancelToken.source();
class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{}],
      user: [],
      loading: false,
      start_date: "",
      end_date: "",
      week_range: { "this_week": [], "last_week": [], "2_weeks_ago": [], "3_weeks_ago": [], "4_weeks_ago": [], "5_weeks_ago": [] },
      manager_id: 0,
      bookie_id: 0,
      user_id: 0,
      managers: [],
      bookies: [],
      users: [],
      total_stake: 0,
      total_win: 0,
      total_bet: 0,
      page: 1,
      total: 0,
      bet_id: 0,
      bet_status: 0,
      balance_type: 0,
      login_status: 0
    };

    this.getDailyProfitLoss = this.getDailyProfitLoss.bind(this);
    this.updateSel = this.updateSel.bind(this);
  }

  get_data = () => {
    let start_date = "";
    let end_date = "";

    if (this.state.start_date) {
      start_date = moment(this.state.start_date).format("YYYY-MM-DD 09:00:00");
    }

    if (this.state.end_date) {
      end_date = moment(this.state.end_date).format("YYYY-MM-DD 08:59:59");
    }

    const token = localStorage.getItem("admin_token");
    if (!token) { this.props.history.push("/admin/login"); }
    const id = localStorage.getItem("admin_id");

    this.setState({ data: [{}], loading: true });

    axios.get('/api/admin/history', {
      params: {
        page: this.state.page,
        id: id,
        start_date: start_date,
        end_date: end_date,
        manager_id: this.state.manager_id,
        bookie_id: this.state.bookie_id,
        user_id: this.state.user_id,
        type: this.state.history_type
      },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {

          let days = this.getDailyProfitLoss(response.data);
          this.setState({
            login_status: 1,
            user: response.user,
            data: days,
            start_date: response.start_date,
            end_date: response.end_date,
            loading: false,
            week_range: response.week_range,
            managers: response.managers,
            bookies: response.bookies,
            users: response.users,
            total_stake: response.total_stake,
            total_win: response.total_win,
            total_bet: response.total_bet,
            total: response.total,
          });
          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);
        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  getDailyProfitLoss(data) {
    let days = {
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
      Monday: 0,
      Total: 0
    };

    let { balance_type } = this.state;
    let usersPL = [];

    for (let entry of data) {
      if (usersPL.find(el => entry.username === el.username)) {
        continue;
      }
      usersPL.push({
        username: entry.username,
        ...days
      })
    }

    for (let entry of data) {
      let { username, created_on, pl, type, status } = entry;

      if (status === 'Open') { continue; }
      if (balance_type === 9 && type !== balance_type) continue;
      if (balance_type === 1 && type === 9) continue;

      let ind = usersPL.findIndex(el => el.username === username);
      pl = parseFloat(pl);
      usersPL[ind][dateFormat(created_on, "dddd")] += pl;
      usersPL[ind].Total += pl;

    }

    return usersPL;
  }

  updateWeek = (event) => {
    const value = event.target.value;
    this.setState({
      start_date: this.state.week_range[value][0],
      end_date: this.state.week_range[value][1]
    }, () => this.get_data());

  }

  UNSAFE_componentWillMount() {
    this.get_data();
  }

  updateSel(event) {
    this.setState({
      balance_type: parseInt(event.target.value)
    }, () => this.get_data());

  }

  render() {
    if (this.props.login_status === 0) return (<></>);

    return (
      <>
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col className="text-end">
              <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="balance_type" onChange={this.updateSel} defaultValue={this.state.history_type}>
                <option value="0" key='0'>All</option>
                <option value="1" key='1'>Sports</option>
                <option value="9" key='9'>Casino</option>
              </select>

              <select
                className='form-control d-inline w-auto bg-s-secondary mx-1 mb-5 text-white'
                onChange={this.updateWeek}
                defaultValue={"this_week"}
              >
                <option value="this_week">This Week</option>
                <option value="last_week">Last Week</option>
                <option value="2_weeks_ago">2 Weeks Ago</option>
                <option value="3_weeks_ago">3 Weeks Ago</option>
                <option value="4_weeks_ago">4 Weeks Ago</option>
                <option value="5_weeks_ago">5 Weeks Ago</option>
              </select>

            </Col>
          </Row>
        </Container>

        <Container fluid className='mb-20'>
          <Row>
            <Col className='billing'>
              <Card body className='bg-s-secondary datatable'>
                {this.state.loading ? <div className="loading">Loading...</div> :
                  <DataTable
                    title={"Balance Sheet (" + dateFormat(this.state.start_date, "mm/dd/yyyy") + " to " + dateFormat(this.state.end_date, "mm/dd/yyyy") + ")"}
                    columns={columns}
                    data={this.state.data}
                    highlightOnHover={true}
                    theme="dark_s"
                  />
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(BalanceSheet);