import React, { Component} from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import {Container, Row, Col, Card, Button, Toast, Form } from 'react-bootstrap';
import socketIOClient from "socket.io-client";
import { IoMdLock, IoMdAlert, IoIosDocument, IoMdArrowUp, IoMdArrowDown, IoMdClose, IoIosGift } from 'react-icons/io';
import config from '../config'
import {format_sportdata} from '../helper/General'
const periods = require("../helper/periods.json");


//console.log(periods[2].periods[20].description);
const SOCKET_URL = config.SOCKET_URL;
var moment = require('moment-timezone');

const socket = socketIOClient(SOCKET_URL);
class Sports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_active:1,
            sptab_active:"All",
            bet_slip_active:1,
            prevsportdata:[],
            sportdata:[],
            specialmarket:{
                "basketball" : [],
                "football" : []
            },
            add_bet_slip:{},
            si_stake:0,
            si_win:0,
            user:[],
            placingbet:0,
            toastMsg:"",
            showToast:false,
            multiple:0,
            multiple_odd:1,
            mu_stake:"",
            mu_win:"",
            placingbet_mu:0,
            sp_events:[],
            sp_loading:1,
            freebet:0,
            freebet_balance:0,
            freebet_display:0,
            login_status: 0
        };

        this.verify_token();
    }


    updateFreeBet = () => {
        const cfb = this.state.freebet;
        if(cfb===1) {
            //remove fb
            const fb=0;
            this.setState({
                freebet:fb,
                freebet_msg:''
            }, () => {
                this.updateBetSlip();
            });
        } else {
            //add fb, set stake to fb balance, make stake field disabled
            const fb = 1;
            
            this.setState({
                freebet:fb,
                freebet_msg:''
            }, () => {
                this.updateBetSlip();
            }); 
        }

        
        
    }

    tab_select = (num) => {
        this.setState({
            tab_active:num
        }); 
    }

    sptab_select = (num) => {
        this.setState({
            sptab_active:num
        }); 
    }

    bet_slip_active = (num) => {

        this.setState({
            bet_slip_active:num
        });
    }

    sign = (team, hdp) => {
        if(team==='away'){
            if(hdp<0) { return "+"+Math.abs(hdp);}
            if(hdp>0) { return "-"+Math.abs(hdp);}
            return Math.abs(hdp);
        }

        if(team==='home'){
            if(hdp<0) { return "-"+Math.abs(hdp);}
            if(hdp>0) { return "+"+Math.abs(hdp);}
            return Math.abs(hdp);
        }
    }

    signsymbol = (num) => {
        if(num>0) { return "+"+Math.abs(num); }
        if(num<0) { return "-"+Math.abs(num);}
    }

    setBetterOdds = (odd) => {
        let digit = parseFloat(odd / 10).toFixed(1).split(".")[1];
        let nodigit = parseInt(parseFloat(odd / 10).toFixed(0) * 10);
        let final = odd > 0 ? (
          digit < 5 ? nodigit : odd + (5 - digit)
        ) : (
          digit < 5 ? odd - (5 - digit) : nodigit
        );
        return final;
    };

    format_odds = (odds) => {
        odds = parseFloat(odds);
        
        if(odds>=2){
            odds = (odds-1)*100;
            odds = this.setBetterOdds(odds);
            return this.signsymbol(parseFloat(odds).toFixed(0));
        } else {
            odds = (-100)/(odds-1);
            odds = this.setBetterOdds(odds);
            return this.signsymbol(parseFloat(odds).toFixed(0));
        }
    }

    format_odds_mu = (odds) => {
        odds = parseFloat(odds);
        
        if(odds>=2){
            odds = (odds-1)*100;
            return this.signsymbol(parseFloat(odds).toFixed(0));
        } else {
            odds = (-100)/(odds-1);
            return this.signsymbol(parseFloat(odds).toFixed(0));
        }
    }
    
    format_time = (time) => {
        const event_time = moment.tz(moment(time+"Z"), "America/Los_Angeles").format("hh:mm A");
        return event_time;
    }

    format_datetime = (time) => {
        const event_time = moment.tz(moment(time+"Z"), "America/Los_Angeles").format("MM/DD/YYYY hh:mm A");
        return event_time;
    }

    format_datetime_la = (time) => {
        const event_time = moment(time).format("MM/DD/YYYY hh:mm A");
        return event_time;
    }

    add_bet_slip = (event, id, type, data, team, event_id, sport, bettype, line, line_name, point) => {

        let bet_slip = this.state.add_bet_slip;
        let key = line+"_"+point+"_"+type+"_"+team+"_"+event_id;
        if (bet_slip[key]) {
            delete bet_slip[key];
        } else {
            bet_slip[key] =  {
                id:id,
                line:line,
                line_name:line_name,
                point:point,
                type:type,
                data:data,
                team:team,
                event_id:event_id,
                sport:sport,
                bettype:bettype,
                stake:"",
                win:"",
                event:event
                
            }
        }

        this.setState({
            add_bet_slip:bet_slip
        }, this.updateBetSlip()); 
    }

    removeBet = (bet) => {
        let bet_slip = this.state.add_bet_slip;
        let key = bet.line+"_"+bet.point+"_"+bet.type+"_"+bet.team+"_"+bet.event_id;
        if (bet_slip[key]) {
            delete bet_slip[key];
        }

        this.setState({
            add_bet_slip:bet_slip
        }, this.updateBetSlip()); 
    }

    betSlipChange = (event) => {
        let bet_slip = this.state.add_bet_slip;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        bet_slip[name] =  {
            id:bet_slip[name].id,
            type:bet_slip[name].type,
            line:bet_slip[name].line,
            line_name:bet_slip[name].line_name,
            point:bet_slip[name].point,
            data:bet_slip[name].data,
            team:bet_slip[name].team,
            event_id:bet_slip[name].event_id,
            sport:bet_slip[name].sport,
            bettype:bet_slip[name].bettype,
            event:bet_slip[name].event,
            stake:value
        }


        this.setState({
            add_bet_slip:bet_slip
        }, this.updateBetSlip());
    }

    get_multiplier = (odds) => {
        const am_odds = this.format_odds_mu(odds);
        
        let m = 1;
        if(am_odds>0) {
            m = (parseFloat(am_odds)/100).toFixed(2)
        } else {
            m = (100/Math.abs(parseFloat(am_odds))).toFixed(2)
        }

        
        return m;

    }

    mu_betSlipChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;
        let mu_win = "";
        if(value>0){
            let multiplier = this.get_multiplier(this.state.multiple_odd);
            mu_win = (multiplier*value).toFixed(2);
        }
        
        this.setState({
            mu_stake:value,
            mu_win:mu_win
        }, this.updateBetSlip());
    }

    updateBetSlip = () => {
        let bet_slip = this.state.add_bet_slip;
        const fb = this.state.freebet;
        let total_stake = 0;
        let total_win = 0;
        let multiple = 1;
        let multiplearray = [];
        let multiple_odd = 1;
        //freebet 
        this.setState({
            freebet_display:1
        });
        
        const total_bets = Object.values(bet_slip).length;
        if(total_bets===1) {
            //active free bet
            if(fb===1) {
                const fb_stake = this.state.freebet_balance;
                for(const bet of Object.values(bet_slip) ){
                    let key = bet.line+"_"+bet.point+"_"+bet.type+"_"+bet.team+"_"+bet.event_id;
                    bet_slip[key].stake = fb_stake;
                }
            }

        } else {
            //rm freebet
            this.setState({
                freebet_display:0,
                freebet:0,
                freebet_msg:''
            });
        }


        for(const bet of Object.values(bet_slip) ){
            let key = bet.line+"_"+bet.point+"_"+bet.type+"_"+bet.team+"_"+bet.event_id;

            let data = bet_slip[key];
           
            let event = '';
            let event_name = '';

            if(data.sport==='special') {
                event = data.event;
                event_name = data.data.name;
            } else {
                event = this.event_by_id(bet_slip[key]);
                event_name = event.home+" "+event.away;
            }


            if (multiplearray.includes(event_name) || data.sport==='special') {
                multiple = 0;
                //multiplearray=[];
            } else {
                multiplearray.push(event_name); 
            }

            if(!event) {

                this.removeBet(data);

            } else {
                let newdata = {};

                let odds_mu = 1
                if(data.type==='spread'){
                    odds_mu = event.periods[bet_slip[key].line].spreads[bet_slip[key].point][bet_slip[key].team];

                    //console.log("spreads", odds);
                }

                if(data.type==='moneyline'){
                    odds_mu = event.periods[bet_slip[key].line].money_line[bet_slip[key].team];
                }

                if(data.type==='totals'){
                    odds_mu = event.periods[bet_slip[key].line].totals[bet_slip[key].point][bet_slip[key].team];
                }

                if(data.type==='teamtotal'){
                    odds_mu = event.periods[bet_slip[key].line].team_total[bet_slip[key].team][bet_slip[key].point];
                }

                if(data.type==='playerprop'){
                    odds_mu = data.data.price;
                    event = data.event;
                }

                if(data.type==='specialevents'){
                    odds_mu = data.points;
                }

        
                multiple_odd = multiple_odd*parseFloat(odds_mu);
            
                if(data?.stake!=='undefined' && data.stake>0) {
                    let odds = 0;
                    if(data.type==='spread'){
                        newdata = event.periods[bet_slip[key].line].spreads[bet_slip[key].point];
                        odds = this.format_odds(event.periods[bet_slip[key].line].spreads[bet_slip[key].point][bet_slip[key].team]);
                    }
    
                    if(data.type==='moneyline'){
                        newdata = event.periods[bet_slip[key].line].money_line;
                        odds = this.format_odds(event.periods[bet_slip[key].line].money_line[bet_slip[key].team]);
                    }
    
                    if(data.type==='totals'){
                        newdata = event.periods[bet_slip[key].line].totals[bet_slip[key].point];
                        odds = this.format_odds(event.periods[bet_slip[key].line].totals[bet_slip[key].point][bet_slip[key].team]);
                    }

                    if(data.type==='teamtotal'){
                        newdata = event.periods[bet_slip[key].line].team_total[bet_slip[key].team];
                        odds = this.format_odds(event.periods[bet_slip[key].line].team_total[bet_slip[key].team][bet_slip[key].point]);
                    }

                    if(data.type==='playerprop'){
                        //console.log("pd", data);
                        //event = this.sp_event_by_id(bet.event_id, bet.team);
                        newdata = data.data;
                        odds = this.format_odds(data.data.price);
                    }

                    if(data.type==='specialevents'){
                        //console.log("pd", data);
                        //event = this.sp_event_by_id(bet.event_id, bet.team);
                        newdata = data.data;
                        odds = this.format_odds(data.point);
                    }
    
                    odds = parseFloat(odds);

                    //freebet 
                    if(odds<parseFloat(-150) || odds>parseFloat(200)) {
                        if(fb===1) {
                            bet_slip[key].stake=0;
                            this.setState({
                                freebet_display:1,
                                freebet:0,
                                freebet_msg:"Free bet only works on single bet and odds must be between -150 and +200"
                            });
                        }
                    }
    
                    let multiplier = 0;
                    if(odds>0) {
                        odds = Math.abs(odds);
                        multiplier = odds/100;
                    }
    
                    if(odds<0) {
                        odds = Math.abs(odds);
                        multiplier = 100/odds;
                    }
    
    
                    const win = (data.stake*multiplier).toFixed(2);
    
                    bet_slip[key] =  {
                        id:bet_slip[key].id,
                        type:bet_slip[key].type,
                        line:bet_slip[key].line,
                        line_name:bet_slip[key].line_name,
                        point:bet_slip[key].point,
                        data:newdata,
                        team:bet_slip[key].team,
                        event_id:bet_slip[key].event_id,
                        sport:bet_slip[key].sport,
                        bettype:bet_slip[key].bettype,
                        stake:bet_slip[key].stake,
                        win:win,
                        event:event
                    }
    
        
                    
                    
                    if((((bet_slip[key].type==='spread' && event?.periods[bet_slip[key].line]?.spreads) || (bet_slip[key].type==='moneyline' && event?.periods[bet_slip[key].line]?.money_line) || (bet_slip[key].type==='totals' && event?.periods[bet_slip[key].line]?.totals) || (bet_slip[key].type==='teamtotal' && event?.periods[bet_slip[key].line]?.team_total))  && event?.periods?.num_0?.period_status === 1) || (bet_slip[key].type==='playerprop') || (bet_slip[key].type==='specialevents')) {
                        total_stake = parseFloat(total_stake)+parseFloat(data.stake);
                        total_win = parseFloat(total_win)+parseFloat(win);
                    }

                    //console.log(bet_slip[key].type, total_stake);
    
                } else {
                    bet_slip[key] =  {
                        id:bet_slip[key].id,
                        type:bet_slip[key].type,
                        line:bet_slip[key].line,
                        line_name:bet_slip[key].line_name,
                        point:bet_slip[key].point,
                        data:bet_slip[key].data,
                        team:bet_slip[key].team,
                        event_id:bet_slip[key].event_id,
                        sport:bet_slip[key].sport,
                        bettype:bet_slip[key].bettype,
                        stake:"",
                        win:"",
                        event:event
                    }
                }

            }
            
        }

        if(multiplearray.length>=2 && multiple!==0 ) { 
            multiple = 1; 
        } else {
            multiple = 0;
        }
        
        let mu_win = "";
        let mu_stake = this.state.mu_stake;
        let multiplier = this.get_multiplier(multiple_odd);
        if(mu_stake>0){
            mu_win = (mu_stake*multiplier).toFixed(2);
        }

        if(multiplier>25) {
            multiple=0;
        }

        

        let bet_slip_active = this.state.bet_slip_active;
        if(multiple===0){
            bet_slip_active=1;
            mu_win="";
            mu_stake="";
        }

        this.setState({
            add_bet_slip:bet_slip,
            si_stake:total_stake.toFixed(0),
            si_win:total_win.toFixed(0),
            multiple:multiple,
            multiple_odd:multiple_odd,
            mu_win:mu_win,
            mu_stake:mu_stake,
            bet_slip_active:bet_slip_active
        });

        //freebet
        if(total_stake>this.state.freebet_balance){
            this.setState({
                freebet_display:0,
                freebet:0
            });
        }
        
    }

    event_info_by_id = (event_id) => {
        const sport = this.props.match.params.sport;
        
        if(sport==='special') {
            let events = this.state.sp_events;
            let obj = events.find(o => o.id == event_id);
            //console.log("obj", events);
            return obj;
        } else {
            let events = this.state.sportdata[sport]["all_events"];
            let obj = events.find(o => o.event_id == event_id);
            return obj;
        }

        
        
    }

    convertToSlug = (Text) => {
        return Text.toLowerCase()
                   .replace(/[^\w ]+/g, '')
                   .replace(/ +/g, '-');
    }

    event_by_id = (data) => {
        
        let events = this.state.sportdata[data.sport]["all_events"];
        let obj = events.find(o => o.event_id === data.event_id);
        return obj;
        
    }

    sp_event_by_id = (event_id, sp_id) => {
        const sport = this.props.match.params.sport;
        const events = this.state.sportdata[sport].sp_data[event_id];
        let obj = events.find(o => o.special_id === sp_id);
        return obj;
    }

    prev_event_by_id = (sport, event_id) => {
        let events = this.state.prevsportdata[sport]["all_events"];
        let obj = events.find(o => o.event_id === event_id);
        if(!obj){
            //console.log("not")
            events = this.state.sportdata[sport]["all_events"];
            obj = events.find(o => o.event_id === event_id);
            return obj;
        } else {
            return obj;
        }
    }

    updownarrow(current, prev) {

        current = parseFloat(current);
        prev = parseFloat(prev);
        
        if(current>prev) {
            return <IoMdArrowUp className='upicon' />
        } else if (current<prev) {
            return <IoMdArrowDown className='downicon' />
        } else {
            return "";
        }
    }

    updownclass(current, prev) {
        current = parseFloat(current);
        prev = parseFloat(prev);
        if(current>prev) {
            return "up"
        } else if (current<prev) {
            return "down"
        } else {
            return "";
        }
    }

   
    verify_token = () => {
        const token = localStorage.getItem("token");
        if(!token){this.props.history.push("/login");}
        const sport = this.props.match.params.sport;
        axios.get('/api/auth', {
            params:{sport:sport},
            headers:{token: token}
        })
        .then(async (res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){

                const fdata = await this.updatesportdata(response.sportdata, 0);
                this.updatesp("basketball", response.sp_basketball);
                this.updatesp("football", response.sp_football);

                this.setState({
                    user:response.data,
                    sp_events:response.sp_events,
                    sp_loading:0,
                    prevsportdata:fdata,
                    freebet_balance:response.data.freebet_balance,
                    login_status: 1
                });

                if(this.props.login_status !== 1)
                    this.props.setLoginStatus(1);

                this.register_socket();
                localStorage.setItem('balance', response.data.balance);
                localStorage.setItem('casino', response.data.casino);
                localStorage.setItem('api', response.api);
                window.dispatchEvent(new Event('storage'));
                
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    placeBet = () => {
        this.setState({
            placingbet:1,
        });
        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        axios.post('/api/placebet', {
            id:this.state.user.id,
            freebet:this.state.freebet,
            betdata:this.state.add_bet_slip
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    add_bet_slip:{},
                    placingbet:0,
                    si_stake:"",
                    si_win:"",
                    toastMsg:response.error_msg,
                    showToast:1,
                    placingbet_mu:0,
                    mu_stake:"",
                    mu_win:"",
                    multiple_odd:1,
                    multiple:0,
                    freebet:0,
                    freebet_balance:response.freebet_balance,
                    freebet_display:0
                }, this.updateBetSlip());
                this.hideBetSlip();
                localStorage.setItem('balance', response.balance);
                window.dispatchEvent(new Event('storage'));
            } else {
                this.setState({
                    placingbet:0,
                    placingbet_mu:0,
                    toastMsg:response.error_msg,
                    showToast:1
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                placingbet_mu:0,
                toastMsg:e.message,
                showToast:1
            });
            //yconsole.log(e.message);
        });
    }


    muplaceBet = () => {
        this.setState({
            placingbet_mu:1,
        });
        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        axios.post('/api/mu-placebet', {
            id:this.state.user.id,
            betdata:this.state.add_bet_slip,
            stake:this.state.mu_stake,
            win:this.state.mu_win,
            odds:this.state.multiple_odd
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    add_bet_slip:{},
                    placingbet:0,
                    si_stake:"",
                    si_win:"",
                    toastMsg:response.error_msg,
                    showToast:1,
                    placingbet_mu:0,
                    mu_stake:"",
                    mu_win:"",
                    multiple_odd:1,
                    multiple:0
                }, this.updateBetSlip());
                localStorage.setItem('balance', response.balance);
                window.dispatchEvent(new Event('storage'));
                this.hideBetSlip();
            } else {
                this.setState({
                    placingbet:0,
                    placingbet_mu:0,
                    toastMsg:response.error_msg,
                    showToast:1
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                placingbet_mu:0,
                toastMsg:e.message,
                showToast:1
            });
            //yconsole.log(e.message);
        });
    }


    //format socket data.
    groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
          
          // If an array already present for key, push it to the array. Else create an array and push the object
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
          return result;
        }, {}); // empty object is the initial value for result object
    };

    /*
    
      
    sortByKey = (array, key) => {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    };

    format_sportdata = (id, name, data) => {
        if(!data){ data = [] }
        const top_leagues = {
            1 : ['England - Premier League', 'FIFA - World Cup', 'France - Ligue 1'],
            2 : [],
            3 : ['NBA', 'NCAA'],
            4 : ['NHL', 'NCAA'],
            5 : [],
            6 : [],
            7 : ['NCAA', 'NFL', 'NFL - Alternate Lines'],
            8 : [],
            9 : ['MLB', 'NCAA'],
            11 : [],
            12 : [],
            13 : [],
            14 : [],
            15 : [],
            16 : [],
            17 : [],
        }

        let live_data = [];
        let live_data_league = [];
        let upcoming_data = [];
        let upcoming_data_league = [];
        let matchup_data = [];
        let matchup_data_league = [];
        let upcoming_leagues_slugs=[];
        let all_events=[];

        //fetch previous data and merge the new data
        let prev_data = this.state.sportdata[name].all_events;
        for(const event of data ){
            //console.log(spd.category);
            let added = 0;
            for (let key in prev_data) {
                if(prev_data[key].event_id === event.event_id){
                    prev_data[key]=event;
                    added = 1;
                }
            }
            if(added===0) { prev_data.push(event); }
            //console.log(added);
        }

        data = prev_data;


        for(const event of data ){
            const now = moment.tz("America/Los_Angeles").format("YYYYMMDDHHmmss");
            const event_time = moment.tz(moment(event.starts+"Z"), "America/Los_Angeles").format("YYYYMMDDHHmmss");
      
            if((id===2 && event.resulting_unit==='Games') || event.resulting_unit==='Hits + Runs + Errors') {
              continue;
            }
      
            if(id===1 || id===5) {
              // Remove 0, 0.25 && 0.75 handicap & total
              for (const prs in event.periods) {
                  for(const hkey in event.periods[prs].spreads) {
                    let n = Math.abs(event.periods[prs].spreads[hkey].hdp);
                    let decimal = n - Math.floor(n);
                    if( decimal == '0.25' || decimal == '0.75' || n == '0') {
                      delete event.periods[prs].spreads[hkey];
                    }
                  }
      
                  for(const tkey in event.periods[prs].totals) {
                    let n = Math.abs(event.periods[prs].totals[tkey].points);
                    let decimal = n - Math.floor(n);
                    if( decimal == '0.25' || decimal == '0.75' || n == '0') {
                      delete event.periods[prs].totals[tkey];
                    }
                  }
      
              }
            }
      
            //if(event.event_type==='live' && !event.period_results && event.periods.num_0 && event.periods.num_0.period_status!==2) {
            if(event.event_type==='live') {
              let addlive = 1;
              if(event?.period_results?.length > 0) {
                for(let index in event.period_results) {
                    let result = event.period_results[index];
                    if(result.number==0){
                        addlive=0;
                    }
                }
              }

              if(addlive===1){
                live_data.push(event);
                live_data_league.push(event.league_name);
                all_events.push(event);
              }
              
            } else if(event.event_type==='prematch' && !event.period_results && event.periods.num_0 && event.periods.num_0.period_status===1 && event_time>now) {
                upcoming_data.push(event);
                upcoming_data_league.push(event.league_name);
                all_events.push(event);
                if(top_leagues[id].length>0) {
                  if(top_leagues[id].includes(event.league_name)){
                      matchup_data.push(event);
                      matchup_data_league.push(event.league_name);
                  }
                } else {
                    matchup_data.push(event);
                    matchup_data_league.push(event.league_name);
                }
                
            }
        }

        live_data = this.sortByKey(live_data, "starts");
        upcoming_data = this.sortByKey(upcoming_data, "starts");
        matchup_data = this.sortByKey(matchup_data, "starts");
        
        
        live_data = this.groupBy(live_data, "league_name");
        upcoming_data = this.groupBy(upcoming_data, "league_name");
        matchup_data = this.groupBy(matchup_data, "league_name");

        live_data_league = [...new Set(live_data_league)].sort();
        upcoming_data_league = [...new Set(upcoming_data_league)].sort();
        matchup_data_league = [...new Set(matchup_data_league)].sort();

        for(const league of upcoming_data_league ){
            upcoming_leagues_slugs.push({
              name : league,
              slug : this.convertToSlug(league),
              count: upcoming_data[league].length
            });
        }
      
        if(matchup_data_league.length===0) {
            matchup_data=upcoming_data;
            matchup_data_league=upcoming_data_league;
        }

        let sp_data = this.state.sportdata[name].sp_data;

        const sport_data = {
            live: live_data,
            live_data_league:live_data_league,
            upcoming:upcoming_data,
            upcoming_data_league:upcoming_data_league,
            matchup:matchup_data,
            matchup_data_league:matchup_data_league,
            upcoming_leagues_slugs:upcoming_leagues_slugs,
            all_events:all_events,
            top_leagues:top_leagues[id],
            sp_data:sp_data
        }
      
        return sport_data;
    }

    */
    updatesportdata = async (data, pd) => {
        let prev_data = this.state.sportdata;
        if(pd===0) {
            prev_data = null;
        }
        let basketball = await format_sportdata(3, "basketball", data.basketball, prev_data);
        let football = await format_sportdata(7, "football", data.football, prev_data);
        let baseball = await format_sportdata(9, "baseball", data.baseball, prev_data);
        let hockey = await format_sportdata(4, "hockey", data.hockey, prev_data);
        let mma = await format_sportdata(8, "MMA", data.MMA, prev_data);
        let soccer = await format_sportdata(1, "soccer", data.soccer, prev_data);
        let golf = await format_sportdata(14, "golf", data.golf, prev_data);
        let custom = await format_sportdata(99, "custom", data.custom, prev_data);

        const final_data = {
            basketball:basketball,
            football:football,
            baseball:baseball,
            hockey:hockey,
            MMA:mma,
            soccer:soccer,
            golf:golf,
            custom:custom
        }

        this.setState({
            sportdata:final_data
        }, function () {
            this.updateBetSlip();
        });

        return final_data;
    }

    updatesp = (sport, data) => {
        let fdata = this.state.specialmarket;
        //let prevdata = this.state.sportdata;
       
        
        if(data?.length>0) {
            let sp_data = this.groupBy(data, "category");
            //let sp_data = data;
            //console.log(sport, fdata);
        
            fdata[sport]=sp_data;
            this.setState({
                specialmarket:fdata,
            }, function () {
                //console.log("fdata", fdata)
                this.updateBetSlip();
            });

            
        }
        
    }

    

    register_socket = () => {
        
        socket.on("sport_data", data => {
            const size = new TextEncoder().encode(JSON.stringify(data)).length
            const kiloBytes = size / 1024;
            console.log("incoming", new Date(), kiloBytes+"kb");
            this.updatesportdata(data, 1);
        });

        /*
        socket.on("sp_basketball", data => {
            const size = new TextEncoder().encode(JSON.stringify(data)).length
            const kiloBytes = size / 1024;
            console.log("incoming-sp-bb", new Date(), kiloBytes+"kb");
            this.updatesp("basketball", data);
        });

        
        socket.on("sp_football", data => {
            const size = new TextEncoder().encode(JSON.stringify(data)).length
            const kiloBytes = size / 1024;
            console.log("incoming-sp-fb", new Date(), kiloBytes+"kb");
            this.updatesp("football", data);
        });
        */
        
    }

    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const event_id = this.props.match.params.event_id;
        const pre_event_id = prevProps.match.params.event_id;
        if(event_id!==pre_event_id) {
            this.setState({
                sptab_active:'All'
            });
        }

        
    }
    

    componentWillUnmount(){
        socket.off("sport_data");
        socket.removeAllListeners();
        //console.log("component unmount")
    }

    showBetSlip = () => {
        document.body.classList.add('show-bet-slip');
    }

    hideBetSlip = () => {
        document.body.classList.remove('show-bet-slip');
    }


    eventstable = (type, league_data, event_data) => {
        
        const sport = this.props.match.params.sport;
        let table = '';
        if(league_data?.length === 0 || Object.keys(event_data)?.length === 0 || (league_data?.length === 1 && !event_data?.[league_data[0]])){
            table = <div className='no-live-data'>
                        <IoMdAlert/>
                        <p>There are currently no events to show.</p>
                    </div>;
        } else {
            table = league_data.map((league, i) => (
                    event_data[league] ?
                        <div key={i}>
                                <div className='league_name bg-s-light'>
                                    <Container fluid>
                                        <Row>
                                            <Col xs={4}>{league}</Col>
                                            <Col xs={8}>
                                                <Row>
                                                    <Col xs={4} className="bet-heading">HANDICAP {sport==='tennis' ? "- Sets" : ""}</Col>
                                                    <Col xs={4} className="bet-heading">MONEY LINE</Col>
                                                    <Col xs={4} className="bet-heading">TOTAL {sport==='tennis' ? "- Sets" : ""}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>

                            <div className="events">
                                <Container fluid>
                                    {
                                        event_data[league].map((event, e) => (
                                            event.event_id ?
                                            <Row key={event.event_id} className="event-row">
                                                <Col xs={4}>
                                                    <NavLink to={sport==='custom' ? "#" : "/sports/"+sport+"/event/"+event.event_id} className="event_link">
                                                        <ul className='team-name'>
                                                            <li>{event.away}</li>
                                                            <li>{event.home}</li>
                                                            { type==='live' ? <li className='time-live'>Live Now</li> : "" }
                                                            { type==='matchups' ? <li className='time'>{this.format_datetime(event.starts)}</li> : "" }
                                                            { type==='upcoming' ? <li className='time'>{this.format_datetime(event.starts)}</li> : "" }
                                                        </ul>
                                                        {sport==='custom' ? "" : <span className='more-bets'><span>+</span> More Bets</span> }
                                                    </NavLink>
                                                </Col>
                                                <Col xs={8}>
                                                    <Row>
                                                        <Col xs={4} className="bet-lines">
                                                            <Row className="g-1">
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                        event?.periods?.num_0?.spreads!==null && event?.periods?.num_0?.spreads!==null && event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_"+Object.keys(event?.periods?.num_0?.spreads)[0]+"_spread_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "spread", Object.values(event.periods.num_0.spreads)[0], "away", event.event_id, sport, type, "num_0", "Game", Object.keys(event?.periods?.num_0?.spreads)[0])}>
                                                                            <small>{this.sign("away", Object.values(event?.periods?.num_0?.spreads)[0]?.hdp)}</small>

                                                                            
                                                                            {this.format_odds(Object.values(event.periods.num_0.spreads)[0]?.away)}
                                                                            {/*this.updownarrow(this.format_odds(Object.values(event.periods.num_0.spreads)[0].away), this.format_odds(Object.values(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.spreads)[0]?.away))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                        event?.periods?.num_0?.spreads!=='undefined' && event?.periods?.num_0?.spreads!==null && event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_"+Object.keys(event?.periods?.num_0?.spreads)[0]+"_spread_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "spread", Object.values(event.periods.num_0.spreads)[0], "home", event.event_id, sport, type, "num_0", "Game", Object.keys(event?.periods?.num_0?.spreads)[0])}>
                                                                            <small>{this.sign("home", Object.values(event.periods.num_0.spreads)[0]?.hdp)}</small>
                                                                            {this.format_odds(Object.values(event.periods.num_0.spreads)[0]?.home)}
                                                                            {/*this.updownarrow(this.format_odds(Object.values(event.periods.num_0.spreads)[0].home), this.format_odds(Object.values(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.spreads)[0]?.home))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={4} className="bet-lines">
                                                            <Row className="g-1">
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                        event?.periods?.num_0?.money_line!=='undefined' && event?.periods?.num_0?.money_line!==null && event?.periods?.num_0?.money_line?.away!==null && event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_0_moneyline_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "moneyline", event.periods.num_0.money_line, "away", event.event_id, sport, type, "num_0", "Game", "0")}>
                                                                            {this.format_odds(event.periods.num_0.money_line.away)}
                                                                            {/*this.updownarrow(this.format_odds(event.periods.num_0.money_line.away), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.money_line?.away))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>
                                                                
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                        event?.periods?.num_0?.money_line!=='undefined' && event?.periods?.num_0?.money_line!==null && event?.periods?.num_0?.money_line?.home!==null &&  event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_0_moneyline_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "moneyline", event.periods.num_0.money_line, "home", event.event_id, sport, type, "num_0", "Game", "0")}>
                                                                            {this.format_odds(event.periods.num_0.money_line.home)}
                                                                            {/*this.updownarrow(this.format_odds(event.periods.num_0.money_line.home), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.money_line?.home))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>

                                                                {
                                                                    sport==='soccer' || sport==='custom' ?
                                                                    
                                                                    <Col xs={12} className="bet-lines">
                                                                        {
                                                                           event?.periods?.num_0?.money_line!=='undefined' && event?.periods?.num_0?.money_line!==null && event?.periods?.num_0?.money_line?.draw!==null && event?.periods?.num_0?.period_status===1 ?
                                                                            
                                                                            <Button className={this.state.add_bet_slip["num_0_0_moneyline_draw_"+event.event_id] ? "btn-sm btn-draw active" : "btn-sm btn-draw"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "moneyline", event.periods.num_0.money_line, "draw", event.event_id, sport, type, "num_0", "Game", "0")}>
                                                                                {this.format_odds(event.periods.num_0.money_line.draw)}
                                                                                {/*this.updownarrow(this.format_odds(event.periods.num_0.money_line.draw), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.money_line?.draw))*/}
                                                                            </Button>
                                                                            
                                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                                        }
                                                                    </Col>

                                                                    :""
                                                                }
                                                            </Row>
                                                        </Col>
                                                        <Col xs={4} className="bet-lines">
                                                            <Row className="g-1">
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                       event?.periods?.num_0?.totals!=='undefined' && event?.periods?.num_0?.totals!==null && event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_"+Object.keys(event.periods.num_0.totals)[0]+"_totals_over_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "totals", Object.values(event.periods.num_0.totals)[0], "over", event.event_id, sport, type, "num_0", "Game", Object.keys(event.periods.num_0.totals)[0])}>
                                                                            <small>O {Object.values(event.periods.num_0.totals)[0]?.points}</small>
                                                                            {this.format_odds(Object.values(event.periods.num_0.totals)[0]?.over)}
                                                                            {/*this.updownarrow(this.format_odds(Object.values(event.periods.num_0.totals)[0].over), this.format_odds(Object.values(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.totals)[0]?.over))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>
                                                                <Col xs={12} className="bet-lines">
                                                                    {
                                                                        event?.periods?.num_0?.totals!=='undefined' && event?.periods?.num_0?.totals!==null && event?.periods?.num_0?.period_status===1 ?
                                                                        
                                                                        <Button className={this.state.add_bet_slip["num_0_"+Object.keys(event.periods.num_0.totals)[0]+"_totals_under_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, event.periods.num_0.line_id, "totals", Object.values(event.periods.num_0.totals)[0], "under", event.event_id, sport, type, "num_0", "Game", Object.keys(event.periods.num_0.totals)[0])}>
                                                                            <small>U {Object.values(event.periods.num_0.totals)[0]?.points}</small>
                                                                            {this.format_odds(Object.values(event.periods.num_0.totals)[0]?.under)}
                                                                            {/*this.updownarrow(this.format_odds(Object.values(event.periods.num_0.totals)[0].under), this.format_odds(Object.values(this.prev_event_by_id(sport, event.event_id)?.periods?.num_0?.totals)[0]?.under))*/}
                                                                        </Button>
                                                                        
                                                                        : <Button className='btn-sm'><IoMdLock/></Button>
                                                                    }
                                                                </Col>

                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                            : ""
                                        ))
                                    }
                                </Container>
                            </div>
                        </div>
                        : ""
                    ));
        }

        return table;
    }


    Alleventstable = (event) => {

        const sport = this.props.match.params.sport;
        let table = '';
        const prs = event['periods'];
        table = Object.keys(prs).map((key, e) => (
                this.state.sptab_active === 'All' || this.state.sptab_active===periods[event.sport_id].periods[prs[key].number].description ?
                <Card className="bg-s-secondary mb-15" key={e}>
                    <Card.Header className="card-header-small">{periods[event.sport_id].periods[prs[key].number].description}</Card.Header>
                    {/* Moneyline */}
                    <Card.Body className="bg-s-primary p-0" key="ml1">
                        <div className='league_name bg-s-light'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>Money Line - {periods[event.sport_id].periods[prs[key].number].description}</Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="events">
                            <Container fluid>
                                <Row key={e} className="event-row">
                                    <Col xs={12} className="bet-lines">
                                        <Row className="g-1">
                                            <Col xs={sport==='soccer' || sport==='custom' ? 4 : 6} className="bet-lines">
                                                {
                                                    prs[key]?.money_line!=='undefined' && prs[key]?.money_line?.away  && prs[key]?.period_status===1 ?
                                                    
                                                    <Button className={this.state.add_bet_slip[key+"_0_moneyline_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "moneyline", prs[key].money_line, "away", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "0")}>
                                                        <small>{event.away}</small>
                                                        {this.format_odds(prs[key].money_line.away)}
                                                        {/*this.updownarrow(this.format_odds(prs[key].money_line.away), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.money_line?.away))*/}
                                                    </Button>
                                                    
                                                    : <Button className='btn-sm'><IoMdLock/></Button>
                                                }
                                            </Col>
                                            
                                            {sport==='soccer' || sport==='custom' ? 
                                            <Col xs={4} className="bet-lines">
                                                {
                                                    prs[key]?.money_line!=='undefined' && prs[key]?.money_line?.draw && prs[key]?.period_status===1 ?
                                                    
                                                    <Button className={this.state.add_bet_slip[key+"_0_moneyline_draw_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "moneyline", prs[key].money_line, "draw", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "0")}>
                                                        <small>Draw</small>
                                                        {this.format_odds(prs[key].money_line.draw)}
                                                        {/*this.updownarrow(this.format_odds(prs[key].money_line.draw), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.money_line?.draw))*/}
                                                    </Button>
                                                    
                                                    : <Button className='btn-sm'><IoMdLock/></Button>
                                                }
                                            </Col>
                                            : "" }

                                            <Col xs={sport==='soccer' || sport==='custom' ? 4 : 6} className="bet-lines">
                                                {
                                                    prs[key]?.money_line!=='undefined' && prs[key]?.money_line?.home && prs[key]?.period_status===1 ?
                                                    
                                                    <Button className={this.state.add_bet_slip[key+"_0_moneyline_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "moneyline", prs[key].money_line, "home", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "0")}>
                                                        <small>{event.home}</small>
                                                        {this.format_odds(prs[key].money_line.home)}
                                                        {/*this.updownarrow(this.format_odds(prs[key].money_line.home), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.money_line?.home))*/}
                                                    </Button>
                                                    
                                                    : <Button className='btn-sm'><IoMdLock/></Button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Card.Body>


                    {/* Handicap */}
                    { prs[key]?.spreads!=='undefined' && prs[key]?.spreads!==null ?
                    <Card.Body className="bg-s-primary p-0" key="s1">
                        <div className='league_name bg-s-light'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>Handicap - {periods[event.sport_id].periods[prs[key].number].description}</Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="events">
                            <Container fluid>
                                <Row className="event-row text-center">
                                    <Col xs={6}>{event.away}</Col>
                                    <Col xs={6}>{event.home}</Col>
                                </Row>
                            </Container>
                            <Container fluid> 
                                {
                                    Object.keys(prs[key].spreads).map((skey, se) => (
                                        
                                        <Row key={se} className="event-row">
                                            <Col xs={12} className="bet-lines">
                                                <Row className="g-1">
                                                    <Col xs={6} className="bet-lines">
                                                        {
                                                            prs[key]?.period_status===1 ?
                                                            
                                                            <Button className={this.state.add_bet_slip[key+"_"+skey+"_spread_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "spread", prs[key].spreads[skey], "away", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, skey)}>
                                                                <small>{this.sign("away", prs[key].spreads[skey].hdp)}</small>
                                                                {this.format_odds(prs[key].spreads[skey].away)}
                                                                {/*this.updownarrow(this.format_odds(prs[key].spreads[skey].away), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.spreads[skey]?.away))*/}
                                                            </Button>
                                                            
                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                        }
                                                    </Col>

                                                    <Col xs={6} className="bet-lines">
                                                        {
                                                            prs[key]?.period_status===1 ?
                                                            
                                                            <Button className={this.state.add_bet_slip[key+"_"+skey+"_spread_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "spread", prs[key].spreads[skey], "home", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, skey)}>
                                                                <small>{this.sign("home", prs[key].spreads[skey].hdp)}</small>
                                                                {this.format_odds(prs[key].spreads[skey].home)}
                                                                {/*this.updownarrow(this.format_odds(prs[key].spreads[skey].home), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.spreads[skey]?.home))*/}
                                                            </Button>
                                                            
                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                
                            </Container>
                        </div>
                    </Card.Body>

                    : "" }

                    {/*totals*/}
                    { prs[key]?.totals!=='undefined' && prs[key]?.totals!==null ?
                    <Card.Body className="bg-s-primary p-0" key="t1">
                        <div className='league_name bg-s-light'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>Total - {periods[event.sport_id].periods[prs[key].number].description}</Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="events">
                            <Container fluid> 
                                {
                                    Object.keys(prs[key].totals).map((tkey, te) => (
                                        <Row key={te} className="event-row">
                                            <Col xs={12} className="bet-lines">
                                                <Row className="g-1">
                                                    <Col xs={6} className="bet-lines">
                                                        {
                                                            prs[key]?.period_status===1 ?
                                                            
                                                            <Button className={this.state.add_bet_slip[key+"_"+tkey+"_totals_over_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "totals", prs[key].totals[tkey], "over", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, tkey)}>
                                                                <small>Over {prs[key].totals[tkey].points}</small>
                                                                {this.format_odds(prs[key].totals[tkey].over)}
                                                                {/*this.updownarrow(this.format_odds(prs[key].totals[tkey].over), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.totals[tkey]?.over))*/}
                                                            </Button>
                                                            
                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                        }
                                                    </Col>

                                                    <Col xs={6} className="bet-lines">
                                                        {
                                                            prs[key]?.period_status===1 ?
                                                            
                                                            <Button className={this.state.add_bet_slip[key+"_"+tkey+"_totals_under_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "totals", prs[key].totals[tkey], "under", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, tkey)}>
                                                                <small>Under {prs[key].totals[tkey].points}</small>
                                                                {this.format_odds(prs[key].totals[tkey].under)}
                                                                {/*this.updownarrow(this.format_odds(prs[key].totals[tkey].under), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.totals[tkey]?.under))*/}
                                                            </Button>
                                                            
                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                
                            </Container>
                        </div>
                    </Card.Body>
                    : "" }

                    {/*team totals*/}
                    <Card.Body className="bg-s-primary p-0" key="tt1">
                        <div className='league_name bg-s-light'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>Team Total - {periods[event.sport_id].periods[prs[key].number].description}</Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="events">
                            <Container fluid>
                                <Row className="event-row text-center">
                                    <Col xs={6}>{event.away}</Col>
                                    <Col xs={6}>{event.home}</Col>
                                </Row>
                            </Container>
                            <Container fluid> 
                                <Row key={"ttr1"} className="event-row">
                                    <Col xs={6} className="bet-lines">
                                        {
                                            prs[key]?.team_total!=='undefined' && prs[key]?.team_total!==null && prs[key]?.team_total?.away?.points && prs[key]?.period_status===1 ?
                                            
                                            <Button className={this.state.add_bet_slip[key+"_over_teamtotal_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "teamtotal", prs[key].team_total, "away", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "over")}>
                                                <small>Over {prs[key].team_total.away.points}</small>
                                                {this.format_odds(prs[key].team_total.away.over)}
                                                {/*this.updownarrow(this.format_odds(prs[key].team_total.away.over), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.team_total?.away?.over))*/}
                                            </Button>
                                            
                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                        }
                                    </Col>

                                    <Col xs={6} className="bet-lines">
                                        {
                                            prs[key]?.team_total!=='undefined' && prs[key]?.team_total!==null && prs[key]?.team_total?.home?.points && prs[key]?.period_status===1 ?
                                            
                                            <Button className={this.state.add_bet_slip[key+"_over_teamtotal_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "teamtotal", prs[key].team_total, "home", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "over")}>
                                                <small>Over {prs[key]?.team_total.home.points}</small>
                                                {this.format_odds(prs[key]?.team_total.home.over)}
                                                {/*this.updownarrow(this.format_odds(prs[key].team_total.home.over), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.team_total?.home?.over))*/}
                                            </Button>
                                            
                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                        }
                                    </Col>
                                </Row>

                                <Row key={"ttr2"} className="event-row">
                                    <Col xs={6} className="bet-lines">
                                        {
                                            prs[key]?.team_total!=='undefined' && prs[key]?.team_total!==null && prs[key]?.team_total?.away?.points && prs[key]?.period_status===1 ?
                                            
                                            <Button className={this.state.add_bet_slip[key+"_under_teamtotal_away_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "teamtotal", prs[key].team_total, "away", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "under")}>
                                                <small>Under {prs[key]?.team_total.away.points}</small>
                                                {this.format_odds(prs[key]?.team_total.away.under)}
                                                {/*this.updownarrow(this.format_odds(prs[key].team_total.away.under), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.team_total?.away?.under))*/}
                                            </Button>
                                            
                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                        }
                                    </Col>

                                    <Col xs={6} className="bet-lines">
                                        {
                                            prs[key]?.team_total!=='undefined' && prs[key]?.team_total!==null && prs[key]?.team_total?.home?.points && prs[key]?.period_status===1 ?
                                            
                                            <Button className={this.state.add_bet_slip[key+"_under_teamtotal_home_"+event.event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(event, prs[key].line_id, "teamtotal", prs[key].team_total, "home", event.event_id, sport, "all", key, periods[event.sport_id].periods[prs[key].number].description, "under")}>
                                                <small>Under {prs[key]?.team_total.home.points}</small>
                                                {this.format_odds(prs[key]?.team_total.home.under)}
                                                {/*this.updownarrow(this.format_odds(prs[key].team_total.home.under), this.format_odds(this.prev_event_by_id(sport, event.event_id)?.periods['num_'+prs[key].number]?.team_total?.home?.under))*/}
                                            </Button>
                                            
                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Card.Body>

                </Card>

                : "" 
            ));
        
        return table;
    }

    SpecialMarket = (event) => {
        const sport = this.props.match.params.sport;
        const {sportdata} = this.state;
        const sp_data = sportdata[sport].sp_data;
        
        let table = '';
        const event_id = event.event_id;
        if(sp_data[event_id]){
            const spd = sp_data[event_id];
            //console.log(spd)
            table = 
                    this.state.sptab_active === 'All' || this.state.sptab_active==='playerprop' ?
                    <Card className="bg-s-secondary mb-15" key="spd">
                        <Card.Header className="card-header-small">Player Props</Card.Header>
                        <Card.Body className="bg-s-primary p-0" key="spl1">
                        {
                            Object.keys(spd).map((key, e) => (
                                <div className="events" key={e}>
                                    <Container fluid> 
                                        <Row key={"ttr1"} className="event-row align-items-center">
                                            <Col>{spd[key].name}</Col>
                                            {
                                                Object.keys(spd[key].lines).map((linekey, le) => (
                                                    <Col className="bet-lines" key={le}>
                                                        {
                                                            spd[key]?.status==='O' ?
                                                            <Button className={this.state.add_bet_slip["num_0_"+spd[key].lines[linekey].name+"_playerprop_"+spd[key].special_id+"_"+spd[key].event_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(spd[key], spd[key].lines[linekey].line_id, "playerprop", spd[key].lines[linekey], spd[key].special_id, event.event_id, sport, "special", "num_0", "PlayerProp", spd[key].lines[linekey].name)}>
                                                                <small>{spd[key].lines[linekey].name} {spd[key].lines[linekey].handicap}</small>
                                                                {this.format_odds(spd[key].lines[linekey].price)}
                                                            </Button>
                                                            
                                                            : <Button className='btn-sm'><IoMdLock/></Button>
                                                        }
                                                    </Col>
                                                )) 
                                            }
                                        </Row>
                                    </Container>
                                </div>
                            ))
                        }
                        </Card.Body>
                    </Card>

                    : ""
                
            
        }

        return table;
    }


    special_event_data = (sp_data) => {
        const sport = this.props.match.params.sport;
        let spd = sp_data.category
        let table = '';
        //console.log(this.state.add_bet_slip);
        table = 
                Object.keys(spd).map((key, e) => (
                    <Card className="bg-s-secondary mb-15" key={e}>
                        <Card.Header className="card-header-small">{spd[key].name}</Card.Header>
                        <Card.Body className="bg-s-primary p-0" key="spl1">
                            {
                                Object.keys(spd[key].bets).map((linekey, le) => (
                                    <div key={le}>
                                    <div className='league_name bg-s-light'>
                                        <Container fluid>
                                            <Row>
                                                <Col xs={12}>{spd[key].bets[linekey].name}</Col>
                                            </Row>
                                        </Container>
                                    </div>

                                    <div className="events" key={e}>
                                        <Container fluid> 
                                            <Row key={"ttr1"} className="event-row align-items-center gy-2">
                                                {
                                                    Object.keys(spd[key].bets[linekey].odds).map((oddkey, oe) => (
                                                        <Col className="bet-lines" key={oe} xs={6}>
                                                            <Button className={this.state.add_bet_slip["num_0_"+spd[key].bets[linekey].odds[oddkey].value+"_specialevents_"+spd[key].bets[linekey].odds[oddkey].name+"_"+spd[key].bets[linekey].id] ? "btn-sm active" : "btn-sm"}  onClick={()=>this.add_bet_slip(spd[key], spd[key].bets[linekey].id, "specialevents", spd[key].bets[linekey], spd[key].bets[linekey].odds[oddkey].name, spd[key].bets[linekey].id, sport, "SP", "num_0", "SpecialEvents", spd[key].bets[linekey].odds[oddkey].value)} >
                                                                <small>{spd[key].bets[linekey].odds[oddkey].name}</small>
                                                                {this.format_odds(spd[key].bets[linekey].odds[oddkey].value)}
                                                            </Button>
                                                            
                                                        </Col>    
                                                    ))
                                                }
                                            </Row>          
                                        </Container>
                                    </div>
                                    </div>
                                )) 
                            }
                        </Card.Body>
                    </Card>
                ))
            
            

        return table;
    }

    special_event_data_football = (sp_data) => {
        const sport = this.props.match.params.sport;
        let spd = sp_data;
        let table = '';
        //console.log(this.state.add_bet_slip);
        table = 
                Object.keys(spd).map((key, e) => (
                    <Card className="bg-s-secondary mb-15" key={e}>
                        <Card.Header className="card-header-small">{key}</Card.Header>
                        <Card.Body className="bg-s-primary p-0" key={e}>
                            {
                                Object.keys(spd[key]).map((linekey, le) => (
                                    spd[key][linekey].status=='O' ?
                                    <div key={le}>
                                        <div className='league_name bg-s-light'>
                                            <Container fluid>
                                                <Row>
                                                    <Col xs={12}>{spd[key][linekey].name}</Col>
                                                </Row>
                                            </Container>
                                        </div>

                                        <div className="events" key={e}>
                                            <Container fluid> 
                                                <Row key={"ttr1"} className="event-row align-items-center gy-2">
                                                    {
                                                        Object.keys(spd[key][linekey].lines).map((oddkey, oe) => (
                                                            <Col className="bet-lines" key={oe} xs={6}>
                                                                {
                                                                spd[key][linekey]?.status=='O' ?
                                                                <Button className={this.state.add_bet_slip["num_0_"+spd[key][linekey].lines[oddkey].name+"_playerprop_"+spd[key][linekey].special_id+"_"+spd[key][linekey].lines[oddkey].line_id] ? "btn-sm active" : "btn-sm"} onClick={()=>this.add_bet_slip(spd[key][linekey], spd[key][linekey].lines[oddkey].line_id, "playerprop", spd[key][linekey].lines[oddkey], spd[key][linekey].special_id, spd[key][linekey].lines[oddkey].line_id, "special", "special", "num_0", "PlayerProp", spd[key][linekey].lines[oddkey].name)}>
                                                                    <small>{spd[key][linekey].lines[oddkey].name} {spd[key][linekey].lines[oddkey].handicap}</small>
                                                                    {this.format_odds(spd[key][linekey].lines[oddkey].price)}
                                                                </Button>
                                                                
                                                                : <Button className='btn-sm'><IoMdLock/></Button>
                                                                }
                                                                
                                                            </Col>    
                                                        ))
                                                    }
                                                </Row>          
                                            </Container>
                                        </div>
                                    </div>

                                    : ""
                                )) 
                            }
                        </Card.Body>
                    </Card>
                ))
            
            

        return table;
    }

    render () {
        //console.log(this.state.prevsportdata);
        //const { sportdata, live_data, live_data_league, matchup_data, matchup_data_league, upcoming_data_league, upcoming_leagues_slugs } = this.state;
        if(this.state.login_status === 0) return (<></>);
        
        const {sportdata, add_bet_slip} = this.state;
        const sport = this.props.match.params.sport;
        const event_id = this.props.match.params.event_id;

        let live_data = "";
        let live_data_league = ""; 
        let matchup_data = ""; 
        let matchup_data_league = ""; 
        let upcoming_leagues_slugs = ""; 
        let league_info = "";
        let event_info = [];
        let upcoming_data = "";
        let top_leagues = [];


        if(sportdata[sport]){
            live_data = sportdata[sport].live;
            live_data_league = sportdata[sport].live_data_league;
            matchup_data = sportdata[sport].matchup;
            matchup_data_league = sportdata[sport].matchup_data_league;
            upcoming_leagues_slugs = sportdata[sport].upcoming_leagues_slugs;
            upcoming_data = sportdata[sport].upcoming;
            top_leagues = sportdata[sport].top_leagues;
            if(event_id) {
                event_info = this.event_info_by_id(event_id);
                //console.log(event_info);
            }
        } else if(sport==='special'){
            event_info = this.event_info_by_id(event_id);
        }

        let sport_league = "";
        if(this.props.match.params.league){
            league_info = [];
            sport_league = this.props.match.params.league;
            if(sportdata[sport]){
                for(const league of upcoming_leagues_slugs ){
                    if(league.slug===sport_league) {
                        league_info.push(league.name);
                    }
                }
            }
        }

        //console.log(sport, Object.keys(this.state.specialmarket[sport]).length)

    
        return (
            
            <>
            <Toast bg="dark" onClose={() => this.setState({ showToast:false, toastMsg:""})} show={this.state.showToast} delay={3000} autohide>
                    <Toast.Body>{this.state.toastMsg}</Toast.Body>
            </Toast>
            <div className='main-content'>
                { sport==='special' ? 

                <div className='sport-betting-lines'>
                    {event_id ? 

                        event_info ? 
                            <Container fluid>
                                <Row>
                                    <Col md={12} className="aet">
                                        <Card className="bg-s-secondary mb-15" key="crd1">
                                            <Card.Body className='bg-s-primary'>
                                                <h4 className="mb-0 text-sentance-case breadcrumb">{event_info.name}</h4>
                                                <small>Expiry: {this.format_datetime_la(event_info.expiry)}</small>
                                            </Card.Body>
                                        </Card>

                                        {this.special_event_data(event_info)}
                                    </Col>
                                </Row>
                            </Container>

                            :

                            <Container fluid>
                                <Row>
                                    <Col md={12} className="aet">
                                        <Card className="bg-s-secondary mb-15" key="crd1">
                                            <Card.Body className='bg-s-primary'>
                                                Loading...
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        
                        :
                        <Container fluid>
                            <Row>
                                <Col md={12} className="aet">
                                    <Card className="bg-s-secondary mb-15" key="crd1">
                                        <Card.Body className='bg-s-primary p-0'>
                                            {this.state.sp_events.length<=0 ? 
                                            this.state.sp_loading===0 ? <div className='p-10'>No events found.</div> : <div className='p-10'>Loading...</div>
                                            :
                                                Object.keys(this.state.sp_events).map((key, e) => (
                                                    <div className="all_leagues" key={e}><NavLink to={"/sports/special/event/"+this.state.sp_events[key].id}>{this.state.sp_events[key].name} <span className='more-bets'><span>+</span> View Bets</span></NavLink></div>
                                                ))
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>

                :

                <div className='sport-betting-lines'>
                    {!sportdata[sport] ? <Container fluid><Row><Col md={12}><div className="loading">Loading...</div></Col></Row></Container> :
                    <Container fluid>
                        <Row>
                            {/* v2 : Event details  */}

                            {event_id ? 
                            
                            event_info ?
                            <Col md={12} className="aet">
                                <Card className="bg-s-secondary mb-15" key="crd1">
                                    <Card.Body>
                                        <h4 className="mb-0 text-sentance-case breadcrumb"><NavLink to={"/sports/"+this.props.match.params.sport}>{this.props.match.params.sport}</NavLink><NavLink to={"/sports/"+this.props.match.params.sport+"/"+this.convertToSlug(event_info?.league_name)}> / {event_info?.league_name}</NavLink></h4>
                                    </Card.Body>
                                </Card>

                                <Card className="bg-s-secondary mb-15" key="crd2">
                                    <Card.Body className="bg-s-primary p-0">
                                        <div className="events">
                                            <Container fluid>
                                                <Row key={event_info.event_id} className="event-row">
                                                    <Col xs={12}>
                                                        <ul className='team-name'>
                                                            <li>{event_info.away}</li>
                                                            <li>{event_info.home}</li>
                                                            { event_info.event_type==='live' ? <li className='time-live'>Live Now</li> : "" }
                                                            { event_info.event_type==='prematch' ? <li className='time'>{this.format_datetime(event_info.starts)}</li> : "" }
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer className="p-0 bg-s-primary">
                                        <ul>
                                            <li key={"all"} className={this.state.sptab_active==='All' ? "active" : ""} onClick={()=>this.sptab_select('All')}>All</li>
                                            {
        
                                                Object.keys(event_info.periods).map((key, e) => (
                                                    <li key={e} className={this.state.sptab_active===periods[event_info.sport_id]?.periods[event_info.periods[key].number].description ? "active" : ""} onClick={()=>this.sptab_select(periods[event_info.sport_id].periods[event_info.periods[key].number].description)}>{periods[event_info.sport_id].periods[event_info.periods[key].number].description}</li>
                                                ))
                                                
                                            }

                                            { sportdata[sport].sp_data[event_info.event_id] ?
                                            <li key={"playerprop"} className={this.state.sptab_active==='playerprop' ? "active" : ""} onClick={()=>this.sptab_select('playerprop')}>player props</li>
                                            : "" }
                                        </ul>
                                    </Card.Footer>
                                </Card>

                                {this.Alleventstable(event_info)}

                                {this.SpecialMarket(event_info)}
                                
                            </Col>

                            : 

                            <Col md={12} className="aet">
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Body>
                                        Error: Event not found
                                    </Card.Body>
                                </Card>
                                
                            </Col>
                            
                            : 

                            !league_info ?
                            <Col md={12}>
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Body>
                                        <h4 className="mb-0 text-sentance-case">{this.props.match.params.sport}</h4>
                                    </Card.Body>
                                    <Card.Footer className="p-0 bg-s-primary">
                                        <ul>
                                            <li key="t1" className={this.state.tab_active===1 ? "active" : ""} onClick={()=>this.tab_select(1)}>MATCHUPS</li>
                                            <li key="t2" className={this.state.tab_active===2 ? "active" : ""} onClick={()=>this.tab_select(2)}>LIVE</li>
                                            <li key="t3" className={this.state.tab_active===3 ? "active" : ""} onClick={()=>this.tab_select(3)}>LEAGUES</li>
                                            {/*Object.keys(this.state.specialmarket[sport]).length>0 ? <li key="t4" className={this.state.tab_active===4 ? "active" : ""} onClick={()=>this.tab_select(4)}>SPECIAL MARKETS</li> : "" */}
                                        </ul>
                                    </Card.Footer>
                                </Card>

                                
                                
                                { sportdata[sport] && this.state.tab_active===1 ?
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Header className="card-header-small">TOP LEAGUES</Card.Header>
                                    <Card.Body className="bg-s-primary p-0">
                                        {this.eventstable("matchups", matchup_data_league, matchup_data)}
                                    </Card.Body>
                                </Card>

                                : "" }

                                { sportdata[sport] && this.state.tab_active===2 ?
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Header className="card-header-small">Live</Card.Header>
                                    <Card.Body className="bg-s-primary p-0">
                                        {this.eventstable("live", live_data_league, live_data)}
                                    </Card.Body>
                                </Card>

                                : "" }




                                { sportdata[sport] && this.state.tab_active===3 ?
                                    <>
                                        {top_leagues.length>0 ?
                                        <Card className="bg-s-secondary mb-15">
                                            <Card.Header className="card-header-small">Top Leauges</Card.Header>
                                            <Card.Body className="bg-s-primary p-0">
                                                {
                                                    upcoming_leagues_slugs.length === 0 ?
                                                    <div className='no-live-data'>
                                                        <IoMdAlert/>
                                                        <p>There are currently no upcoming leagues to show for this sport.</p>
                                                    </div>
                                                    :
                                                    upcoming_leagues_slugs.map((league, i) => {
                                                        
                                                        if(top_leagues.includes(league.name)){
                                                            return (  
                                                                <div key={i} className="all_leagues">
                                                                    <NavLink to={"/sports/"+sport+"/"+league.slug}>{league.name} ({league.count})</NavLink>
                                                                </div>
                                                            )
                                                        }

                                                        return "";
                                                    })
                                                }
                                            </Card.Body>
                                        </Card>
                                        :""}

                                        <Card className="bg-s-secondary mb-15">
                                            <Card.Header className="card-header-small">All Leauges</Card.Header>
                                            <Card.Body className="bg-s-primary p-0">
                                                {
                                                    upcoming_leagues_slugs.length === 0 ?
                                                    <div className='no-live-data'>
                                                        <IoMdAlert/>
                                                        <p>There are currently no upcoming leagues to show for this sport.</p>
                                                    </div>
                                                    :
                                                    upcoming_leagues_slugs.map((league, i) => (  
                                                        <div key={i} className="all_leagues">
                                                            <NavLink to={"/sports/"+sport+"/"+league.slug}>{league.name} ({league.count})</NavLink>
                                                        </div>
                                                    ))
                                                }
                                            </Card.Body>
                                        </Card>
                                    </>

                                    : ""
                                }

                                { sportdata[sport] && this.state.tab_active===4 ?
                                    this.special_event_data_football(this.state.specialmarket[sport])

                                : "" }
                            </Col>
                            : 
                            
                            <Col md={12}>
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Body>
                                        <h4 className="mb-0 text-sentance-case breadcrumb"><NavLink to={"/sports/"+this.props.match.params.sport}>{this.props.match.params.sport}</NavLink> {" / "+league_info[0]}</h4>
                                    </Card.Body>
                                </Card>

                                { league_info ?
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Header className="card-header-small">Live</Card.Header>
                                    <Card.Body className="bg-s-primary p-0">
                                        {this.eventstable("live", league_info, live_data)}
                                    </Card.Body>
                                </Card>
                                : "" }


                                { league_info ?
                                <Card className="bg-s-secondary mb-15">
                                    <Card.Header className="card-header-small">Pre-match</Card.Header>
                                    <Card.Body className="bg-s-primary p-0">
                                        {this.eventstable("upcoming", league_info, upcoming_data)}
                                    </Card.Body>
                                </Card>

                                : "" }
                            </Col>
                            
                            }
                        </Row>
                    </Container>
                    }
                </div>

                }

                {Object.keys(this.state.add_bet_slip).length ?
                <Container className='d-lg-none bet-slip-button'>
                    <Row>
                        <Col className='text-center'>
                            <Button className='btn btn-warning btn-yellow' onClick={()=>this.showBetSlip()}>
                                BET SLIP  <span>{Object.keys(this.state.add_bet_slip).length}</span>
                            </Button>
                        </Col>
                    </Row>
                </Container>
                : ""}

                <div className='bet-slip'>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <ul>
                                            <li key="bs1" className={this.state.bet_slip_active===1 ? "active" : ""} onClick={()=>this.bet_slip_active(1)}>SINGLES</li>
                                            {this.state.multiple===1 ?
                                            <li key="bs2" className={this.state.bet_slip_active===2 ? "active" : ""} onClick={()=>this.bet_slip_active(2)}>MULTIPLES</li>
                                            : "" }
                                        </ul>
                                    </Card.Header>
                                    {this.state.bet_slip_active===1 || this.state.multiple===0 ?
                                    <>
                                    <Card.Body className='p-0'>
                                        {
                                            Object.keys(this.state.add_bet_slip).length===0 ?
                                            <div className='no-bets'>
                                                <IoIosDocument />
                                                <p>There are no bets on your ticket.</p>
                                                <p><small>Click the odds to add a bet.</small></p>
                                            </div>

                                            :
                                            
                                            Object.keys(add_bet_slip).map((bet, e) => {
                                                //console.log(add_bet_slip)
                                                let event = "";
                                                if(add_bet_slip[bet].sport==='special'){
                                                    event = add_bet_slip[bet];
                                                } else {
                                                    event = this.event_by_id(add_bet_slip[bet]);
                                                }
                                                return(
                                                    event ?
                                                    <div className='bet-slip-row' key={e}>
                                                        
                                                        <Container>
                                                            <Row className='align-items-center'>
                                                                {   add_bet_slip[bet].sport==='special' ? 
                                                                    <Col xs={12} className="bs-teamname">{event.data.name} <IoMdClose className='closebet' onClick={()=>this.removeBet(add_bet_slip[bet])} /></Col>
                                                                    :
                                                                    <Col xs={12} className="bs-teamname">{event.away} vs {event.home} <IoMdClose className='closebet' onClick={()=>this.removeBet(add_bet_slip[bet])} /></Col>
                                                                }
                                                                {add_bet_slip[bet].type!=='playerprop' && event.type!=='specialevents' ? <Col xs={12} className="bs-type">{add_bet_slip[bet].type} - {add_bet_slip[bet].line_name}</Col> : "" }
                                                                <Col xs={8} className="bs-selection">
                                                                    {   
                                                                        add_bet_slip[bet].type==='spread' && event?.periods[add_bet_slip[bet].line]?.spreads!=='undefined' ?
                                                                        <div>
                                                                            {add_bet_slip[bet].team==='away' ? event.away : event.home} {this.sign(add_bet_slip[bet].team, add_bet_slip[bet].point)}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='moneyline' && event?.periods[add_bet_slip[bet].line]?.money_line!=='undefined' ?
                                                                        <div>
                                                                            {add_bet_slip[bet].team === 'home' ? event.home : ""} {add_bet_slip[bet].team === 'away' ? event.away : ""} {add_bet_slip[bet].team === 'draw' ? "Draw" : ""}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='totals' && event?.periods[add_bet_slip[bet].line]?.totals!=='undefined' ?
                                                                        <div>
                                                                            {add_bet_slip[bet].team} {add_bet_slip[bet].point}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='teamtotal' && event?.periods[add_bet_slip[bet].line]?.team_total!=='undefined' ?
                                                                        <>
                                                                            <div>
                                                                                {add_bet_slip[bet].point} {event.periods[add_bet_slip[bet].line].team_total[add_bet_slip[bet].team].points}
                                                                            </div>
                                                                            <div><small className='me-1'>{add_bet_slip[bet].team === 'home' ? event.home : ""} {add_bet_slip[bet].team === 'away' ? event.away : ""}</small></div>
                                                                        </>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='playerprop' ?
                                                                        <>
                                                                            <div  className='my-1'><small>{add_bet_slip[bet].event.name}</small></div>
                                                                            <div>{add_bet_slip[bet].data.name} {add_bet_slip[bet].data.handicap}</div>
                                                                        </>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='specialevents' ?
                                                                        <>
                                                                            <div  className='my-1'><small>{event.event.name}</small></div>
                                                                            <div>{event.team}</div>
                                                                        </>
                                                                        : 
                                                                        ""
                                                                    }
                                                                    {add_bet_slip[bet].type}
                                                                </Col>
                                                                <Col xs={4} className="text-end bs-odds">
                                                                    {   
                                                                        add_bet_slip[bet].type==='spread' && event?.periods[add_bet_slip[bet].line]?.spreads!=='undefined' ?
                                                                        <div>
                                                                            {this.format_odds(event.periods[add_bet_slip[bet].line].spreads[add_bet_slip[bet].point][add_bet_slip[bet].team])}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='moneyline' && event?.periods[add_bet_slip[bet].line]?.money_line!=='undefined' ?
                                                                        <div>
                                                                            {this.format_odds(event.periods[add_bet_slip[bet].line].money_line[add_bet_slip[bet].team])}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='totals' && event?.periods[add_bet_slip[bet].line]?.totals!=='undefined'?
                                                                        <div>
                                                                            {this.format_odds(event.periods[add_bet_slip[bet].line].totals[add_bet_slip[bet].point][add_bet_slip[bet].team])}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='teamtotal' && event?.periods[add_bet_slip[bet].line]?.team_total!=='undefined'?
                                                                        <div>
                                                                            {this.format_odds(event.periods[add_bet_slip[bet].line].team_total[add_bet_slip[bet].team][add_bet_slip[bet].point])}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='playerprop' ?
                                                                        <div>
                                                                            {this.format_odds(add_bet_slip[bet].data.price)}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }

                                                                    {   
                                                                        add_bet_slip[bet].type==='specialevents' ?
                                                                        <div>
                                                                            {this.format_odds(add_bet_slip[bet].point)}
                                                                        </div>
                                                                        : 
                                                                        ""
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            
                                                            {add_bet_slip[bet].type==='specialevents' || (((add_bet_slip[bet].type==='spread' && event?.periods[add_bet_slip[bet].line]?.spreads!=='undefined') || (add_bet_slip[bet].type==='moneyline' && event?.periods[add_bet_slip[bet].line]?.money_line!=='undefined') || (add_bet_slip[bet].type==='totals' && event?.periods[add_bet_slip[bet].line]?.totals!=='undefined') || (add_bet_slip[bet].type==='teamtotal' && event?.periods[add_bet_slip[bet].line]?.team_total!=='undefined') || (add_bet_slip[bet].type==='playerprop')) && (event?.periods?.num_0?.period_status===1 || event?.event?.status=='O')) ?
                                                            <Row className='g-1 bs-inputs'>
                                                                <Col xs={6}>
                                                                <input className="form-control form-control-sm" type="number" name={bet} placeholder="Stake" value={add_bet_slip[bet].stake} onChange={this.betSlipChange} disabled={this.state.freebet===1 ? true : false} />
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <input className="form-control form-control-sm" type="readonly" name={"win"+bet} placeholder="Win" value={add_bet_slip[bet].win} disabled />
                                                                </Col>
                                                            </Row> :  ""
                                                            }
                                                        </Container>
                                                    </div>

                                                    : ""
                                                )
                                            })

                                        }

                                    </Card.Body>
                                    {this.state.freebet_balance>0 && this.state.freebet_display===1 ?
                                    <Card.Footer className="text-center bg-s-secondary">
                                        <Row>
                                            {this.state.freebet_msg ? 
                                                <Col xs={12}>
                                                    <div className='alert alert-danger fb-alert'>{this.state.freebet_msg}</div>
                                                </Col>
                                            : ""
                                            }
                                            <Col xs={9} className='text-start'>
                                                <IoIosGift className='icon'/> Free bet : <b>${this.state.freebet_balance}</b>
                                            </Col>
                                            <Col xs={3} className='text-end'>
                                                <Form.Check 
                                                    type="switch"
                                                    id="fb-switch"
                                                    label=""
                                                    checked={this.state.freebet===1 ? true : false}
                                                    onClick={()=>this.updateFreeBet()}
                                                />
                                            </Col>
                                            
                                        </Row>
                                    </Card.Footer>
                                    : "" }
                                    
                                    <Card.Footer className="text-center bg-s-secondary">
                                        <div className='stake-status'>
                                            <p>Total Stake: <b>${this.state.si_stake}</b></p>
                                            <p>Total Win: <b>${this.state.si_win}</b></p>
                                        </div>
                                        {this.state.placingbet===0 ?
                                            <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.si_stake>0 ? false : true} onClick={()=>this.placeBet()}>Place Bets</Button>
                                            : <Button className='btn btn-warning w-100 btn-yellow'>Placing Bet...</Button>
                                        }

                                        <Button className='btn btn-light w-100 d-lg-none' onClick={()=>this.hideBetSlip()}>Close</Button>
                                    </Card.Footer>
                                    </>
                                    : 
                                        <>
                                            <Card.Body className='p-0'>
                                                {this.state.multiple===1 ? 
                                                    
                                                    Object.keys(add_bet_slip).map((bet, e) => {
                                                        //console.log(add_bet_slip[bet]);
                                                        const event = this.event_by_id(add_bet_slip[bet]);
                                                        //const pre_event = this.prev_event_by_id(add_bet_slip[bet].sport, add_bet_slip[bet].event_id);
                                                        return(
                                                            event ?
                                                            <div className='bet-slip-row' key={e}>
                                                                
                                                                <Container>
                                                                    <Row className='align-items-center'>
                                                                        <Col xs={12} className="bs-teamname">{event.away} vs {event.home} <IoMdClose className='closebet' onClick={()=>this.removeBet(add_bet_slip[bet])} /></Col>
                                                                        <Col xs={12} className="bs-type">{add_bet_slip[bet].type} - {add_bet_slip[bet].line_name}</Col>
                                                                        <Col xs={8} className="bs-selection">
                                                                            {   
                                                                                add_bet_slip[bet].type==='spread' && event?.periods[add_bet_slip[bet].line]?.spreads!=='undefined' ?
                                                                                <div>
                                                                                    {add_bet_slip[bet].team==='away' ? event.away : event.home} {this.sign(add_bet_slip[bet].team, add_bet_slip[bet].point)}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='moneyline' && event?.periods[add_bet_slip[bet].line]?.money_line!=='undefined' ?
                                                                                <div>
                                                                                    {add_bet_slip[bet].team === 'home' ? event.home : ""} {add_bet_slip[bet].team === 'away' ? event.away : ""} {add_bet_slip[bet].team === 'draw' ? "Draw" : ""}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='totals' && event?.periods[add_bet_slip[bet].line]?.totals!=='undefined' ?
                                                                                <div>
                                                                                    {add_bet_slip[bet].team} {add_bet_slip[bet].point}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='teamtotal' && event?.periods[add_bet_slip[bet].line]?.team_total!=='undefined' ?
                                                                                <>
                                                                                    <div>
                                                                                        {add_bet_slip[bet].point} {event.periods[add_bet_slip[bet].line].team_total[add_bet_slip[bet].team].points}
                                                                                    </div>
                                                                                    <div><small className='me-1'>{add_bet_slip[bet].team === 'home' ? event.home : ""} {add_bet_slip[bet].team === 'away' ? event.away : ""}</small></div>
                                                                                </>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='playerprop' ?
                                                                                <>
                                                                                    <div  className='my-1'><small>{add_bet_slip[bet].event.name}</small></div>
                                                                                    <div>{add_bet_slip[bet].data.name} {add_bet_slip[bet].data.handicap}</div>
                                                                                </>   
                                                                                :  ""
                                                                            }
                                                                        </Col>
                                                                        <Col xs={4} className="text-end bs-odds">
                                                                            {   
                                                                                add_bet_slip[bet].type==='spread' && event?.periods[add_bet_slip[bet].line]?.spreads!=='undefined' ?
                                                                                <div>
                                                                                    {this.format_odds(event.periods[add_bet_slip[bet].line].spreads[add_bet_slip[bet].point][add_bet_slip[bet].team])}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='moneyline' && event?.periods[add_bet_slip[bet].line]?.money_line!=='undefined' ?
                                                                                <div>
                                                                                    {this.format_odds(event.periods[add_bet_slip[bet].line].money_line[add_bet_slip[bet].team])}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='totals' && event?.periods[add_bet_slip[bet].line]?.totals!=='undefined'?
                                                                                <div>
                                                                                    {this.format_odds(event.periods[add_bet_slip[bet].line].totals[add_bet_slip[bet].point][add_bet_slip[bet].team])}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='teamtotal' && event?.periods[add_bet_slip[bet].line]?.team_total!=='undefined'?
                                                                                <div>
                                                                                    {this.format_odds(event.periods[add_bet_slip[bet].line].team_total[add_bet_slip[bet].team][add_bet_slip[bet].point])}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }

                                                                            {   
                                                                                add_bet_slip[bet].type==='playerprop' ?
                                                                                <div>
                                                                                    {this.format_odds(add_bet_slip[bet].data.price)}
                                                                                </div>
                                                                                : 
                                                                                ""
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div>
        
                                                            : ""
                                                        )
                                                    })
                                                
                                                
                                                : 
                                            
                                                <div className='no-bets'>
                                                    <IoIosDocument />
                                                    <p>No qualifying multiple bets.</p>
                                                    <p><small>Click the odds to add/remove a bet.</small></p>
                                                </div>
                                                }
                                            </Card.Body>
                                            <Card.Footer className="text-center bg-s-secondary">
                                                <Row className='g-1 bs-inputs'>
                                                    <Col xs={6} className="text-start">
                                                        1's X {Object.keys(this.state.add_bet_slip).length}
                                                    </Col>
                                                    <Col xs={6} className="text-end bs-odds">
                                                        <div>{this.format_odds_mu(this.state.multiple_odd)}</div>
                                                    </Col>
                                                </Row>
                                                <Row className='g-1 bs-inputs'>
                                                    <Col xs={6}>
                                                    <input className="form-control form-control-sm" type="number" name="mu_stake" placeholder="Stake" value={this.state.mu_stake} onChange={this.mu_betSlipChange} />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <input className="form-control form-control-sm" type="readonly" name="mu_win" placeholder="Win" value={this.state.mu_win} disabled />
                                                    </Col>
                                                </Row>
                                                {this.state.placingbet_mu===0 ?
                                                    <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.mu_stake>0 ? false : true} onClick={()=>this.muplaceBet()}>Place Bets</Button>
                                                    : <Button className='btn btn-warning w-100 btn-yellow'>Placing Bet...</Button>
                                                }

                                                <Button className='btn btn-light w-100 d-lg-none' onClick={()=>this.hideBetSlip()}>Close</Button>
                                            </Card.Footer>
                                        </>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            </>
        )
    }
}

export default withRouter(Sports);