import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { Container, Card, Row, Col, Form, InputGroup, Alert } from "react-bootstrap";

import CanvasWheel from "./CanvasWheel";
import './wheel.css';

const low = {
    list: [0, 1.1, 1.9],
    10: [
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0
    ],
    20: [
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0,
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0
    ],
    30: [
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0
    ],
    40: [
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0,
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0,
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0,
        1.9, 1.1, 1.1, 1.1, 0, 1.1, 1.1, 1.1, 1.1, 0
    ],
    50: [
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0,
        1.1, 1.1, 1.1, 1.1, 0, 1.9, 1.1, 1.1, 1.1, 0
    ]
}

const medium = {
    10: {
        list: [0, 1.5, 1.6, 2, 3],
        order: [
            0, 1.6, 0, 1.5, 0, 2, 0, 1.5, 0, 3
        ]
    },
    20: {
        list: [0, 1.2, 1.8, 2, 3],
        order: [
            1.2, 0, 2, 0, 2, 0, 2, 0, 1.2, 0,
            3, 0, 1.8, 0, 2, 0, 2, 0, 2, 0
        ]
    },
    30: {
        list: [0, 1.2, 1.6, 2, 3, 5],
        order: [
            1.2, 0, 1.2, 0, 2, 0, 1.2, 0, 2, 0,
            2, 0, 1.2, 0, 3, 0, 1.2, 0, 2, 0,
            2, 0, 1.6, 0, 5, 0, 1.2, 0, 2, 0
        ]
    },
    40: {
        list: [0, 1.2, 1.4, 2.2, 3],
        order: [
            2.2, 0, 3, 0, 2.2, 0, 1.2, 0, 3, 0,
            1.2, 0, 1.2, 0, 2.2, 0, 1.2, 0, 3, 0,
            1.2, 0, 2.2, 0, 2.2, 0, 1.4, 0, 2.2, 0,
            1.2, 0, 3, 0, 1.2, 0, 2.2, 0, 1.2, 0
        ]
    },
    50: {
        list: [0, 1.5, 1.8, 3, 5.1],
        order: [
            1.8, 0, 1.5, 0, 1.8, 0, 1.5, 0, 3, 0,
            1.5, 0, 1.5, 0, 1.8, 0, 1.5, 0, 3, 0,
            1.5, 0, 1.8, 0, 1.5, 0, 1.8, 0, 1.8, 0,
            1.5, 0, 3, 0, 1.5, 0, 1.8, 0, 1.5, 0,
            1.5, 0, 5.1, 0, 1.5, 0, 1.8, 0, 1.5, 0
        ]
    }
}

const high = {
    10: 9.6,
    20: 19.2,
    30: 28.8,
    40: 38.4,
    50: 48
}

class Wheel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login_status: 0,
            bet_amount: 0,
            risk: 'medium',
            segments: 10,
            results: [],
            error_code: 0,
            error_msg: '',
            multiplier_obtained: null,
            last_multiplier_obtained: 0,
            repeated: false,
            has_repeated: 0,
            balance_update: 0
        }
    }

    componentDidMount() {
        this.verify_token();
    }

    verify_token = () => {
        const token = localStorage.getItem("token");
        axios.get('/api/simpleauth', {
            headers: { token: token }
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/login");
                } else if (response.error_code === 0) {
                    this.setState({
                        user: response.data,
                        login_status: 1
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    localStorage.setItem('balance', response.data.balance);
                    window.dispatchEvent(new Event('storage'));

                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    placeBet = () => {
        let bet_data = {
            bet_amount: parseFloat(this.state.bet_amount),
            risk: this.state.risk,
            segments: this.state.segments
        }

        this.setState({
            loading: true
        })

        let token = localStorage.getItem('token');
        let id = this.state.user.id;

        axios.post('/api/casino/wheel-bet', {
            bet_data: bet_data,
            id: id
        }, {
            headers: { token: token }
        })
            .then(res => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/login");
                } else if (response.error_code === 0) {
                    let isWin = response.bet_data.multiplier > 1;
                    let result = {
                        win: isWin ? 'win' : '',
                        position: response.bet_data.position,
                        segment: response.bet_data.segment,
                        multiplier: response.bet_data.multiplier
                    }
                    this.setState({
                        error_code: 0,
                        error_msg: "",
                        bet_data: response.bet_data,
                        new_position: response.bet_data.position,
                        last_multiplier_obtained: response.bet_data.multiplier,
                        repeated: this.state.results[0]?.multiplier === result.multiplier,
                        results: [result, ...this.state.results],
                        balance_update: response.balance
                    })

                } else {
                    this.setState({
                        loading: false,
                        error_code: response.error_code,
                        error_msg: response.error_msg
                    })

                    setTimeout(() => {
                        this.setState({
                            error_code: 0,
                            error_msg: ''
                        })
                    }, 8000);
                }
            }).catch(err => {
                // console.log(err)
            })
    }

    handleChange = event => {
        let { name, value } = event.target;
        if (name === 'bet_amount') {
            this.setState({
                bet_amount: value
            })

            return;
        }
        this.setState({
            [name]: value
        })
    }

    handleAnimationEnd = (multiplier) => {
        this.setState({
            loading: false,
            multiplier_obtained: multiplier,
            has_repeated: this.state.repeated ? this.state.has_repeated + 1 : this.state.has_repeated
        })

        localStorage.setItem('balance', this.state.balance_update);
        window.dispatchEvent(new Event('storage'));
    }

    render() {
        if (this.state.login_status === 0) return (<></>);
        let bet_amount = parseFloat(this.state.bet_amount);
        return (
            <GameWrapper title={'WHEEL'} error_code={this.state.error_code} error_msg={this.state.error_msg}>
                <Col className="mb-10">
                    <div className='bg-s-dark'>
                        <Multipliers risk={this.state.risk} segments={this.state.segments} multiplier_obtained={this.state.multiplier_obtained} repeated={this.state.has_repeated}>
                            <CanvasWheel
                                segments={this.state.segments}
                                risk={this.state.risk}
                                multiplier_obtained={this.state.last_multiplier_obtained}
                                new_position={this.state.new_position}
                                updateState={this.handleAnimationEnd}
                                low={low}
                                medium={medium}
                            />
                        </Multipliers>
                    </div>
                </Col>
                <Col className="mines-config" md={4}>
                    <Form.Group className="mb-10">
                        <Form.Label className="text-start">Bet: </Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>$</InputGroup.Text>
                            <Form.Control
                                className="form-control text-white"
                                name="bet_amount"
                                type="number"
                                step="0.001"
                                value={bet_amount}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-10">
                        <Form.Label className="text-start" >Risk: </Form.Label>
                        <select value={this.state.risk} name={'risk'} onChange={this.handleChange} className='form-control text-white' disabled={false}>
                            <option value={'low'}>Low</option>
                            <option value={'medium'}>Medium</option>
                            <option value={'high'}>High</option>
                        </select>
                    </Form.Group>
                    <Form.Group className="mb-10">
                        <Form.Label className="text-start" >Segments: </Form.Label>
                        <select value={this.state.segments} name={'segments'} onChange={this.handleChange} className='form-control text-white' disabled={false}>
                            {new Array(5).fill(10).map((segments, index) => (
                                <option key={index} value={segments * index + segments}>{segments * index + segments}</option>
                            ))}
                        </select>
                    </Form.Group>
                    <button
                        disabled={this.state.loading}
                        onClick={() => this.placeBet()}
                        className='make-bet-button text-white d-block w-100 p-5 mb-10'
                    >
                        Bet
                    </button>
                </Col>
            </GameWrapper>
        );
    }
}

function GameWrapper({ title, error_code, error_msg, children }) {
    return (
        <div className="main-content wheel-game">
            <Container className="bet-lines">
                <Card className="bg-s-dark">
                    <Card.Header><h1>{title}</h1></Card.Header>
                    <Card.Body className="bg-s-secondary">
                        {error_code === 1 ? <Alert variant='success'>{error_msg}</Alert> : ''}
                        <Row>
                            {children}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

function MultiplierQueue({ multipliers }) {
    if (!multipliers) return;
    return (
        <>
            {multipliers?.map((mult) => <>{mult()}</>)}
        </>
    );
}

function Multipliers({ risk, segments, multiplier_obtained, repeated, children }) {

    const [multipliers_queue, setMultiliersQueue] = useState([]);

    let list;
    if (risk === 'low') {
        list = low.list;
    }
    if (risk === 'medium') {
        list = medium[segments].list;
    }
    if (risk === 'high') {
        list = [0, -1, -1, -1, high[segments]];
    }

    useEffect(() => {
        if (multiplier_obtained !== null && multiplier_obtained !== undefined) {
            let index = list?.findIndex((mult) => mult === multiplier_obtained);

            let mult = () => (
                <div>
                    {index > 0 ? new Array(index).fill(0).map(() => (
                        <div></div>
                    )) : ''}
                    <div className="bottom-list-multiplier active">
                        {multiplier_obtained?.toFixed(2)}
                    </div>
                </div>
            );

            setMultiliersQueue([mult, ...multipliers_queue.slice(0, 4)]);
        }
    }, [multiplier_obtained, repeated]);

    return (
        <>
            <div className="multipliers-queue">
                <MultiplierQueue multipliers={multipliers_queue} />
            </div>
            {children}
            <div className="multipliers-bottom-list">
                {list.map(multiplier => {
                    let class_name = "bottom-list-multiplier" + ((multiplier_obtained === multiplier) ? ' active' : '');
                    if (multiplier === -1) return <div></div>
                    return (
                        <div key={multiplier} className={class_name}>
                            {multiplier.toFixed(2)}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default withRouter(Wheel);
