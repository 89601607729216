import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { Button, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faChartPie, faStore, faSms, faCog, faPowerOff, faHandshake, faCreditCard, faCalendar, faHeadset, faBars, faUser, faChartLine, faList, faDollarSign, faCheckCircle, faPlus, faListAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from "../pages/LoadingScreen";


function hidenavbar() {
    document.body.classList.add('hidenav');
}

function shownavbar() {
    document.body.classList.remove('hidenav');
}

const Admin = ({ children }) => {
    const [login_status, setLoginStatus] = useState(0);
    const [user_type, setUserType] = useState(0);
    return (
        <div id="admin" >
            <>
                {login_status === 1 ?
                    <>
                        <Button variant="dark" onClick={shownavbar} size="sm" className="menu mr-5">
                            <FontAwesomeIcon icon={faBars} className="menuicon" />
                        </Button>
                        <div className="mobile-overlay" onClick={hidenavbar}></div>
                        <section id="sidebar">
                            <div className="navbar-wrapper">
                                <div className="brand">
                                    TUTORING 89 ADMIN
                                </div>
                                <div className="navbar">
                                    <ul>
                                        <li>
                                            <NavLink exact to="/admin" onClick={hidenavbar}><FontAwesomeIcon icon={faSlidersH} className="menuicon mr-8" />Dashboard</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/users" onClick={hidenavbar}><FontAwesomeIcon icon={faUser} className="menuicon mr-8" />Users</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/accounts" onClick={hidenavbar} ><FontAwesomeIcon icon={faListAlt} className="menuicon mr-8" />Accounting</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/grading" onClick={hidenavbar} ><FontAwesomeIcon icon={faCheckCircle} className="menuicon mr-8" />Grading</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/history" onClick={hidenavbar} ><FontAwesomeIcon icon={faDollarSign} className="menuicon mr-8" />Bet History</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/transactions" onClick={hidenavbar}><FontAwesomeIcon icon={faList} className="menuicon mr-8" />Transactions</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/balance-sheet" onClick={hidenavbar}><FontAwesomeIcon icon={faList} className="menuicon mr-8" />Balance Sheet</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/charge-history" onClick={hidenavbar}><FontAwesomeIcon icon={faList} className="menuicon mr-8" />Charge History</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/admin/custom-bets" onClick={hidenavbar}><FontAwesomeIcon icon={faList} className="menuicon mr-8" />Custom Bets</NavLink>
                                        </li> <li>
                                            <NavLink to="/admin/manual-bet" onClick={hidenavbar}><FontAwesomeIcon icon={faList} className="menuicon mr-8" />Manual Bet</NavLink>
                                        </li>
                                        <li>

                                            <Accordion defaultActiveKey={['0', '1']}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><FontAwesomeIcon icon={faUser} className="menuicon mr-8" />Users</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul>
                                                            { user_type === 1 ? <li><NavLink to="/admin/adminusers/2"> Managers </NavLink></li> : ""}
                                                            {(user_type === 2 || user_type === 1)
                                                                ? <li><NavLink to="/admin/adminusers/3"> Bookies </NavLink></li>
                                                                : ""
                                                            }
                                                            <li>
                                                                <NavLink to="/admin/users" onClick={hidenavbar}> Players </NavLink>
                                                            </li>

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                        </li>
                                        
                                        <li>

                                            <Accordion defaultActiveKey={['0', '1']}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><FontAwesomeIcon icon={faPlus} className="menuicon mr-8" />Add New</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul>
                                                            { user_type === 1 ? <li><NavLink to="/admin/users/add/2"> Manager</NavLink></li> : ""}
                                                            {(user_type === 2 || user_type === 1)
                                                                ? <li><NavLink to="/admin/users/add/3"> Bookie</NavLink></li>
                                                                : ""
                                                            }
                                                            <li><NavLink to="/admin/users/add/4"> User </NavLink></li>

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                        </li>
                                       

                                        <li className="divider"></li>
                                        <li className="title">Account</li>

                                        <li>
                                            <NavLink to="/admin/settings" onClick={hidenavbar}><FontAwesomeIcon icon={faCog} className="menuicon mr-8" /> Settings</NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/admin/logout" onClick={hidenavbar}><FontAwesomeIcon icon={faPowerOff} className="menuicon mr-8" /> Logout</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </> : <LoadingScreen />
                }

            </>

            <section id="content">
                {React.cloneElement(
                    children, { login_status: login_status, setLoginStatus: setLoginStatus, user_type: user_type, setUserType: setUserType }
                )}
            </section>
        </div>
    )
}

export default Admin;