import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Form, Button, Alert } from 'react-bootstrap';
var dateFormat = require("dateformat");


let source = axios.CancelToken.source();
class Users extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data: [{}],
        user: [],
        loading: false,
        name: "",
        email: "",
        contact: "",
        username: "",
        password: "",
        weekly_balance: "",
        manager_id: "",
        bookie_id: "",
        payment_type: 0,
        payment_value: 0,
        managers: [],
        bookies: [],
        login_status: 0
    };

    get_data = () => {
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");
        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/auth', {
            params: { id: id },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {

                    this.setState({
                        login_status: 1,
                        user: response.data,
                        loading: false,
                        managers: response.managers,
                        bookies: response.bookies
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.data.type)
                        this.props.setUserType(response.data.type);

                    const type = this.props.match.params.type;
                    if (response.data.user.type >= type) {
                        //unauthorise to add
                        this.props.history.push("/admin/users");
                    }
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }


    handleChange = (event) => {
        this.setState({ dataupdate: true });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'bookie_id') {
            const selectedIndex = event.target.selectedIndex;
            const manager_id = event.target.options[selectedIndex].getAttribute('mid');
            this.setState({
                manager_id: manager_id
            });
        }
        this.setState({
            [name]: value
        });

    }


    handleSave = (event) => {

        const type = this.props.match.params.type;
        event.preventDefault();
        this.setState({ loading: true });
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        axios.post('/api/admin/users/add', {
            id: id,
            token: token,
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            weekly_balance: this.state.weekly_balance,
            username: this.state.username,
            password: this.state.password,
            manager_id: this.state.manager_id,
            bookie_id: this.state.bookie_id,
            type: type,
            payment_type: this.state.payment_type,
            payment_value: this.state.payment_value
        })
            .then((res) => {
                const response = res.data;
                this.setState({
                    loading: false,
                    error_code: response.error_code,
                    error_msg: response.error_msg,
                    name: "",
                    email: "",
                    contact: "",
                    username: "",
                    password: "",
                    weekly_balance: ""
                });
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }



    UNSAFE_componentWillMount() {
        this.get_data();
    }

    render() {
        if (this.state.login_status === 0) return (<></>);

        const type = this.props.match.params.type;
        return (<>
            <Container fluid className='mb-20 mt-60'>
                <Row>
                    <Col className='billing'>
                        <Card className='bg-s-dark'>
                            <Card.Header>{type === '2' ? "Add Manager" : type === '3' ? "Add Bookie" : "Add User"}</Card.Header>
                            <Card.Body className='bg-s-secondary'>
                                {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
                                {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}

                                {type === '2' || type === '3' ?
                                    <Form onSubmit={this.handleSave}>
                                        {type === '3' && this.state.user.type === 1 ?

                                            <Form.Group className="mb-20">
                                                <Form.Label>Select Manager</Form.Label>
                                                <select className='form-control text-white mx-1 mb-5' name="manager_id" onChange={this.handleChange} defaultValue={this.state.manager_id} required>
                                                    <option value="">Select</option>
                                                    {
                                                        this.state.managers.map((data, e) => (
                                                            <option key={data.id} value={data.id}>{data.username}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Form.Group>

                                            : ""}


                                        <Form.Group className="mb-20">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" name="password" value={this.state.password} onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Name <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Email <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Contact <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="contact" value={this.state.contact} onChange={this.handleChange} />
                                        </Form.Group>

                                        {type === '3' ?
                                            <Form.Group className="mb-20">
                                                <Form.Label>Payment Type</Form.Label>
                                                <select
                                                    className='form-control text-white mx-1 mb-5'
                                                    name='payment_type'
                                                    onChange={this.handleChange}
                                                    required
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">percentage</option>
                                                    <option value="2">fixed amount</option>
                                                </select>
                                            </Form.Group> : ''
                                        }

                                        {this.state.payment_type ?
                                            <Form.Group className="mb-20">
                                                <Form.Label>{this.state.payment_type === "1" ? "%" : "$"}</Form.Label>
                                                <Form.Control type="text" name="payment_value" value={this.state.payment_value} onChange={this.handleChange} required />
                                            </Form.Group>
                                            : ""
                                        }

                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>

                                    : ""}


                                {type === '4' ?
                                    <Form onSubmit={this.handleSave}>

                                        {this.state.user.type <= 2 ?

                                            <Form.Group className="mb-20">
                                                <Form.Label>Select Bookie</Form.Label>
                                                <select className='form-control text-white mx-1 mb-5' name="bookie_id" onChange={this.handleChange} defaultValue={this.state.bookie_id} required>
                                                    <option value="">Select</option>
                                                    {
                                                        this.state.bookies.map((data, e) => (
                                                            <option key={data.id} value={data.id} mid={data.manager_id}>{data.manager_username}{" > "}{data.username}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Form.Group>

                                            : ""}

                                        <Form.Group className="mb-20">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" name="password" value={this.state.password} onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Weekly Balance</Form.Label>
                                            <Form.Control type="number" name="weekly_balance" value={this.state.weekly_balance} onChange={this.handleChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Name <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Email <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label>Contact <small>(Optional)</small></Form.Label>
                                            <Form.Control type="text" name="contact" value={this.state.contact} onChange={this.handleChange} />
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>

                                    : ""}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

export default withRouter(Users);