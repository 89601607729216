import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Button,Toast } from 'react-bootstrap';
import {cards} from  './cards'
import 'animate.css';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import { FaHandPaper,  } from 'react-icons/fa';
import { BsFileArrowDownFill,  } from 'react-icons/bs';

let source = axios.CancelToken.source();
class BlackJack extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        toastMsg:"",
        showToast:false,
        user:[],
        cards:cards,
        playercards:[],
        dealercards:[],
        placingbet:0,
        bet_stake:0,
        gamedata:{},
        player_score:0,
        dealer_score:0,
        winner:0,
        hit_button:true,
        stand_button:true,
        game_status:"BlackJack - Place your bet",
        animation:true,
        login_status: 0
    };

    PlayerHit = () => {
        const {cards, playercards} = this.state;
        const randomIndex = Math.floor(Math.random() * cards.length);
        playercards.push(cards[randomIndex]);
        cards.splice(randomIndex, 1);
        this.setState({
            playercards:playercards,
            cards:cards
        })
    }

    GetCard = (data) => {
        const {cards} = this.state;
        let card_id = data.cardID;
        return cards[card_id];
       
    } 


    verify_token = () => {
        const token = localStorage.getItem("token");
        axios.get('/api/casino/blackjack-auth', {
            headers:{token: token}
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){

                let playercards = [];
                for (let i = 0; i < response.gamedata?.player?.length; i++) {
                    playercards.push(this.GetCard(response.gamedata.player[i]));
                }

                //dealer cards
                let dealercards = [];
                for (let i = 0; i < response.gamedata?.dealer?.length; i++) {
                    dealercards.push(this.GetCard(response.gamedata.dealer[i]));
                }

                let placingbet = 0;
                let game_status = "BlackJack - Place your bet";
                let animation = true;
                let bet_stake = 0;
                if(response.gamedata?.dealer?.length) {
                    placingbet=1;
                    game_status="";
                    animation=false;
                    bet_stake=response.gamedata.stake;
                }

                const player_score = this.get_score(response.gamedata?.player_total, response.gamedata?.player_total2);
                const dealer_score = this.get_score(response.gamedata?.dealer_total, response.gamedata?.dealer_total2);

                this.setState({
                    user:response.data,
                    placingbet:placingbet,
                    playercards:playercards,
                    dealercards:dealercards,
                    gamedata:response.gamedata,
                    player_score:player_score,
                    dealer_score:dealer_score,
                    hit_button:false,
                    stand_button:false,
                    game_status:game_status,
                    animation:animation,
                    bet_stake:bet_stake,
                    login_status: 1
                });

                if(this.props.login_status !== 1)
                    this.props.setLoginStatus(1);

                localStorage.setItem('balance', response.data.balance);
                window.dispatchEvent(new Event('storage'));
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    betchange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]:value
        });
        
        
    }

    sleep = (ms) => {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
    }

    Bet = (event) => {
        //update immediate balance
        this.setState({
            placingbet:1,
            playercards:[],
            dealercards:[],
            player_score:0,
            dealer_score:0,
            winner:0,
            hit_button:true,
            stand_button:true,
            game_status:"",
            animation:true
        });

        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        const betdata = {
            stake:this.state.bet_stake
        }

        

        axios.post('/api/casino/blackjack-bet', {
            id:this.state.user.id,
            betdata:betdata
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
            
                

                //player cards
                let playercards = [];
                for (let i = 0; i < response.gamedata.player.length; i++) {
                    playercards.push(this.GetCard(response.gamedata.player[i]));
                }

                //dealer cards
                let dealercards = [];
                for (let i = 0; i < response.gamedata.dealer.length; i++) {
                    dealercards.push(this.GetCard(response.gamedata.dealer[i]));
                }

                this.setState({ 
                    placingbet:2,
                    playercards:playercards,
                    dealercards:dealercards,
                    gamedata:response.gamedata
                });

                let placingbet = 1;
                if(response.gamedata.winner!==0) {
                    placingbet = 0;
                }

                let game_status = "";
                if(response.gamedata.winner===1) {
                    game_status = "Player wins $"+response.gamedata.win
                }
                if(response.gamedata.winner===2) {
                    game_status = "Dealer wins"
                }

                setTimeout( () =>{
                    const player_score = this.get_score(this.state.gamedata.player_total, this.state.gamedata.player_total2);
                    const dealer_score = this.get_score(this.state.gamedata.dealer_total, this.state.gamedata.dealer_total2);
                    this.setState({ 
                        player_score:player_score,
                        dealer_score:dealer_score,
                        hit_button:false,
                        stand_button:false,
                        winner:response.gamedata.winner,
                        game_status:game_status,
                        placingbet:placingbet
                    });

                    localStorage.setItem('balance', response.balance);
                    window.dispatchEvent(new Event('storage'));
                }, 2000 );

                
                
            } else {
                this.setState({
                    placingbet:0,
                    toastMsg:response.error_msg,
                    showToast:true
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                toastMsg:e.message,
                showToast:true,
                game_status:"BlackJack - Place your bet"
            });
            //yconsole.log(e.message);
        });
    }


    Hit = (event) => {
        //update immediate balance
        this.setState({
            placingbet:1,
            hit_button:true
        });

        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        const betdata = {
            stake:this.state.bet_stake
        }
        axios.post('/api/casino/blackjack-hit', {
            id:this.state.user.id,
            betdata:betdata
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                
                //player cards
                let playercards = [];
                for (let i = 0; i < response.gamedata.player.length; i++) {
                    playercards.push(this.GetCard(response.gamedata.player[i]));
                }

                let dealercards = [];
                for (let i = 0; i < response.gamedata.dealer.length; i++) {
                    dealercards.push(this.GetCard(response.gamedata.dealer[i]));
                }

                const player_score = this.get_score(response.gamedata.player_total, response.gamedata.player_total2);

                let placingbet = 1;
                if(response.gamedata.winner!==0) {
                    placingbet = 0;
                }

                let game_status = "";
                if(response.gamedata.winner===1) {
                    game_status = "Player wins $"+response.gamedata.win;
                }
                if(response.gamedata.winner===2) {
                    game_status = "Dealer wins"
                }

                

                this.setState({ 
                    placingbet:placingbet,
                    gamedata:response.gamedata,
                    playercards:playercards,
                    dealercards:dealercards,
                    hit_button:false
                });

                
                

                setTimeout( () =>{
                    

                    this.setState({ 
                        player_score:player_score,
                        winner:response.gamedata.winner,
                        game_status:game_status
                    }, ()=>{
                        if(player_score===21) {
                            this.Stand();
                        }
                    });

                    localStorage.setItem('balance', response.balance);
                    window.dispatchEvent(new Event('storage'));
                }, 600 );
                
                
            } else {
                this.setState({
                    placingbet:0,
                    toastMsg:response.error_msg,
                    showToast:true
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                toastMsg:e.message,
                showToast:true
            });
            //yconsole.log(e.message);
        });
    }


    Stand = (event) => {
        //update immediate balance
        this.setState({
            placingbet:1,
            stand_button:true,
            hit_button:true
        });

        const token = localStorage.getItem("token");
        const ax_headers = {
            headers: {
                token: token
            }
        }
        //console.log(JSON.stringify(this.state.add_bet_slip));
        const betdata = {
            stake:this.state.bet_stake
        }
        axios.post('/api/casino/blackjack-stand', {
            id:this.state.user.id,
            betdata:betdata
        }, ax_headers).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
            
                //player cards
                let playercards = [];
                for (let i = 0; i < response.gamedata.player.length; i++) {
                    playercards.push(this.GetCard(response.gamedata.player[i]));
                }



                this.setState({ 
                    gamedata:response.gamedata,
                    playercards:playercards
                });


                let dealercards = [];
                let time = 0;
                for (let i = 0; i < response.gamedata.dealer.length; i++) {
                
                    setTimeout( () =>{
                        dealercards.push(this.GetCard(response.gamedata.dealer[i]));
                        this.setState({ 
                            dealercards:dealercards
                        });
                    }, time );

                    if(i>1) { time+=500; }
                }

                time+=500;

                const dealer_score = this.get_score(response.gamedata.dealer_total, response.gamedata.dealer_total2);
                let game_status = "";
                if(response.gamedata.winner===1) {
                    game_status = "Player wins $"+response.gamedata.win;
                }
                if(response.gamedata.winner===2) {
                    game_status = "Dealer wins"
                }

                setTimeout( () =>{
                    this.setState({ 
                        placingbet:0,
                        stand_button:false,
                        hit_button:false,
                        dealer_score:dealer_score,
                        winner:response.gamedata.winner,
                        game_status:game_status
                    });

                    localStorage.setItem('balance', response.balance);
                    window.dispatchEvent(new Event('storage'));
                }, time );
                
                
            } else {
                this.setState({
                    placingbet:0,
                    toastMsg:response.error_msg,
                    showToast:true
                });
            }
            //const response = res.data;
        })
        .catch((e) => {
            this.setState({
                placingbet:0,
                toastMsg:e.message,
                showToast:true
            });
            //yconsole.log(e.message);
        });
    }

    UNSAFE_componentWillMount(){
        this.verify_token();
    }

    componentDidMount(){
        
    }

    get_score = (t1, t2) => {
        if(t1 && t2) {
            if(t1===21 || t2 === 21){
                return 21;
            } else if(t2<t1 && t1>21) {
                return t2;
            } else if(t1<=21 && t2<=21 && t1!==t2) {
                return t1+", "+t2;
            } else {
                return t1;
            }
        } else {
            return 0;
        }
        
    }

    render () {
        if(this.state.login_status === 0) return (<></>);
        return(
            <>
                <Toast bg="dark" onClose={() => this.setState({ showToast:false, toastMsg:""})} show={this.state.showToast} delay={3000} autohide>
                    <Toast.Body>{this.state.toastMsg}</Toast.Body>
                </Toast>

                <div className='main-content'>
                    <div className='sport-betting-lines casino-crash'>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={this.state.winner===2 ? 'playercards winner' : this.state.winner===1 ? 'playercards loser' : 'playercards'}>
                                        <div className='handwrap'>
                                            {this.state.dealer_score!==0 ? <div className='player_total'>{this.state.dealer_score}</div> : "" }
                                            <div className='cardswrap'>
                                                    {this.state.dealercards.map((cards, e) => (
                                                        
                                                        <div key={e} className={this.state.animation===false ? cards.cardClass : e===0 ? cards.cardClass+" animate__animated animate__backInDown animate__fast animate_delay_500ms" : e===1 ? cards.cardClass+" animate__animated animate__flipInY animate__fast" : cards.cardClass+" animate__animated animate__backInDown animate__fast"}>
                                                            <span className='cardname'>{cards.cardName}</span>
                                                            <span className='cardtype'>{cards.cardType}</span>
                                                        </div>
                                                    ))}

                                                    {this.state.dealercards.length===1 ?
                                                        <div key={"db"} className={this.state.animation===false ? "cards card-blank" : "cards card-blank animate__animated animate__backInDown animate__fast animate_delay_1500ms"}>
                                                            <div className='cardname'><span className='cardlogo'>T</span></div>
                                                        </div>
                                                    : "" }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {this.state.game_status ? 
                            <Row>
                                <Col className='text-center'>
                                    <div className='game-status'>{this.state.game_status}</div>
                                </Col>
                            </Row>
                            : "" }
                            <Row>
                                <Col>
                                <div className={this.state.winner===1 ? 'playercards winner' : this.state.winner===2 ? 'playercards loser' : 'playercards'}>
                                        <div className='handwrap'>
                                            {this.state.player_score!==0 ? <div className='player_total'>{this.state.player_score}</div> : "" }
                                            <div className='cardswrap'>
                                                    {this.state.playercards.map((cards, e) => (
                                                        
                                                        <div key={e} className={this.state.animation===false ? cards.cardClass : e===1 ? cards.cardClass+" animate__animated animate__backInUp animate__fast animate_delay_1000ms" : cards.cardClass+" animate__animated animate__backInUp animate__fast"} >
                                                            <span className='cardname'>{cards.cardName}</span>
                                                            <span className='cardtype'>{cards.cardType}</span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='casino-bet-slip'>
                        <div className=''>
                            <Container fluid>
                                
                                <Row className='bs-inputs gx-2'>
                                    <Col xs={12} className='mb-10'>
                                        <label>Bet Amount</label>
                                        <input className="form-control form-control-sm" type="number" name="bet_stake" placeholder="Bet Amount" value={this.state.bet_stake} onChange={this.betchange}  disabled={this.state.placingbet===0 ? false : true}/>
                                    </Col>

                                    {this.state.placingbet === 0 ?
                                    <Col xs={12} className='mb-10'>
                                        <Button className='btn btn-warning w-100 btn-yellow' onClick={()=>this.Bet()}>Bet</Button>
                                    </Col>

                                    :
                                    <>
                                        <Col xs={6} className='mb-10'>
                                            <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.hit_button} onClick={()=>this.Hit()}><BsFileArrowDownFill/> Hit</Button>
                                        </Col>
                                        <Col xs={6} className='mb-10'>
                                            <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.stand_button}  onClick={()=>this.Stand()}><FaHandPaper/> Stand</Button>
                                        </Col>
                                    </>
                                    }
                                </Row>
                                
                            </Container>
                        </div>

                        <div className='casino-bet-button mb-20'>
                            <Container fluid>
                                <Row>
                                    {/*
                                    <Col>
                                        {this.state.placingbet===0 ?
                                        <Button className='btn btn-warning w-100 btn-yellow' disabled={this.state.bet_win>0 ? false : true} onClick={()=>this.placeBet()}>Bet</Button>
                                        : <Button className='btn btn-warning w-100 btn-yellow' disabled>{this.state.placingbet===1 ? "Placing Bet..." : "Bet Placed" }</Button>
                                        }
                                    </Col>
                                    */}
                                    
                                </Row>
                            </Container>
                        </div>

                        
                    </div>
                </div>
            </>
        )

    }

}

export default withRouter(BlackJack);