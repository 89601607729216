import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { MdEdit } from 'react-icons/md';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
    text: {
        primary: '#fff',
        secondary: '#aaa',
    },
    background: {
        default: 'transparent',
    },
    context: {
        background: '#091929',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333',
    },
    action: {
        button: 'rgba(255,255,255,.54)',
        hover: 'rgba(255,255,255,.08)',
        disabled: 'rgba(255,255,255,.12)',
    },
    sortFocus: {
        default: '#ffc107',
    },
}, 'dark');

const arraytorow = (data) => {
    //return data.map((row) => <p>{row}</p>);
    if (data) {
        return data.map((row) => <p>{row}</p>);
    } else {
        return "";
    }

}

let columns = [];

let source = axios.CancelToken.source();
class History extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
        this.handleCallback = this.handleCallback.bind(this);

        columns = [
            {
                name: 'Placed On',
                selector: 'created_on',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents">{"B" + row.id} <br /> {row.created_on ? dateFormat(row.created_on, "mm/dd/yyyy hh:MM TT") : ""} <br /> {row.fb === 1 ? <span className='badge bg-primary'>Free Bet</span> : ""}</div>,
            },
            {
                name: 'User Details',
                selector: 'username',
                sortable: false,
                left: true,
                width: "180px",
                cell: row => <div data-tag="allowRowEvents"><small className='d-block text-secondary'>{row.manager_username} &gt; {row.bookie_username} &gt;</small> {row.username}</div>,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: false,
                right: false,
                width: "100px",
                cell: row => <div data-tag="allowRowEvents">{row.type === 1 ? "Single" : row.type === 3 ? "Credit" : row.type === 9 ? "Casino" : "Multiple"}</div>,
            },
            {
                name: 'Bet Details',
                selector: 'bet',
                sortable: false,
                right: false,
                cell: row => <div data-tag="allowRowEvents">{arraytorow(row.bet)}</div>,
            },
            {
                name: 'Risk / Win',
                selector: 'stake',
                sortable: false,
                right: true,
                width: "200px",
                cell: row => <div data-tag="allowRowEvents">${row.stake} / ${row.win}</div>,
            },
            {
                name: 'Win',
                selector: 'pl',
                sortable: false,
                right: true,
                width: "100px",
                cell: row => <div data-tag="allowRowEvents">{row.status !== 'Open' && row.pl ? "$" + row.pl : ""}</div>,
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: false,
                right: true,
                width: "120px",
                cell: row => <div data-tag="allowRowEvents">{row.status} {this.state.user.type <= 2 && row.status !== 'Open' && row.type !== 3 ? <Button className='btn btn-xs btn-warning ml-5' onClick={() => this.showmdl("mdlEdit", row.id, row.status)}><MdEdit /></Button> : ""}</div>,
            }
        ];
    }


    state = {
        data: [{}],
        user: [],
        loading: false,
        start_date: "",
        end_date: "",
        week_range: { "this_week": [], "last_week": [], "2_weeks_ago": [], "3_weeks_ago": [], "4_weeks_ago": [], "5_weeks_ago": [] },
        manager_id: 0,
        bookie_id: 0,
        user_id: 0,
        managers: [],
        bookies: [],
        users: [],
        total_stake: 0,
        total_win: 0,
        total_bet: 0,
        page: 1,
        total: 0,
        bet_id: 0,
        bet_status: 0,
        history_type: 0
    };

    updateSel = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'manager_id' && value === '0') {
            this.setState({
                manager_id: 0,
                bookie_id: 0,
                user_id: 0
            }, function () { this.get_data() });
        } else if (name === 'bookie_id' && value === '0') {
            this.setState({
                bookie_id: 0,
                user_id: 0
            }, function () { this.get_data() });
        } else {
            this.setState({
                [name]: value
            }, function () { this.get_data() });
        }
    }



    get_data = () => {
        let start_date = "";
        let end_date = "";
        if (this.state.start_date) { start_date = moment(this.state.start_date).format("YYYY-MM-DD 09:00:00"); }
        if (this.state.end_date) { end_date = moment(this.state.end_date).format("YYYY-MM-DD 08:59:59"); }
        const token = localStorage.getItem("admin_token");
        if (!token) { this.props.history.push("/admin/login"); }
        const id = localStorage.getItem("admin_id");



        this.setState({ data: [{}], loading: true });
        axios.get('/api/admin/history', {
            params: { page: this.state.page, id: id, start_date: start_date, end_date: end_date, manager_id: this.state.manager_id, bookie_id: this.state.bookie_id, user_id: this.state.user_id, type: this.state.history_type },
            headers: { token: token },
            cancelToken: source.token
        })
            .then((res) => {
                const response = res.data;
                if (response.error_code === 401) {
                    this.props.history.push("/admin/login");
                } else if (response.error_code === 0) {

                    this.setState({
                        user: response.user,
                        data: response.data,
                        start_date: response.start_date,
                        end_date: response.end_date,
                        loading: false,
                        week_range: response.week_range,
                        managers: response.managers,
                        bookies: response.bookies,
                        users: response.users,
                        total_stake: response.total_stake,
                        total_win: response.total_win,
                        total_bet: response.total_bet,
                        total: response.total,
                    });

                    if (this.props.login_status !== 1)
                        this.props.setLoginStatus(1);

                    if (this.props.user_type !== response.user.type)
                        this.props.setUserType(response.user.type);
                }
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });
    }

    handleCallback(start, end, label) {
        this.setState({
            start_date: moment(start).format("MM/DD/YYYY"),
            end_date: moment(end).format("MM/DD/YYYY")
        }, function () { this.get_data() });
    }

    UNSAFE_componentWillMount() {
        let mid = 0;
        if (this.props.match.params.mid) { mid = this.props.match.params.mid; }

        let bid = 0;
        if (this.props.match.params.bid) { bid = this.props.match.params.bid; }

        let uid = 0;
        if (this.props.match.params.uid) { uid = this.props.match.params.uid; }

        this.props.history.replace({ pathname: `/admin/history` })

        this.setState({
            manager_id: mid,
            bookie_id: bid,
            user_id: uid
        }, function () { this.get_data() });

    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.get_data();
        }
    }

    showmdl = (name, id, st) => {
        let status = 0;
        if (st === 'Win') {
            status = '1'
        } else if (st === 'Loss') {
            status = '2'
        } else if (st === 'Push') {
            status = '3'
        }

        this.setState({
            [name]: true,
            mdl_error: false,
            mdl_error_msg: "",
            bet_id: id,
            bet_status: status
        })
    }

    hidemdl = (name) => {
        this.setState({
            [name]: false,
            mdl_error: false,
            mdl_error_msg: ""
        })
    }

    mdlEdit = (event) => {
        event.preventDefault();
        this.hidemdl("mdlEdit");
        const token = localStorage.getItem("admin_token");
        const id = localStorage.getItem("admin_id");
        let status = event.target.status.value;
        axios.get('/api/admin/history/edit', {
            params: { id: id, bid: this.state.bet_id, status: status },
            headers: { token: token },
            cancelToken: source.token
        }).then((res) => {
            this.get_data();
            this.setState({
                bet_id: 0,
                bet_status: 0
            });
        }).catch((e) => {
            //yconsole.log(e.message);
        });
    }

    render() {
        if (this.props.login_status === 0) return (<></>);

        const drp_setting = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            ranges: {
                'This Week': this.state.week_range.this_week,
                'Last Week': this.state.week_range.last_week,
                '2 Weeks Ago': this.state.week_range['2_weeks_ago'],
                '3 Weeks Ago': this.state.week_range['3_weeks_ago'],
                '4 Weeks Ago': this.state.week_range['4_weeks_ago'],
                '5 Weeks Ago': this.state.week_range['5_weeks_ago']
            }
        }

        return (
            <>
                <Container fluid className='mb-20 mt-20'>
                    <Row>
                        <Col className="text-end">
                            <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="history_type" onChange={this.updateSel} defaultValue={this.state.history_type}>
                                <option value="0" key='0'>All History</option>
                                <option value="1" key='1'>Sports</option>
                                <option value="2" key='2'>Casino</option>
                            </select>

                            {this.state.user.type === 1 ?
                                <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} defaultValue={this.state.manager_id}>
                                    <option value="0" key='0'>All Managers</option>
                                    {
                                        this.state.managers.map((data, e) => (
                                            <option key={data.id} value={data.id}>{data.username}</option>
                                        ))
                                    }
                                </select>
                                : ""}

                            {this.state.user.type === 1 || this.state.user.type === 2 ?
                                <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="bookie_id" onChange={this.updateSel} defaultValue={this.state.bookie_id}>
                                    <option value="0" key='0'>All Bookies</option>
                                    {
                                        this.state.bookies.map((data, e) => (
                                            <option key={data.id} value={data.id}>{data.username}</option>
                                        ))
                                    }
                                </select>
                                : ""}

                            {this.state.user.id ?
                                <select className='form-control d-inline w-auto text-white mx-1 mb-5' name="user_id" onChange={this.updateSel} defaultValue={this.state.user_id}>
                                    <option value="0" key='0'>All Users</option>
                                    {
                                        this.state.users.map((data, e) => (
                                            <option key={data.id} value={data.id}>{data.username}</option>
                                        ))
                                    }
                                </select>
                                : ""}


                            {this.state.start_date ?
                                <DateRangePicker initialSettings={drp_setting} onCallback={this.handleCallback} >
                                    <input type="text" className="form-control w-auto d-inline-block ml-1 mb-5" />
                                </DateRangePicker>
                                : ""
                            }
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='mb-20'>
                    <Row>
                        <Col className='billing'>
                            <Card body className='bg-s-secondary datatable'>
                                {this.state.loading ? <div className="loading">Loading...</div> :
                                    <>
                                        <DataTable
                                            title={"Bet History"}
                                            columns={columns}
                                            data={this.state.data}
                                            highlightOnHover={true}
                                            theme="dark_s"
                                            pagination={true}
                                            paginationServer={true}
                                            page={this.state.page}
                                            paginationTotalRows={this.state.total}
                                            paginationPerPage={100}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => this.setPage(page)}
                                            paginationDefaultPage={this.state.page}
                                        />
                                        <div className='text-center mt-10 border-top pt-15'>Total Bets: {this.state.total_bet}, Total Stake: ${this.state.total_stake}, Total Win / Loss: ${this.state.total_win}</div>
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <Modal show={this.state.mdlEdit} onHide={() => this.hidemdl("mdlEdit")} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.mdlEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit bet status</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.mdl_error === true ? <Alert variant="danger">{this.state.mdl_error_msg}</Alert> : ""}
                            <Form.Group className="mb-20">
                                <Form.Label>B{this.state.bet_id}</Form.Label>
                                <select className='form-control text-white' name="status" defaultValue={this.state.bet_status} required>
                                    <option value="1">Win</option>
                                    <option value="2">Loss</option>
                                    <option value="3">Push</option>
                                </select>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.hidemdl("mdlEdit")}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default withRouter(History);