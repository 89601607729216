import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
var dateFormat = require("dateformat");
var moment = require('moment-timezone');

createTheme('dark_s', {
  text: {
    primary: '#fff',
    secondary: '#aaa',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#091929',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.08)',
    disabled: 'rgba(255,255,255,.12)',
  },
  sortFocus: {
    default: '#ffc107',
  },
}, 'dark');


const columns = [
  {
    name: 'Date',
    selector: 'created_on',
    sortable: false,
    left: true,
    cell: row => (
      <div data-tag="allowRowEvents">
        {row.week_from ? dateFormat(row.week_from, "mm/dd/yyyy") : ""} / <br />
        {row.week_to ? dateFormat(row.week_to, "mm/dd/yyyy") : ""}
      </div>
    ),
  },
  {
    name: 'User Details',
    selector: 'username',
    sortable: false,
    left: true,
    style: { wordBreak: 'normal' },
    cell: row => <TableCell row={row} />
  },
  {
    name: 'Payment Type',
    selector: 'type',
    sortable: false,
    right: false,
    cell: row => (
      <div data-tag="allowRowEvents">
        {row.payment_type === 1 ? "percentage" : "pay per head"}
      </div>
    )
  },
  {
    name: 'Active Players',
    selector: 'type',
    sortable: false,
    right: false,
    cell: row => (
      <div data-tag="allowRowEvents">
        {row.active_players}
      </div>
    )
  },
  {
    name: 'Rate',
    selector: 'type',
    sortable: false,
    right: false,
    cell: row => (
      <div data-tag="allowRowEvents">
        {row.payment_value}
      </div>
    )
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: false,
    right: true,
    cell: row => <div data-tag="allowRowEvents">${row.amount.toFixed(2)}</div>,
  }
];

function TableCell({ row }) {
  return (
    <div data-tag="allowRowEvents">
      {row.manager_username ?
        <small className='d-block text-secondary'>
          {row.manager_username}
        </small> : ""}
      {row.username}
    </div>
  );
}

let source = axios.CancelToken.source();

class ChargeHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      manager_id: 0,
      bookie_id: 0,
      user_id: 0,
      user: [],
      managers: [],
      bookies: [],
      charges: [],
      filteredCharges: [],
      start_date: "",
      end_date: "",
      week_range: {
        "this_week": [],
        "last_week": [],
        "2_weeks_ago": [],
        "3_weeks_ago": [],
        "4_weeks_ago": [],
        "5_weeks_ago": []
      },
    }

    this.handleCallback = this.handleCallback.bind(this);
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    if (!token) { this.props.history.push("/admin/login"); }
    const id = localStorage.getItem("admin_id");

    this.setState({ data: [{}], loading: true });

    axios.get('/api/admin/charges', {
      params: {
        id: id,
        manager_id: this.state.manager_id,
        bookie_id: this.state.bookie_id,
        user_id: this.state.user_id,
        start_date: this.state.start_date,
        end_date: this.state.end_date
      },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {
          this.setState({
            user: response.user,
            charges: response.data,
            filteredCharges: response.data,
            start_date: response.start_date,
            end_date: response.end_date,
            week_range: response.week_range,
            loading: false,
            managers: response.managers,
            bookies: response.bookies,
          });
          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.user.type)
            this.props.setUserType(response.user.type);
        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  filterBy = (name, value) => {
    if (value === "0") return this.state.charges;

    let data = this.state.charges.slice();

    data = data.filter(
      (entry) => {
        return value === `${entry[name]}`;
      }
    );


    return data;
  }

  updateSel = (event) => {
    let { name, value } = event.target;

    if (name === "bookie_id") {
      this.setState({
        bookie_id: value,
        manager_id: '0',
        filteredCharges: this.filterBy(name, value)
      });
      return;
    }


    if (name === "manager_id") {
      this.setState({
        manager_id: value,
        bookie_id: 0,
        filteredCharges: this.filterBy(name, value)
      })
    }
    // this.setState({
    //   [name]: value,
    //   filteredCharges: this.filterBy(name, value)
    // });

  }

  handleCallback(start, end, label) {
    this.setState({
      start_date: moment(start).format("MM/DD/YYYY"),
      end_date: moment(end).format("MM/DD/YYYY"),
      filteredCharges: this.state.charges.filter((val) =>
        new Date(val.week_from) >= new Date(start) &&
        new Date(end) >= new Date(val.week_to)
      )
    });
  }

  UNSAFE_componentWillMount() {
    this.get_data();
  }

  render() {
    if (this.props.login_status === 0) return (<></>);

    const drp_setting = {
      startDate: this.state.start_date,
      endDate: this.state.end_date,
      ranges: {
        'All': [this.state.start_date, this.state.end_date],
        'This Week': [this.state.start_date, this.state.week_range.this_week?.[1]],
        'Last Week': [this.state.start_date, this.state.week_range.last_week?.[1]],
        '2 Weeks Ago': [this.state.start_date, this.state.week_range['2_weeks_ago']?.[1]],
        '3 Weeks Ago': [this.state.start_date, this.state.week_range['3_weeks_ago']?.[1]],
        '4 Weeks Ago': [this.state.start_date, this.state.week_range['4_weeks_ago']?.[1]],
        '5 Weeks Ago': [this.state.start_date, this.state.week_range['5_weeks_ago']?.[1]]
      }
    }

    return (
      <>
        <Container fluid className='mb-20 mt-20'>
          <Row>
            <Col className="text-end">
              {this.state.user.type === 1 ?
                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="manager_id" onChange={this.updateSel} value={this.state.manager_id} defaultValue={this.state.manager_id}>
                  <option value="0">All Managers</option>
                  {
                    this.state.managers.map((data, e) => (
                      <option key={data.id} value={data.id}>{data.username}</option>
                    ))
                  }
                </select>
                : ""}

              {this.state.user.type === 1 || this.state.user.type === 2 ?
                <select className='form-control d-inline w-auto  text-white mx-1 mb-5' name="bookie_id" onChange={this.updateSel} value={this.state.bookie_id} defaultValue={this.state.bookie_id}>
                  <option value="0">All Bookies</option>
                  {
                    this.state.bookies.map((data, e) => (
                      <option key={data.id} value={data.id}>{data.username}</option>
                    ))
                  }
                </select>
                : ""}

              {this.state.start_date ?
                <DateRangePicker initialSettings={drp_setting} onCallback={this.handleCallback} >
                  <input type="text" className="form-control w-auto d-inline-block ml-1 mb-5" />
                </DateRangePicker>
                : ""
              }

            </Col>
          </Row>
        </Container>
        <Container fluid className='mb-20'>
          <Row>
            <Col className='billing'>
              <Card body className='bg-s-secondary datatable'>
                {this.state.loading ? <div className="loading">Loading...</div> :
                  <>
                    <DataTable
                      title={"Charge History"}
                      columns={columns}
                      data={this.state.filteredCharges}
                      highlightOnHover={true}
                      theme="dark_s"
                      pagination={true}
                      paginationServer={true}
                      page={this.state.page}
                      paginationTotalRows={this.state.total}
                      paginationPerPage={100}
                      paginationComponentOptions={{
                        noRowsPerPage: true
                      }}
                      onChangePage={page => this.setPage(page)}
                      paginationDefaultPage={this.state.page}
                    />
                  </>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );

  }
}

export default withRouter(ChargeHistory);