import React, { useEffect, useState, useRef } from "react";
import useSound from "use-sound";
import spin_sound from './spin.wav';

function CanvasWheel({ segments, risk, multiplier_obtained, new_position = 0, updateState, low, medium }) {
  const [ wheel_position, setWheelPosition ] = useState(0);
  const [ multiplier, setMultiplier ] = useState(0);
  const canvasRef = useRef();

  const [ playSound ] = useSound(spin_sound);

  function animate(update) {
    let last_time = null;
    let animation_id = null;
    let status;

    function frame(time) {
      if (time - last_time >= 10) {
        if (!last_time) {
          status = update(100, status?.prev_position, status?.distance);
        } else {
          status = update((time - last_time), status?.prev_position, status?.distance);
        }
        last_time = time;
        if (status === 'end') {
          setMultiplier(multiplier_obtained)
          updateState(multiplier_obtained);
          cancelAnimationFrame(animation_id);
          return;
        }
      }
      animation_id = requestAnimationFrame(frame);
    }

    playSound();
    animation_id = requestAnimationFrame(frame);

  }

  function drawTopWheel(cx, pos, radius) {
    let vertices = [
      [pos[0] - radius, pos[1]],
      [pos[0] + radius, pos[1]],
      [pos[0], pos[1] + 2.5 * radius]
    ];

    cx.fillStyle = "#9A3B3B";
    cx.beginPath();
    cx.moveTo(vertices[0][0], vertices[0][1]);
    for (let [x, y] of vertices) {
      cx.lineTo(x, y)
    }
    cx.fill();

    cx.beginPath();
    cx.moveTo(pos[0], pos[1]);
    cx.arc(pos[0], pos[1], radius, 0, 2 * Math.PI);
    cx.fill();

    cx.fillStyle = "#040c14";
    cx.beginPath();
    cx.moveTo(pos[0], pos[1]);
    cx.arc(pos[0], pos[1], radius * 0.2, 0, 2 * Math.PI);
    cx.fill();
  }
  
  function drawSegment(cx, pos, radius, from, to, color) {
    cx.fillStyle = color;
    cx.beginPath()
    cx.moveTo(pos, pos)
    cx.arc(pos, pos, radius, from, to);
    cx.lineTo(pos, pos)
    cx.fill();
  }

  function drawCircle(cx, pos, radius, color){
    drawSegment(cx, pos, radius, 0, 2 * Math.PI, color);
  }

  function drawSegments(cx, segments, risk, pos, radius, shift = 0) {
    let segment_size = 2 * Math.PI / segments;
    let offset = - Math.PI / 2 - (wheel_position * Math.PI / 180);
    if (risk === 'high') {
      let segment = (segments / 2) - 1;
      let segment_start = ((segment * segment_size) + offset);
      let segment_end = segment_start + segment_size;

      drawSegment(cx, pos, radius, 0, 2 * Math.PI, '#051525');

      drawSegment(cx, pos, radius, segment_start + shift, segment_end + shift, 'rgba(199, 33, 33, 1)');
      return;
    }

    let { list, order } = risk === 'low'
      ? { list: low.list, order: low[segments] }
      : medium[segments];

    let colors = ['#051525', '#0D6EFD', '#198754', '#E5Af09', '#C72121', '#79155B']
    for (let i = 0; i < order.length; i++) {
      let multiplier = order[i];
      let index = list.findIndex(mult => mult === multiplier);
      let color = colors[index];

      let segment_start = (segment_size * i + offset);
      let segment_end = segment_start + segment_size;

      drawSegment(cx, pos, radius, segment_start + shift, segment_end + shift, color);
    }
  }

  function drawScene(cx, width, height, pos, radius, shift = 0){
    const wheel_color = "#0e1e2a";
    const mult_container_color = "#051525";

    drawCircle(cx, pos, radius, wheel_color);

    drawSegments(cx, segments, risk, width / 2, radius * 0.9, shift);

    drawCircle(cx, pos, radius * 0.4, wheel_color);
    drawCircle(cx, pos, radius * 0.3, mult_container_color);

    drawTopWheel(cx, [width / 2, height * 0.05], radius * 0.1);
  }

  useEffect(() => {
    let canvas = canvasRef.current;
    let size = Math.min(canvas.parentElement.clientWidth * 0.8, window.innerHeight * 0.6);
    canvas.width = size;
    canvas.height = canvas.width;
    let center = canvas.width / 2;
    let radius = center - 10;
    let cx = canvas.getContext('2d');

    const wheel_color = "#0e1e2a";
    const mult_container_color = "#051525";

    drawScene( cx, canvas.width, canvas.height, center, radius)

    if (new_position !== wheel_position) {
      setMultiplier(0);
      //                    to origin               to target  
      let total_distance = (360 - wheel_position) + new_position;
      if (total_distance < 360) total_distance += 360;
      let acceleration = 10;
      let velocity = (total_distance + (1.44 * acceleration / 2)) / 1.2;
      animate((step, prev_position, curr_distance) => {

        cx.clearRect(0, 0, canvas.width, canvas.height);

        

        let time = step / 1000;
        let dG = (velocity * time) - (acceleration * 0.5 * time * time);

        let curr_position;
        if (prev_position === undefined) {
          curr_position = (wheel_position + dG)
        } else {
          curr_position = (prev_position + dG);
        }

        let distance = !curr_distance ? dG : curr_distance + dG;

        if (distance >= total_distance) distance = total_distance;

        
        drawScene( cx, canvas.width, canvas.height, center, radius, -((distance % 360) * Math.PI / 180))
        if (total_distance <= distance) {
          setWheelPosition(new_position);
          return 'end';
        }

        return { curr_position, distance };
      })
    }

  }, [segments, risk, new_position])

  return (
    <div className="canvas-container">
      <canvas ref={canvasRef}></canvas>
      <div className="multiplier-obtained">{(multiplier || 0).toFixed(2)}x</div>
    </div>
  );
}

function useCanvas(setup, frame){

  const canvasRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    
  }, [setup, frame])

  return context
}

export default CanvasWheel;