import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import axios from 'axios';
let moment = require('moment');

let source = axios.CancelToken.source();
class AddCustomBet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_status: 0,
      id: 0,
      user: [],
      sport: "",
      league_name: "",
      starts_date: "",
      starts_time: "",
      home: "",
      away: "",

      bet_type: '1',

      add_moneyline: false,
      moneyline_home: "",
      moneyline_draw: "",
      moneyline_away: "",

      add_spreads: false,
      spreads_handicap: "",
      spreads_home: "",
      spreads_away: "",
      spreads_max: "",

      add_totals: false,
      totals_points: "",
      totals_over: "",
      totals_under: "",
      totals_max: "",

      add_team_totals: false,
      team_totals_home_points: "",
      team_totals_home_over: "",
      team_totals_home_under: "",

      team_totals_away_points: "",
      team_totals_away_over: "",
      team_totals_away_under: ""
    }

    this.sports = ["Basketball", "Football", "Soccer", "Baseball", "Hockey", "MMA"];


  }

  componentDidMount() {
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    this.setState({ data: [{}], loading: true });
    axios.get('/api/admin/auth', {
      params: { id: id },
      headers: { token: token },
      cancelToken: source.token
    })
      .then((res) => {
        const response = res.data;
        if (response.error_code === 401) {
          this.props.history.push("/admin/login");
        } else if (response.error_code === 0) {

          this.setState({
            login_status: 1,
            user: response.data,
            loading: false,
          });

          if (this.props.login_status !== 1)
            this.props.setLoginStatus(1);

          if (this.props.user_type !== response.data.type)
            this.props.setUserType(response.data.type);

        }
      })
      .catch((e) => {
        //yconsole.log(e.message);
      });
  }

  handleListBetSave = (event, data) => {
    event.preventDefault();
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const bet_type = this.state.bet_type;

    let date = moment(data.starts_date + " " + data.starts_time).format("YYYY-MM-DD HH:mm:ss");
    // date = (moment.tz(date, "America/Los_Angeles").utc().format()).replace("Z", "")
    axios.post('/api/admin/custom-bets/add-custom-bet',
      {
        id: id,
        token: token,
        bet_type: bet_type,
        event_data: {
          sport_title: data.sport_title,
          starts: date,
          outcomes: data.outcomes
        }
      }
    )
      .then(res => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
          name: "",
          email: "",
          contact: "",
          username: "",
          password: "",
          weekly_balance: ""
        });

      });
  }

  handleSave = (event) => {
    event.preventDefault();
    let state = this.state;
    const token = localStorage.getItem("admin_token");
    const id = localStorage.getItem("admin_id");
    const bet_type = this.state.bet_type;
    let { moneyline, spreads, totals, team_total } = this.getFormatedOdds();

    let date = moment(state.starts_date + " " + state.starts_time).format("YYYY-MM-DD HH:mm:ss")
    // date = (moment.tz(date, "America/Los_Angeles").utc().format()).replace("Z", "")
    axios.post('/api/admin/custom-bets/add-custom-bet',
      {
        id: id,
        token: token,
        bet_type: bet_type,
        event_data: {
          sport: state.sport,
          league_name: state.league_name,
          starts: date,
          home: state.home,
          away: state.away,

          money_line: moneyline,
          spreads: spreads,
          totals: totals,
          team_total: team_total
        }
      }
    )
      .then(res => {
        const response = res.data;
        this.setState({
          loading: false,
          error_code: response.error_code,
          error_msg: response.error_msg,
          name: "",
          email: "",
          contact: "",
          username: "",
          password: "",
          weekly_balance: ""
        });
      });
  }

  getFormatedOdds = () => {

    let { state } = this;
    let moneyline = null;
    let spreads = null;
    let totals = null;
    let team_total = null;

    if (state.add_moneyline) {
      let isAmerican = (Math.abs(state.moneyline_home) >= 100) || (Math.abs(state.moneyline_away) >= 100) || (Math.abs(state.moneyline_draw) >= 100);
      if (isAmerican) {
        moneyline = {
          home: this.getDecimalOddsdb(state.moneyline_home),
          draw: this.getDecimalOddsdb(state.moneyline_draw),
          away: this.getDecimalOddsdb(state.moneyline_away)
        }
        console.log("am: " + state.moneyline_away);
        console.log("dec: " + moneyline.away);
      } else {
        moneyline = {
          home: state.moneyline_home,
          draw: state.moneyline_draw,
          away: state.moneyline_away
        }
      }
    }
    if (state.add_spreads) {
      let isAmerican = (Math.abs(state.spreads_home) >= 100) || (Math.abs(state.spreads_away) >= 100)
      if (isAmerican) {
        spreads = {
          [state.spreads_handicap]: {
            hdp: state.spreads_handicap,
            home: this.getDecimalOddsdb(state.spreads_home),
            away: this.getDecimalOddsdb(state.spreads_away),
            max: state.spreads_max
          }
        }
      } else {
        spreads = {
          [state.spreads_handicap]: {
            hdp: state.spreads_handicap,
            home: state.spreads_home,
            away: state.spreads_away,
            max: state.spreads_max
          }
        }
      }
    }
    if (state.add_totals) {
      let isAmerican = (Math.abs(this.state.totals_over) >= 100) || (Math.abs(this.state.totals_under) >= 100);
      if (isAmerican) {
        totals = {
          [state.totals_points]: {
            points: state.totals_points,
            over: this.getDecimalOddsdb(state.totals_over),
            under: this.getDecimalOddsdb(state.totals_under),
            max: state.totals_max
          }
        }
      } else {
        totals = {
          [state.totals_points]: {
            points: state.totals_points,
            over: state.totals_over,
            under: state.totals_under,
            max: state.totals_max
          }
        }
      }
      
    }
    if (state.add_team_totals) {
      let isAmerican = (
        (Math.abs(state.team_totals_away_over ) >= 100) || (Math.abs(state.team_totals_away_under) >= 100) ||
        (Math.abs(state.team_totals_home_over ) >= 100) || (Math.abs(state.team_totals_home_under) >= 100)
      )
      if (isAmerican){
        team_total = {
          home: {
            points: state.team_totals_home_points,
            over: state.team_totals_home_over,
            under: state.team_totals_home_under
          },
          away: {
            points: state.team_totals_away_points,
            over: state.team_totals_away_over,
            under: state.team_totals_away_under
          }
        }
      } else {
        team_total = {
          home: {
            points: state.team_totals_home_points,
            over: state.team_totals_home_over,
            under: state.team_totals_home_under
          },
          away: {
            points: state.team_totals_away_points,
            over: state.team_totals_away_over,
            under: state.team_totals_away_under
          }
        }
      }
      
    }
    return {
      moneyline, spreads, totals, team_total
    };
  }
  getDecimalOdds = (odds) => {
    if (!odds) return 0;

    if (odds > 0) {
      return ((odds) / 100) + 1;
    } else {
      return 1 - (100 / (odds))
    }
  }
  getDecimalOddsdb = (odds) => {
    odds = parseFloat(odds);
    if (!odds) return 0;

    if (odds > 0) {
      return ((odds) / 100) + 1;
    } else {
      return 1 - (100 / (odds + 5))
    }
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    console.log(typeof value);
    this.setState({
      [name]: value
    });

  }

  getBetForm = (setControllers) => {
    if (this.state.bet_type === '1')
      return <></>;

    if (this.state.bet_type === '2')
      return <>
        <AddBetList
          submit={this.handleListBetSave}
          setControllers={setControllers}
        />
      </>;

    return <></>;
  }

  getControls = (controllers = []) => {
    return (
      <Controllers
        controls={
          controllers
        }
      />
    );
  }

  render() {

    if (this.props.login_status === 0) return (<></>);
    if (this.state.bet_type === '0' || this.state.bet_type === '2') {
      return (
        <Wrapper form_content={this.getBetForm} controllers={this.getControls}>

          {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
          {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}
          <Form.Group className='mb-20' >
            <Form.Label>Bet Type</Form.Label>
            <select className='form-control text-white' name='bet_type' onChange={this.handleChange} defaultValue={this.state.bet_type}>
              <option value={'1'}>{'normal bet'}</option>
              <option value={'2'}>{'list bet'}</option>
            </select>
          </Form.Group>

          {/* {this.getBetForm()} */}
        </Wrapper>
      );
    }
    return (
      <>
        <Container fluid className='mb-20 mt-60'>
          <Row>
            <Col md={9} className='billing'>
              <Card className='bg-s-dark'>
                <Card.Header>Add Custom Bet</Card.Header>
                <Card.Body className='bg-s-secondary'>
                  {this.state.error_code === -1 ? <Alert variant='success'>{this.state.error_msg}</Alert> : ""}
                  {this.state.error_code > 0 ? <Alert variant='danger'>{this.state.error_msg}</Alert> : ""}

                  <Form.Group className='mb-20' >
                    <Form.Label>Bet Type</Form.Label>
                    <select className='form-control text-white' name='bet_type' onChange={this.handleChange} defaultValue={this.state.bet_type}>
                      <option value={'1'}>{'normal bet'}</option>
                      <option value={'2'}>{'list bet'}</option>
                    </select>
                  </Form.Group>

                  <Form id='add-custom-bet-form' onSubmit={this.handleSave}>

                  <Form.Group className="mb-20">
                      <Form.Label>Sport</Form.Label>
                      <select className='form-control text-white' name='sport' onChange={this.handleChange} value={this.state.sport} defaultValue={'0'}>
                        <option value={'0'}>sport</option>
                        {this.sports.map(sport => (
                          <option key={sport} value={sport}>{sport}</option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>League</Form.Label>
                      <Form.Control type="text" name="league_name"
                        value={this.state.league_name}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Form.Label>Starts</Form.Label>
                    <Row>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='date' name="starts_date"
                          value={this.state.starts_date}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-20">
                        <Form.Control type='time' name="starts_time"
                          value={this.state.starts_time}
                          onChange={this.handleChange} required
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-20">
                      <Form.Label>Home</Form.Label>
                      <Form.Control type="text" name="home"
                        value={this.state.home}
                        onChange={this.handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="mb-20">
                      <Form.Label>Away</Form.Label>
                      <Form.Control type="text" name="away"
                        value={this.state.away}
                        onChange={this.handleChange} required
                      />
                    </Form.Group>

                    <Row>
                      {this.state.add_moneyline ?
                        <>
                          <Form.Label>Moneyline</Form.Label>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control type="text" name="moneyline_home" placeholder="home"
                                value={this.state.moneyline_home}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="moneyline_draw" placeholder="draw"
                                value={this.state.moneyline_draw}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="moneyline_away" placeholder="away"
                                value={this.state.moneyline_away}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }
                    </Row>

                    <Row>

                      {this.state.add_spreads ?
                        <>
                          <Form.Label>Spreads</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="spreads_handicap" placeholder="hdp"
                                value={this.state.spreads_handicap}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="spreads_home" placeholder="home"
                                value={this.state.spreads_home}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="spreads_away" placeholder="away"
                                value={this.state.spreads_away}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="spreads_max" placeholder="max"
                                value={this.state.spreads_max}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }

                    </Row>

                    <Row>

                      {this.state.add_totals ?
                        <>
                          <Form.Label>Totals</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="totals_points" placeholder="points"
                                value={this.state.totals_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="totals_over" placeholder="over"
                                value={this.state.totals_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="totals_under" placeholder="under"
                                value={this.state.totals_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="totals_max" placeholder="max"
                                value={this.state.totals_max}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }

                    </Row>
                    <Row>
                      {this.state.add_team_totals ?
                        <>
                          <Form.Label>Team Totals - Away</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_points" placeholder="points"
                                value={this.state.team_totals_away_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_over" placeholder="over"
                                value={this.state.team_totals_away_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_away_under" placeholder="under"
                                value={this.state.team_totals_away_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                          <Form.Label>Team Totals - Home</Form.Label>
                          <Row>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_points" placeholder="points"
                                value={this.state.team_totals_home_points}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_over" placeholder="over"
                                value={this.state.team_totals_home_over}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} >
                              <Form.Control type="text" name="team_totals_home_under" placeholder="under"
                                value={this.state.team_totals_home_under}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </> :
                        ""
                      }
                    </Row>


                    {/* <Button variant="primary" className="m-5" type="submit">
                      Submit
                    </Button> */}

                  </Form>
                </Card.Body>
              </Card>


            </Col>

            <Col md={3}>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_moneyline: true })}>Add Moneyline</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_spreads: true })}>Add Spreads</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_totals: true })}>Add Totals</Button>
              <Button className='d-block w-100 mb-10' onClick={() => this.setState({ add_team_totals: true })}>Add Team Totals</Button>
              <Button form='add-custom-bet-form' variant='success' className='d-block w-100 mb-10' type='submit'>Submit Bet</Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function Wrapper({ children, form_content, controllers }) {
  const [controls, setControllers] = useState();
  return (
    <Container fluid className='mb-20 mt-60'>
      <Row>
        <Col md={9} className='billing'>
          <Card className='bg-s-dark'>
            <Card.Header>Add Custom Bet</Card.Header>
            <Card.Body className='bg-s-secondary'>
              {children}
              {form_content(setControllers)}
            </Card.Body>
          </Card>
        </Col>
        {controllers(controls)}
      </Row>
    </Container>
  );
}

function AddNormalBet() {

}

function AddBetList(props) {
  const [sport_title, setSportTitle] = useState('');
  const [starts_date, setStartsDate] = useState('');
  const [starts_time, setStartsTime] = useState('');
  const [outcomes, setNewOutcome] = useState([
    { name: '', price: '' }
  ]);

  function updateField(setValue) {
    return event =>
      setValue(event.target.value);
  }

  const submit = (e) => {
    let data = {
      sport_title: sport_title,
      starts_date: starts_date,
      starts_time: starts_time,
      outcomes: outcomes
    }

    props.submit(e, data)
  }

  const addNewOutcomeField = () => {
    setNewOutcome(
      outcomes.concat({ name: '', price: '' })
    );

  }
  function removeOutcome(index) {
    let out = outcomes.slice(0, index).concat(outcomes.slice(index + 1));
    if (out.length === 0) return;
    setNewOutcome(out);
  }
  useEffect(() => {
    props.setControllers([
      { action_name: 'Add Outcome', dispatch: addNewOutcomeField },
      { action_name: 'submit', formId: 'add-custom-bet-form', dispatch: submit }
    ]);
  }, [outcomes]);


  return (
    <>
      <Form id={'add-custom-bet-form'} onSubmit={submit}>
        <Form.Group className="mb-20">
          <Form.Label>Sport Title</Form.Label>
          <Form.Control type="text" name="sport_title"
            value={sport_title}
            placeholder={'Title'}
            onChange={updateField(setSportTitle)}
            required
          />
        </Form.Group>

        <Form.Label>Starts</Form.Label>
        <Row>
          <Form.Group as={Col} className="mb-20">
            <Form.Control type='date' name="starts_date"
              value={starts_date}
              onChange={updateField(setStartsDate)} required
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-20">
            <Form.Control type='time' name="starts_time"
              value={starts_time}
              onChange={updateField(setStartsTime)} required
            />
          </Form.Group>
        </Row>

        <Form.Label>Winner</Form.Label>
        {outcomes.map((outcome, index) => (
          <OutcomeField
            outName={outcome.name}
            outPrice={outcome.price}
            key={index}
            update={(name, value) => outcomes[index][name] = value}
          >
            <Button variant="danger" onClick={() => removeOutcome(index)} >remove</Button>
          </OutcomeField>
        ))}
      </Form>
    </>
  );
}

function OutcomeField({ outName, outPrice, update, children }) {
  const [name, setName] = useState(outName);
  const [price, setPrice] = useState(outPrice);

  function updateOutcome(setValue) {
    return (event) => {
      let { name, value } = event.target;
      update(name, value);
      setValue(value);
    }
  }

  return (
    <Row>
      <InputGroup as={Col} className="mb-20">
        <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>
          Name:
        </InputGroup.Text>
        <Form.Control type='text' name="name"
          value={name}
          onChange={updateOutcome(setName)} required
        />
      </InputGroup>
      <InputGroup as={Col} className="mb-20">
        <InputGroup.Text className="input-text-addOn bg-s-primary text-white" id='inputgroup-size-sm'>
          Price:
        </InputGroup.Text>
        <Form.Control type='number' name="price" step={0.001}
          value={price}
          onChange={updateOutcome(setPrice)} required
        />
      </InputGroup>
      <Col md={"auto"} className="mb-10">
        {children}
      </Col>
    </Row>
  );
}

function Controllers({ controls }) {
  return (
    <Col md={3}>
      {controls.map(
        (control) => (
          control.action_name === 'submit' ?
            <Button form={control.formId} variant='success' className='d-block w-100 mb-10' type='submit'>
              Submit Bet
            </Button>
            :
            <Button className='d-block w-100 mb-10'
              onClick={control.dispatch}>
              {control.action_name}
            </Button>
        )
      )}

    </Col>
  );
}

export default withRouter(AddCustomBet);