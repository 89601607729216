import { GiSpades, GiHearts, GiDiamonds, GiClubs  } from 'react-icons/gi';

export const cards = {
    "AS": {
        cardID: "AS",
        cardClass: "cards card-black",
        cardName:"A",
        cardType:<GiSpades />,
        cardValue: 11
    },
    "AH": {
        cardID: "AH",
        cardClass: "cards card-red",
        cardName:"A",
        cardType:<GiHearts />,
        cardValue: 11
    },
    "AD": {
        cardID: "AD",
        cardClass: "cards card-red",
        cardName:"A",
        cardType:<GiDiamonds />,
        cardValue: 11
    },
    "AC": {
        cardID: "AC",
        cardClass: "cards card-black",
        cardName:"A",
        cardType:<GiClubs />,
        cardValue: 11
    },
    "2S": {
        cardID: "2S",
        cardClass: "cards card-black",
        cardName:"2",
        cardType:<GiSpades />,
        cardValue: 2
    },
    "2H": {
        cardID: "2H",
        cardClass: "cards card-red",
        cardName:"2",
        cardType:<GiHearts />,
        cardValue: 2
    },
    "2D": {
        cardID: "2D",
        cardClass: "cards card-red",
        cardName:"2",
        cardType:<GiDiamonds />,
        cardValue: 2
    },
    "2C": {
        cardID: "2C",
        cardClass: "cards card-black",
        cardName:"2",
        cardType:<GiClubs />,
        cardValue: 2
    },
    "3S": {
        cardID: "3S",
        cardClass: "cards card-black",
        cardName:"3",
        cardType:<GiSpades />,
        cardValue: 3
    },
    "3H": {
        cardID: "3H",
        cardClass: "cards card-red",
        cardName:"3",
        cardType:<GiHearts />,
        cardValue: 3
    },
    "3D": {
        cardID: "3D",
        cardClass: "cards card-red",
        cardName:"3",
        cardType:<GiDiamonds />,
        cardValue: 3
    },
    "3C": {
        cardID: "3C",
        cardClass: "cards card-black",
        cardName:"3",
        cardType:<GiClubs />,
        cardValue: 3
    },
    "4S": {
        cardID: "4S",
        cardClass: "cards card-black",
        cardName:"4",
        cardType:<GiSpades />,
        cardValue: 4
    },
    "4H": {
        cardID: "4H",
        cardClass: "cards card-red",
        cardName:"4",
        cardType:<GiHearts />,
        cardValue: 4
    },
    "4D": {
        cardID: "4D",
        cardClass: "cards card-red",
        cardName:"4",
        cardType:<GiDiamonds />,
        cardValue: 4
    },
    "4C": {
        cardID: "4C",
        cardClass: "cards card-black",
        cardName:"4",
        cardType:<GiClubs />,
        cardValue: 4
    },
    "5S": {
        cardID: "5S",
        cardClass: "cards card-black",
        cardName:"5",
        cardType:<GiSpades />,
        cardValue: 5
    },
    "5H":{
        cardID: "5H",
        cardClass: "cards card-red",
        cardName:"5",
        cardType:<GiHearts />,
        cardValue: 5
    },
    "5D":{
        cardID: "5D",
        cardClass: "cards card-red",
        cardName:"5",
        cardType:<GiDiamonds />,
        cardValue: 5
    },
    "5C":{
        cardID: "5C",
        cardClass: "cards card-black",
        cardName:"5",
        cardType:<GiClubs />,
        cardValue: 5
    },
    "6S":{
        cardID: "6S",
        cardClass: "cards card-black",
        cardName:"6",
        cardType:<GiSpades />,
        cardValue: 6
    },
    "6H":{
        cardID: "6H",
        cardClass: "cards card-red",
        cardName:"6",
        cardType:<GiHearts />,
        cardValue: 6
    },
    "6D":{
        cardID: "6D",
        cardClass: "cards card-red",
        cardName:"6",
        cardType:<GiDiamonds />,
        cardValue: 6
    },
    "6C":{
        cardID: "6C",
        cardClass: "cards card-black",
        cardName:"6",
        cardType:<GiClubs />,
        cardValue: 6
    },
    "7S":{
        cardID: "7S",
        cardClass: "cards card-black",
        cardName:"7",
        cardType:<GiSpades />,
        cardValue: 7
    },
    "7H":{
        cardID: "7H",
        cardClass: "cards card-red",
        cardName:"7",
        cardType:<GiHearts />,
        cardValue: 7
    },
    "7D":{
        cardID: "7D",
        cardClass: "cards card-red",
        cardName:"7",
        cardType:<GiDiamonds />,
        cardValue: 7
    },
    "7C":{
        cardID: "7C",
        cardClass: "cards card-black",
        cardName:"7",
        cardType:<GiClubs />,
        cardValue: 7
    },
    "8S":{
        cardID: "8S",
        cardClass: "cards card-black",
        cardName:"8",
        cardType:<GiSpades />,
        cardValue: 8
    },
    "8H":{
        cardID: "8H",
        cardClass: "cards card-red",
        cardName:"8",
        cardType:<GiHearts />,
        cardValue: 8
    },
    "8D":{
        cardID: "8D",
        cardClass: "cards card-red",
        cardName:"8",
        cardType:<GiDiamonds />,
        cardValue: 8
    },
    "8C":{
        cardID: "8C",
        cardClass: "cards card-black",
        cardName:"8",
        cardType:<GiClubs />,
        cardValue: 8
    },
    "9S":{
        cardID: "9S",
        cardClass: "cards card-black",
        cardName:"9",
        cardType:<GiSpades />,
        cardValue: 9
    },
    "9H":{
        cardID: "9H",
        cardClass: "cards card-red",
        cardName:"9",
        cardType:<GiHearts />,
        cardValue: 9
    },
    "9D":{
        cardID: "9D",
        cardClass: "cards card-red",
        cardName:"9",
        cardType:<GiDiamonds />,
        cardValue: 9
    },
    "9C":{
        cardID: "9C",
        cardClass: "cards card-black",
        cardName:"9",
        cardType:<GiClubs />,
        cardValue: 9
    },
    "10S":{
        cardID: "10S",
        cardClass: "cards card-black",
        cardName:"10",
        cardType:<GiSpades />,
        cardValue: 10
    },
    "10H":{
        cardID: "10H",
        cardClass: "cards card-red",
        cardName:"10",
        cardType:<GiHearts />,
        cardValue: 10
    },
    "10D":{
        cardID: "10D",
        cardClass: "cards card-red",
        cardName:"10",
        cardType:<GiDiamonds />,
        cardValue: 10
    },
    "10C":{
        cardID: "10C",
        cardClass: "cards card-black",
        cardName:"10",
        cardType:<GiClubs />,
        cardValue: 10
    },
    "JS":{
        cardID: "JS",
        cardClass: "cards card-black",
        cardName:"J",
        cardType:<GiSpades />,
        cardValue: 10
    },
    "JH":{
        cardID: "JH",
        cardClass: "cards card-red",
        cardName:"J",
        cardType:<GiHearts />,
        cardValue: 10
    },
    "JD":{
        cardID: "JD",
        cardClass: "cards card-red",
        cardName:"J",
        cardType:<GiDiamonds />,
        cardValue: 10
    },
    "JC":{
        cardID: "JC",
        cardClass: "cards card-black",
        cardName:"J",
        cardType:<GiClubs />,
        cardValue: 10
    },
    "QS":{
        cardID: "QS",
        cardClass: "cards card-black",
        cardName:"Q",
        cardType:<GiSpades />,
        cardValue: 10
    },
    "QH":{
        cardID: "QH",
        cardClass: "cards card-red",
        cardName:"Q",
        cardType:<GiHearts />,
        cardValue: 10
    },
    "QD":{
        cardID: "QD",
        cardClass: "cards card-red",
        cardName:"Q",
        cardType:<GiDiamonds />,
        cardValue: 10
    },
    "QC":{
        cardID: "QC",
        cardClass: "cards card-black",
        cardName:"Q",
        cardType:<GiClubs />,
        cardValue: 10
    },
    "KS":{
        cardID: "KS",
        cardClass: "cards card-black",
        cardName:"K",
        cardType:<GiSpades />,
        cardValue: 10
    },
    "KH":{
        cardID: "KH",
        cardClass: "cards card-red",
        cardName:"K",
        cardType:<GiHearts />,
        cardValue: 10
    },
    "KD":{
        cardID: "KD",
        cardClass: "cards card-red",
        cardName:"K",
        cardType:<GiDiamonds />,
        cardValue: 10
    },
    "KC": {
        cardID: "KC",
        cardClass: "cards card-black",
        cardName:"K",
        cardType:<GiClubs />,
        cardValue: 10
    }
};




  